import { combineReducers } from "redux";

import { style } from "./style";
import { rules } from "./rules";
import { events } from './events';
import { devices } from './devices';
import { vehicles } from './vehicles';
import { deviceLog } from './deviceLog';
import { geofences } from './geofences';
import { hierarchy } from './hierarchy';
import { statusBar } from "./statusBar";
import { mapControl } from './mapControl';
import { fuelRecord } from './fuelRecord';
import { tripRecord } from './tripRecord';
import { vehicleLog } from './vehicleLog';
import { ruleTemplate } from './ruleTemplate';
import { deviceGroups } from './deviceGroups';
import { vehicleGroups } from './vehicleGroups';
import { notifications } from './notifications';
import { transitRecord } from './transitRecord';
import { geofenceRecord } from './geofenceRecord';
import { geofenceTemplate } from './geofenceTemplate';
import { tripRecordControl } from './tripRecordControl';
import { notificationRT } from './notificationRT';
import { managementControl } from './managementControl';
import { user } from './user';
import { migration } from './migration';
import { escalations } from './escalations';
import { ticketStates } from './ticketStates'
import { address } from './address'
import { vehiclePackage } from './vehiclePackage'
import {vehiclePackageLog} from './vehiclePackageLog'

export const v2Reducer = combineReducers({
    style,
    rules,
    events,
    devices,
    vehicles,
    deviceLog,
    statusBar,
    geofences,
    hierarchy,
    mapControl,
    notificationRT,
    managementControl,
    fuelRecord,
    tripRecord,
    vehicleLog,
    ruleTemplate,
    deviceGroups,
    vehicleGroups,
    notifications,
    transitRecord,
    geofenceRecord,
    geofenceTemplate,
    tripRecordControl,
    user,
    migration,
    escalations,
    ticketStates,
    address,
    vehiclePackage,
    vehiclePackageLog,
})