import moment from 'moment';
import sanityCheckNumber from './SanityCheckNumber';

export const parseTime = time => {
    if (!time) return '-'
    return moment(Number(time)).format("YYYY/MM/DD HH:mm:ss");
}

export const parseDate = (millisec) => {
    if (millisec === null || isNaN(Number(millisec))) return '-';

    return moment(millisec).format('YYYY/MM/DD');
}

export const parseDuration = (millisec) => {
    if (!sanityCheckNumber(millisec)) { return "-" }

    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    // console.log("milisec", millisec)
    // console.log("minute", minutes);

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (Number.isNaN(millisec)) {
        return " Not Available "
    }
    else if (hours > 0) {
        return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
    }
    else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${seconds > 1 ? "secs" : "sec"}`.trim()
}

export const parseDuration2 = (millisec) => {
    if (!sanityCheckNumber(millisec)) { return "-" }

    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    // console.log("milisec", millisec)
    // console.log("minute", minutes);

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (Number.isNaN(millisec)) {
        return " Not Available "
    }
    else if (hours > 0) {
        return `${hours} ${hours > 1 ? "hours" : "hour"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
    }
    else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${seconds > 1 ? "seconds" : "second"}`.trim()
}

export const parseDurationByHours = (duration) => {
    const MS_IN_HOUR = 60 * 60 * 1000

    if (!duration) return '-'

    return `${duration / MS_IN_HOUR} hours`
};

export const parseDurationByDays = (duration) => {
    const MS_IN_DAY = 24 * 60 * 60 * 1000

    if (!duration) return '-'

    return `${Math.floor(duration / MS_IN_DAY)} days`
};

export const parseDayNight = time => {
    if (!time) return '-'

    const dayNight = moment(Number(time)).format("H");
    if (dayNight <= 19 && dayNight >=7 ){ 
        return "Day"
    } else {
        return "Night"
    }
}