import * as ActionTypes from "../action-types/style";

export const expandSidebar = () => {
    return {
        type: ActionTypes.EXPAND_SIDEBAR,
    };
};

export const originalSidebar = () => {
    return {
        type: ActionTypes.ORIGINAL_SIDEBAR,
    };
};

export const toggleSidebar = () => {
    return {
        type: ActionTypes.TOGGLE_SIDEBAR,
    };
};

export const closeSidebar = () => {
    return {
        type: ActionTypes.CLOSE_SIDEBAR,
    };
};

export const openSidebar = () => {
    return {
        type: ActionTypes.OPEN_SIDEBAR,
    };
};

export const changePageHeaderColor = color => {
    return {
        type: ActionTypes.CHANGE_PAGE_HEADER_COLOR,
        color,
    };
};

export const setLogo = url => {
    return {
        type: ActionTypes.SET_LOGO,
        url,
    };
};

export const setLoadingFleetRecord = () => {
    return {
        type: ActionTypes.SET_LOADING_FLEETRECORD
    }
}

export const unsetLoadingFleetRecord = () => {
    return{
        type: ActionTypes.UNSET_LOADING_FLEETRECORD
    }
}


export const setLoadingEvent = () => {
    return {
        type: ActionTypes.SET_LOADING_EVENT
    }
}

export const unsetLoadingEvent = () => {
    return{
        type: ActionTypes.UNSET_LOADING_EVENT
    }
}

export const setLoadingSubmit = () => {
    return {
        type: "SET_LOADING_SUBMIT"
    }
}

export const unsetLoadingSubmit = () => {
    return{
        type: ActionTypes.UNSET_LOADING_SUBMIT
    }
}

export const toggleUpdate = () => {
    return{
        type: "TOGGLE_UPDATE"
        
    }
}