import React, { Component } from "react";
import { connect } from "react-redux";

import { DeleteOutlined } from '@ant-design/icons';
import { NOTIFICATION_TYPE } from '../../../../../constants';
import {
  Col,
  Form,
  Input,
  message,
  Select,
  Checkbox,
} from "antd";

const FormItem = Form.Item;

const CALL_SUPPORTED_COUNTRY_CODES = {
  MALAYSIA: '+60'
}

class RecipientForm extends Component {
  state = {
    userPhonePrefix: CALL_SUPPORTED_COUNTRY_CODES.MALAYSIA
  }

  isPassEmailRegex = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  isPassPhoneRegex = phone => {
    const re = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/
    return re.test(phone)
  }

  render() {
    const returnNotificationContactField = () => {
      switch (this.props.type) {
        case (NOTIFICATION_TYPE.PUSH): {
          return (
            <Col span = {this.props.index > 0 ? 22 : 24}>
              <Select
                showSearch
                value = {this.props.recipient.uid}
                placeholder = "Select a user"
                optionFilterProp = "children"
                onChange = {uid => {
                  this.props.updateRecipientUid(uid, this.props.index)
                }}
                filterOption = {(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  Object.values(this.props.hierarchy.byId).map(user => {
                    return <Select.Option
                      key = {user.uid}
                    >
                      {`${user.userName} (${user.userRole})`}
                    </Select.Option>
                  })
                }
              </Select>
            </Col>
          );
        }
        case (NOTIFICATION_TYPE.EMAIL): {
          return (
            <div>
              <Col span = {12}>
                <Input
                  placeholder = {`Enter recipient's name here`}
                  value = {this.props.recipient.name}
                  onChange = {e => {
                    this.props.updateRecipientName(e.target.value, this.props.index);
                  }}
                />
              </Col>

              <Col span = {this.props.index > 0 ? 10 : 12}>
                <Input
                  placeholder = {`e.g. johnsmith@johnsmith.com`}
                  value = {this.props.recipient.email}
                  // onFocus = {e => e.target.select()}
                  onBlur = {e => {
                    const email = e.target.value;

                    if (!this.isPassEmailRegex(email)) {
                      message.error(`Invalid email format`)
                    }
                    else {
                      message.success(`Valid email`)
                    }
                  }}
                  onChange = {e => {
                    this.props.updateRecipientEmail(e.target.value, this.props.index);
                  }}
                />
              </Col>
            </div >
          );
        }
        case (NOTIFICATION_TYPE.SMS):
        case (NOTIFICATION_TYPE.CALL): {
          return (

            <div>
              {/* <Col span = {12}> */}
              <Col span = {24}>
                <Input
                  placeholder = {`Enter recipient's name here`}
                  value = {this.props.recipient.name}
                  onChange = {e => {
                    this.props.updateRecipientName(e.target.value, this.props.index);
                  }}
                />
              </Col>

              {/* <Col span = {this.props.index > 0 ? 10 : 12}> */}
              <Col span = {24}>
                <Input
                  addonBefore = {CALL_SUPPORTED_COUNTRY_CODES.MALAYSIA}
                  placeholder = {`e.g. 123456789`}
                  value = {this.props.recipient.contact && this.props.recipient.contact.replace(CALL_SUPPORTED_COUNTRY_CODES.MALAYSIA, '')}
                  onBlur = {e => {
                    // const phone = `${this.state.userPhonePrefix}${e.target.value}`;

                    // if (!this.isPassPhoneRegex(phone)) {
                    //   message.error(`Invalid phone format for ${phone}`);
                    // }
                    // else {
                    //   message.success(`Valid phone number ${phone}`);
                    // }
                  }}
                  onChange = {e => {
                    const newNumber = `${this.state.userPhonePrefix}${e.target.value}`;

                    this.props.updateRecipientContact(newNumber, this.props.index);
                  }}
                />
              </Col>

              {
                this.props.type === NOTIFICATION_TYPE.CALL ?
                  <Col 
                    // span = {12}
                    span = {24}
                    style = {{ marginTop: 5 }}
                  >
                    <Checkbox 
                      checked = {this.props.recipient.isKeypadAuthorized}
                      onChange = {(event) => {
                        this.props.updateRecipientAuth(event.target.checked, this.props.index);
                      }}
                    >
                      Is Keypad Authorized?
                    </Checkbox>
                  </Col> :
                  null
              }
            </div>
          );
        }
        default: {
          return null
        }
      }
    }

    // console.log("Type:", this.props.type);

    return (
      <FormItem
        label = {`Recipient ${this.props.index + 1}`}
        labelCol = {{ span: 7 }}
        wrapperCol = {{ span: 15 }}
      >
        <div
          style = {{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style = {{ flex: 9 }}>
            {
              Object.values(NOTIFICATION_TYPE).includes(this.props.type) ?
                returnNotificationContactField() :
                <Input disabled placeholder = {"Select a notification type first!"} />
            }
          </div>

          <div 
            style = {{ 
              flex: 1, 

              display: "flex",
              justifyContent: "center",
            }}
          >
            {
              this.props.index > 0 &&
                // <Col span = {2}>
                  <button className = "transparent-button">
                    <DeleteOutlined onClick = {() => this.props.deleteRecipient(this.props.index)}/>
                  </button>
                // </Col>
            }
          </div>
        </div>
      </FormItem>
    )
  }
}

const mapStateToProps = (state) => ({
  state,
  hierarchy: state.v2.hierarchy,
  geofences: state.v2.geofences,
  ruleTemplate: state.v2.ruleTemplate,
  notifications: state.v2.notifications,
  geofenceTemplate: state.v2.geofenceTemplate,
});

export default connect(mapStateToProps)(RecipientForm);
