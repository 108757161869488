import React from "react";
import { connect } from "react-redux";

import Page from "../../components/Page";
import VehicleListingTab from "./VehicleListingTab";
import GeofenceSummaryTab from "./GeofenceSummaryTab";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { Tabs } from "antd";

// Redux Actions
import { get_ticket_states } from "../../services/redux/actions/ticketStates";

class InformationTablePage extends React.Component {
    getInitialData = () => {
        this.props.dispatch(get_ticket_states(Object.keys(this.props.vehicles.byId)));
    }

    componentDidMount = () => {
        this.getInitialData();
    }

    componentDidUpdate = (prevProps) => {
        const { vehicles } = this.props;

        // Check if there are new vehicles
        if (
            Object.keys(prevProps.vehicles.byId).sort((a, b) => a - b).join() !== Object.keys(vehicles.byId).sort((a, b) => a - b).join() 
            && Object.keys(vehicles.byId).length
        ) {
            // console.log("Test");

            this.getInitialData();
        }
    }

    render() {
        return(
            <div className = "page-container">
                <Page title = "Information Table">
                    <Tabs>
                        <Tabs.TabPane tab = 'Vehicle Listing' key = {'1'}>
                            <VehicleListingTab/>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab = 'Geofence Summary' key = {'2'}>
                            <GeofenceSummaryTab/>
                        </Tabs.TabPane>
                    </Tabs>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    vehicles: state.v2.vehicles,
});

const ConnectedVehicleDisplayPage = connect(mapStateToProps)(InformationTablePage);
export default showSecondarySidebar(false)(ConnectedVehicleDisplayPage);