import * as actionType from "../action-types/escalations";

const defaultState = {
    states: {
        byECID: {},
    },
    records: {
        byECID: {},
        byECRID: {},
    }
};

export const escalations = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case actionType.GET_ESCALATION_STATES: {
            action.newStates.forEach((currState) => {
                newState.states.byECID[currState.ecid] = currState;
            })

            return newState;
        }

        case actionType.UPDATE_ESCALATION_STATES: {
            newState.states.byECID[action.editedState.ecid] = action.editedState;

            return newState;
        }

        case actionType.GET_ESCALATION_RECORDS: {
            action.newRecords
            .sort((a, b) => { // Sort so future data is automatically sorted from latest to oldest
                return b.createdAt - a.createdAt;
            })
            .forEach((currRecord) => {
                newState.records.byECRID[currRecord.ecrid] = currRecord;

                if (!newState.records.byECID[currRecord.ecid]) {
                    newState.records.byECID[currRecord.ecid] = [];
                }

                newState.records.byECID[currRecord.ecid].push(currRecord.ecrid);
            })

            return newState;
        }

        default: {
            return state;
        }
    }
}