import {
    GROUP
} from '../../constants'

const {
    DEFAULT_GEOFENCE_HEXCODE
} = GROUP

export const returnLatestGeofenceTemplateColorForGeoID = (geoID, geofenceTemplates) => {

    let latestTemplate = {};

    geofenceTemplates
    &&
    Object.keys(geofenceTemplates)
    &&
    Object.keys(geofenceTemplates).length
    &&
    Object.values(geofenceTemplates)
        .filter((currTemplate) => currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
        .forEach((currTemplate) => {
            if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                latestTemplate = currTemplate;
            }
        })

    return (latestTemplate && latestTemplate.colorHexCode) || DEFAULT_GEOFENCE_HEXCODE;
}