import React, { Component } from "react";
import { connect } from "react-redux";

import ConditionForm from "./ConditionForm";
import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    RULE_TYPE,
    DISPLAY_RULE_TYPE
} from "../../../../../constants/index";
import {
    Form,
    Input,
    Select,
    Button,
    message,
} from "antd";

// Redux Actions
import { edit_rule } from "../../../services/redux/actions/rules";

const FormItem = Form.Item;

class EditRule extends Component {
    state = {
        selectedRTID: "",

        ruleName: "",
        ruleType: "",
        ruleCompliedName: "",
        ruleViolatedName: "",

        conditions: []
    }

    submitForm = () => {
        this.setState({ isLoading: true })
        const {
            selectedRTID,
            ruleName,
            ruleType,
            ruleCompliedName,
            ruleViolatedName,
            conditions,
        } = this.state;

        let isValid = true;

        if (!this.props.user.uid) {
            message.error("Current User ID Not Found");

            isValid = false;
        }

        if (!ruleName) {
            message.error("Please Enter The Rule's Name");

            isValid = false;
        }

        if (ruleType === undefined) {
            message.error("Please Select The Rule's Type");

            isValid = false;
        }

        if (!selectedRTID) {
            message.error("Please Select A Rule Template");

            isValid = false;
        }

        if (conditions.length < 1) {
            message.error("Please Have At Least 1 Condition");

            isValid = false;
        }

        if (conditions.filter((currCond) => (!currCond.parameter || !currCond.operator || !currCond.value) && currCond).length > 0) {
            message.error(
                conditions.filter((currCond) => (!currCond.parameter || !currCond.operator || !currCond.value) && currCond).length > 1 ?
                    `${conditions.filter((currCond) => (!currCond.parameter || !currCond.operator || !currCond.value) && currCond).length} Conditions Are Not Complete` :
                    `${conditions.filter((currCond) => (!currCond.parameter || !currCond.operator || !currCond.value) && currCond).length} Condition Is Not Complete`
            );

            isValid = false;
        }

        if (isValid) {
            const editedRule = {
                ...this.props.rules.byRuID[this.state.ruid],
                ruleName: ruleName,
                ruleType: ruleType,
                ruleCompliedName: ruleCompliedName ? ruleCompliedName : `Rule "${ruleName}" Complied`,
                ruleViolatedName: ruleViolatedName ? ruleViolatedName : `Rule "${ruleName}" Violated`,

                conditions: conditions,
            }

            // console.log(
            //     `Editing Rule:
            //     - Edited Rule: ${JSON.stringify(editedRule)}`
            // );

            this.props.dispatch(edit_rule(editedRule));
        }
    }

    addNewCondition = () => {
        const newConditionList = [
            ...this.state.conditions,
            {
                parameter: "",
                operator: "",
                value: ""
            }
        ];

        this.setState({
            ...this.state,
            conditions: newConditionList
        });
    }

    deleteCondition = (index) => {
        const newConditionList = [...this.state.conditions];

        newConditionList.splice(index, 1); // Delete specific element

        this.setState({
            ...this.state,
            conditions: newConditionList
        });
    }

    resetCondition = () => {
        this.setState({
            ...this.state,
            conditions: [...this.props.rules.byRuID[this.state.ruid].conditions]
        })
    }

    updateCondition = (updatedCondition, index) => {
        // console.log("Updated Condition:", updatedCondition);
        // console.log("Index:", index);

        const newConditionList = [...this.state.conditions];

        newConditionList[index] = updatedCondition;

        this.setState({
            ...this.state,
            conditions: newConditionList
        })
    }

    setUpState = () => {
        const ruid = `ru${this.props.router.location.hash}`;
        const currRule = this.props.rules.byRuID[ruid];

        this.setState({
            selectedRTID: Object.keys(this.props.ruleTemplate.byId).find(currRTID => this.props.ruleTemplate.byId[currRTID].rules.includes(ruid)),

            ruid: ruid,
            ruleName: currRule.ruleName,
            ruleType: currRule.ruleType,
            triggerType: currRule.triggerType,
            templateType: currRule.templateType,
            ruleCompliedName: currRule.ruleCompliedName,
            ruleViolatedName: currRule.ruleViolatedName,
            conditions: currRule.conditions,
            // conditions: currRule.conditions.map((currCond) => {
            //     // (currCond.parameter === "idlingTime" || currCond.parameter === "parkingTime") && console.log((JSON.stringify(currCond)));

            //     return ((currCond.parameter === "idlingTime" || currCond.parameter === "parkingTime") ? 
            //         {
            //             ...currCond,
            //             // value: (currCond.value / 1000 / 60).toString()
            //             value: currCond.value
            //         } :
            //         currCond
            //     )
            // }),
        },
            // () => console.log("State:", this.state)
        );
    }

    componentDidMount = () => {
        if (this.props.rules.byRuID[`ru${this.props.router.location.hash}`] && Object.keys(this.props.ruleTemplate.byId).length > 0) {
            this.setUpState();
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.rules !== this.props.rules || prevProps.ruleTemplate !== this.props.ruleTemplate)
            && this.props.rules.byRuID[`ru${this.props.router.location.hash}`] && Object.keys(this.props.ruleTemplate.byId).length > 0) {
            this.setUpState();
        }
    }

    render() {
        const {
            conditions,
            selectedRTID
        } = this.state;

        return (
            <div className = "page-container">
                <Page title = "Edit Rule">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Rule Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Rule Name"
                                    value = {this.state.ruleName}
                                    onChange = {e =>
                                        this.setState({
                                            ...this.state,
                                            ruleName: e.target.value
                                        })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label = "Rule Type"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Select
                                    placeholder = "Select a rule type"
                                    value = {this.state.ruleType}
                                    onChange = {value =>
                                        this.setState({
                                            ...this.state,
                                            ruleType: value
                                        },
                                            // () => console.log("Selected Rule Type:", this.state.ruleType)
                                        )
                                    }
                                >
                                    {
                                        Object.keys(RULE_TYPE).map(currType => {
                                            return (
                                                <Select.Option key = {RULE_TYPE[currType]} value = {RULE_TYPE[currType]}>
                                                    {DISPLAY_RULE_TYPE[RULE_TYPE[currType]]}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label = "Rule Template"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Select
                                    placeholder = "Select a rule template"
                                    onChange = {rtid =>
                                        this.setState({
                                            ...this.state,
                                            selectedRTID: rtid
                                        },
                                            () => {
                                                this.props.ruleTemplate.byId[this.state.selectedRTID].templateName === "DEFAULT" &&
                                                    this.setState({
                                                        ...this.state,
                                                        conditions: conditions.length > 1 ? [conditions[0]] : conditions
                                                    })
                                            }
                                        )
                                    }
                                >
                                    {
                                        Object.values(this.props.ruleTemplate.byId).map(ruleTemplate => {
                                            const disabled = ruleTemplate.templateName === 'DEFAULT'

                                            return (
                                                <Select.Option
                                                    key = {ruleTemplate.rtid}
                                                    disabled = {disabled}
                                                    value = {ruleTemplate.rtid}
                                                >
                                                    {ruleTemplate.templateName}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label = "Rule Complied Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Rule Complied Name (Optional)"
                                    value = {this.state.ruleCompliedName}
                                    onChange = {e =>
                                        this.setState({
                                            ruleCompliedName: e.target.value
                                        })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label = "Rule Violated Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Rule Violated Name (Optional)"
                                    value = {this.state.ruleViolatedName}
                                    onChange = {e =>
                                        this.setState({
                                            ruleViolatedName: e.target.value
                                        })
                                    }
                                />
                            </FormItem>

                            {
                                this.state.conditions.map((condition, index) => {
                                    // console.log("Current Condition:", condition);

                                    return (
                                        <ConditionForm
                                            key = {index}
                                            index = {index}
                                            rtid = {selectedRTID}
                                            condition = {condition}
                                            deleteCondition = {(index) => this.deleteCondition(index)}
                                            updateCondition = {(condition) => this.updateCondition(condition, index)}
                                        />
                                    );
                                })
                            }

                            {
                                (!selectedRTID || (selectedRTID && this.props.ruleTemplate.byId[selectedRTID].templateName !== "DEFAULT")) &&
                                <div
                                    style = {{
                                        display: "flex",
                                        flex: 1,

                                        justifyContent: "center",

                                        marginBottom: 25
                                    }}
                                >
                                    <PrimaryButton onClick = {this.addNewCondition}>{"Add Condition"}</PrimaryButton>

                                    <PrimaryButton onClick = {this.resetCondition}>{"Reset Condition"}</PrimaryButton>
                                </div>
                            }

                            <div
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    onClick = {this.submitForm}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    user: state.v2.user,
    router: state.router,
    rules: state.v2.rules,
    ruleTemplate: state.v2.ruleTemplate,
});

const ConnectedEditRule = connect(mapStateToProps)(EditRule);
export default showSecondarySidebar(false)(ConnectedEditRule);