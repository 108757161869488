import 
    React,
    {
        useState,
        useRef,
        useEffect
    }
from "react";
import { 
    useDispatch, 
    useSelector 
} from 'react-redux';
import { 
    Button,
    Switch,
    Tag
} from "antd";
import {
    LeftOutlined
} from '@ant-design/icons'

import {
    moveToDash,
} from "../../navigation/navigationService";
import { 
    signOutAccount,  
} from "../../services/auth/authService";
import {
    user_sign_out,
    user_profile_preference_update_request
} from "../../services/redux/actions/user";
import {
    IMAGE_RESOURCE,
    COMPANY,
    DISPLAY_USER_ROLE
} from "../../../../constants";

const ProfilePage = (props) => {

    const dispatch = useDispatch()

    const user = useSelector(state => state.v2.user)
    const style = useSelector(state => state.v2.style)

    const [userPreference, setUserPreference] = useState(user.preference)

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const prevUserPreference = usePrevious(user.preference);

    const userPreferenceUpdate = (preference) => {

        dispatch(user_profile_preference_update_request(user.uid, preference))

        setUserPreference({
            ...userPreference,
            ...preference
        })
    }

    useEffect(() => {

        if(prevUserPreference !== user.preference) {
            setUserPreference({
                ...userPreference,
                ...user.preference
            })
        }

    }, [user.preference, prevUserPreference, userPreference])

    return (
        <div className="page-container">
            <div
                className="page-header"
                style={{
                    backgroundColor: style.pageHeaderColor,
                    color: style.textColor
                }}
            >
                <div className="page-header-contents">
                    <button
                        className="transparent-button"
                        onClick={() => dispatch(moveToDash())}
                    >
                        <LeftOutlined />
                    </button>
                    <div>Profile</div>
                </div>
            </div>

            <div className='scroll-div' style={{display:'flex', flexDirection:'column'}}>
            <div style={{ position: "relative", alignSelf: "center" }}>
                <img
                    alt={COMPANY.NAME}
                    style={{
                        padding: "20px 0px 20px 0px",
                        width: "250px",
                        margin: "5px"
                    }}
                    src={IMAGE_RESOURCE.LOGO}
                />
            </div>

            <div style={{ alignSelf: "center", width: "80%" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            Name
                        </div>
                    </div>
                    <div style={{ width: "60%" }}>
                        {user.userName || "Celcom"}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            Email
                        </div>
                    </div>
                    <div style={{ width: "60%" }}>{user.userEmail}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            Phone Number
                        </div>
                    </div>
                    <div style={{ width: "60%" }}>
                        {user.userContact}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>Role</div>
                    <div style={{ width: "60%" }}>
                        {DISPLAY_USER_ROLE(user.userRole)}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "80%" }}>In App Notification</div>
                    <div style={{ width: "20%" }}>
                        <Switch
                            checkedChildren="On" 
                            unCheckedChildren="Off"
                            defaultChecked={(user.preference && user.preference.in_app_notification)}
                            checked={userPreference && userPreference.in_app_notification}
                            onChange={(e) => userPreferenceUpdate({in_app_notification: e})}
                        />
                    </div>
                </div>
                {
                    userPreference 
                    && userPreference.in_app_notification
                    && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}
                        >
                            <div style={{ width: "80%" }}>In App Notification <Tag color="green">INFO</Tag></div>
                            <div style={{ width: "20%" }}>
                                <Switch
                                    checkedChildren="On" 
                                    unCheckedChildren="Off"
                                    defaultChecked={(user.preference && user.preference.in_app_notification_info)}
                                    checked={userPreference && userPreference.in_app_notification_info}
                                    onChange={(e) => userPreferenceUpdate({in_app_notification_info: e})}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    userPreference 
                    && userPreference.in_app_notification
                    && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}
                        >
                            <div style={{ width: "80%" }}>In App Notification <Tag color="yellow">WARNING</Tag></div>
                            <div style={{ width: "20%" }}>
                                <Switch
                                    checkedChildren="On" 
                                    unCheckedChildren="Off"
                                    defaultChecked={(user.preference && user.preference.in_app_notification_warning)}
                                    checked={userPreference && userPreference.in_app_notification_warning}
                                    onChange={(e) => userPreferenceUpdate({in_app_notification_warning: e})}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    userPreference 
                    && userPreference.in_app_notification
                    && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}
                        >
                            <div style={{ width: "80%" }}>In App Notification <Tag color="red">CRITICAL</Tag></div>
                            <div style={{ width: "20%" }}>
                                <Switch
                                    checkedChildren="On" 
                                    unCheckedChildren="Off"
                                    defaultChecked={(user.preference && user.preference.in_app_notification_critical)}
                                    checked={userPreference && userPreference.in_app_notification_critical}
                                    onChange={(e) => userPreferenceUpdate({in_app_notification_critical: e})}
                                />
                            </div>
                        </div>
                    )
                }
                <div
                    style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px"
                    }}
                >
                    <Button
                        onClick={() => {
                            signOutAccount(() => dispatch(user_sign_out()))
                            // deleteSubscription();
                        }}
                    >
                        Log Out
                    </Button>
                </div>
            </div>

            <div style={{minHeight: '60px'}}></div>
            </div>
        </div>
    );
}

export default ProfilePage;
