import { GET_ADDRESS } from '../action-types/address';

const defaultState = {
  byLocation: {}
};

export const address = (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state))

  switch (action.type) {
    case GET_ADDRESS: {
      const string = JSON.stringify(action.location)
      newState.byLocation[string] = action.address

      return newState;
    }

    default:
      return state;
    }
}