import React from "react";
import PropTypes from "prop-types";

// Constant
import {
  STATUS
} from '../../../../constants'

// Styles
import "./EventBadge.css";

const {
  EVENT_TICKET_COLOR,
  EVENT_TICKET_TYPES_ICON,
  EVENT_TICKET_TYPES_OBJECT
} = STATUS

const EventBadge = ({ eventType, count, fontSize }) => {  
  return (
    <div>
      <div className = "centered-content">
        <div
          className = "status-icon-container centered-content"
          style = {{
            backgroundColor: EVENT_TICKET_COLOR[eventType]
          }}
        >
          <img
            alt={ (eventType && EVENT_TICKET_TYPES_OBJECT[eventType]) || EVENT_TICKET_TYPES_OBJECT.ALL }
            src={ (eventType && EVENT_TICKET_TYPES_ICON[eventType]) || EVENT_TICKET_TYPES_ICON[EVENT_TICKET_TYPES_OBJECT.ALL]}
            style = {{
              width: "22px",
              height: "22px"
            }}
            // style = {{
            //   width: "36px",
            //   height: "36px"
            // }}
          />
        </div>
      </div>
      <p
        className = "status-count"
        style = {{ fontSize: fontSize + "px", lineHeight: fontSize + 2 + "px" }}
      >
        {eventType.slice(-8)} ({count})
      </p>
    </div>
  );
};

EventBadge.propTypes = {
  eventType: PropTypes.string,
  count: PropTypes.number,
  fontSize: PropTypes.number
};

EventBadge.defaultProps = {
  eventType: EVENT_TICKET_TYPES_OBJECT.ALL,
  count: 0,
  fontSize: 12
};

export default EventBadge;
