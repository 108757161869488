import * as API from "../../api";
import * as ActionTypes from "../action-types/vehiclePackageLog";

import { message } from "antd";
import {
  setLoadingSubmit,
  unsetLoadingSubmit,
} from './style';


export const get_vehicle_package_logs = (vids) => dispatch => {
  dispatch(setLoadingSubmit());

  API.getVehiclePackageLogs(vids)
    .then(data => {

      const {
        status,
        vehiclePackageLog: vehiclePackageLogs = []
      } = data

      if (status !== 200) {
        message.error(data.message || 'Error in getting vehicle package logs')
      }
      else {

        dispatch(get_vehicle_package_logs_success(vehiclePackageLogs))
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const get_vehicle_package_logs_success = (vehiclePackageLogs) => {
  return {
    type: ActionTypes.GET_VEHICLE_PACKAGE_LOGS,
    vehiclePackageLogs
  }
}
