import * as API from "../../api"
import * as actionType from '../action-types/ruleTemplate';

import { message } from "antd";
import { goBackToPrev } from "../../../navigation/navigationService";
import { 
    setLoadingSubmit, 
    unsetLoadingSubmit 
} from '../actions/style';

// Redux Actions
import { get_rules } from './rules';

export const get_rule_template = (ruleTemplate) => (dispatch) => {

    API.getRuleTemplate(ruleTemplate)
        .then(data => {

            data.ruleTemplate && dispatch(get_rule_template_success(data.ruleTemplate));
            data.hasOwnProperty('rules') && dispatch(get_rules(data.rules));
        })
}

export const get_rule_template_success = (ruleTemplates) => {
    return {
        type: actionType.GET_RULE_TEMPLATES,
        ruleTemplates: ruleTemplates
    }
}

export const add_rule_template = (uid, ruleTemplateName) => (dispatch) => {
    dispatch(setLoadingSubmit())

    API.createRuleTemplate(uid, ruleTemplateName)
        .then(data => {
            if (data.err) {
                message.error(data.err);
                dispatch(unsetLoadingSubmit());
            }
            else{
    
                data.ruleTemplate && dispatch(create_rule_template_success(data.ruleTemplate));
                data.ruleTemplate && data.ruleTemplate.rules && dispatch(get_rules(data.ruleTemplate.rules));
    
                message.success("Successfully Added Rule Template");
                dispatch(unsetLoadingSubmit())
                dispatch(goBackToPrev());
            }
        })
}

export const create_rule_template_success = (ruleTemplate) => {
    return {
        type: actionType.CREATE_RULE_TEMPLATE,
        ruleTemplate: ruleTemplate
    }
}

export const edit_rule_template = (uid, ruleTemplate) => (dispatch) => {
    dispatch(setLoadingSubmit())

    API.updateRuleTemplate(uid, ruleTemplate)
        .then(data => {

            if(data.status !== 200){
                message.error(data.message || `Error at updating rule template`);
            }
            else{
                // console.log("Get Rule Template Success Data:", data);
    
                data.ruleTemplate && dispatch(update_rule_template_success(data.ruleTemplate));
                data.ruleTemplate && data.ruleTemplate.rules && dispatch(get_rules(data.ruleTemplate.rules));
    
                message.success("Successfully Updated Rule Template");
                dispatch(goBackToPrev());
            }
        })
        .finally(()=>{
            dispatch(unsetLoadingSubmit());
        })
}

export const update_rule_template_success = (ruleTemplate) => {
    return {
        type: actionType.UPDATE_RULE_TEMPLATES,
        ruleTemplate: ruleTemplate
    }
}

export const add_rule_to_rule_template = (newRuID, selectedRTID) => {
    // console.log("Add Rule To Rule Template:", newRuID);

    return {
        type: actionType.ADD_RULE_TO_RULE_TEMPLATE,
        newRuID: newRuID,
        selectedRTID: selectedRTID
    }
}
