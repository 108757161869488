import * as API from "../../api";
import * as actionType from "../action-types/transitRecord";

import { message } from 'antd';
import { 
  setLoadingFleetRecord, 
  // unsetLoadingFleetRecord, 
} from "./style";

export const get_transit_record_by_timeframe_request = (vid, startTime, endTime) => dispatch => {
  dispatch(setLoadingFleetRecord());
  
  API.getTransitRecordByTimeframe(vid, startTime, endTime)
  .then(transitRecords => {
    try {
        if (!Array.isArray(transitRecords)) throw transitRecords

        if (transitRecords.length === 0) throw new Error(`No transit records found within this time period`)

        message.success(`${transitRecords.length} transit records found`)

        dispatch(get_transit_record_by_timeframe_success(vid, transitRecords))

    } catch (err) {
        message.error((err && err.message) || 'Something went wrong')
    }
  });
}

export const get_transit_record_by_timeframe_success = (vid, transitRecord) => {
  return {
    type: actionType.GET_TRANSIT_RECORD,
    vid,
    transitRecord
  }
}

export const set_selected_tsid = (selectedTsID) => {
  return {
    type: actionType.SET_SELECTED_TSID,
    selectedTsID: selectedTsID
  }
}

export const clear_selected_tsid = () => {
  return {
    type: actionType.CLEAR_SELECTED_TSID,
  }
}

export const set_transit_record_index = (index) => {
  // console.log("Setting transit record index to ", index);

  return {
    type: actionType.SET_TRANSIT_RECORD_INDEX,
    transitRecordIndex: index
  }
}

export const clear_transit_record_index = () => {
  return {
    type: actionType.CLEAR_TRANSIT_RECORD_INDEX
  }
}

export const get_pdf_transit_record_by_timeframe_request = (vid, startTime, endTime, status) => dispatch => {
  dispatch(set_transit_record_pdf_status('loading'))

  API.getPDFTransitRecordByTimeframe(vid,startTime,endTime, status) 
  .then(json => {
    // console.log({json});

    const buffer = Buffer.from(json.buffer.data);
    const file = new Blob([buffer], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.download = `${json.name}.pdf`;
    fileLink.click();

    dispatch(set_transit_record_pdf_status('done'));
  })
  .catch(err => {
    // console.log('error in generating pdf report ', err);

    dispatch(set_transit_record_pdf_status('error'));
  })
}

export const set_transit_record_pdf_status = status => {
  return {
    type: actionType.SET_TRANSIT_RECORD_PDF_STATUS,
    status: status
  }
}