import React from "react";

import Logo from "./Logo";
import SidebarItems from "./SidebarItems";

import { config } from '../../../../../config';
import { 
  Layout 
} from "antd";

import "./index.css";

const { Sider } = Layout;

const Sidebar = props => {
  return (
    <Sider
      collapsed = {props.collapsed}
      style = {{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: props.themeColor
      }}
    >
      <Logo onClick = {props.onClick} selectedKeys = {props.selectedKeys} />

      <SidebarItems
        onClick = {props.onClick}
        theme = {props.theme}
        collapsed = {props.collapsed}
        items = {props.items}
        selectedKeys = {props.selectedKeys.toString()}
      />

      {/* <Menu
        // theme = {props.theme}
        // selectedKeys = {[props.selectedKeys]}
        onClick = {() => window.location.href = "https://track.gpsfleet.my"}
        mode = 'inline'
        style = {{
          // height: "calc(90vh - 68px)", // Minus the logo height
          // overflowY: "auto",
          background: "transparent"
        }}
      >
        <Menu.Item 
          key = "V1/V2 Migration" 
          style = {{
            // color: "white",
            color: "rgba(255, 255, 255, 0.65)",

            // width: 24,
            // height: 24,
          }}
        >
          <span style = {{ fontSize: 18 }}>V1</span>
        </Menu.Item>
      </Menu> */}

      <div style = {{
        color: 'white',
        position: 'absolute',
        marginLeft: '15px',
        bottom: '10px'
      }}>
        V {config.version}
      </div>
    </Sider>
  );
};

// Sidebar.propTypes = {
//   collapsed: PropTypes.string,
//   items: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.string.isRequired,
//       icon: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired
//     })
//   ).isRequired,
//   onClick: PropTypes.func,
//   selectedKeys: PropTypes.string,
//   themeColor: PropTypes.string
// };

Sidebar.defaultProps = {
  collapsed: "true",
  items: [],
  onClick: () => {}
};

export default Sidebar;
