import * as actionType from "../action-types/events";

const defaultState = {
  byId: {},
  byType: {
    "ALL": [],
    "INFO": [],
    "WARNING": [],
    "CRITICAL": [],
    "CONTROL": []
  },

  selectedEvID: null,
  selectedIndex: null,
}

export const events = (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case actionType.GET_EVENTS: {
      newState = {
        ...newState,
        byId: {},
        byType: {
          "ALL": [],
          "INFO": [],
          "WARNING": [],
          "CRITICAL": [],
          "CONTROL": []
        },
      }

      action.data.map((event) => {
        if (!newState.byId.hasOwnProperty(event.vid)) {
          newState.byId[event.vid] = {};
        }

        newState.byId[event.vid][event.evid] = event;

        if (!newState.byType["ALL"].includes(event.evid)) {
          newState.byType["ALL"].push(event.evid);
        }

        if (!newState.byType.hasOwnProperty(event.eventType)) {
          newState.byType[event.eventType] = []
        }

        if (!newState.byType[event.eventType].includes(event.evid)) {
          newState.byType[event.eventType].push(event.evid)
        }

        return null;
      })

      return newState;
    }

    case actionType.GET_TODAYS_EVENTS: {
      newState = {
        ...newState,
        byId: {},
        byType: {
          "ALL": [],
          "INFO": [],
          "WARNING": [],
          "CRITICAL": [],
          "CONTROL": []
        },
      }

      action.data.map((event) => {
        if (!newState.byId.hasOwnProperty(event.vid)) {
          newState.byId[event.vid] = {};
        }
        
        newState.byId[event.vid][event.evid] = event;

        if (!newState.byType["ALL"].includes(event.evid)) {
          newState.byType["ALL"].push(event.evid);
        }

        if (!newState.byType.hasOwnProperty(event.eventType)) {
          newState.byType[event.eventType] = []
        }

        if (!newState.byType[event.eventType].includes(event.evid)) {
          newState.byType[event.eventType].push(event.evid)
        }

        return null;
      })

      return newState;
    }

    case actionType.SET_SELECTED_EVID: {
      return {
        ...state,
        selectedEvID: action.selectedEvID
      }
    }

    case actionType.CLEAR_SELECTED_EVID: {
      return {
        ...state,
        selectedEvID: null
      }
    }

    case actionType.SET_SELECTED_EVENT_INDEX: {
      return {
        ...state,
        selectedIndex: action.selectedIndex
      }
    }

    case actionType.CLEAR_SELECTED_EVENT_INDEX: {
      return {
        ...state,
        selectedIndex: null
      }
    }

    default:
      return state;
  }

}