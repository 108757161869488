import { api_next, headers } from '../constants'

export const getReportMileageTimeframeSync = (vidList, startTime, endTime) => {
    return fetch(`${api_next}/report/mileage/timeframe/get/sync`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          "vids": vidList,
          "startTime": Number(startTime),
          "endTime": Number(endTime)
        })
      })
        .then(res => res.json())
}

export const getReportDailyMileageTimeframeSync = (vidList, startTime, endTime) => {
    return fetch(`${api_next}/report/dailyMileage/timeframe/get/sync`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          "vids": vidList,
          "startTime": Number(startTime),
          "endTime": Number(endTime)
        })
      })
        .then(res => res.json())
}

export const getReportTransitTimeframeSync = (vidList, startTime, endTime) => {
  return fetch(`${api_next}/report/transit/timeframe/get/sync`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        "vids": vidList,
        "startTime": Number(startTime),
        "endTime": Number(endTime)
      })
    })
      .then(res => res.json())
}

export const getReportTripTimeframeSync = (vidList, startTime, endTime) => {
  return fetch(`${api_next}/report/trip/timeframe/get/sync`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        "vids": vidList,
        "startTime": Number(startTime),
        "endTime": Number(endTime)
      })
    })
      .then(res => res.json())
}