import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Highlighter from 'react-highlight-words';
import UploadExcelButton from "../../../components/UploadExcelButton";

import { PrimaryButton } from "../../../components/Button";
import { moveToPage } from "../../../navigation/navigationService";
import {
  DEVICE_MODEL,
  TEMPLATE_URLS
} from "../../../../../constants";
import {
  Table,
  Input,
  Button,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

// APIs
import { addDevice } from '../../../services/api';

// Redux Actions
import { add_new_device_success } from "../../../services/redux/actions/devices";
import { update_user_hierarchy_devices } from "../../../services/redux/actions/hierarchy";

class DevicePanel extends React.Component {
  state = {
    isLoading: true,

    searchText: "",
    getUserInfo: false,
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style = {{ padding: 8 }}>
        <Input
          ref = {(node) => {
            this.searchInput = node;
          }}
          value = {selectedKeys[0]}
          placeholder = {`Search ${dataIndex}`}
          style = {{ width: 188, marginBottom: 8, display: 'block' }}
          onPressEnter = {() => this.handleSearch(selectedKeys, confirm)}
          onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        />
        <Button
          size = "small"
          icon = {<SearchOutlined />}
          type = "primary"
          style = {{ width: 90, marginRight: 8 }}
          onClick = {() => this.handleSearch(selectedKeys, confirm)}
        >
          Search
        </Button>

        <Button size = "small" style = {{ width: 90 }} onClick = {() => this.handleReset(clearFilters)}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        searchWords = {[this.state.searchText]}
        textToHighlight = {text ? text.toString() : "-"}
        highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
        autoEscape
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  componentDidMount = () => {
    if (Object.keys(this.props.devices.byId).length) {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(prevProps.devices) !== JSON.stringify(this.props.devices)) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading } = this.state;

    const { devices } = this.props;

    const deviceArr = 
      Object.values(devices.byId)
      .sort((a, b) => b.createdAt - a.createdAt)
      .map(device => {
        const deviceVehicle = Object.values(this.props.vehicles.byId).find((currVehicle) => currVehicle.dvid === device.dvid);

        return {
          key: device.dvid,
          ...device,
          vehicle: deviceVehicle ? deviceVehicle.vehicleDisplayName : "-",
        }
      })

    const columns = [
      {
        title: "Device ID",
        dataIndex: "dvid",
        ...this.getColumnSearchProps("dvid"),
      },
      {
        title: "Assigned Sim Card",
        dataIndex: "simCard",
        ...this.getColumnSearchProps("simCard"),
      },
      {
        title: "Assigned Vehicle",
        dataIndex: "vehicle",
        ...this.getColumnSearchProps("vehicle"),
      },
      {
        title: "ACC",
        dataIndex: "engineStatus",
        render: (data) => data ? data : "-",
      },
      {
        title: "GPS",
        dataIndex: "gpsStatus",
        render: (data) => data ? data : "-",
      },
      {
        title: "Speed (KM/H)",
        dataIndex: "speed",
        render: (data) => !isNaN(data) ? data.toFixed(2) : "-",
      },
      {
        title: "Fuel (%)",
        dataIndex: "fuel",
        render: (data) => !isNaN(data) ? data.toFixed(2) : "-",
      },
      {
        title: "Location",
        dataIndex: "location",
        render: (location) =>
          <div>
            {
              location ?
                (
                  <a
                    target = "_blank"
                    rel = "noopener noreferrer"
                    href = {`https://www.google.com.my/maps/place/${location.lat},${location.lon}`}
                    style = {{ color: 'grey' }}
                  >
                    {
                      `${parseFloat(location.lat).toFixed(7).toString()}, ${parseFloat(location.lon).toFixed(7).toString()}`
                    }
                  </a>
                ) :
                null
            }
          </div>
      },
      {
        title: "Package",
        dataIndex: "package",
        render: (data) => data ? data : "-",
      },
      {
        title: "Device Time",
        dataIndex: "deviceTime",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.deviceTime - b.deviceTime,
        render: text => moment(Number(text)).format("YYYY/MM/DD h:mm:ss A"),
      },
      {
        title: "Server Time",
        dataIndex: "updatedAt",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.updatedAt - b.updatedAt,
        render: text => moment(Number(text)).format("YYYY/MM/DD h:mm:ss A"),
      },
      {
        title: "Creation Date",
        dataIndex: "createdAt",
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.createdAt - b.createdAt,
        render: text => moment(Number(text)).format("YYYY/MM/DD h:mm:ss A"),
      },
      {
        title: "Actions",
        fixed: 'right',
        render: (rowData) => (
          <Button.Group>
            <button className = "transparent-button">
              <EditOutlined onClick = {() => {
                const targetUser = this.props.user.uid;
                const device = this.props.devices.byId[rowData.dvid];

                this.props.dispatch(moveToPage(`/Management/Device/Edit/${device.dvid}`, {
                  device,
                  targetUser,
                }))
              }} />
            </button>
          </Button.Group>
        )
      }
    ];

    return (
      <div style = {{ display: "flex", flex: 1 }}>
        <div
          style = {{
            width: "95%",
            margin: "0 5%",
          }}
        >
          <div
            style = {{
              display: "flex",
              justifyContent: "flex-end",

              padding: 5,
              marginBottom: 10,
            }}
          >
            <UploadExcelButton
              loading = {isLoading}
              title = 'Bulk add device'
              buttonName = {`Bulk Add Device`}
              excelTemplateName = {`Bulk Add Device`}
              templateUrl = {TEMPLATE_URLS.BULK_ADD_DEVICES}
              dataColumns = {
                {
                  simCard: {
                    label: 'Sim Card',
                    rule: (simCard, allSimCards) => {
                      if (!simCard) throw new Error(`Please enter a sim card number!"`);
                      if (Object.values(this.props.devices.byId).find(device => device.simCard === simCard)) throw new Error(`Sim card is already in use!`);
                      if (simCard.substring(0, 2) !== "+6") throw new Error(`Sim card does not have Malaysian country code (+6)!`);
                    },
                  },

                  deviceIMEI: {
                    label: 'Device IMEI',
                    rule: (deviceIMEI, allDeviceIMEIs) => {
                      if (!deviceIMEI) throw new Error(`Please enter a device IMEI!"`);
                    },
                  },

                  deviceModel: {
                    label: 'Device Model',
                    rule: (deviceModel, allDeviceModels) => {
                      if (!deviceModel) throw new Error(`Please enter a device model!"`);
                      if (!Object.values(DEVICE_MODEL).includes(deviceModel)) throw new Error(`Please enter a valid device model! Our options are 'GPS100' and 'TEL100'."`);
                    },
                  },

                  deviceBrand: {
                    optional: true,
                    label: 'Device Brand (Optional)',
                    // rule: (deviceBrand, allDeviceBrand) => true,
                  },
                }
              }
              uploadLoop = {async (excelData) => {
                const dgid = Object.values(this.props.deviceGroups.byId).find(dg => dg.groupName === 'ALL').dgid;

                const newDevice = {
                  imei: excelData.deviceIMEI,
                  simCard: excelData.simCard,
                  model: excelData.deviceModel,
                  brand: excelData.deviceBrand ? excelData.deviceBrand.toString() : null, // Ensure the brand is a string
                };

                const addDeviceRes = await addDevice(dgid, newDevice);

                switch (addDeviceRes.status) {
                  case 200: {
                    this.props.dispatch(add_new_device_success(addDeviceRes.device));
                    this.props.dispatch(update_user_hierarchy_devices(this.props.user.uid, addDeviceRes.device));

                    return {
                      uploadStatus: 'SUCCESS',
                      message: ''
                    }
                  }

                  default: {
                    return {
                      uploadStatus: 'FAILED',
                      message: addDeviceRes.message
                    }
                  }
                }
              }}
            />

            <PrimaryButton
              icon = {<PlusOutlined />}
              onClick = {() => this.props.dispatch(moveToPage("/Management/Device/Add"))}
              style = {{ marginLeft: 10 }}
            >
              Add New Device
            </PrimaryButton>
          </div>

          <div>
            <Table 
              loading = {isLoading}
              columns = {columns} 
              dataSource = {deviceArr} 
              scroll = {{ x: true }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.v2.user, // To create new geofences when uploading
  devices: state.v2.devices,
  vehicles: state.v2.vehicles,
  deviceGroups: state.v2.deviceGroups, // To create new geofences when uploading
});

export default connect(mapStateToProps)(DevicePanel);