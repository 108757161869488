import 
  React,
  {
    useState
  }
from "react";
import { 
  useDispatch,
  useSelector
} from 'react-redux'

import moment from "moment";
import Highlighter from 'react-highlight-words';

import { ROLE_TYPE } from '../../../../../constants';
import { moveToPage } from "../../../navigation/navigationService";
import { EmptyIndicator } from "../../../components/EmptyIndicator";
import {
  EditOutlined,
  SearchOutlined,
  MonitorOutlined
} from '@ant-design/icons';
import {
  Tabs,
  Table,
  Input,
  Button,
  Tooltip,
} from "antd";

// React Actions
import { select_user_dvid } from "../../../services/redux/actions/hierarchy";

const TabPane = Tabs.TabPane;

const UserInfoTab = (props) => {

  const dispatch = useDispatch()

  const user = useSelector(state => state.v2.user)
  const hierarchy = useSelector(state => state.v2.hierarchy)
  const style = useSelector(state => state.v2.style)

  const [ searchText, setSearchText ] = useState('')

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0])
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = dataIndex => {

    let searchInput;

    return ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            value={selectedKeys[0]}
            placeholder={`Search ${dataIndex}`}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{
              width: 188,
              marginBottom: 8,
              display: 'block',
            }}
          />
  
          <Button
            size="small"
            icon={<SearchOutlined />}
            type="primary"
            style={{
              width: 90,
              marginRight: 8
            }}
            onClick={() => handleSearch(selectedKeys, confirm)}
          >
            Search
          </Button>
  
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    });

  }
  
  const returnIfUserIsFleetOwnerOrOperator = () => {
    return user.userRole && (user.userRole === ROLE_TYPE.FLEET_OWNER || user.userRole === ROLE_TYPE.FLEET_OPERATOR);
  }

  const selectedUser = hierarchy.byId[hierarchy.selectedId];

  const vehicleArr =
      (
        selectedUser
        && selectedUser.vehicles
        && selectedUser.vehicles.map((currVehicle, index) => {
          const currDevice =
            selectedUser
            && selectedUser.devices
            && selectedUser.devices.find((device) => device.dvid === currVehicle.dvid)

          const simCard = currDevice && currDevice.simCard;

          return ({
            key: index,
            simCard: simCard,
            ...currVehicle,
          });
        })
      ) ||
      []

    const columnsVehicle = [
      {
        title: `Vehicle Plate (${vehicleArr.length})`,
        dataIndex: "vehiclePlate",
        key: "vehiclePlate",
        ...getColumnSearchProps("vehiclePlate"),
      },

      {
        title: "Display Name",
        dataIndex: "vehicleDisplayName",
        key: "vehicleDisplayName",
        ...getColumnSearchProps("vehicleDisplayName"),
      },

      {
        title: "Type",
        dataIndex: "vehicleType"
      },

      {
        title: "Model",
        dataIndex: "vehicleModel"
      },
      {
        title: "Assigned Device",
        dataIndex: "dvid",
        key: "dvid",
        ...getColumnSearchProps("dvid"),
      },

      {
        title: "Device Sim Card",
        dataIndex: "simCard",
        key: "simCard",
        ...getColumnSearchProps("simCard"),
      },
      {
        title: "Engine Service Next Odometer",
        dataIndex: "engineServiceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Engine Service Next Working Hour",
        dataIndex: "engineServiceNextMileage",
        render: text => (text && `${text} hrs`) || '-',
      },
      {
        title: "Engine Service Due Date",
        dataIndex: "engineServiceDueDate",
        sorter: (a, b) => a.engineServiceDueDate - b.engineServiceDueDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Transmission Service Next Odometer",
        dataIndex: "transmissionServiceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Transmission Service Due Date",
        dataIndex: "transmissionServiceDueDate",
        sorter: (a, b) => a.transmissionServiceDueDate - b.transmissionServiceDueDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Rear Axle Service Next Odometer",
        dataIndex: "rearAxleServiceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Rear Axle Service Due Date",
        dataIndex: "rearAxleServiceDueDate",
        sorter: (a, b) => a.rearAxleServiceDueDate - b.rearAxleServiceDueDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Service Next Odometer",
        dataIndex: "serviceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Service Due Date",
        dataIndex: "serviceDueDate",
        sorter: (a, b) => a.serviceDueDate - b.serviceDueDate,
        render: text => (text && moment(text).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Subscription Start Date",
        dataIndex: "subsStartDate",
        sorter: (a, b) => a.subsStartDate - b.subsStartDate,
        render: text => (text && moment(text).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Subscription End Date",
        dataIndex: "subsEndDate",
        sorter: (a, b) => a.subsEndDate - b.subsEndDate,
        render: text => (text && moment(text).format("YYYY/MM/DD")) || '-',
      },

      {
        title: "Creation Date",
        dataIndex: "createdAt",
        render: text => moment(Number(text)).format("YYYY-MM-DD h:mm:ss A"),
        sorter: (a, b) => b.createdAt - a.createdAt,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "ascend"
      },

      {
        title: "Actions",
        fixed: 'right',
        render: vehicle => (
          <Button.Group>
            <button className="transparent-button">
              <Tooltip title="Inspect Vehicle Log">
                <MonitorOutlined
                  onClick={() => {
                    dispatch(moveToPage(`/VehicleInspector/${vehicle.vid}`, { vehicle, uid: hierarchy.selectedId }))
                  }}
                />
              </Tooltip>
            </button>

            <button className="transparent-button">
              <Tooltip title="Edit">
                <EditOutlined
                  onClick={
                    () => {
                      // Delete extra keys to not accidentally push to Edit Vehicle API
                      delete vehicle.key;
                      delete vehicle.simCard;

                      dispatch(moveToPage(`/UserManagement/EditVehicle/${vehicle.vid}`, { vehicle, uid: hierarchy.selectedId }))
                    }
                  }
                />
              </Tooltip>
            </button>

            {/* unimplemented */}
            {/* <button className = "transparent-button">

              <Tooltip title = "Information List">
                <Icon
                  type = "unordered-list"
                  onClick = {() => this.props.dispatch(moveToPage(`/VehicleDisplay/${this.props.hierarchy.selectedId}/`, { vid: vehicle.vid }))}
                />
              </Tooltip>
            </button> */}
          </Button.Group>
        )
      }
    ]

    const columnsVehicleWithoutDevice = [
      {
        title: `Vehicle Plate (${vehicleArr.length})`,
        dataIndex: "vehiclePlate",
        key: "vehiclePlate",
        ...getColumnSearchProps("vehiclePlate"),
      },

      {
        title: "Display Name",
        dataIndex: "vehicleDisplayName",
        key: "vehicleDisplayName",
        ...getColumnSearchProps("vehicleDisplayName"),
      },

      {
        title: "Type",
        dataIndex: "vehicleType"
      },

      {
        title: "Model",
        dataIndex: "vehicleModel"
      },

      // {
      //   title: "Assigned Device",
      //   dataIndex: "dvid",
      //   key: "dvid",
      //   ...getColumnSearchProps("dvid"),
      // },

      // {
      //   title: "Device Sim Card",
      //   dataIndex: "simCard",
      //   key: "simCard",
      //   ...getColumnSearchProps("simCard"),
      // },
      {
        title: "Engine Service Next Odometer",
        dataIndex: "engineServiceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Engine Service Next Working Hour",
        dataIndex: "engineServiceNextMileage",
        render: text => (text && `${text} hrs`) || '-',
      },
      {
        title: "Engine Service Due Date",
        dataIndex: "engineServiceDueDate",
        sorter: (a, b) => a.engineServiceDueDate - b.engineServiceDueDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Transmission Service Next Odometer",
        dataIndex: "transmissionServiceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Transmission Service Due Date",
        dataIndex: "transmissionServiceDueDate",
        sorter: (a, b) => a.transmissionServiceDueDate - b.transmissionServiceDueDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Rear Axle Service Next Odometer",
        dataIndex: "rearAxleServiceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Rear Axle Service Due Date",
        dataIndex: "rearAxleServicServiceDueDate",
        sorter: (a, b) => a.rearAxleServiceDueDate - b.rearAxleServiceDueDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Service Next Odometer",
        dataIndex: "serviceNextMileage",
        render: text => (text && `${text} km`) || '-',
      },
      {
        title: "Service Due Date",
        dataIndex: "serviceDueDate",
        sorter: (a, b) => a.serviceDueDate - b.serviceDueDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Subscription Start Date",
        dataIndex: "subsStartDate",
        sorter: (a, b) => a.subsStartDate - b.subsStartDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Subscription End Date",
        dataIndex: "subsEndDate",
        sorter: (a, b) => a.subsEndDate - b.subsEndDate,
        render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
      },
      {
        title: "Creation Date",
        dataIndex: "createdAt",
        render: text => moment(Number(text)).format("YYYY-MM-DD h:mm:ss A"),
        sorter: (a, b) => b.createdAt - a.createdAt,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "ascend"
      },

      {
        title: "Actions",
        fixed: 'right',
        render: vehicle => (
          <Button.Group>
            <button className="transparent-button">
              <Tooltip title="Inspect Vehicle Log">
                <MonitorOutlined
                  onClick={() => {
                    dispatch(moveToPage(`/VehicleInspector/${vehicle.vid}`, { vehicle, uid: hierarchy.selectedId }))
                  }}
                />
              </Tooltip>
            </button>

            <button className="transparent-button">
              <Tooltip title="Edit">
                <EditOutlined
                  onClick={
                    () => {
                      // Delete extra keys to not accidentally push to Edit Vehicle API
                      delete vehicle.key;
                      delete vehicle.simCard;

                      dispatch(moveToPage(`/UserManagement/EditVehicle/${vehicle.vid}`, { vehicle, uid: hierarchy.selectedId }))
                    }
                  }
                />
              </Tooltip>
            </button>

            {/* unimplemented */}
            {/* <button className = "transparent-button">

              <Tooltip title = "Information List">
                <Icon
                  type = "unordered-list"
                  onClick = {() => this.props.dispatch(moveToPage(`/VehicleDisplay/${this.props.hierarchy.selectedId}/`, { vid: vehicle.vid }))}
                />
              </Tooltip>
            </button> */}
          </Button.Group>
        )
      }
    ]

    const deviceArr =
      (
        selectedUser
        && selectedUser.devices
        && selectedUser.devices
          .map((currDevice, index) => {
            const currVehicle =
              selectedUser
              && selectedUser.vehicles
              && selectedUser.vehicles.find((vehicle) => vehicle.dvid === currDevice.dvid)

            const vehiclePlate = currVehicle && currVehicle.vehiclePlate;

            return ({
              key: index,
              vehiclePlate: vehiclePlate ? vehiclePlate : "-",
              ...currDevice,
            });
          })
      ) ||
      []

    let columnsDevice = [
      {
        key: "dvid",
        title: `Device ID (${deviceArr.length})`,
        dataIndex: "dvid",
        ...getColumnSearchProps("dvid"),
      },

      {
        title: `Vehicle Plate (${vehicleArr.length})`,
        dataIndex: "vehiclePlate",
        key: "vehiclePlate",
        ...getColumnSearchProps("vehiclePlate"),
      },

      {
        key: "simCard",
        title: "Assigned Sim Card",
        dataIndex: "simCard",
        ...getColumnSearchProps("simCard"),
      },

      {
        title: "Creation Date",
        dataIndex: "createdAt",
        render: text => moment(Number(text)).format("YYYY-MM-DD h:mm:ss A"),
        sorter: (a, b) => b.createdAt - a.createdAt,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "ascend"
      },

      {
        title: "Actions",
        render: device => (
          <Button.Group>
            <button className="transparent-button">
              <Tooltip title="Inspect Device Log">
                <MonitorOutlined
                  onClick={() => {
                    dispatch(select_user_dvid(device.dvid));
                    dispatch(moveToPage(`/DeviceInspector/${hierarchy.selectedId}/`, { dvid: device.dvid, uid: hierarchy.selectedId }));
                  }}
                />
              </Tooltip>
            </button>

            {
              !returnIfUserIsFleetOwnerOrOperator() ?
                <button className="transparent-button">
                  <EditOutlined onClick={() => dispatch(moveToPage(`/UserManagement/EditDevice/${device.dvid}`, { device, targetUser: hierarchy.selectedId }))} />
                </button> :
                null
            }
          </Button.Group>
        )
      }
    ]

  const isHideDeviceRelatedInfo = returnIfUserIsFleetOwnerOrOperator()

  return (
    <div style={{ margin: "5% 10%" }}>
      {
        !hierarchy.selectedId ? (
          <EmptyIndicator />
        ) : (
          isHideDeviceRelatedInfo ? (
            <Table
              dataSource={vehicleArr}
              columns={columnsVehicleWithoutDevice}
              scroll={{
                x: columnsVehicleWithoutDevice && columnsVehicleWithoutDevice.length * 150
              }}
            />
          ) : (
            <Tabs
              type="card"
              defaultActiveKey="1"
            >
              <TabPane tab="Vehicles" key="vehicles">
                {
                  hierarchy.selectedId ?
                    <Table
                      loading={style.isLoadingSubmit}
                      dataSource={vehicleArr}
                      columns={columnsVehicle}
                      scroll={{
                        x: columnsVehicle && columnsVehicle.length * 150
                      }}
                    /> :
                    <EmptyIndicator />
                }
              </TabPane>

              <TabPane tab="Devices" key="devices">
                {
                  hierarchy.selectedId ?
                    <Table
                      dataSource={deviceArr}
                      columns={columnsDevice}
                      scroll={{
                        x: columnsDevice && columnsDevice.length * 150
                      }}
                    /> :
                    <EmptyIndicator />
                }
              </TabPane>
            </Tabs>
          )
        )
      }
    </div>
  )

}

export default UserInfoTab;