import * as actionType from "../action-types/rules";

const defaultState = {
    byRuID: {}, 
    allRuIDs: [],

    // selectedRuID: null
};

export const rules = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case actionType.GET_RULES: {
            action.rules.forEach(currRule => {
                newState.allRuIDs.push(currRule.ruid);
                newState.byRuID[currRule.ruid] = currRule;
            })

            return newState;
        }

        case actionType.ADD_RULE: {
            // console.log ("Add Rule Action Data:", action.data);

            newState.allRuIDs.push(action.data.ruid);
            newState.byRuID[action.data.ruid] = action.data;

            return newState;
        }

        case actionType.EDIT_RULE: {
            // console.log ("Edit Rule Action Data:", action.data);

            newState.byRuID[action.data.ruid] = action.data;

            return newState;
        }

        // case actionType.SET_SELECTED_RUID: {
        //     // console.log ("Set Selected RuID Action Data:", action.selectedRuID);

        //     return {
        //         ...newState,
        //         selectedRuID: action.selectedRuID
        //     };
        // }

        default: {
            return state;
        }
    }
}