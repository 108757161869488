import React, { Component } from "react";
import { connect } from "react-redux";

import ExpandedTable from './ExpandedTable';
import Highlighter from 'react-highlight-words';

import { SearchOutlined } from '@ant-design/icons';
import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../../constants";
import {
    Table,
    Input,
    Button,
} from "antd";

class GeofenceSummaryTab extends Component {
    state = {
        dataSource: [],
        geofenceVehicleTally: {},
    }

    getColumnSearchProps = (dataIndex) => {
        const handleSearch = (selectedKeys, confirm) => {
            confirm();
    
            this.setState({ searchText: selectedKeys[0] });
        };
    
        const handleReset = (clearFilters) => {
            clearFilters();
    
            this.setState({ searchText: '' });
        };

        return (
            {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style = {{ padding: 8 }}>
                        <Input
                            ref = {(node) => { this.searchInput = node }}
                            placeholder = {`Search ${dataIndex}`}
                            value = {selectedKeys[0]}
                            onPressEnter = {() => handleSearch(selectedKeys, confirm)}
                            onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            style = {{
                                display: 'block',
                                width: 188,
                                marginBottom: 8,
                            }}
                        />
        
                        <Button
                            type = "primary"
                            size = "small"
                            icon = {<SearchOutlined />}
                            onClick = {() => handleSearch(selectedKeys, confirm)}
                            style = {{
                                width: 90,
                                marginRight: 8,
                            }}
                        >
                            Search
                        </Button>
        
                        <Button
                            size = "small"
                            onClick = {() => handleReset(clearFilters)}
                            style = {{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </div>
                ),
                filterIcon: (filtered) => (
                    <SearchOutlined
                        style = {{ color: filtered ? '#1890ff' : undefined }}
                    />
                ),
                onFilter: (value, record) =>
                    record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase()),
                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => this.searchInput.select());
                    }
                },
                render: (text) => (
                    <Highlighter
                        autoEscape
                        textToHighlight = {text ? text : ""}
                        searchWords = {[this.state.searchText]}
                        highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
                    />
                ),
            }
        );
    };

    returnLatestGeofenceTemplateColorForGeoID = (geoID) => {
        let latestGT = {};

        Object.values(this.props.geofenceTemplates.byId)
        .filter((currGT) => currGT.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
        .forEach((currGT) => {
            if (!latestGT.createdAt || currGT.createdAt > latestGT.createdAt) {
                latestGT = currGT;
            }
        })

        // console.log("Color:", latestGT, latestGT.colorHexCode);

        return latestGT.colorHexCode ? latestGT.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    tallyNoOfVehiclesInGeofences = (cb) => {
        let newTally = {};

        // Set up newTally to avoid any missing geofences
        Object.keys(this.props.geofences.byId).forEach((currGeofenceID) => { newTally[currGeofenceID] = [] });

        Object.values(this.props.vehicles.byId)
        .filter((currVehicle) => 
            currVehicle 
            && currVehicle.geofenceProfile 
            && typeof currVehicle.geofenceProfile === "object" 
            && Object.keys(currVehicle.geofenceProfile).length
        ) // Make sure geofenceProfile exists and is an object and has keys
        .forEach((currVehicle) => {
            Object.keys(currVehicle.geofenceProfile).forEach((currGeofenceID) => {
                // Just in case the vehicle has a geofence that isn't listed in the geofence redux store, we add it to the tally list
                if (!newTally[currGeofenceID]) {
                    newTally[currGeofenceID] = [];
                }
                
                // Make sure vehicle is in the geofence
                // If vehicle is in geofence, currVehicle.geofenceProfile[currGeofenceID] = 1
                if (currVehicle.geofenceProfile[currGeofenceID]) {
                    newTally[currGeofenceID].push(currVehicle.vid); // Increase tally
                }
            })
        })

        // console.log("newTally:", newTally);

        this.setState({ 
            geofenceVehicleTally: newTally 
        },
            () => {
                cb && cb()
            }
        );
    }

    setInitialData = () => {
        // Tally vehicle count first
        this.tallyNoOfVehiclesInGeofences(
            () => {
                const newDataSource = 
                    Object.values(this.props.geofences.byId)
                    .sort((a, b) => {
                        const A = a.geofenceName && a.geofenceName.toLowerCase();
                        const B = b.geofenceName && b.geofenceName.toLowerCase();
    
                        if (A < B) return -1;
                        if (A > B) return 1;
                        return 0;
                    })
                    .map((currGeofence) => {
                        // List out all geofence templates this geofence belongs to
                        const geofenceTemplates = 
                            Object.values(this.props.geofenceTemplates.byId)
                            .filter((currGT) => currGT.geofences.includes(currGeofence.geoid))
                            .map((currGT) => currGT.templateName)
                            .sort((a, b) => {
                                const A = a && a.toLowerCase();
                                const B = b && b.toLowerCase();
            
                                if (A < B) return -1;
                                if (A > B) return 1;
                                return 0;
                            })
                            .join(", ")
        
                        return {
                            key: currGeofence.geoid,
        
                            geoType: currGeofence.geoType,
                            geofenceName: currGeofence.geofenceName,
                            noOfVehicles: this.state.geofenceVehicleTally[currGeofence.geoid].length,
        
                            geofenceTemplates,
                        }
                    });
        
                // console.log("newDataSource:", newDataSource);
        
                this.setState({ dataSource: newDataSource });
            }
        );
    }

    componentDidMount = () => {
        if (Object.keys(this.props.geofences.byId) && Object.keys(this.props.geofenceTemplates.byId)) {
            this.setInitialData();
        }
    }

    componentDidUpdate = (prevProps) => {
        const {
            geofences,
            geofenceTemplates,
        } = this.props;

        if (
            (
                JSON.stringify(prevProps.geofences) !== JSON.stringify(geofences)
                || JSON.stringify(prevProps.geofenceTemplates) !== JSON.stringify(geofenceTemplates)
                || JSON.stringify(prevProps.vehicles) !== JSON.stringify(this.props.vehicles)
            )
            && Object.keys(geofences.byId)
            && Object.keys(geofenceTemplates.byId)
        ) {
            this.setInitialData();
        }
    }

    render() {
        const { dataSource } = this.state;

        const columns = [
            {
                title: "Name",
                dataIndex: "geofenceName",
                render: (data) => data ? data : "-",
                ...this.getColumnSearchProps("geofenceName"),
            },
            {
                title: "Geofence Templates",
                dataIndex: "geofenceTemplates",
                render: (data) => data ? data : "-",
                ...this.getColumnSearchProps("geofenceTemplates"),
            },
            {
                title: "Type",
                dataIndex: "geoType",
                filters: [{ text: "POI", value: "POI" }, { text: "Polygon", value: "Polygon" }],
                onFilter: (value, record) => record.geoType && record.geoType.indexOf(value) === 0,
                // render: (data) => data ? data : "-",
                render: (data) => (
                    <div
                        style = {{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            span = {7}
                            className = "POI"
                            style = {{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                width: 100,
                                height: 20,
                                // backgroundColor: this.returnLatestGeofenceTemplateColorForGeoID(rowData.key),
                            }}
                        >
                            {/* {data} */}
                            {data === "Polygon" ? "Zone" : data}
                        </div>
                    </div>
                ),
                // ...this.getColumnSearchProps("geoType"),
            },
            {
                title: "No. of Vehicles Inside",
                dataIndex: "noOfVehicles",
                sorter: (a, b) => a.noOfVehicles - b.noOfVehicles,
                // render: (data) => !isNaN(data) ? data : "-",
                // ...this.getColumnSearchProps("noOfVehicles"),
            },
        ]

        return (
            <div
                style = {{
                    flex: 1,
                    display: "flex",
                }}
            >
                <div
                    className = "scroll-div"
                    style = {{ width: "100%" }}
                >
                    <Table
                        columns = {columns}
                        dataSource = {dataSource}
                        expandable = {{
                            rowExpandable: (record) => this.state.geofenceVehicleTally[record.key].length,
                            expandedRowRender: (record) => <ExpandedTable selectedGeoID = {record.key}/>,
                        }}
                        pagination = {dataSource.length > 10}
                        scroll = {{
                            x: columns.length * 150,
                            y: window.innerHeight * 0.6,
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    geofenceTemplates: state.v2.geofenceTemplate,
});

export default connect(mapStateToProps)(GeofenceSummaryTab);

