import React from 'react';
import { connect } from "react-redux";

import { Tooltip } from "antd";
import { MAP } from '../../constants';

// Redux Actions
import {
    enable_show_vehicle_info,
    disable_show_vehicle_info,
    enable_show_geofence_info,
    disable_show_geofence_info,
    // enable_show_geofences,
    // disable_show_geofences,
    enable_show_pois,
    disable_show_pois,
    enable_show_zones,
    disable_show_zones,
    enable_show_live_traffic,
    disable_show_live_traffic,
    clear_enabled_geofence_pop_ups,
} from "../../services/redux/actions/mapControl";

import "./index.css";

const { MAP_CONTROL_PANEL_ICON } = MAP;

function MapControlPanel(props) {
    return (
        <div
            style = {{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",

                width: "100%",
                height: "100%",
            }}
        >
            {/* {
                !props.disableToggleGeofences &&
                    <Tooltip title = "Toggle Geofences" placement = "left">
                        {
                            props.mapControl.uiControl.showAllGeofence ?
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_AVAILABLE}
                                    alt = {"geofenceMarker_Yay"}
                                    onClick = {() => {
                                        props.dispatch(disable_show_geofences());
                                    }}
                                /> :
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_UNAVAILABLE}
                                    alt = {"geofenceMarker_Nay"}
                                    onClick = {() => {
                                        props.dispatch(enable_show_geofences());
                                    }}
                                />
                        }
                    </Tooltip>
            } */}

            {
                !props.disableToggleGeofences &&
                    <Tooltip title = "Toggle POIs" placement = "left">
                        {
                            props.mapControl.uiControl.showAllPOIs ?
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_AVAILABLE}
                                    alt = {"geofenceMarker_Yay"}
                                    onClick = {() => {
                                        props.dispatch(disable_show_pois());
                                    }}
                                /> :
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_UNAVAILABLE}
                                    alt = {"geofenceMarker_Nay"}
                                    onClick = {() => {
                                        props.dispatch(enable_show_pois());
                                    }}
                                />
                        }
                    </Tooltip>
            }
            
            {
                !props.disableToggleGeofences &&
                    <Tooltip title = "Toggle Zones" placement = "left">
                        {
                            props.mapControl.uiControl.showAllZones ?
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_AVAILABLE}
                                    alt = {"geofenceMarker_Yay"}
                                    onClick = {() => {
                                        props.dispatch(disable_show_zones());
                                    }}
                                /> :
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_UNAVAILABLE}
                                    alt = {"geofenceMarker_Nay"}
                                    onClick = {() => {
                                        props.dispatch(enable_show_zones());
                                    }}
                                />
                        }
                    </Tooltip>
            }

            {
                !props.disableToggleGeofenceInfo &&
                    <Tooltip title = "Toggle Geofence Info" placement = "left">
                        {
                            props.mapControl.uiControl.showAllGeofencesInfoWindow ?
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_INFOBOX_AVAILABLE}
                                    alt = {"geofenceMarkerCallout_Yay"}
                                    onClick = {() => {
                                        props.dispatch(disable_show_geofence_info());
                                    }}
                                /> :
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_INFOBOX_UNAVAILABLE}
                                    alt = {"geofenceMarkerCallout_Nay"}
                                    onClick = {() => {
                                        props.dispatch(enable_show_geofence_info());
                                        props.dispatch(clear_enabled_geofence_pop_ups());
                                    }}
                                />
                        }
                    </Tooltip>
            }

            {
                !props.disableToggleVehicleInfo &&
                    <Tooltip title = "Toggle Vehicle Info" placement = "left">
                        {
                            props.mapControl.uiControl.showAllVehiclesInfoWindow ?
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_VEHICLE_MAKER_INFOBOX_AVAILABLE}
                                    alt = {"vehicleMarkerCallout_Yay"}
                                    onClick = {() => {
                                        props.dispatch(disable_show_vehicle_info());
                                    }}
                                /> :
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_VEHICLE_MAKER_INFOBOX_UNAVAILABLE}
                                    alt = {"vehicleMarkerCallout_Nay"}
                                    onClick = {() => {
                                        props.dispatch(enable_show_vehicle_info());
                                        // props.dispatch(clear_enabled_vehicle_pop_ups());
                                    }}
                                />
                        }
                    </Tooltip>
            }

            {
                !props.disableToggleLiveTraffic &&
                    <Tooltip title = "Toggle Live Traffic" placement = "left">
                        {
                            props.mapControl.uiControl.showLiveTraffic ?
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_LIVE_TRAFFIC_AVAILABLE}
                                    alt = {"liveTraffic_Yay"}
                                    onClick = {() => {
                                        props.dispatch(disable_show_live_traffic());
                                    }}
                                /> :
                                <img
                                    className = {"icon"}
                                    src = {MAP_CONTROL_PANEL_ICON.SVG_LIVE_TRAFFIC_UNAVAILABLE}
                                    alt = {"liveTraffic_Nay"}
                                    onClick = {() => {
                                        props.dispatch(enable_show_live_traffic());
                                    }}
                                />
                        }
                    </Tooltip>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    mapControl: state.v2.mapControl,
});

export default connect(mapStateToProps)(MapControlPanel);