import React from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';

import Page from '../../components/Page';
import showSecondarySidebar from '../../components/Layout/helpers/showSecondarySidebar';

import {
    Table,
    Button,
    Input,
    Modal,
    Tooltip,
} from 'antd';
import {
    SearchOutlined,
    DoubleRightOutlined
} from '@ant-design/icons';

// Redux Actions
import {
    get_stages,
    migrate_user
} from "../../services/redux/actions/migration";

import { parseTime } from '../../util/time'

class MigrationPage extends React.Component {
    state = {
        dataSource: [],
    }

    setInitialData = () => {

        const dataSource = Object.values(this.props.migration.byId).map(user => {
            return {
                key: user.uid,
                ...user,
            }
        })

        this.setState({ dataSource })
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => { this.searchInput = node }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block',
                    }}
                />

                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    size="small"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    style={{
                        width: 90,
                        marginRight: 8,
                    }}
                >
                    Search
                </Button>

                <Button
                    size="small"
                    style={{ width: 90 }}
                    onClick={() => this.handleReset(clearFilters)}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {

            return record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                textToHighlight={text ? text : ""}
                searchWords={[this.state.searchText]}
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                autoEscape
            />
        ),
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();

        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();

        this.setState({ searchText: '' });
    };


    componentDidMount = () => {
        this.setInitialData();
        this.getInitialData()
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props.migration) !== JSON.stringify(prevProps.migration)) {
            this.setInitialData();
        }
    }

    getInitialData = () => {
        this.props.dispatch(get_stages(null));
    }

    render() {

        window.props = this.props
        const columns = [
            {
                title: 'Name',
                fixed: 'left',
                dataIndex: 'userName',
                key: 'userName',
                ...this.getColumnSearchProps("userName"),

            },
            {
                title: 'V1',
                dataIndex: 'V1',
                render: time => parseTime(time),
                sorter: (a, b) => a.V1 - b.V1,
            },
            {
                title: 'V2',
                dataIndex: 'V2',
                render: time => parseTime(time),
                sorter: (a, b) => a.V2 - b.V2,
            },
            {
                title: 'Uid',
                dataIndex: 'uid',
                ...this.getColumnSearchProps("uid"),

            },
            {
                title: 'Email',
                dataIndex: 'userEmail',
                ...this.getColumnSearchProps("userEmail"),

            },
            {
                title: 'User Role',
                dataIndex: 'userRole',
                ...this.getColumnSearchProps("userRole"),

            },
            {
                title: "Actions",
                render: rowData => {
                    const isMigratable = !rowData.V2 || !rowData.uid

                    if (!isMigratable) return null

                    return <Tooltip title={'Migrate'}>
                        <DoubleRightOutlined
                            onClick={() => {
                                Modal.confirm({
                                    title: `Confirm migrate ${rowData.userName} from V1 to V2?`,
                                    content: (
                                        <div>
                                            <p>Take note</p>

                                            <ol>
                                                <li>V2 account will be created for user using same email + password</li>
                                                <li>All downline will be migrated</li>
                                                <li>Vehicles, Geofences, Devices, Rules etc will <b>not</b> be migrated</li>
                                            </ol>

                                            <p style={{ color: 'red' }}>Important</p>
                                            <ol>
                                                <li>V1 account will not be affected</li>
                                                <li>This step is irreversible</li>
                                            </ol>
                                        </div>
                                    ),
                                    okText: `Migrate`,
                                    okButtonProps: {
                                        type: 'danger'
                                    },
                                    onOk: () => {

                                        const uid = rowData.uid
                                        const sourceStage = 'V1'
                                        const targetStage = 'V2'
                                        console.log({ uid, sourceStage, targetStage })

                                        this.props.dispatch(migrate_user(uid, sourceStage, targetStage))
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                }
            }
        ]


        return (
            <div className='page-container'>
                <Page title='Migration'>
                    <Table
                        columns={columns}
                        scroll={{
                            x: columns.length * 150
                        }}
                        loading={!this.state.dataSource || this.state.dataSource.length === 0 || this.props.style.isLoadingSubmit}
                        dataSource={this.state.dataSource}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    migration: state.v2.migration,
});

// const ConnectedMigrationPage = connect(mapStateToProps)(MigrationPage);
export default showSecondarySidebar(false)(connect(mapStateToProps)(MigrationPage));