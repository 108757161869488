import React from "react";
import PropTypes from "prop-types";
import SVG from "../../../components/SVG";

import { EVENT_COLOR } from "../../../../../constants";

const EventAvatar = (props) => {
  // console.log("Avatar Status", props.status)
  
  return (
    <SVG
      name = "right-chevron"
      fill = {EVENT_COLOR[props.eventType]}
      size = {50}
    />
  );
};

EventAvatar.propTypes = {
  status: PropTypes.string
};

export default EventAvatar;
