import React from 'react';
import parseAddress from '../../../util/parseAddress';
import {
    parseDuration, parseTime
} from '../../../util/time';




export const TABLE_TYPE_KEYS = {
    MILEAGE: 'Mileage',
    DAILY_MILEAGE: 'Daily Mileage',
    TRIP: 'Trip',
    TRANSIT: 'Transit',
    DETAIL: 'Detail',
    GEOFENCE: 'Geofence',
    EVENT: 'Event',
    REFUEL_REPORT: 'Refuel Report',
    FUEL_ANALYSIS: 'Fuel Usage Analysis',
    DAILY_FUEL_ANALYSIS: 'Daily Fuel Usage Analysis',
    DISCONNECT: 'Disconnect',
    IDLING: 'Idling',
    OVERSPEEDING: 'OverSpeeding',
}

// Do not sort!
export const TABLE_TYPES = {
    [TABLE_TYPE_KEYS.MILEAGE]: {
        title: TABLE_TYPE_KEYS.MILEAGE,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
                render: number => isNaN(number) ? number : number.toFixed(2),
            },

            {
                title: 'Number of Trips',
                key: 'numberOfTrips',
                dataIndex: 'numberOfTrips',
            },

            {
                title: 'Total Engine Duration (hrs)',
                key: 'totalEngineDuration',
                dataIndex: 'totalEngineDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Moving Duration (hrs)',
                key: 'totalMovingDuration',
                dataIndex: 'totalMovingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Idling Duration (hrs)',
                key: 'totalIdlingDuration',
                dataIndex: 'totalIdlingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Estimated Total Litres Spent (l)',
                key: 'totalLitresSpent',
                dataIndex: 'totalLitresSpent',
                render: number => isNaN(number) ? number : number.toFixed(2),
            },

            {
                title: 'Estimated Total Fuel Expenses (RM)',
                key: 'totalFuelExpenses',
                dataIndex: 'totalFuelExpenses',
                render: number => isNaN(number) ? number : number.toFixed(2),
            },
        ]
    },

    [TABLE_TYPE_KEYS.DAILY_MILEAGE]: {
        title: TABLE_TYPE_KEYS.DAILY_MILEAGE,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Start Date',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'End Date',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (time) => parseTime(time),
            },
            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
                render: number => {
                    return isNaN(number) ? number : number.toFixed(2)
                }
            },

            {
                title: 'Number of Trips',
                key: 'numberOfTrips',
                dataIndex: 'numberOfTrips',
            },

            {
                title: 'Total Engine Duration (hrs)',
                key: 'totalEngineDuration',
                dataIndex: 'totalEngineDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Moving Duration (hrs)',
                key: 'totalMovingDuration',
                dataIndex: 'totalMovingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Idling Duration (hrs)',
                key: 'totalIdlingDuration',
                dataIndex: 'totalIdlingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Estimated Total Litres Spent (l)',
                key: 'totalLitresSpent',
                dataIndex: 'totalLitresSpent',
                render: number => isNaN(number) ? number : number.toFixed(2),
            },

            {
                title: 'Estimated Total Fuel Expenses (RM)',
                key: 'totalFuelExpenses',
                dataIndex: 'totalFuelExpenses',
                render: number => isNaN(number) ? number : number.toFixed(2),
            },
        ]
    },

    [TABLE_TYPE_KEYS.TRIP]: {
        title: TABLE_TYPE_KEYS.TRIP,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Trip Status',
                key: 'tripStatus',
                dataIndex: 'tripStatus',
            },

            {
                title: 'Start Date',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'End Date',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'Start Location',
                key: 'startLocation',
                dataIndex: 'startLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.startAddress) : "-",
            },

            {
                title: 'End Location',
                key: 'endLocation',
                dataIndex: 'endLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.endAddress) : "-",
            },

            {
                title: 'Total Duration (hrs)',
                dataIndex: 'totalDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
            },

            {
                title: 'Total Moving Duration',
                key: 'totalMovingDuration',
                dataIndex: 'totalMovingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Idling Duration',
                key: 'totalIdlingDuration',
                dataIndex: 'totalIdlingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Parking Duration',
                key: 'totalParkingDuration',
                dataIndex: 'totalParkingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Top Speed (km/h)',
                key: 'topSpeed',
                dataIndex: 'topSpeed',
            },

            {
                title: 'Total Events',
                key: 'totalEvents',
                dataIndex: 'totalEvents',
            },
        ]
    },

    [TABLE_TYPE_KEYS.TRANSIT]: {
        title: TABLE_TYPE_KEYS.TRANSIT,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Transit Status',
                key: 'transitStatus',
                dataIndex: 'transitStatus',
            },

            {
                title: 'Start Date',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'End Date',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'Total Duration (hrs)',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Start Location',
                key: 'startLocation',
                dataIndex: 'startLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.startAddress) : "-",
            },

            {
                title: 'End Location',
                key: 'endLocation',
                dataIndex: 'endLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.endAddress) : "-",
            },

            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
            },
        ]
    },

    [TABLE_TYPE_KEYS.DETAIL]: {
        title: TABLE_TYPE_KEYS.DETAIL,
        columns: [
            {
                title: 'Server Time',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: (time) => parseTime(time),
            },

            {
                title: "Device Time",
                key: 'deviceTime',
                dataIndex: "deviceTime",
                render: (time) => parseTime(time),
            },

            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Device IMEI',
                key: 'deviceIMEI',
                dataIndex: 'deviceIMEI',
            },

            {
                title: 'Data Package',
                key: 'devicePackage',
                dataIndex: 'devicePackage',
            },

            {
                title: "Message",
                key: "deviceMessage",
                dataIndex: "deviceMessage",
            },

            {
                title: "GPS Status",
                key: "gpsStatus",
                dataIndex: "gpsStatus",
                render: (data) => data === "ON" ? <b style={{ color: "green" }}>{data}</b> : <b style={{ color: "red" }}>{data}</b>
            },

            {
                title: "Engine",
                key: "engineStatus",
                dataIndex: "engineStatus",
                render: (data) => data === "ON" ? <b style={{ color: "green" }}>{data}</b> : <b style={{ color: "red" }}>{data}</b>
            },

            {
                title: "Location",
                key: "location",
                dataIndex: "location",
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.address) : "-",
            },

            {
                title: "Geofences",
                key: "geofences",
                dataIndex: "geofences",
                render: (data) => data.length > 0 ? data.join(', ') : "-"
            },

            {
                title: "Speed (km/h)",
                key: "speed",
                dataIndex: "speed",
            },

            {
                title: "Direction",
                key: "heading",
                dataIndex: "heading",
            },
        ]
    },

    [TABLE_TYPE_KEYS.DISCONNECT]: {
        title: TABLE_TYPE_KEYS.DISCONNECT,
        columns: [
            {
                title: 'Vehicle Plate',
                key: 'vehicle',
                dataIndex: 'vehicle'
            },
            
            {
                title: 'Device',
                key: 'deviceIMEI',
                dataIndex: 'deviceIMEI'  
            },

            {
                title: 'Disconnect Location',
                key: 'startLocation',
                dataIndex: 'startLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.startAddress) : "-",
            },

            {
                title: 'Start Date',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (data) => parseTime(data)
            },

            {
                title: 'End Date',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (data) => data === 'ONGOING'? 'ONGOING' : parseTime(data)
            },

            {
                title: 'Duration',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data)
            }
        ]
    },

    [TABLE_TYPE_KEYS.GEOFENCE]: {
        title: TABLE_TYPE_KEYS.GEOFENCE,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Geofence Template(s)',
                key: 'geofenceTemplates',
                dataIndex: 'geofenceTemplates',
            },

            {
                title: 'Geofence Name',
                key: 'geofenceName',
                dataIndex: 'geofenceName',
            },

            {
                title: 'IN',
                key: 'inAt',
                dataIndex: 'inAt',
                render: (data) => parseTime(data),
            },

            {
                title: 'OUT',
                key: 'outAt',
                dataIndex: 'outAt',
                render: (data) => parseTime(data),
            },

            {
                title: 'Total Duration (hrs)',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data),
            },
        ]
    },

    [TABLE_TYPE_KEYS.EVENT]: {
        title: TABLE_TYPE_KEYS.EVENT,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Date',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: (data) => parseTime(data),
            },

            {
                title: 'Event Name',
                key: 'eventName',
                dataIndex: 'eventName',
            },

            {
                title: 'Event Type',
                key: 'eventType',
                dataIndex: 'eventType',
            },

            {
                title: 'Location',
                key: 'location',
                dataIndex: 'location',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.address) : "-",
            },
        ]
    },

    [TABLE_TYPE_KEYS.REFUEL_REPORT]: {
        title: TABLE_TYPE_KEYS.REFUEL_REPORT,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },
            {
                title: 'Fuel Tank #',
                dataIndex: 'fuelTankNumber',
            },
            {
                title: 'Fuel Tank Capacity',
                dataIndex: 'fuelCapacity',
            },


            {
                title: 'Refuel Start Time',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (data) => parseTime(data),
            },

            {
                title: 'Refuel End Time',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (data) => parseTime(data),
            },

            {
                title: 'Start Fuel Level (L)',
                key: 'startFuel',
                dataIndex: 'startFuel',
                render: (number) => isNaN(number) ? number : number.toFixed(2)
            },

            {
                title: 'End Fuel Level (L)',
                key: 'endFuel',
                dataIndex: 'endFuel',
                render: (number) => isNaN(number) ? number : number.toFixed(2)
            },

            {
                key: 'deviceFuelUse',
                title: "Refuel (L)",
                dataIndex: "deviceFuelUse",
                render: (number) => isNaN(number) ? number : number.toFixed(2)
            },

            {
                key: 'deviceTotalCost',
                title: "Estimated Fuel Cost (RM)",
                dataIndex: "deviceTotalCost",
                render: (number) => isNaN(number) ? number : number.toFixed(2)
            },

            {
                key: 'address',
                title: "Address",
                dataIndex: "location",
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.address) : "-",
            },
        ]
    },

    [TABLE_TYPE_KEYS.DAILY_FUEL_ANALYSIS]: {
        title: TABLE_TYPE_KEYS.DAILY_FUEL_ANALYSIS,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Start Date',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'End Date',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (time) => parseTime(time),
            },
            {
                title: 'Fuel Tank #',
                dataIndex: 'fuelTankNumber',
                render: fuelTankNumber => {
                    if (!fuelTankNumber) return `No fuel tank available`
                    return fuelTankNumber
                }
            },
            {
                title: 'Fuel Tank Capacity',
                dataIndex: 'fuelCapacity',
                render: fuelCapacity => {
                    if (!fuelCapacity) return `No fuel tank available`
                    return fuelCapacity
                }
            },
            {
                title: 'Fuel Tank Operator',
                dataIndex: 'fuelLevelOperator',
                render: fuelLevelOperator => {
                    return fuelLevelOperator
                }
            },
            {
                title: 'Fuel Tank Sensor Type',
                dataIndex: 'fuelSensorType',
                render: fuelSensorType => {
                    return fuelSensorType
                }
            },

            {
                title: 'Total Fuel Consumption (L)',
                dataIndex: 'totalFuelConsumptionInLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Fuel Consumption Rate (L/km)',
                dataIndex: 'totalFuelConsumptionRate',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Fuel Consumption Efficiency (km/kL)',
                dataIndex: 'totalFuelConsumptionEfficiency',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Total Moving Distance (km)',
                dataIndex: 'totalMovingDistance',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Total Moving Fuel Consumption (L)',
                dataIndex: 'totalMovingFuelConsumptioninLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Total Idling Duration',
                dataIndex: 'totalIdlingDuration',
                render: (duration, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return duration && parseDuration(duration)
                }
            },

            {
                title: 'Total Idling Fuel Consumption (L)',
                dataIndex: 'totalIdlingFuelConsumptioninLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },


            // {
            //     title: 'Total Idling Fuel Consumption Rate (L/hr)',
            //     dataIndex: 'idlingFuelConsumptionRateInLitrePerHour',
            //     render: (number, rowData) => {
            //         if (!rowData.fuelCapacity) return `Not available`
            //         return (number && Number(number.toFixed(2)))  || 0
            //     }
            // },

            // {
            //     title: 'Total Idling Fuel Efficiency (duration / L)',
            //     dataIndex: 'idlingFuelEffiencyInLitre',
            //     render: (duration, rowData) => {
            //         if (!rowData.fuelCapacity) return `Not available`
            //         return duration && parseDuration(duration)
            //     }
            // },


            {
                title: 'Total Parking Duration',
                dataIndex: 'totalParkingDuration',
                render: (duration, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return duration && parseDuration(duration)
                }
            },

            {
                title: 'Total Parking Fuel Consumption (L)',
                dataIndex: 'totalParkingFuelConsumptioninLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },
        ]
    },
    [TABLE_TYPE_KEYS.FUEL_ANALYSIS]: {
        title: TABLE_TYPE_KEYS.FUEL_ANALYSIS,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },
            {
                title: 'Fuel Tank #',
                dataIndex: 'fuelTankNumber',
                render: fuelTankNumber => {
                    if (!fuelTankNumber) return `No fuel tank available`
                    return fuelTankNumber
                }
            },
            {
                title: 'Fuel Tank Capacity',
                dataIndex: 'fuelCapacity',
                render: fuelCapacity => {
                    if (!fuelCapacity) return `No fuel tank available`
                    return fuelCapacity
                }
            },
            {
                title: 'Fuel Tank Operator',
                dataIndex: 'fuelLevelOperator',
                render: fuelLevelOperator => {
                    return fuelLevelOperator
                }
            },
            {
                title: 'Fuel Tank Sensor Type',
                dataIndex: 'fuelSensorType',
                render: fuelSensorType => {
                    return fuelSensorType
                }
            },

            {
                title: 'Total Fuel Consumption (L)',
                dataIndex: 'totalFuelConsumptionInLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Fuel Consumption Rate (L/km)',
                dataIndex: 'totalFuelConsumptionRate',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Fuel Consumption Efficiency (km/kL)',
                dataIndex: 'totalFuelConsumptionEfficiency',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Total Moving Distance (km)',
                dataIndex: 'totalMovingDistance',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Total Moving Fuel Consumption (L)',
                dataIndex: 'totalMovingFuelConsumptioninLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },

            {
                title: 'Total Idling Duration',
                dataIndex: 'totalIdlingDuration',
                render: (duration, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return duration && parseDuration(duration)
                }
            },

            {
                title: 'Total Idling Fuel Consumption (L)',
                dataIndex: 'totalIdlingFuelConsumptioninLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },


            // {
            //     title: 'Total Idling Fuel Consumption Rate (L/hr)',
            //     dataIndex: 'idlingFuelConsumptionRateInLitrePerHour',
            //     render: (number, rowData) => {
            //         if (!rowData.fuelCapacity) return `Not available`
            //         return (number && Number(number.toFixed(2)))  || 0
            //     }
            // },

            // {
            //     title: 'Total Idling Fuel Efficiency (duration / L)',
            //     dataIndex: 'idlingFuelEffiencyInLitre',
            //     render: (duration, rowData) => {
            //         if (!rowData.fuelCapacity) return `Not available`
            //         return duration && parseDuration(duration)
            //     }
            // },


            {
                title: 'Total Parking Duration',
                dataIndex: 'totalParkingDuration',
                render: (duration, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return duration && parseDuration(duration)
                }
            },

            {
                title: 'Total Parking Fuel Consumption (L)',
                dataIndex: 'totalParkingFuelConsumptioninLitre',
                render: (number, rowData) => {
                    if (!rowData.fuelCapacity) return `Not available`
                    return (number && Number(number.toFixed(2)))  || 0
                }
            },
        ]
    },

    [TABLE_TYPE_KEYS.IDLING]: {
        title: TABLE_TYPE_KEYS.IDLING,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Start Time',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'End Time',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'Day / Night',
                key: 'dayNight',
                dataIndex: 'dayNight',
            },

            {
                title: 'Total Duration (hrs)',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Start Location',
                key: 'startLocation',
                dataIndex: 'startLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.startAddress) : "-",
            },

            {
                title: 'End Location',
                key: 'endLocation',
                dataIndex: 'endLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.endAddress) : "-",
            },

            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
            },

            {
                title: 'Estimated Total Litres Spent (l)',
                key: 'totalLitresSpent',
                dataIndex: 'totalLitresSpent',
                render: number => isNaN(number) ? number : number.toFixed(2),
            },

            {
                title: 'Estimated Total Fuel Expenses (RM)',
                key: 'totalFuelExpenses',
                dataIndex: 'totalFuelExpenses',
                render: number => isNaN(number) ? number : number.toFixed(2),
            },
        ]
    },

    [TABLE_TYPE_KEYS.OVERSPEEDING]: {
        title: TABLE_TYPE_KEYS.OVERSPEEDING,
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Start Time',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'End Time',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (time) => parseTime(time),
            },

            {
                title: 'Speed',
                key: 'speed',
                dataIndex: 'speed',
            },

            {
                title: 'Start Location',
                key: 'startLocation',
                dataIndex: 'startLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.startAddress) : "-",
            },

            {
                title: 'End Location',
                key: 'endLocation',
                dataIndex: 'endLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.endAddress) : "-",
            },

            {
                title: 'Total Duration (hrs)',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data),
            },
            
            {
                title: 'Estimated Distance (km)',
                key: 'distance',
                dataIndex: 'distance',
            },
        ]
    },
}
