import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Highlighter from 'react-highlight-words';

import { moveToPage } from '../../navigation/navigationService';
import {
    Tag,
    Input,
    Modal,
    Table,
    Button,
    Tooltip,
} from 'antd';
import {
    EyeOutlined,
    CheckOutlined,
    PhoneOutlined,
    ReloadOutlined,
    SearchOutlined,
    LoadingOutlined,
} from '@ant-design/icons';

// Redux Actions
import { 
    get_ticket_states, 
    resolve_ticket_states, 
} from '../../services/redux/actions/ticketStates';

const GPSFLEET_SUPPORT_NUMBER = `60137439285`;

// const END_OF_DAY = moment().endOf('day').valueOf();
// const START_OF_DAY = moment().startOf('day').valueOf();

const EVENT_STATUS_FILTER_BUTTONS = {
    INFO: 'info',
    WARNING: 'warning',
    CRITICAL: 'critical',
}

class Tickets extends Component {
    state = {
        isLoading: true,

        columns: [],
        dataSource: [],
        selectedTicketIDs: [],
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style = {{ padding: 8 }}>
                <Input
                    ref = {(node) => { this.searchInput = node }}
                    placeholder = {`Search ${dataIndex}`}
                    value = {selectedKeys[0]}
                    onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        width: 188,
                        marginBottom: 8,
                        display: 'block',
                    }}
                />

                <Button
                    type = "primary"
                    icon = {<SearchOutlined />}
                    size = "small"
                    onClick = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        width: 90,
                        marginRight: 8,
                    }}
                >
                    Search
                </Button>

                <Button
                    size = "small"
                    onClick = {() => this.handleReset(clearFilters)}
                    style = {{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                autoEscape
                textToHighlight = {text ? text : ""}
                searchWords = {[this.state.searchText]}
                highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();

        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();

        this.setState({ searchText: '' });
    };

    markAcknowledge = (selectedTicketIDs) => {
        if (!selectedTicketIDs) return

        const userName = this.props.user.userName
        let remark = `Tickets acknowledged on ${moment().format('LLLL')} by ${userName}`;

        return (
            Modal.confirm({
                width: 700,
                title: `Resolve ${selectedTicketIDs.length} Ticket${selectedTicketIDs.length === 1 ? "" : "s"}?`,
                content: (
                    <div>
                        <h3>Remarks</h3>
                        <Input.TextArea
                            rows = {4}
                            defaultValue = {remark}
                            onChange = {e => {
                                remark = e.target.value
                            }}
                        />
                    </div>
                ),
                onOk: () => {
                    const ticketStates = selectedTicketIDs.map(ticketId => this.props.ticketStates.byTicketId[ticketId]);

                    this.props.dispatch(resolve_ticket_states(ticketStates, remark, this.props.user.uid));
                },
            })
        )
    }

    contactSupport = (vehiclePlate, eventName, createdAt) => {
        let message = `Hi I am ${this.props.user.userRole}, ${this.props.user.userName} (${this.props.user.userEmail}). 
        I need help for vehicle *${vehiclePlate}* for event *${eventName}* at *${moment(createdAt).format('LLLL')}*
            `
        message = message.replace('/ /g', '%20')

        // console.log(message)

        window.open(`https://api.whatsapp.com/send?phone=${GPSFLEET_SUPPORT_NUMBER}&text=${message}`, '_blank');
    }

    getTicketsSummary = () => {
        const { dataSource } = this.state;

        // const dataSourceByEventType = {
        //     [EVENT_STATUS_FILTER_BUTTONS.INFO]: 0,
        //     [EVENT_STATUS_FILTER_BUTTONS.WARNING]: 0,
        //     [EVENT_STATUS_FILTER_BUTTONS.CRITICAL]: 0,
        // }

        // dataSource.forEach(d => {
        //     if (!dataSourceByEventType[d.eventType]) {
        //         dataSourceByEventType[d.eventType] = 0
        //     }

        //     dataSourceByEventType[d.eventType]++;
        // })

        return (
            // <h3>Total number of tickets: {dataSource.length}</h3>
            <h3>Total No. Of Tickets: {dataSource.length}</h3>
        )
    }

    getInitialData = () => {
        if (Object.keys(this.props.vehicles.byId).length) {
            this.props.dispatch(get_ticket_states(Object.keys(this.props.vehicles.byId)))
            .then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    setInitialData = () => {
        const {
            events,
            devices,
            vehicles,
            isLoading,
            ticketStates,
        } = this.props;

        // console.log("Test");

        // console.log({ START_OF_DAY, END_OF_DAY })

        const dataSource =
            Object.values(ticketStates.byTicketId)
                .filter((ticketState) => !devices.selectedId || (vehicles.byId[ticketState.vid] && vehicles.byId[ticketState.vid].dvid && vehicles.byId[ticketState.vid].dvid === devices.selectedId))
                .filter(ticketState => ticketState.event)
                .sort((a, b) => b.createdAt - a.createdAt)
                .map(ticketState => {
                    const {
                        vid,
                        evid
                    } = ticketState

                    const event = ticketState.event;
                    const vehicle = vehicles.byId[vid];
                    const address = event && event.location && JSON.stringify(event.location) && this.props.address.byLocation[JSON.stringify(event.location)];

                    return {
                        key: ticketState.ticketId,
                        createdAt: ticketState.createdAt,

                        evid: evid,
                        address: address,
                        location: event && event.location,
                        eventName: event && event.eventName,
                        eventType: event && event.eventType,
                        vehiclePlate: vehicle && `${vehicle.vehicleDisplayName} (${vehicle.vehiclePlate})`,
                    }
                })

        const columns = [
            {
                title: 'Date',
                dataIndex: 'createdAt',
                render: (date) => moment(date).format('LLLL')
            },
            {
                title: 'Vehicle',
                dataIndex: 'vehiclePlate',
                ...this.getColumnSearchProps('vehiclePlate')
            },
            {
                title: 'Event Type',
                dataIndex: 'eventType',
                filters: Object.values(EVENT_STATUS_FILTER_BUTTONS).map(type => ({ text: type, value: type })),
                onFilter: (value, record) => record.eventType && record.eventType.toLowerCase().indexOf(value) === 0,
                render: eventType => {
                    if (!Object.keys(events.byId).length && isLoading) return <LoadingOutlined />

                    if (eventType) {
                        eventType = eventType.toUpperCase();

                        let color = 'black';

                        switch (eventType.toLowerCase()) {
                            case EVENT_STATUS_FILTER_BUTTONS.INFO:
                                color = 'blue'
                                break
                            case EVENT_STATUS_FILTER_BUTTONS.CRITICAL:
                                color = 'red'
                                break
                            case EVENT_STATUS_FILTER_BUTTONS.WARNING:
                                color = 'yellow'
                                break
                            default:
                                color = 'white'
                                break
                        }

                        return <Tag color = {color}>{eventType}</Tag>
                    }
                },
            },
            {
                title: 'Event',
                dataIndex: 'eventName',
                render: eventName => {
                    if (!Object.keys(events.byId).length && isLoading) return <LoadingOutlined />

                    return eventName;
                },
                ...this.getColumnSearchProps('eventName')
            },
            {
                title: 'View On Map',
                dataIndex: 'location',
                render: (location, rowData) => {
                    if (!location) return 'N\\A'
                    else return (
                        <EyeOutlined
                            style = {{ fontSize: 20 }}
                            onClick = {() => {
                                // console.log(rowData.key)

                                this.props.dispatch(moveToPage('/', { ticketId: rowData.key }))
                            }}
                        />
                    )
                }
            },
            {
                title: 'Action',
                render: (rowData) => {
                    return (
                        <div 
                            style = {{ 
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Tooltip title = {"Mark Resolved"}>
                                <CheckOutlined 
                                    onClick = {() => {
                                        console.log("Mark Resolved:", [rowData.key]);

                                        this.markAcknowledge([rowData.key]);
                                    }}
                                    style = {{ fontSize: 15, marginRight: 5 } }
                                />
                            </Tooltip>

                            <Tooltip title = {"Contact Support"}>
                                <PhoneOutlined 
                                    onClick = {() => {
                                        const { vehiclePlate, eventName, createdAt } = rowData;

                                        this.contactSupport(vehiclePlate, eventName, createdAt);
                                    }}
                                    style = {{ fontSize: 15 }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];

        this.setState({
            columns,
            dataSource,
            isLoading: false,
        })
    }

    componentDidMount = () => {
        this.getInitialData();

        if (Object.keys(this.props.vehicles.byId).length && Object.keys(this.props.ticketStates.byTicketId).length) {
            this.setInitialData();
        }
    }

    componentDidUpdate = (prevProps) => {
        const { 
            vehicles,
            ticketStates, 
        } = this.props;
        
        if (JSON.stringify(prevProps.vehicles) !== JSON.stringify(vehicles) && Object.keys(this.props.vehicles.byId).length) {
            this.props.dispatch(get_ticket_states(Object.keys(this.props.vehicles.byId)))
            .then(() => {
                this.setState({ isLoading: false });
            })
        }

        if (
            (
                JSON.stringify(prevProps.ticketStates) !== JSON.stringify(ticketStates)
                || JSON.stringify(prevProps.vehicles) !== JSON.stringify(vehicles)
                || JSON.stringify(prevProps.events) !== JSON.stringify(this.props.events)
                || JSON.stringify(prevProps.devices) !== JSON.stringify(this.props.devices)
            )
            && Object.keys(vehicles.byId).length
            && Object.keys(ticketStates.byTicketId).length
        ) {
            this.setInitialData();
        }
    }

    render() {
        const {
            columns,
            isLoading,
            dataSource,
            selectedTicketIDs,
        } = this.state;

        return (
            <div>
                <div
                    style = {{
                        display: 'flex',

                        padding: 5,
                        backgroundColor: 'white',
                    }}
                >
                    {this.getTicketsSummary()}

                    {
                        <div
                            style = {{
                                flex: 5,

                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                icon = {<ReloadOutlined/>}
                                loading = {isLoading}
                                onClick = {() => {
                                    this.setState({ 
                                        isLoading: true,
                                    },
                                        () => {
                                            this.getInitialData();
                                        }
                                    )
                                }}
                                style = {{ marginRight: 5 }}
                            >
                                Refresh
                            </Button>

                            <Button
                                icon = {<CheckOutlined />}
                                disabled = {!selectedTicketIDs || !selectedTicketIDs.length}
                                onClick = {() => this.markAcknowledge(selectedTicketIDs)}
                            >
                                Bulk Mark Resolved
                            </Button>
                        </div>
                    }
                </div>

                <Table
                    columns = {columns}
                    dataSource = {dataSource}
                    loading = {isLoading}
                    rowSelection = {{
                        onChange: (selectedTicketIDs) => {
                            this.setState({ selectedTicketIDs })
                        }
                    }}
                    scroll = {{
                        x: columns && columns.length * 100,
                        y: window.innerHeight * 0.2,
                    }}
                    style = {{
                        flex: 1,

                        backgroundColor: 'white',
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    style: state.v2.style,
    events: state.v2.events,
    address: state.v2.address,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    ticketStates: state.v2.ticketStates,
});

export default connect(mapStateToProps)(Tickets);