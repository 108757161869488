import * as ActionTypes from '../action-types/user'

export const user = (state = {}, action) => {

    let newObj = JSON.parse(JSON.stringify(state))

    switch (action.type) {
        case ActionTypes.USER_SIGN_IN: {
            let user = action.user;
            return({
                ...state,
                ...user
            });
        }
        case ActionTypes.USER_PROFILE_PREFERENCE_UPDATE: {

            let preference = action.preference;

            return({
                ...newObj,
                preference: {
                    ...newObj.preference,
                    ...preference
                }
            })
        }
        default: {
            return state;
        }
    }
}