import * as API from "../../api";
import * as actionType from "../action-types/deviceGroups";

import { message } from "antd";
import { get_devices_data } from './devices';
import { subscribeWS } from '../../websocket';

export const get_devices_group = (deviceGroupArray, uid) => (dispatch) => {
    try {
        API.getDeviceByGroup(deviceGroupArray, uid)
    .then(data => {
        // console.log("Get Device Group Data:", data);

        if (data.status !== 200 && data.message) {
          message.error(data.message);
        }

        if (data.status === 200) {
            if(data.devices && data.devices.length > 0) {
                let dvids = data.devices.map(device => `dev#${device.dvid}`);
                
                subscribeWS(dvids, uid);
            }

            data.devices && dispatch(get_devices_data(data.devices));
            data.deviceGroups && dispatch(get_devices_group_success(data.deviceGroups));
        }
    })
    } catch(err) {
        console.log('Error', err)
    }
}

export const get_devices_group_success = (deviceGroups) => {
    return {
        type: actionType.GET_DEVICES_GROUP,
        deviceGroups: deviceGroups
    }
}