import React from 'react';
import { connect } from 'react-redux';

import { EVENT_TYPES } from '../../../../../constants';
import {
    Form,
    Select,
    TreeSelect,
} from 'antd';
import { TABLE_TYPE_KEYS } from '../TableTypes';

const {
    TreeNode,
    SHOW_PARENT
} = TreeSelect;

const defaultFilter = "All";

// Requires props:
// - isModal
// - tempTable
// - selectedDuration
// - selectedEventTypes
// - selectedGeofenceFilters
// - onEventFilterChange
// - onDurationFilterChange
// - onGeofenceFilterChange
class AdvancedFilters extends React.Component {
    returnAdvancedFilters = () => {
        const { geofences } = this.props;

        const returnDurationList = () => {
            const durationList = [1, 2, 5, 10, 15, 30];

            let durationOptionList = [
                <Select.Option key = {defaultFilter} value = {defaultFilter}>
                    {defaultFilter}
                </Select.Option>
            ];

            durationList.forEach((currDur) => {
                durationOptionList.push(
                    <Select.Option key = {currDur} value = {currDur}>
                        {`> ${currDur} minutes`}
                    </Select.Option>
                );
            }) 

            return durationOptionList;
        }

        switch(this.props.tempTable) {
            case TABLE_TYPE_KEYS.EVENT: {
                return (
                    <Form layout = 'inline'>
                        <Form.Item label = {"Event Type:"}>
                            <TreeSelect
                                allowClear
                                treeCheckable
                                showCheckedStrategy = {SHOW_PARENT}
                                disabled = {!Object.keys(EVENT_TYPES).length}
                                placeholder = {Object.keys(EVENT_TYPES).length ? 'Filter by Event Types' : "No Event Types Found"}
                                value = {this.props.selectedEventTypes}
                                onChange = {(value) => this.props.onEventFilterChange(value)}
                                style = {{ width: 200 }}
                            >
                                {
                                    <TreeNode
                                        key = {defaultFilter}
                                        value = {defaultFilter}
                                        title = {"All Event Types"}
                                    >
                                        {
                                            Object.keys(EVENT_TYPES)
                                                .filter((currEvent) => currEvent !== "ALL")
                                                .map((currEvent) => 
                                                    <TreeNode
                                                        key = {currEvent}
                                                        value = {currEvent}
                                                        title = {currEvent}
                                                    />
                                                )
                                        }
                                    </TreeNode>
                                }
                            </TreeSelect>
                        </Form.Item> 
                    </Form>
                );
            }
            
            case TABLE_TYPE_KEYS.GEOFENCE: {
                return (
                    <Form layout = {this.props.isModal ? null : 'inline'}>
                        <Form.Item 
                            label = {"Duration:"}
                            labelCol = {this.props.isModal ? { span: 7 } : { span: 0 }}
                            wrapperCol = {this.props.isModal ? { span: 15 } : { span: 0 }}
                        >
                            <Select
                                placeholder = {"Filter by Duration"}
                                value = {this.props.selectedDuration}
                                onChange = {(value) => this.props.onDurationFilterChange(value)}
                                style = {{ width: 200 }}
                            >
                                {returnDurationList()}
                            </Select>
                        </Form.Item> 
                    
                        <Form.Item 
                            label = {"Geofences:"}
                            labelCol = {this.props.isModal ? { span: 7 } : { span: 0 }}
                            wrapperCol = {this.props.isModal ? { span: 15 } : { span: 0 }}
                        >
                            <TreeSelect
                                allowClear
                                treeCheckable
                                showCheckedStrategy = {SHOW_PARENT}
                                disabled = {!Object.keys(geofences.byId).length}
                                placeholder = {Object.keys(geofences.byId).length ? 'Filter by Geofences' : "No Geofences Found"}
                                value = {this.props.selectedGeofenceFilters}
                                onChange = {(value) => this.props.onGeofenceFilterChange(value)}
                                style = {{ width: 200 }}
                            >
                                {
                                    <TreeNode
                                        key = {defaultFilter}
                                        value = {defaultFilter}
                                        title = {"All Geofences"}
                                    >
                                        {
                                            Object.values(geofences.byId)
                                                .filter((currGeofence) => currGeofence.geofenceName)
                                                .sort((a, b) => {
                                                    const gA = a.geofenceName.toLowerCase();
                                                    const gB = b.geofenceName.toLowerCase();
                                            
                                                    if (gA < gB) return -1;
                                                    if (gA > gB) return 1;
                                                    return 0;
                                                })
                                                .map((currGeofence) => 
                                                    <TreeNode
                                                        key = {currGeofence.geoid}
                                                        value = {currGeofence.geoid}
                                                        title = {currGeofence.geofenceName}
                                                    />
                                                )
                                        }
                                    </TreeNode>
                                }
                            </TreeSelect>
                        </Form.Item>
                    </Form>
                );
            }

            default: {
                return null;
            }
        }
    }

    render() { 
        return this.returnAdvancedFilters()
    }
}

const mapStateToProps = (state) => ({
    geofences: state.v2.geofences,
});

export default connect(mapStateToProps)(AdvancedFilters);