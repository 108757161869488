import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";

import asyncComponent from "../../../components/AsyncComponent";

// Pages
import MainPanel from "../pages/MainPanel";
import InformationTablePage from "../pages/InformationTablePage";
// import GeofenceRecordPage from "../pages/GeofenceRecordPage";

// import ReportPage from "../pages/ReportPage";
import ProfilePage from "../pages/ProfilePage";
import EventRecord from "../pages/EventRecordPage";
// import VehicleActivityPage from "../pages/VehicleActivityPage";

import CustomerManagementPage from "../pages/UserManagementPage"
import AddUser from "../pages/UserManagementPage/AddUser";
import EditUser from "../pages/UserManagementPage/EditUser";
import ConfigureUserPage from "../pages/UserManagementPage/ConfigureUser";
// import AddCustomerDevicePage from "../pages/UserManagementPage/_AddDevice";
import AddCustomerVehiclePage from "../pages/UserManagementPage/AddVehicle";
import EditCustomerVehiclePage from "../pages/UserManagementPage/EditVehicle";

import ManagementPage from "../pages/ManagementPage";
// import EditUserPage from "../pages/ManagementPage/UserPanel/EditUser";
import AddRulePage from "../pages/ManagementPage/RuleEnginePanel/AddRule";
import EditRulePage from "../pages/ManagementPage/RuleEnginePanel/EditRule";
import AddDevicePage from "../pages/ManagementPage/DevicePanel/AddDevice";
// import AddDeviceGroupPage from "../pages/ManagementPage/DevicePanel/AddDeviceGroup";
import EditDevicePage from "../pages/ManagementPage/DevicePanel/EditDevice";
import AddVehiclePage from "../pages/ManagementPage/VehiclePanel/AddVehicle";
import EditVehiclePage from "../pages/ManagementPage/VehiclePanel/EditVehicle";
import AddRuleTemplatePage from "../pages/ManagementPage/RuleEnginePanel/AddRuleTemplate";
import EditRuleTemplatePage from "../pages/ManagementPage/RuleEnginePanel/EditRuleTemplate";
import AddNotificationPage from "../pages/ManagementPage/NotificationPanel/AddNotification";
import EditNotificationPage from "../pages/ManagementPage/NotificationPanel/EditNotification";
import NotificationLogPage from "../pages/ManagementPage/NotificationPanel/NotificationLogPage";

import FuelAnalysisPage from "../pages/FuelAnalysisPage";
// import FuelAnalysis2Page from "../pages/FuelAnalysisPage2";

import DeviceLogInspectorPage from "../pages/DeviceLogInspectorPage";
import VehicleLogInspectorPage from "../pages/VehicleLogInspectorPage";

import AddGeofenceTemplatePage from "../pages/GeofencePage/AddGeofenceTemplatePage";
import EditGeofenceTemplatePage from "../pages/GeofencePage/EditGeofenceTemplatePage";

import MigrationPage from "../pages/MigrationPage";
import MigrateUserPage from "../pages/MigrationPage/MigrateUser";

import SOSAlertsPage from "../pages/SOSAlertsPage";
import VehicleReportPage from "../pages/VehicleReportPage";

import VehicleSubscriptionPage from "../pages/VehicleSubscriptionPage";
import AddVehiclePackagePage from "../pages/VehicleSubscriptionPage/AddVehiclePackage";
import EditVehiclePackagePage from "../pages/VehicleSubscriptionPage/EditVehiclePackage";
import RenewVehiclePackagePage from "../pages/VehicleSubscriptionPage/RenewVehiclePackage";


import AddVehicleGroupPage from "../pages/AddVehicleGroupPage";
import EditVehicleGroupPage from "../pages/EditVehicleGroupPage";

// import TemperatureAnalysisPage from "../pages/TemperatureAnalysisPage";

const AsyncNotFoundPage = asyncComponent(() => import("../../../pages/NotFoundPage"));

/**
 * Available Routes for the app
 */

const mainRoute = [
    "/",
    "/Test",
    "/Profile",
    "/EventRecord",

    "/TripRecord",
    "/FleetRecord_Test",

    "/Geofence",
    "/Geofence/EditPOI",
    "/Geofence/AddNewPOI",
    "/Geofence/EditZone",
    "/Geofence/AddNewZone",
]

const MainRoutes = props => {
    return (
        <Switch>
            {
                mainRoute.map(mr =>
                    <Route
                        key={mr}
                        exact
                        path={mr}
                        render={routeProps => <MainPanel {...props} {...routeProps} />}
                    />
                )
            }
            <Route
                exact
                path={"/InformationTable"}
                render={routeProps => (
                    <InformationTablePage {...props} {...routeProps} />
                )}
            />

            {/* <Route
                exact
                path={"/GeofenceRecord"}
                render={routeProps => (
                    <GeofenceRecordPage {...props} {...routeProps} />
                )}
            /> */}

            {/* <Route
                exact
                path={"/VehicleActivity"}
                render={routeProps => (
                    <VehicleActivityPage {...props} {...routeProps} />
                )}
            /> */}

            <Route
                exact
                path={"/UserManagement"}
                render={routeProps => (
                    <CustomerManagementPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/UserManagement/Add"}
                render={routeProps => (
                    <AddUser {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/UserManagement/Edit"}
                render={routeProps => (
                    <EditUser {...props} {...routeProps} />
                )}
            />
            <Route
                exact
                path={"/Management/Vehicle/Add"}
                render={routeProps => (
                    <AddVehiclePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/VehicleGroup/Add"}
                render={routeProps => (
                    <AddVehicleGroupPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/VehicleGroup/Edit"}
                render={routeProps => (
                    <EditVehicleGroupPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/EditVehicleGroup"}
                render={routeProps => (
                    <EditVehicleGroupPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/Vehicle/Edit/:vid"}
                render={routeProps => (
                    <EditVehiclePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/Device/Add"}
                render={routeProps => (
                    <AddDevicePage {...props} {...routeProps} />
                )}
            />


            {/* <Route
                exact
                path={"/Management/DeviceGroup/Add"}
                render={routeProps => (
                    <AddDeviceGroupPage {...props} {...routeProps} />
                )}
            /> */}

            <Route
                exact
                path={"/Management/Device/Edit/:dvid"}
                render={routeProps => (
                    <EditDevicePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/EventRecord"}
                render={routeProps => (
                    <EventRecord {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/Rule/Add"}
                render={routeProps => (
                    <AddRulePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/RuleTemplate/Add"}
                render={routeProps => (
                    <AddRuleTemplatePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/RuleTemplate/Edit/:rtid"}
                render={routeProps => (
                    <EditRuleTemplatePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/Rule/Edit/:ruid"}
                render={routeProps => (
                    <EditRulePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/Notification/Add"}
                render={routeProps => (
                    <AddNotificationPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/Notification/Edit/:nfid"}
                render={routeProps => (
                    <EditNotificationPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Management/Notification/Log"}
                render={routeProps => (
                    <NotificationLogPage {...props} {...routeProps} />
                )}
            />

            <Route
                // exact
                path={"/Management"}
                render={routeProps => (
                    <ManagementPage {...props} {...routeProps} />
                )}
            />

            {/* <Route
                exact
                path={"/Report"}
                render={routeProps => (
                    <ReportPage {...props} {...routeProps} />
                )}
            /> */}

            <Route
                exact
                path={"/Profile"}
                render={routeProps => (
                    <ProfilePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/UserManagement/AddDevice"}
                render={routeProps => (
                    // <AddCustomerDevicePage {...props} {...routeProps} />
                    <AddDevicePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/UserManagement/EditDevice/:dvid"}
                render={routeProps => (
                    // <AddCustomerDevicePage {...props} {...routeProps} />
                    <EditDevicePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/UserManagement/AddVehicle"}
                render={routeProps => (
                    <AddCustomerVehiclePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/UserManagement/EditVehicle/:vid"}
                render={routeProps => (
                    <EditCustomerVehiclePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/UserManagement/ConfigureUser"}
                render={routeProps => (
                    <ConfigureUserPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/FuelAnalysis"}
                render={routeProps => (
                    <FuelAnalysisPage {...props} {...routeProps} />
                )}
            />

            {/* <Route
                exact
                path={"/FuelAnalysis/RefuelReceipt"}
                render={routeProps => (
                    <FuelAnalysisRefuelReceiptPage {...props} {...routeProps}/>
                )}
            /> */}

            {/* <Route
                exact
                path={"/FuelAnalysis/FuelLossRemark"}
                render={routeProps => (
                    <FuelAnalysisFuelLossRemarkPage {...props} {...routeProps} />
                )}
            /> */}

            <Route
                exact
                path={"/DeviceInspector/:uid"}
                render={routeProps => (
                    <DeviceLogInspectorPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/VehicleInspector/:uid"}
                render={routeProps => (
                    <VehicleLogInspectorPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/AddVehicleGroup"}
                render={routeProps => (
                    <AddVehicleGroupPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Geofence/AddGroup"}
                render={routeProps => (
                    <AddGeofenceTemplatePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Geofence/EditGroup"}
                render={routeProps => (
                    <EditGeofenceTemplatePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Migration"}
                render={routeProps => (
                    <MigrationPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/Migration/MigrateUser/:uid"}
                render={routeProps => (
                    <MigrateUserPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/SOSAlerts"}
                render={routeProps => (
                    <SOSAlertsPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/VehicleReport"}
                render={routeProps => (
                    <VehicleReportPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/VehicleSubscription"}
                render={routeProps => (
                    <VehicleSubscriptionPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/VehicleSubscription/Add"}
                render={routeProps => (
                    <AddVehiclePackagePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/VehicleSubscription/Edit"}
                render={routeProps => (
                    <EditVehiclePackagePage {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/VehicleSubscription/Renew"}
                render={routeProps => (
                    <RenewVehiclePackagePage {...props} {...routeProps} />
                )}
            />

            {/* <Route
                exact
                path={"/FuelAnalysis2"}
                render={routeProps => (
                    <FuelAnalysis2Page {...props} {...routeProps} />
                )}
            /> */}

            {/* <Route
                exact
                path={"/TemperatureAnalysis"}
                render={routeProps => (
                    <TemperatureAnalysisPage {...props} {...routeProps} />
                )}
            /> */}

            <Route component={AsyncNotFoundPage} />
        </Switch>
    )
}

// const mapStateToProps = (state)=> ({

// });

// export default connect(mapStateToProps)(MainRoutes);
export default connect(null)(MainRoutes);