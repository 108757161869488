import * as actionType from "../action-types/deviceGroups";

const defaultState = {
    byId: {},
    byName: {},
};

export const deviceGroups = (state = defaultState, action) => {
    const newObject = JSON.parse(JSON.stringify(defaultState))

    switch (action.type) {
        case actionType.GET_DEVICES_GROUP: {
            // console.log("Device Groups Data: ", action.deviceGroups);
            action.deviceGroups.map(deviceGroup => {
                if (!newObject.byId.hasOwnProperty(deviceGroup.dgid)) {
                    newObject.byId[deviceGroup.dgid] = {}
                }

                if (!newObject.byName.hasOwnProperty(deviceGroup.groupName)) {
                    newObject.byName[deviceGroup.groupName] = {}
                }

                newObject.byId[deviceGroup.dgid] = deviceGroup;
                newObject.byName[deviceGroup.groupName] = deviceGroup;

                return null;
            })

            return newObject;
        }

        default: {
            return state;
        }
    }
}