import React from "react";
import { connect } from "react-redux";
import { 
  Route, 
  Switch 
} from "react-router";

import asyncComponent from "../../../components/AsyncComponent";

const AsyncDashboard = asyncComponent(() => import("../pages/Dashboard"));
const AsyncProfilePage = asyncComponent(() => import("../pages/ProfilePage"));
const AsyncGeofencePage = asyncComponent(() => import("../pages/GeofencePage"));
const AsyncEventRecordPage = asyncComponent(() => import("../pages/EventRecordPage"));
const AsyncFleetRecordPage = asyncComponent(() => import("../pages/FleetRecordPage"));
const AsyncReportPage = asyncComponent(() => import("../pages/ReportPage"));
const AsyncEditPOIPage = asyncComponent(() => import("../pages/GeofencePage/EditPOIPage"));
const AsyncAddNewPOIPage = asyncComponent(() => import("../pages/GeofencePage/AddNewPOIPage"));
const AsyncEditPolygonPage = asyncComponent(() => import("../pages/GeofencePage/EditPolygonPage"));
const AsyncAddNewPolygonPage = asyncComponent(() => import("../pages/GeofencePage/AddNewPolygonPage"));
const AsyncCustomerTreePage = asyncComponent(() => import("../pages/CustomerTreeSidebar"));


/**
 * Available Routes for the Secondary Sidebar
 */
const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path = "/" component = {AsyncDashboard}/>

      <Route exact path = "/TripRecord" component = {AsyncFleetRecordPage}/>

      <Route exact path = "/Report" component = {AsyncReportPage}/>
      <Route exact path = "/Profile" component = {AsyncProfilePage}/>
      <Route exact path = "/EventRecord" component = {AsyncEventRecordPage}/>
      <Route exact path = "/UserManagement" component = {AsyncCustomerTreePage}/>

      <Route exact path = "/Geofence" component = {AsyncGeofencePage}/>
      <Route exact path = "/Geofence/EditPOI" component = {AsyncEditPOIPage}/>
      <Route exact path = "/Geofence/AddNewPOI" component = {AsyncAddNewPOIPage}/>
      <Route exact path = "/Geofence/EditZone" component = {AsyncEditPolygonPage}/>
      <Route exact path = "/Geofence/AddNewZone" component = {AsyncAddNewPolygonPage}/>
    </Switch>
  )
}

export default connect(null)(AppRoutes);