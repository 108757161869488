import * as API from "../../api";
import * as ActionTypes from "../action-types/vehicles";

import { message } from "antd";
import { goBackToPrev } from "../../../navigation/navigationService";

// Redux Actions
import { updateVehicleGroupAfterAdd } from './vehicleGroups';
import { update_selected_user_hierarchy } from './hierarchy';
import {
  setLoadingSubmit,
  unsetLoadingSubmit,
} from './style';

export const get_vehicles_data = (vehicles) => {
  // console.log("Get Vehicles Data:", vehicles);

  return {
    type: "GET_VEHICLES",
    vehicles: vehicles
  }
}

export const add_new_vehicle = (newVehicle, targetUserDetails, vgidList, gtidList, rtidList) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.addVehicle(newVehicle, targetUserDetails, vgidList, gtidList, rtidList)
    .then(data => {
      // console.log("Add Vehicle Data:", data);

      if (data.status !== 200) {
        message.error(data.message || 'Error in adding vehicle')

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Add New Vehicle");

        dispatch(add_new_vehicle_success(data.vehicle));
        dispatch(updateVehicleGroupAfterAdd(data.vehicle.vid, vgidList));
        dispatch(update_selected_user_hierarchy(targetUserDetails.uid, data.vehicle, vgidList, gtidList, rtidList));

        dispatch(unsetLoadingSubmit());
        dispatch(goBackToPrev());
      }
    })
}

export const add_new_vehicle_success = (vehicle) => {
  return {
    type: "ADD_NEW_VEHICLE_SUCCESS",
    vehicle: vehicle
  }
}

export const edit_vehicle = (editedVehicle, targetUID, vgidList, gtidList, rtidList) => (dispatch, getState) => {
  dispatch(setLoadingSubmit());

  const state = getState()

  const currUserDetails = {
    uid: state.v2.user.uid,
    userRole: state.v2.user.userRole,
  }

  API.editVehicle(editedVehicle, currUserDetails, vgidList, gtidList, rtidList)
    .then(data => {
      if (data.status !== 200) {
        message.error(data.message || 'Error in editing vehicle')

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Edited the Vehicle");

        if (state.user.uid === targetUID) {
          dispatch(edit_vehicle_success(data.vehicle));

          // dispatch(get_vehicles_groups(vgids, uid))
          // dispatch(select_user(currUserDetails))
          // dispatch(get_user_hierarchy(uid))
          // dispatch(updateVehicleGroupAfterEdit(data.vehicle.vid, vgidList));
          // dispatch(update_selected_user_hierarchy(targetUID, data.vehicle, vgidList, gtidList, rtidList));

          dispatch(goBackToPrev());
        }
        else {
          message.info("Reloading page");

          window.location.reload();
        }
      }
    })
    .finally(()=>{
      dispatch(unsetLoadingSubmit());
    })
}

const edit_vehicle_success = (editedVehicle) => {
  return {
    type: "EDIT_VEHICLE_SUCCESS",
    editedVehicle: editedVehicle
  }
}

export const set_selected_vehicle = (vid) => {
  return {
    type: "SET_SELECTED_VEHICLE",
    vid
  }
}

export const clear_selected_vehicle = () => {
  return {
    type: ActionTypes.CLEAR_SELECTED_VEHICLE
  }
}

export const update_vehicle = (vehicle) => (dispatch) => {
  dispatch(update_vehicle_success(vehicle));
}

const update_vehicle_success = (vehicle) => {
  // if (vehicle.vid === "vhc#0625e2c0-8d48-11eb-96e7-0d4de9e9e9ef") console.log("UPDATE_VEHICLE:", vehicle);
  // console.log(vehicle);

  return {
    type: "UPDATE_VEHICLE",
    vehicle,
  }
}

// Exported to VehicleMarkers component for a button
export const temp_update_vehicle_address_2 = (vid, lat, lng) => (dispatch) => {

  API
  .mapGeoCode(lat, lng)
  .then((res) => res.json())
    .then((data) => {
      if (data.status !== 200) {
        message.error(data.msg);
      }
      else if (data.status === 200) {
        dispatch(temp_update_vehicle_address_success(vid, data.address));
      }
    })
}

const temp_update_vehicle_address_success = (vid, newAddressText) => {
  return {
    type: ActionTypes.TEMP_UPDATE_VEHICLE_ADDRESS,
    vid,
    newAddressText,
  }
}


export const update_vehicle_state_info = (vid, vehicle) => dispatch => {
  dispatch(update_vehicle_state_info_success(vid, vehicle))
}

const update_vehicle_state_info_success = (vid, vehicle) => {
  return {
      type: ActionTypes.UPDATE_VEHICLE_STATE_INFO,
      vid,
      vehicle
  };
}