import React from "react";
import { 
  useDispatch, 
  useSelector, 
} from 'react-redux';

import Layout from "./Layout";
import PropTypes from "prop-types";

import { FEATURE } from "../../../../constants";
import { buildAbility } from "../../services/auth/accessRight";
import { moveToPage } from "../../navigation/navigationService";

import {
	BellOutlined,
	CopyOutlined,
	// FireOutlined,
	ForkOutlined,
	TeamOutlined,
	UserOutlined,
	ExportOutlined,
	GatewayOutlined,
	SettingOutlined,
	// PieChartOutlined,
	SnippetsOutlined,
	LineChartOutlined,
	// EnvironmentOutlined,
	UnorderedListOutlined,
} from '@ant-design/icons';

const iconSize = 24;

const LayoutContainer = (props) => {
  const user = useSelector(state => state.v2.user)
  const style = useSelector(state => state.v2.style)

  const dispatch = useDispatch();
  
  const location = props.location

  const navigateTo = pageKey => dispatch(moveToPage(pageKey));

  let sidebarItems = [
		{
			key: `/${FEATURE.INFORMATION_TABLE}`,
			icon: <UnorderedListOutlined style = {{ fontSize: iconSize }}/>,
			label: "Information Table"
		},

		{
			key: `/${FEATURE.TRIP_RECORD}`,
			icon: <ForkOutlined style = {{ fontSize: iconSize }}/>,
			label: "Trip Record"
		},

		{
			key: `/${FEATURE.GEOFENCE}`,
			icon: <GatewayOutlined style = {{ fontSize: iconSize }}/>,
			label: "Geofence Management"
		},

		// {
		// 	key: `/${FEATURE.GEOFENCE_RECORD}`,
		// 	icon: <EnvironmentOutlined style = {{ fontSize: iconSize }}/>,
		// 	label: "Geofence Record"
		// },

		{
			key: `/${FEATURE.USER_MANAGEMENT}`,
			icon: <TeamOutlined style = {{ fontSize: iconSize }}/>,
			label: "User Management"
		},

		{
			key: `/${FEATURE.MANAGEMENT}`,
			icon: <SettingOutlined style = {{ fontSize: iconSize }}/>,
			label: "Management"
		},

		{
			key: `/${FEATURE.EVENT_RECORD}`,
			icon: <SnippetsOutlined style = {{ fontSize: iconSize }}/>,
			label: "Event Record"
		},

		// {
		// 	key: `/${FEATURE.REPORT}`,
		// 	icon: <PieChartOutlined style = {{ fontSize: iconSize }}/>,
		// 	label: "Report"
		// },

		{
			key: `/${FEATURE.FUEL_ANALYSIS}`,
			icon: <LineChartOutlined style = {{ fontSize: iconSize }}/>,
			label: "Fuel Analysis"
		},

		{
			key: `/${FEATURE.MIGRATION}`,
			icon: <ExportOutlined style = {{ fontSize: iconSize }}/>,
			label: "Migration"
		},

		{
			key: `/${FEATURE.ESCALATION}`,
			icon: <BellOutlined style = {{ fontSize: iconSize }}/>,
			label: "SOS Alerts"
		},

		{
			key: `/${FEATURE.VEHICLE_REPORT}`,
			icon: <CopyOutlined style = {{ fontSize: iconSize }}/>,
			label: "Vehicle Reports"
		},

		// {
		// 	key: `/${FEATURE.TEMPERATURE_ANALYSIS}`,
		// 	icon: <FireOutlined style = {{ fontSize: iconSize }}/>,
		// 	label: "Temperature Analysis"
		// },

		{
			key: `/${FEATURE.PROFILE}`,
			icon: <UserOutlined style = {{ fontSize: iconSize }}/>,
			label: "Profile"
		},
	];

	let items = sidebarItems.filter(listItem => {
		if(user && user.userRole)
			return buildAbility(user.userRole).can("read", listItem.key.slice(1))
		else
			return buildAbility('NONE').can("read", listItem.key.slice(1))
	});

	return (
		<Layout
			sidebarItems = {items}
			selectedKeys = {location ? location.pathname : ""}
			themeColor = {(style && style.sidebarColor)|| "#fff"}
			onClickSidebarItem = {sidebarItem => navigateTo(sidebarItem.key|| "/")}
		>
			{props.children}
		</Layout>
	);
}

LayoutContainer.propTypes = {
  	children: PropTypes.element
};

export default LayoutContainer;