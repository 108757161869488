import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import ExportExcelButton from '../../components/ExportExcelButton';
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { parseTime } from '../../util/time';
import {
    Form,
    Table,
    Select,
    Button,
    DatePicker,
} from 'antd';

// React Actions
import { select_user } from "../../services/redux/actions/hierarchy";
import { get_device_log_debug_by_timeframe_request } from "../../services/redux/actions/deviceLog";

const { Option } = Select;

class DeviceLogInspectorPage extends React.Component {
    state = {
        isLoading: false,

        date: moment(),

        selectedUID: "",
        selectedDVID: "",

        dataLog: null,
        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        filteredInfo: {},
        sortedInfo: {
            order: 'descend',
            columnKey: 'createdAt',
        },
    }

    onSubmit = () => {
        let {
            endTime,
            startTime,
            selectedDVID,
        } = this.state;

        endTime = endTime.valueOf();
        startTime = startTime.valueOf();

        // console.log({
        //     selectedDVID,
        //     startTime,
        //     endTime
        // })

        this.setState({
            isLoading: true,
        },
            () => {
                this.props.dispatch(get_device_log_debug_by_timeframe_request(selectedDVID, startTime, endTime))
                .then(() => {
                    this.setState({ isLoading: false });
                })
            }
        )
    }

    setupDefaultFormValues = () => {
        const state = this.props.router.location.state;
        
        // console.log({ state });

        const {
            uid,
            dvid,
        } = state;

        this.setState({
            selectedUID: uid,
            selectedDVID: dvid,
        })
    }

    setupDataSource = () => {
        const deviceLogs = this.props.deviceLog.debug.byId[this.state.selectedDVID] || []

        const dataSource = deviceLogs.map((log, index) => {
            let returnValue = {
                key: index,
                fuel: log.fuel,
                speed: log.speed,
                package: log.package,
                location: log.location,
                direction: log.heading,
                createdAt: log.createdAt,
                gpsStatus: log.gpsStatus,
                deviceTime: log.deviceTime,
                engineStatus: log.engineStatus,
                deviceMessage: log.deviceMessage,
                devicePackage: log.devicePackage,
            }    

            if (log.hasOwnProperty('Analog_Input_1')) returnValue.extra_para = log.Analog_Input_1
            return returnValue
        })

        this.setState({ dataSource });
    }

    componentDidMount = () => {
        this.setupDefaultFormValues()
    }

    componentDidUpdate = prevProps => {
        const {
            router,
            hierarchy,
        } = this.props;
        
        if (JSON.stringify(prevProps.router) !== JSON.stringify(router)) {
            this.setupDefaultFormValues()
        }

        if (JSON.stringify(prevProps.deviceLog) !== JSON.stringify(this.props.deviceLog)) {
            this.setupDataSource()
        }

        // Calling select_user action to populate device list in case user refresh this page
        // Possible infinite but shouldn't happen as selected device in this page is derived from user management device table which lists selected user's devices according to hierarchy store
        if (
            JSON.stringify(prevProps.hierarchy) !== JSON.stringify(hierarchy)
            && router.location.state.uid 
            && hierarchy.byId[router.location.state.uid]
            && !hierarchy.byId[router.location.state.uid].devices.length
        ) {
            this.props.dispatch(select_user(hierarchy.byId[router.location.state.uid]));
        }
    }

    render() {
        const {
            endTime,
            isLoading,
            startTime,
            selectedUID,
            selectedDVID,
            dataSource = [],
        } = this.state;

        const { hierarchy } = this.props;

        const selectedUser = hierarchy.byId[selectedUID];

        // console.log("Test:", selectedUID, selectedUser);

        const columnsGPSLog = [
            {
                key: 'createdAt',
                title: 'Server Time',
                dataIndex: 'createdAt',
                sorter: (a, b) => a.createdAt - b.createdAt,
                render: time => parseTime(time)
            },

            {
                key: 'deviceTime',
                title: "Device Time",
                dataIndex: "deviceTime",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.deviceTime - b.deviceTime,
                render: time => parseTime(time)
            },
            
            {
                title: "Data Package",
                dataIndex: "devicePackage",
                filters: [
                    {
                        text: "auth",
                        value: "auth"
                    },
                    {
                        text: "heartbeat",
                        value: "heartbeat"
                    },
                    {
                        text: "gps#latest",
                        value: "gps#latest"
                    },
                    {
                        text: "gps#memory",
                        value: "gps#memory"
                    },
                    {
                        text: "event#session#error",
                        value: "event#session#error"
                    },
                    {
                        text: "event#session#close",
                        value: "event#session#close"
                    },
                    {
                        text: "event#session#timeout",
                        value: "event#session#timeout"
                    }
                ],
                onFilter: (value, record) => record.devicePackage !== null && record.devicePackage.includes(value),
            },

            {
                title: "Message",
                dataIndex: "deviceMessage",
            },

            {
                title: "GPS Status",
                dataIndex: "gpsStatus",
                filters: [
                    {
                        text: "0",
                        value: 0
                    },
                    {
                        text: "1",
                        value: 1
                    },
                ],
                onFilter: (value, record) => record.gpsStatus !== null && record.gpsStatus === value,
            },

            {
                title: "ACC",
                dataIndex: "engineStatus",
                filters: [
                    {
                        text: "0",
                        value: 0
                    },
                    {
                        text: "1",
                        value: 1
                    },
                ],
                onFilter: (value, record) => record.engineStatus !== null && record.engineStatus === value,
            },

            {
                title: "Location",
                dataIndex: "location",
                render: location => {
                    if (
                        !location
                        || !location.lat
                        || !location.lon
                    ) return 'N/A'
                    return (
                        <a
                            href = {`https://www.google.com.my/maps/place/${location.lat},${location.lon}`}
                            target = "_blank"
                            rel = "noopener noreferrer"
                        >
                            {location.lat},{location.lon}
                        </a>
                    )

                }
            },

            {
                title: "Speed",
                dataIndex: "speed"
            },

            {
                title: "Direction",
                dataIndex: "direction"
            },

            {
                title: "Fuel",
                dataIndex: "fuel"
            },

            {
                title: "Extra",
                dataIndex: "extra_para"
            }
        ]

        // if (selectedUser) console.log("Selected User Devices:", selectedUser.devices);
        // if (selectedUser) console.log("Invalid User Devices:", selectedUser.devices.filter((currDevice) => !currDevice.dvid));

        return (
            <div className = "page-container">
                <Page title = "Device Inspector Table">
                    <div style = {{ marginBottom: '10px' }}>
                        <Form layout = "inline">
                            <Form.Item>
                                <span style = {{ marginLeft: '10px' }}>Devices: </span>

                                <Select
                                    disabled = {!(selectedUser && selectedUser.devices && selectedUser.devices.length > 1)}
                                    value = {selectedDVID}
                                    onChange = {selectedDVID => {
                                        this.setState({ selectedDVID });
                                    }}
                                    style = {{
                                        width: 200,
                                    }}
                                >
                                    {
                                        selectedUser 
                                        && selectedUser.devices
                                            .filter((currDevice) => currDevice.dvid) // Filter out invalid devices just in case
                                            .sort((a, b) => {
                                                const A = a.dvid.toLowerCase();
                                                const B = b.dvid.toLowerCase();
                                
                                                if (A < B) return -1;
                                                if (A > B) return 1;
                                                return 0;
                                            })
                                            .map((currDevice, index) => {
                                                return (
                                                    <Option key = {index} value = {currDevice.dvid}>{currDevice.dvid}</Option>
                                                )
                                            })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <span style = {{ marginLeft: '10px' }}>Start Date: </span>

                                <DatePicker 
                                    showTime 
                                    allowClear = {false}
                                    defaultValue = {startTime} 
                                    onChange = {(startTime) => this.setState({ startTime })} 
                                />

                                <span style = {{ marginLeft: '10px' }}>End Date: </span>

                                <DatePicker 
                                    showTime 
                                    allowClear = {false}
                                    defaultValue = {endTime} 
                                    onChange = {(endTime) => this.setState({ endTime })} 
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type = "primary"
                                    loading = {isLoading || !(selectedUser && selectedUser.devices && selectedUser.devices.length > 1)}
                                    disabled = {!selectedDVID}
                                    onClick = {this.onSubmit}
                                >
                                    Submit
                                </Button>

                                <ExportExcelButton
                                    filename = {`Device Log Report ${moment().format('DD-MM-YYYY')}`}
                                    sheetName = {`Device Log Report ${moment().format('DD-MM-YYYY')}`}
                                    disabled = {dataSource.length === 0}
                                    sheetData = {dataSource}
                                    sheetRow = {
                                        columnsGPSLog
                                            .filter(col => col.title !== 'Actions')
                                            .filter(col => col.title !== 'No')
                                            .map(col => {
                                                switch (col.dataIndex) {
                                                    case 'deviceTime':
                                                    case 'createdAt':
                                                        return {
                                                            label: col.title,
                                                            formatter: value => col.render(value[col.dataIndex])
                                                        }

                                                    case 'location':
                                                        return {
                                                            label: col.title,
                                                            formatter: value => value[col.dataIndex] ? Object.values(value[col.dataIndex]).join(", ") : "-" 
                                                        }

                                                    default:
                                                        return {
                                                            label: col.title,
                                                            formatter: value => value[col.dataIndex]
                                                        }
                                                }
                                            })
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </div>

                    <Table
                        loading = {isLoading}
                        columns = {columnsGPSLog}
                        dataSource = {dataSource}
                        pagination = {{ pageSize: 25 }}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    deviceLog: state.v2.deviceLog,
    hierarchy: state.v2.hierarchy,
});

const ConnectedPage = connect(mapStateToProps)(DeviceLogInspectorPage);
export default showSecondarySidebar(false)(ConnectedPage);