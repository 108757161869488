import React from "react";
import { connect } from "react-redux";

import { PrimaryButton } from "../../../components/Button";
import { moveToPage } from "../../../navigation/navigationService";
import { DISPLAY_NOTIFICATION_TYPE } from "../../../../../constants";
import {
    Table,
    Button,
    Tooltip,
} from "antd";
import {
    EditOutlined,
    PlusOutlined,
    MonitorOutlined,
} from '@ant-design/icons'

// Redux Actions
import { set_selected_nfid } from "../../../services/redux/actions/notifications";

class NotificationPanel extends React.Component {
    state = {
        isLoading: true,

        columns: [],
        dataSource: [],
    };

    setUpDataSource = () => {
        const columns = [
            {
                title: "Notification Name",
                dataIndex: "notificationName",
            },

            {
                title: "Type",
                dataIndex: "notificationType",
            },

            {
                title: "Rule Templates",
                dataIndex: "ruleTemplateNames",
                render: ruleTemplateNames => {
                    return <ul>
                        {
                            ruleTemplateNames.map((ruleTemplateName, i) => {
                                return <li key = {i}>{ruleTemplateName}</li>
                            })
                        }
                    </ul>
                }
            },

            {
                title: "Geofences",
                dataIndex: "geofenceNames",
                render: (geofenceNames) => {
                    return (
                        <ul>
                            {
                                geofenceNames.length ? 
                                    geofenceNames.map((geofenceName, i) => {
                                        return <li key = {i}>{geofenceName}</li>
                                    }) :
                                    "-"
                            }
                        </ul>
                    );
                }
            },

            {
                title: "Recipients",
                dataIndex: "recipients",
                render: (recipients, rowData) => {

                    switch (rowData.notificationType) {
                        // email, sms, call
                        case DISPLAY_NOTIFICATION_TYPE[0]:
                        case DISPLAY_NOTIFICATION_TYPE[3]:
                        case DISPLAY_NOTIFICATION_TYPE[2]:
                            recipients = recipients.map(r => r.name)
                            break

                        // push
                        case DISPLAY_NOTIFICATION_TYPE[1]:
                            const uids = recipients.map(r => r.uid)
                            recipients = uids.map(uid => {
                                const user = this.props.hierarchy.byId[uid]
                                return (user && `${user.userName} (${user.userRole})`) || `Unknown user`
                            })
                            break

                        default:
                            break
                    }

                    return <ol>
                        {
                            recipients.map((recipient, i) => {
                                return <li key = {i}>{recipient}</li>
                            })
                        }
                    </ol>
                }
            },

            {
                title: "Enabled",
                dataIndex: "isKeypadEnabled",
                render: (isKeypadEnabled) => {
                    return (
                        isNaN(isKeypadEnabled) ? 
                            "-" :
                            isKeypadEnabled ? 
                                <b style = {{ color: "green" }}>TRUE</b> :
                                <b style = {{ color: "red" }}>FALSE</b>
                    );
                }
            },

            {
                title: "Actions",
                render: columnData => (
                    <Button.Group>
                        <button className = "transparent-button">
                            <Tooltip title = "Inspect Notification Log">
                                <MonitorOutlined 
                                    onClick = {() => {
                                        this.props.dispatch(set_selected_nfid(columnData.key));
                                        this.props.dispatch(moveToPage(`/Management/Notification/Log/`, columnData.key));
                                    }}
                                />
                            </Tooltip>
                        </button>

                        <button className = "transparent-button">
                            <Tooltip title = "Edit Notification">
                                <EditOutlined 
                                    onClick = {() => this.props.dispatch(moveToPage(`/Management/Notification/Edit/${columnData.key}`, this.props.notifications.byNfID[columnData.key]))}
                                />
                            </Tooltip>
                        </button>
                    </Button.Group>
                )
            }
        ]

        // console.log("Test:", this.props.notifications.byNfID);

        const dataSource = Object.values(this.props.notifications.byNfID)
            .map((currNotification) => {
                const ruleTemplateNames = Object.keys(currNotification.rules)
                    .map(rtid => {
                        const ruleTemplate = this.props.ruleTemplate.byId[rtid];

                        return ruleTemplate && ruleTemplate.templateName
                    })

                const geofenceNames = Object.values(currNotification.geofences)
                    .reduce((a, b) => [...a, ...b], [])
                    .map(geoid => {
                        const geofence = this.props.geofences.byId[geoid];
                        
                        return geofence && geofence.geofenceName
                    })
                    .filter((currName) => currName) // In case geofence name doesn't exist for some reason

                const recipients = currNotification.recipient;

                return ({
                    key: currNotification.nfid,
                    notificationName: currNotification.notificationName,
                    notificationType: DISPLAY_NOTIFICATION_TYPE[currNotification.serviceType],
                    geofenceNames: geofenceNames,
                    recipients: recipients,
                    ruleTemplateNames: ruleTemplateNames,
                })
            })
            // .sort((a, b) => b.createdAt - a.createdAt);
            .sort((a, b) => {
                const A = a.notificationName.toLowerCase();
                const B = b.notificationName.toLowerCase();

                if (A < B) return -1;
                if (A > B) return 1;
                return 0;
            })

        this.setState({
            columns,
            dataSource,
        })
    }

    componentDidMount = () => {
        this.setUpDataSource();

        if (Object.keys(this.props.notifications.byNfID).length) {
            this.setState({ isLoading: false });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setUpDataSource()
        }
        
        if (JSON.stringify(prevProps.notifications) !== JSON.stringify(this.props.notifications)) {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const {
            columns,
            dataSource,
        } = this.state;

        return (
            <div style = {{ display: "flex", flex: 1 }}>
                <div
                    className = "scroll-div"
                    style = {{
                        width: "95%",
                        margin: "0 5%"
                    }}
                >
                    <div
                        style = {{
                            display: "flex",
                            justifyContent: "flex-end",

                            padding: 5,
                            marginBottom: 10,
                        }}
                    >
                        <PrimaryButton
                            icon = {<PlusOutlined/>} 
                            onClick = {() => this.props.dispatch(moveToPage("/Management/Notification/Add"))}
                        >
                            Add New Notification
                        </PrimaryButton>
                    </div>

                    <div>
                        <Table
                            isLoading = {this.state.isLoading}
                            columns = {columns}
                            dataSource = {dataSource}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    state,
    geofences: state.v2.geofences,
    hierarchy: state.v2.hierarchy,
    ruleTemplate: state.v2.ruleTemplate,
    notifications: state.v2.notifications,
    geofenceTemplate: state.v2.geofenceTemplate,
});

export default connect(mapStateToProps)(NotificationPanel);