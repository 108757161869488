import React from "react";
import { connect } from "react-redux";

import moment from "moment";

import {
    Modal,
    Input,
} from "antd";

// Redux Actions
import { update_escalation_states } from "../../../services/redux/actions/escalations";

class SOSAlertsPage extends React.Component {
    state = {
        remark: "",
    }

    handleOk = () => {
        // console.log("Test:", {
        //     ...this.props.editedState,
        //     isAcknowledged: true,
        //     acknowledgedBy: this.props.user.uid,
        //     acknowledgedAt: moment.now(),
        //     acknowledgedRemark: this.state.remark,
        // });

        this.props.dispatch(update_escalation_states({
            ...this.props.editedState,
            isAcknowledged: true,
            acknowledgedBy: this.props.user.uid,
            acknowledgedAt: moment.now(),
            acknowledgedRemark: this.state.remark,
        }))

        this.props.onClose();
    }

    render() {
        return (
            <Modal
                title = {"Are you sure you want to acknowledge this alert?"}
                visible = {this.props.isVisible}
                onOk = {this.handleOk}
                onCancel = {this.props.onClose}
            >
                <div>
                    Remark:

                    <Input.TextArea rows = {4} onChange = {(event) => this.setState({ remark: event.target.value })}/>
                </div>
            </Modal>
        )
    };
}

const mapStateToProps = (state) => ({
    style: state.style,
    user: state.v2.user,
});

export default connect(mapStateToProps)(SOSAlertsPage);