const initialState =  {
    byId: {},
    selectedTime: { startTime: null, endTime: null },
}
export const geofenceRecord = (state = initialState, action) => {
    let newObject = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case "GET_GEOFENCE_RECORD": {
            newObject.byId = {};

            if (action.geofenceRecord && action.geofenceRecord.length) {
                action.geofenceRecord.map(record => newObject.byId[record.geoCreatedAt] = record);
    
                action.geofenceRecord.map(record =>
                    newObject.byId[record.geoCreatedAt] = record
                )
            }
            
            // console.log({newObject})
            return newObject;
        }

        default: {
            return state;
        }
    }
}
