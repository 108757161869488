import * as API from "../../api"
import * as actionType from '../action-types/notifications';

import { message } from "antd";
import { goBackToPrev } from "../../../navigation/navigationService";
import { 
    setLoadingSubmit, 
    unsetLoadingSubmit 
} from '../actions/style';

export const get_notifications = (nfidList) => (dispatch) => {
    // console.log("Get Notifications:", nfidList);

    API.getNotifications(nfidList)
        .then(data => {
            // console.log("Get Notification Success Data:", data);
            
            data.notifications && dispatch(get_notifications_success(data.notifications));
        })
}

const get_notifications_success = (notifications) => {
    return {
        type: actionType.GET_NOTIFICATIONS,
        notifications: notifications
    }
}

export const add_notification = (newNotification) => (dispatch) => {
    // console.log("Add Notification:", newNotification);

    dispatch(setLoadingSubmit());

    API.addNotification(newNotification)
        .then(data => {
            // console.log("Add Notification Data:", data);

            if (data.err) {
                message.error(data.err);
                
                dispatch(unsetLoadingSubmit());
            }
            else {
                message.success("Successfully Added Notification");

                dispatch(add_notifications_success(data.notification));

                dispatch(goBackToPrev());
          
                dispatch(unsetLoadingSubmit());
            }
        })
}

const add_notifications_success = (notification) => {
    return {
        type: actionType.ADD_NOTIFICATION,
        notification: notification
    }
}

export const edit_notification = (editedNotification) => (dispatch) => {
    // console.log("Edit Notification:", editedNotification);

    dispatch(setLoadingSubmit());

    API.editNotification(editedNotification)
        .then(data => {
            
            // console.log("Edit Notification Data:", data);

            if (data.err) {
                message.error(data.err);
                
                dispatch(unsetLoadingSubmit());
            }
            else {
                const {
                    notification,
                } = data
                message.success("Successfully Edited Notification");

                dispatch(edit_notifications_success(notification));

                dispatch(goBackToPrev());
          
                dispatch(unsetLoadingSubmit());
            }
        })
}

const edit_notifications_success = (notification) => {
    return {
        type: actionType.EDIT_NOTIFICATION,
        notification: notification
    }
}

export const get_notification_by_timeframe = (nfid, startTime, endTime) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.getNotificationLogByTimeframe(nfid, startTime, endTime)
        .then(data => {
            // console.log("Get Notification By Timeframe Data:", data);

            if (data.status !== 200) {
                message.error(data.message || 'Error at getting notification log');
            }
            else {
                
                const notificationLog = (data && data.notificationLog && data.notificationLog.Items) || []

                message.info(`${notificationLog.length} notifications received`)

                dispatch(get_notification_by_timeframe_success(data.notificationLog.Items));
            }
        })
        .finally(()=>{
            dispatch(unsetLoadingSubmit());
        })
}

const get_notification_by_timeframe_success = (notificationLog) => {
    return {
        type: actionType.GET_NOTIFICATION_LOG_BY_TIMEFRAME,
        notificationLog: notificationLog
    }
}

export const clear_notification_log = () => {
    return {
        type: actionType.CLEAR_NOTIFICATION_LOG,
    }
}

export const set_selected_nfid = (selectedNfID) => {
    return {
        type: actionType.SET_SELECTED_NFID,
        selectedNfID: selectedNfID
    }
}

export const clear_selected_nfid = () => {
    return {
        type: actionType.CLEAR_SELECTED_NFID,
    }
}