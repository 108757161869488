import React, { useState, useEffect, useCallback } from "react";
import { 
    useDispatch,
    useSelector,
} from "react-redux";

import moment from 'moment';
import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import { 
    ROLE_TYPE,
    VEHICLE_TYPE,  
} from '../../../../../constants';
import {
    PlusOutlined,
    MinusCircleOutlined
} from "@ant-design/icons";
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Select,
    message,
    DatePicker,
    InputNumber,
} from "antd";

// Redux Actions
// import { select_user } from "../../../services/redux/actions/hierarchy";
import { edit_vehicle } from "../../../services/redux/actions/vehicles";
import { get_devices_group } from "../../../services/redux/actions/deviceGroups";

const FormItem = Form.Item;

const { Option } = Select;
const { RangePicker } = DatePicker;

const fuelSensorTypes = [
    "dipstick",
    "ultrasonic",
];

const EditVehiclePage = () => {
    const dispatch = useDispatch();

    const router = useSelector(state => state.router);
    
    const user = useSelector(state => state.v2.user);
    const devices = useSelector(state => state.v2.devices);
    const hierarchy = useSelector(state => state.v2.hierarchy);
    const deviceGroups = useSelector(state => state.v2.deviceGroups);
    const vehicleGroups = useSelector(state => state.v2.vehicleGroups);

    const { uid: router_uid, vehicle: router_vehicle = {} } = router.location.state || {}

    const [ isLoading, setLoading ] = useState(false)
    const [ targetUserUid, setTargetUserUid ] = useState(null)
    const [ vgids, setVgids ] = useState([])
    const [ gtids, setGtids ] = useState([])
    const [ rtids, setRtids ] = useState([])
    const [ editedVehicle, setEditedVehicle ] = useState({
        dvid: "",
        vehicleType: "",
        vehicleModel: "",
        vehiclePlate: "",
        vehicleDisplayName: "",
        
        serviceNextMileage: 0,
        engineServiceNextMileage: 0,
        rearAxleServiceNextMileage: 0,
        engineServiceNextWorkingHour: 0,
        transmissionServiceNextMileage: 0,

        subsStartDate: moment().startOf("day").valueOf(),
        subsEndDate: moment().startOf("day").add(3, "months").valueOf(),
        permitDueDate: moment().startOf("day").add(3, "months").valueOf(),
        serviceDueDate: moment().startOf("day").add(3, "months").valueOf(),
        engineServiceDueDate: moment().startOf("day").add(3, "months").valueOf(),
        rearAxleServiceDueDate: moment().startOf("day").add(3, "months").valueOf(),
        transmissionServiceDueDate: moment().startOf("day").add(3, "months").valueOf(),
    })

    const [ fuelTanks, setFuelTanks ] = useState([
        {
            portNumber: 0,
            fuelCapacity: 0,
            fuelLevelMin: 0,
            fuelLevelMax: 10,
            fuelLevelOperator: 'V',
            fuelSensorType: fuelSensorTypes[0]
        }
    ])

    const fuelTankSanityCheck = (fuelTanks) => {
        // port number must be unique

        fuelTanks.forEach((tank, i) => {
            // check invalidity
            ['fuelCapacity', 'fuelLevelMin', 'fuelLevelMax', 'fuelSensorType', 'fuelLevelOperator'].forEach(key => {
                if (tank[key] === null || tank[key] === undefined) {
                    throw new Error(`Error at tank ${i}. Make sure fields are filled in correctly`)
                }
            })

            // update port number
            tank.portNumber = i
        })
    }

    /**
     * Build newVgids, newGtids, newRtids based on
     * 1. targetUser's default groups/templates
     * 2. any additional groups/templates selected in form
     * 3. (special case if current user is distributor) current user's default vehicle group
     */
    const getGroups = (uid) => {
        const {
            vehicleGroups: HVehicleGroups = [],
            ruleTemplates: HRuleTemplates  = [],
            geofenceTemplates: HGeofenceTemplates = [],
        } = hierarchy.byId[uid];

        const targetUserDefaultVehicleGroup = HVehicleGroups.find(vg => vg.groupName === 'ALL');
        const targetUserDefaultVgid = targetUserDefaultVehicleGroup && targetUserDefaultVehicleGroup.vgid;

        const targetUserDefaultRulesTemplate = HRuleTemplates.find(rt => rt.templateName === 'DEFAULT');
        const targetUserDefaultRtid = targetUserDefaultRulesTemplate && targetUserDefaultRulesTemplate.rtid;

        const targetUserDefaultGeofenceTemplate = HGeofenceTemplates.find(gt => gt.templateName === 'UNGROUP');
        const targetUserDefaultGtid = targetUserDefaultGeofenceTemplate && targetUserDefaultGeofenceTemplate.gtid;

        let newGtids = [...gtids, targetUserDefaultGtid];
        let newRtids = [...rtids, targetUserDefaultRtid];
        let newVgids = [...vgids, targetUserDefaultVgid];

        /**
         * If current user is distributor, created vehicle would be added in current user's default vehicle group as well
         */
        switch (user.userRole) {
            case ROLE_TYPE.DISTRIBUTOR:
                const defaultVehicleGroup = Object.values(vehicleGroups.byVGID).find(vg => vg.groupName === 'ALL');
                const defaultVgid = defaultVehicleGroup && defaultVehicleGroup.vgid;

                newVgids.push(defaultVgid);
                break
            default:
                break
        }

        return {
            newGtids: [...new Set(newGtids)], // new Set() removes duplicates
            newRtids: [...new Set(newRtids)], // new Set() removes duplicates
            newVgids: [...new Set(newVgids)], // new Set() removes duplicates
        }
    }

    const submitForm = () => {
        let newEditedVehicle = JSON.parse(JSON.stringify(editedVehicle))

        setLoading(true)

        let {
            dvid,
            vehicleType,
            vehicleModel,
            vehiclePlate,
            vehicleDisplayName,
        } = newEditedVehicle;

        try {
            if (!dvid) throw new Error('Please select a device for this vehicle');
            if (!vehicleDisplayName) throw new Error('Please enter a vehicle name');
            if (!vehiclePlate) throw new Error('Please enter vehicle plate');
            if (!vehicleModel) throw new Error(`Please enter a vehicle model`);
            if (!vehicleType) throw new Error(`Please select vehicle type`);
            if (!targetUserUid) throw new Error(`Please select target user`);

            fuelTankSanityCheck(fuelTanks);

            const {
                newGtids,
                newRtids,
                newVgids,
            } = getGroups(targetUserUid);

            /**Build fuel profile */
            newEditedVehicle.fuelProfile = {}
            fuelTanks.forEach((tank) => {
                const key = tank.portNumber ? `fuel${tank.portNumber}` : `fuel`;
                newEditedVehicle.fuelProfile[key] = tank;

                delete tank.portNumber;
            })

            dispatch(edit_vehicle(newEditedVehicle, targetUserUid, newVgids, newGtids, newRtids));
        }
        catch (err) {
            message.error((err && err.message) || `Something went wrong`)
        }
    }

    const addFuelTank = () => {
        const newfuelTanks = JSON.parse(JSON.stringify(fuelTanks));
        const sampleFuelTank = {
            portNumber: 0,
            fuelCapacity: 0,
            fuelLevelMin: 0,
            fuelLevelMax: 10,
            fuelLevelOperator: 'V',
            fuelSensorType: fuelSensorTypes[0],
        }

        newfuelTanks.push(sampleFuelTank);
        setFuelTanks(newfuelTanks);
    }

    const removeFuelTank = (id) => {
        const newfuelTanks = JSON.parse(JSON.stringify(fuelTanks));
        newfuelTanks.splice(id, 1);

        setFuelTanks(newfuelTanks);
    }

    const fuelInfoForm = (index) => {
        const newfuelTanks = JSON.parse(JSON.stringify(fuelTanks));
        const fuelTank = { ...newfuelTanks[index] };
        const isLatest = index === fuelTanks.length - 1;

        return (
            <FormItem
                labelCol = {{ span: 6 }}
                wrapperCol = {{ span: 18 }}
            >
                Fuel Capacity (L)
                <InputNumber
                    value = {fuelTank.fuelCapacity}
                    onChange = {fuelCapacity => {
                        fuelTank.fuelCapacity = fuelCapacity;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                    style = {{ width: '100%' }}
                />

                Fuel Maximum Level
                <InputNumber
                    value = {fuelTank.fuelLevelMax}
                    onChange = {fuelLevelMax => {
                        fuelTank.fuelLevelMax = fuelLevelMax;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                    style = {{ width: '100%' }}
                />

                Fuel Minimum Level
                <InputNumber
                    value = {fuelTank.fuelLevelMin}
                    onChange = {fuelLevelMin => {
                        fuelTank.fuelLevelMin = fuelLevelMin;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                    style = {{ width: '100%' }}
                />

                Fuel Level Operator
                <Select
                    defaultValue = {fuelTank.fuelLevelOperator}
                    value = {fuelTank.fuelLevelOperator}
                    placeholder = "% or V"
                    onChange = {fuelLevelOperator => {
                        fuelTank.fuelLevelOperator = fuelLevelOperator;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                >
                    {
                        ['%', 'V'].map(operator => {
                            return (
                                <Option key = {operator} value = {operator}>
                                    {operator}
                                </Option>
                            );
                        })
                    }
                </Select>

                Fuel Sensor Type
                <Select
                    defaultValue = {fuelTank.fuelSensorType}
                    value = {fuelTank.fuelSensorType}
                    placeholder = "% or V"
                    onChange = {fuelSensorType => {
                        fuelTank.fuelSensorType = fuelSensorType;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                >
                    {
                        fuelSensorTypes.map(type => {
                            return (
                                <Option key = {type} value = {type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Option>
                            );
                        })
                    }
                </Select>

                <div style = {{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Button
                        ghost
                        type = "dashed"
                        disabled = {fuelTanks.length === 1}
                        onClick = {() => removeFuelTank(index)}
                        style = {{
                            width: index === fuelTanks.length - 1 ? '48%' : '100%',
                            borderColor: 'red',
                            color: 'red'
                        }}
                    >
                        <MinusCircleOutlined /> Remove
                    </Button>

                    {
                        index === fuelTanks.length - 1 &&
                        <Button
                            type = "dashed"
                            onClick = {() => addFuelTank()}
                            style = {{ width: isLatest ? '48%' : '100%' }}
                        >
                            <PlusOutlined /> Add
                            </Button>
                    }
                </div>
            </FormItem>
        )
    }

    const getTargetUsers = (vid) => {
        const users = Object.values(hierarchy.byId).filter(user => {
            return user.vehicleGroups && user.vehicleGroups.find(vg => vg.vehicles.includes(vid))
        })

        return users;
    }

    const setUpFormDefaultValues = useCallback(() => {
        if (router_uid && router_vehicle) {
            const props_editedVehicle = {
                ...editedVehicle, // Future proofing by using editedVehicle to patch up any missing/new vehicle data with it's default values
                ...router_vehicle,
            };

            if (!props_editedVehicle) return null
            
            const hierarchy_user = hierarchy.byId[router_uid];

            // console.log("hierarchy_user:", hierarchy_user);

            props_editedVehicle.subsEndDate = moment(props_editedVehicle.subsEndDate);
            props_editedVehicle.subsStartDate = moment(props_editedVehicle.subsStartDate);
            props_editedVehicle.serviceDueDate = moment(props_editedVehicle.serviceDueDate);

            const props_fuelTanks = Object.keys(props_editedVehicle.fuelProfile || {}).map(key => {
                return {
                    ...props_editedVehicle.fuelProfile[key],
                    portNumber: Number(key.replace('fuel', '')),
                    fuelSensorType: // In case the vehicle did not have a fuel sensor type or was using the old "rod" fuel sensor type
                        !fuelSensorTypes.includes(props_editedVehicle.fuelProfile[key].fuelSensorType) ?
                            fuelSensorTypes[0] :
                            props_editedVehicle.fuelProfile[key].fuelSensorType,
                }
            })

            const props_vgids =
                (
                    hierarchy_user
                    && hierarchy_user.vehicleGroups
                    && hierarchy_user.vehicleGroups
                        .filter(vg => vg.groupName !== 'ALL')
                        .filter(vg => vg.vehicles.includes(editedVehicle.vid))
                        .map(vg => vg.vgid)
                )
                || []

            const props_gtids =
                (
                    hierarchy_user
                    && hierarchy_user.geofenceTemplates
                    && hierarchy_user.geofenceTemplates
                        .filter(gt => gt.templateName !== 'UNGROUP')
                        .filter(gt => props_editedVehicle.geofenceTemplates.includes(gt.gtid))
                        .map(gt => gt.gtid)
                )
                || []

            const props_rtids =
                (
                    hierarchy_user
                    && hierarchy_user.ruleTemplates
                    && hierarchy_user.ruleTemplates
                        .filter(rt => rt.templateName !== 'DEFAULT')
                        .filter(rt => props_editedVehicle.ruleTemplates.includes(rt.rtid))
                        .map(rt => rt.rtid)
                )
                || []

            setTargetUserUid(router_uid)
            setVgids(props_vgids)
            setGtids(props_gtids)
            setRtids(props_rtids)
            setFuelTanks(props_fuelTanks)
            setEditedVehicle(props_editedVehicle)
        }
    }, [editedVehicle, hierarchy.byId, router_uid, router_vehicle])

    useEffect(() => {

        setUpFormDefaultValues();

        return () => {}
    }, 
        []
    )

    // useEffect(() => {
    //     const user = hierarchy.byId[router_uid];

    //     if (!hierarchy.byId && user) dispatch(select_user(user));
    // },
    //     [router_uid, dispatch, hierarchy.byId]
    // )
    
    // Component Did Mount
    // Get device list just in case it's updated
    useEffect(() => {
        if (Object.keys(deviceGroups.byId).length) {
            dispatch(get_devices_group(Object.keys(deviceGroups.byId), user.uid));
        }
    },
        [deviceGroups.byId, dispatch, user.uid]
    );

    const userObject = hierarchy.byId[targetUserUid];

    let HRuleTemplates = (userObject && userObject.ruleTemplates) || [];
    let HVehicleGroups = (userObject && userObject.vehicleGroups) || [];
    let HGeofenceTemplates = (userObject && userObject.geofenceTemplates) || [];

    return (
        <div className = "page-container">
            <Page title = "Edit Vehicle">
                <div style = {{ padding: "30px 20px 0px 20px" }}>
                    <Form className = "login-form">
                        {/* Vehicle Information */}
                        <FormItem
                            label = "Vehicle Name"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "Car 1"
                                value = {editedVehicle.vehicleDisplayName}
                                onFocus = {e => e.target.select()}
                                onChange = {e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        vehicleDisplayName: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            label = "Vehicle Plate"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "ABC123"
                                value = {editedVehicle.vehiclePlate}
                                onFocus = {e => e.target.select()}
                                onChange = {e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        vehiclePlate: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            label = "Vehicle Model"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "Toyota Vios"
                                value = {editedVehicle.vehicleModel}
                                onFocus = {e => e.target.select()}
                                onChange = {e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        vehicleModel: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            label = "Vehicle Type"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Select
                                placeholder = "Select a vehicle type"
                                value = {editedVehicle.vehicleType ? editedVehicle.vehicleType : undefined}
                                onChange = {value =>
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        vehicleType: value
                                    })
                                }
                            >
                                {
                                    VEHICLE_TYPE.map(vehicleType => {
                                        return (
                                            <Option key = {vehicleType.value} value = {vehicleType.value}>
                                                {vehicleType.label}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>

                        <FormItem
                            label = "Vehicle Fuel Information"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Row>
                                {
                                    fuelTanks.length > 0 ?
                                        fuelTanks.map((fuelTank, i) => {
                                            return (
                                                <Col span = {12} key = {i}>
                                                    {fuelInfoForm(i)}
                                                </Col>
                                            )
                                        }) :
                                        <Button
                                            type = "dashed"
                                            onClick = {() => addFuelTank()}
                                            style = {{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Add
                                        </Button>
                                }
                            </Row>
                        </FormItem>

                        {/* Vehicle extra information */}
                        <FormItem
                            required
                            label = "Engine Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {editedVehicle.engineServiceNextMileage || 0} 
                                onChange = { e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        engineServiceNextMileage: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Engine Next Running Hours (hrs)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {editedVehicle.engineServiceNextWorkingHour || 0} 
                                onChange = { e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        engineServiceNextWorkingHour: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Engine Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(editedVehicle.engineServiceDueDate)}
                                onChange = {(date) => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        engineServiceDueDate: date.startOf("day").valueOf()
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Transmission Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {editedVehicle.transmissionServiceNextMileage || 0} 
                                onChange = { e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        transmissionServiceNextMileage: e.target.value
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Transmission Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(editedVehicle.transmissionServiceDueDate)} 
                                onChange = {(date) => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        transmissionServiceDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Rear Axle Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {editedVehicle.rearAxleServiceNextMileage || 0} 
                                onChange = { e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        rearAxleServiceNextMileage: e.target.value
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Rear Axle Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker
                                allowClear = {false}
                                value = {moment(editedVehicle.rearAxleServiceDueDate)} 
                                onChange = {(date) => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        rearAxleServiceDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Service Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {editedVehicle.serviceNextMileage || 0} 
                                onChange = { e => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        serviceNextMileage: e.target.value
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(editedVehicle.serviceDueDate)} 
                                onChange = {(date) => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        serviceDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Permit Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(editedVehicle.permitDueDate)} 
                                onChange = {(date) => 
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        permitDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Subscription Duration"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <RangePicker 
                                allowClear = {false}
                                value = {[moment(editedVehicle.subsStartDate), moment(editedVehicle.subsEndDate)]} 
                                onChange = {(date) =>
                                    setEditedVehicle({
                                        ...editedVehicle,
                                        subsEndDate: date[1].startOf("day").valueOf(),
                                        subsStartDate: date[0].startOf("day").valueOf(),
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Device"
                            // help = {`Cannot be edited once set`}
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            {
                                devices.listIds.filter((dvid) => (router_vehicle.dvid === dvid || devices.byId[dvid].assignedStatus !== 1) && dvid).length < 2 ?
                                    <Select
                                        disabled
                                        placeholder = "No other unallocated devices"
                                        value={`${editedVehicle.dvid ? editedVehicle.dvid : undefined} (No other available/unallocated devices)`}
                                    /> :
                                    <Select
                                        // disabled
                                        showSearch
                                        optionFilterProp = "children"
                                        placeholder = "Assign to a device"
                                        value = {editedVehicle.dvid ? editedVehicle.dvid : undefined}
                                        filterOption = {(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange = {value => 
                                            setEditedVehicle({
                                                ...editedVehicle,
                                                dvid: value
                                            })
                                        }
                                    >
                                        <Option key={router_vehicle.dvid} value={router_vehicle.dvid}>
                                            {router_vehicle.dvid}
                                        </Option>
                                        {
                                            devices.listIds
                                                .filter((dvid) => dvid && devices.byId[dvid].assignedStatus !== 1)
                                                .map((dvid) => {
                                                    // console.log(dvid);

                                                    return (
                                                        <Option key = {dvid} value = {dvid}>
                                                            {dvid}
                                                        </Option>
                                                    );
                                                })
                                        }
                                    </Select>
                            }
                        </FormItem>

                        {/* 
                            Not allowed to select fleet operator as target user.
                            To assign fleet operator a vehicle,
                            1. log in as fleet owner
                            2. go to user management
                            3. click on designated fleet operator
                            4. configure user
                        */}
                        <FormItem
                            required
                            label = "Target User"
                            help = {'Cannot be edited once set'}
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <ol>
                                {getTargetUsers(editedVehicle.vid).map(user => <li key = {user.uid}>{user && `${user.userName} (${user.userRole})`}</li>)}
                            </ol>
                        </FormItem>

                        {
                            targetUserUid && (
                                <div style = {{ marginTop: 20 }}>
                                    {/* To decide which vehicle group of the target user this vehicle should be assigned to */}
                                    <FormItem
                                        label = "Vehicle Groups"
                                        labelCol = {{ span: 7 }}
                                        wrapperCol = {{ span: 15 }}
                                        help = {targetUserUid ? '' : 'Select a target user first'}
                                    >
                                        {
                                            <Select
                                                mode = "multiple"
                                                showSearch
                                                labelInValue = {true}
                                                placeholder = "Select vehicle groups to assign vehicle to (Optional)"
                                                value = {vgids.map(vgid => {
                                                    const vehicleGroup = HVehicleGroups.find(vg => vg.vgid === vgid);

                                                    return {
                                                        label: vehicleGroup && vehicleGroup.groupName,
                                                        value: vgid
                                                    }
                                                })}
                                                onChange = {(values) => {
                                                    const new_vgids = values.map(v => v.key)
                                                    setVgids(new_vgids)
                                                }}
                                                style = {{ width: '100%' }}
                                            >
                                                {
                                                    HVehicleGroups
                                                        .filter(vg => vg.groupName !== 'ALL')
                                                        .sort((a, b) => {
                                                            const A = a.groupName.toLowerCase();
                                                            const B = b.groupName.toLowerCase();

                                                            if (A < B) return -1;
                                                            if (A > B) return 1;

                                                            return 0;
                                                        })
                                                        .map(vg => {
                                                            return (
                                                                (
                                                                    vg
                                                                    &&
                                                                    vg.subName
                                                                    &&
                                                                    <Option key = {vg.vgid}>{vg.groupName} [${vg.subName}]</Option>
                                                                )
                                                                ||
                                                                <Option key = {vg.vgid}>{vg.groupName}</Option>
                                                            )
                                                        })
                                                }
                                            </Select>
                                        }
                                    </FormItem>

                                    {/* Important for rule engine. */}
                                    <FormItem
                                        label = "Geofence Templates"
                                        help = {targetUserUid ? '' : 'Select a target user first'}
                                        labelCol = {{ span: 7 }}
                                        wrapperCol = {{ span: 15 }}
                                    >
                                        {
                                            <Select
                                                mode = "multiple"
                                                showSearch
                                                labelInValue = {true}
                                                placeholder = "Select geofence templates to assign vehicle to (Optional)"
                                                value = {
                                                    gtids.map(gtid => {
                                                        const geofenceTemplate = HGeofenceTemplates.find(gt => gt.gtid === gtid);

                                                        return {
                                                            label: geofenceTemplate && geofenceTemplate.templateName,
                                                            value: gtid
                                                        }
                                                    })
                                                }
                                                onChange = {values => {
                                                    const new_gtids = values.map(v => v.key)
                                                    setGtids(new_gtids)
                                                }}
                                                style = {{ width: '100%' }}
                                            >
                                                {
                                                    HGeofenceTemplates
                                                        .filter(gt => gt.templateName !== 'UNGROUP')
                                                        .map(gt => {
                                                            return <Option key = {gt.gtid}>{gt.templateName}</Option>
                                                        })
                                                }
                                            </Select>
                                        }
                                    </FormItem>

                                    {/* Important for rule engine. */}
                                    <FormItem
                                        label = "Rule Templates"
                                        help = {targetUserUid ? '' : 'Select a target user first'}
                                        labelCol = {{ span: 7 }}
                                        wrapperCol = {{ span: 15 }}
                                    >
                                        {
                                            <Select
                                                mode = "multiple"
                                                showSearch
                                                labelInValue = {true}
                                                placeholder = "Select rule templates to assign vehicle to (Optional)"
                                                value = {rtids.map(rtid => {
                                                    const ruleTemplate = HRuleTemplates.find(rt => rt.rtid === rtid)
                                                    return {
                                                        label: ruleTemplate && ruleTemplate.templateName,
                                                        value: rtid
                                                    }
                                                })}
                                                onChange = {(values) => {
                                                    const new_rtids = values.map(v => v.key)
                                                    setRtids(new_rtids)
                                                }}
                                                style = {{ width: '100%' }}
                                            >
                                                {
                                                    HRuleTemplates
                                                        .filter(rt => rt.templateName !== 'DEFAULT')
                                                        .map(rt => {
                                                            return <Option key = {rt.rtid}>{rt.templateName}</Option>
                                                        })
                                                }
                                            </Select>
                                        }
                                    </FormItem>
                                </div>
                            )
                        }
                        <div
                            style = {{
                                display: "flex",
                                justifyContent: "flex-end",

                                padding: 5,
                                marginBottom: 10,
                            }}
                        >
                            <Button
                                className = "login-form-button"
                                type = "danger"
                                disabled = {isLoading}
                                onClick = {() => dispatch(goBackToPrev())}
                            >
                                Cancel
                            </Button>

                            <PrimaryButton
                                loading = {isLoading}
                                onClick = {() => submitForm()}
                                style = {{ marginLeft: 10 }}
                            >
                                Edit
                            </PrimaryButton>
                        </div>
                    </Form>
                </div>
            </Page>
        </div>
    )
}

export default showSecondarySidebar(false)(React.memo(EditVehiclePage));