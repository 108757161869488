import * as ActionTypes from "../action-types/deviceLog";

const defaultState = {
    byId: {},
    markerPlaybackIndex: 0,
    selectedTime: { 
        startTime: null, 
        endTime: null,
    },
    debug: {
        byId: {},
    },
    selectedPackageTime: ""
}

export const deviceLog = (state = defaultState, action) => {
    let newObject = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.SET_SELECTED_TIME: {
            return {
                ...state,
                selectedTime: {
                    startTime: action.startTime,
                    endTime: action.endTime
                }
            };
        }

        case ActionTypes.CLEAR_SELECTED_TIME: {
            return {
                ...state,
                selectedTime: {
                    startTime: null,
                    endTime: null
                }
            };
        }

        case ActionTypes.SET_SELECTED_MARKER: {
            return {
                ...state,
                markerPlaybackIndex: action.markerPlaybackIndex
            };
        }

        case ActionTypes.GET_DEVICE_LOG: {
            newObject = JSON.parse(JSON.stringify(state));

            // console.log("get gps log",state.byId);
            // console.log("Action deviceLog", action.deviceLog);

            if (action.deviceLog && action.deviceLog.length > 0) {
                newObject.byId[action.deviceLog[0].dvid] = {
                    "dvid": action.deviceLog[0].dvid, 
                    "startTime" : state.selectedTime.startTime, 
                    "endTime" : state.selectedTime.endTime,
                    "deviceLog" : action.deviceLog.sort((a, b) => {            
                        if (a.deviceTime < b.deviceTime) return -1;
                        if (a.deviceTime > b.deviceTime) return 1;
            
                        return 0;
                    }),
                }
            }

            return newObject;
        }

        case "GET_DEVICE_LOG_DEBUG": {
            // console.log("Enter GPS LOG DEBUG");
            // console.log("Get Device Log Debug Data:", action);

            newObject = JSON.parse(JSON.stringify(state));

            if (action.data.length > 0) {
                action.data.forEach((currLog) => {
                    if (!Object.keys(newObject.debug.byId).includes(currLog.dvid)) {
                        newObject.debug.byId[currLog.dvid] = [];
                    }

                    newObject.debug.byId[currLog.dvid].push(currLog);
                })
            }

            return newObject;
        }

        case ActionTypes.GET_DEVICE_LOG_DEBUG_TEST: {
            // console.log("Get Device Log Debug Test Data:", action);

            // newObject = JSON.parse(JSON.stringify(state));

            if (action.data && action.data.length > 0) {
                action.data.forEach((currLog) => {
                    if (!Object.keys(newObject.debug.byId).includes(currLog.dvid)) {
                        newObject.debug.byId[currLog.dvid] = [];
                    }

                    newObject.debug.byId[currLog.dvid].push(currLog);
                })
            }

            return newObject;
        }

        case ActionTypes.SET_SELECTED_PACKAGE_TIME: {
            return {
                ...state,
                selectedPackageTime: action.selectedTime
            };
        }

        case ActionTypes.CLEAR_SELECTED_PACKAGE_TIME: {
            return {
                ...state,
                selectedPackageTime: ""
            };
        }

        default: {
            return state;
        }
    }
};
