import * as ActionTypes from "../action-types/vehicleLog";

export const vehicleLog = (
    state = {
        byVID: {},
    },
    action
) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_VEHICLE_LOG: {
            // console.log("Get Vehicle Log Data:", action);

            newState = JSON.parse(JSON.stringify(state));

            if (action.data.length > 0) {
                action.data.forEach((currLog) => {
                    if (!Object.keys(newState.byVID).includes(currLog.vid)) {
                        newState.byVID[currLog.vid] = [];
                    }

                    newState.byVID[currLog.vid].push(currLog);
                })
            }

            return newState;
        }

        default: {
            return state;
        }
    }
};
