import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../components/Page";
import ColorPicker from '../../components/ColorPicker';
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../components/Button";
import { goBackToPrev } from "../../navigation/navigationService";
import { 
    VEHICLE_GROUP_COLORS,
    DEFAULT_VEHICLE_GROUP_HEXCODE,
} from "../../../../constants";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import {
    add_vehicle_group,
    clear_temp_new_vgid,
    assign_vehicle_group,
} from "../../services/redux/actions/vehicleGroups";

const FormItem = Form.Item;

class AddVehicleGroup extends Component {
    state = {
        isLoading: true,

        groupName: "",
        colorHexCode: DEFAULT_VEHICLE_GROUP_HEXCODE,

        searchedVehicleNameString: "",
        selectedVIDKeys: [],
    }

    keySeperator = " - ";

    submitForm = () => {
        const { 
            groupName,
            colorHexCode,
        } = this.state;

        let isValid = true;

        if (!this.props.user.uid) {
            message.error("Current User ID Not Found");

            isValid = false;
        }

        if (!groupName) {
            message.error("Please Enter The Vehicle Group's Name");

            isValid = false;
        }

        if (!colorHexCode) {
            message.error("Please Enter A Vehicle Color For The Vehicle Group");

            isValid = false;
        }

        if (isValid) {
            // console.log(
            //     `Adding New Vehicle Group:
            //     - UID: ${this.props.user.uid}
            //     - Group Name: ${groupName}`
            // );

            this.setState({ 
                isLoading: true, 
            },
                () => {
                    this.props.dispatch(add_vehicle_group(this.props.user.uid, groupName, colorHexCode))
                    .then(() => {
                        this.setState({ isLoading: false });
                    })
                }
            );
        }
    }

    componentDidMount = () => {
        // Stop loading if already have vehicles
        if (Object.keys(this.props.vehicles.byId).length) {
            this.setState({ isLoading: false });
        }
    }

    componentDidUpdate = (prevProps) => {
        const { 
            vehicles,
            vehicleGroups, 
        } = this.props;

        // Stop loading once vehicles return
        if (JSON.stringify(prevProps.vehicles) !== JSON.stringify(vehicles) && Object.keys(vehicles.byId).length) {
            this.setState({ isLoading: false });
        }

        if (JSON.stringify(prevProps.vehicleGroups) !== JSON.stringify(vehicleGroups) && vehicleGroups.tempNewVGID) {
            if (this.state.selectedVIDKeys.length > 0) {
                const selectedVIDs = this.state.selectedVIDKeys.map((currKey) => {
                    if (currKey.includes(this.keySeperator)) {
                        return currKey.split(this.keySeperator)[1];
                    }
                    else {
                        return currKey
                    }
                })

                // console.log(`
                //     Assigning Vehicle Group:
                //     - UID: ${this.props.user.uid}
                //     - Selected VGID: ${vehicleGroups.tempNewVGID}
                //     - Selected VIDs: ${selectedVIDs}
                // `);

                this.props.dispatch(assign_vehicle_group(this.props.user.uid, vehicleGroups.tempNewVGID, selectedVIDs));
            }
            else {
                this.props.dispatch(goBackToPrev());
            }
        }
    }

    UNSAFE_componentWillMount = () => {
        this.props.dispatch(clear_temp_new_vgid());
    }

    render() {
        const { isLoading } = this.state;
        const { vehicles } = this.props;

        return (
            <div className = "page-container">
                <Page title = "Add New Vehicle Group">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form" style = {{ marginBottom: 25 }}>
                            <FormItem
                                label = "Group Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Group Name"
                                    value = {this.state.groupName}
                                    onChange = {e =>
                                        this.setState({
                                            groupName: e.target.value
                                        })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label = "Vehicle Group Color"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <div
                                    style = {{
                                        display: "flex",
                                        alignItems: "center",

                                        height: 40,
                                    }}
                                >
                                    <ColorPicker
                                        currHexCode = {this.state.colorHexCode}
                                        presetColors = {VEHICLE_GROUP_COLORS}
                                        onChange = {(color) => {
                                            this.setState({
                                                ...this.state,
                                                colorHexCode: color.hex.toUpperCase(),
                                            },
                                                () => {
                                                    // console.log("New Color:", this.state.colorHexCode);
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </FormItem>
                            
                            <FormItem
                                label = "Assign Vehicle(s)"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    Object.values(vehicles.byId).length ?
                                        <Select
                                            mode = "multiple"
                                            placeholder = "Select Vehicles To Add (Optional)"
                                            value = {this.state.selectedVIDKeys.length > 0 ? this.state.selectedVIDKeys : undefined}
                                            onSearch = {(value) => {
                                                // console.log("Test:", value);

                                                this.setState({ 
                                                    ...this.state,
                                                    searchedVehicleNameString: value
                                                })
                                            }}
                                            onChange = {(value) => {
                                                // console.log("Test:", value);

                                                this.setState({ 
                                                    ...this.state,
                                                    selectedVIDKeys: [...value]
                                                })
                                            }}
                                            style = {{ width: '100%' }}
                                        >
                                            {
                                                Object.values(vehicles.byId)
                                                .filter((currVehicle) => currVehicle.vehicleDisplayName && currVehicle.vehicleDisplayName.toLowerCase().includes(this.state.searchedVehicleNameString.toLowerCase()))
                                                .sort((a, b) => {
                                                    const vA = a.vehicleDisplayName.toLowerCase();
                                                    const vB = b.vehicleDisplayName.toLowerCase();
            
                                                    if (vA < vB) return -1;
                                                    if (vA > vB) return 1;
                                                    return 0;
                                                })
                                                .map((currVehicle) => 
                                                    <Select.Option key = {currVehicle.vid} value = {currVehicle.vid}>
                                                        {currVehicle.vehicleDisplayName}
                                                    </Select.Option>
                                                )
                                            }
                                        </Select> :
                                        <Select
                                            disabled
                                            placeholder = "No Vehicles Found"
                                        />
                                }
                            </FormItem>

                            <div
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    className = "login-form-button"
                                    type = "danger"
                                    disabled = {isLoading}
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading = {isLoading}
                                    onClick = {this.submitForm}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    vehicles: state.v2.vehicles,
    vehicleGroups: state.v2.vehicleGroups,
});

const ConnectedPage = connect(mapStateToProps)(AddVehicleGroup);
export default showSecondarySidebar(false)(ConnectedPage);