
export const user = (state = {}, action) => {
    switch (action.type) {
        case "SA_USER_SIGN_IN": {
            let user = action.user;
            return({
                ...state,
                ...user[0],
                stages: user
            });
        }
        default: {
            return state;
        }
    }
}