import React, { useState, useEffect } from 'react';
import {
    connect,
    useDispatch,
    useSelector,
} from 'react-redux';

import moment from 'moment';
import Tickets from '../Ticket';
import MapSearchBar from '../MapSearchBar';
import MapControlPanel from '../MapControlPanel';
import POIGeofences from "./components/POIGeofences";
import VehicleMarkers from "./components/VehicleMarkers";
import PolygonGeofences from "./components/PolygonGeofences";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { parseTime } from '../../util/time';
import { LOCATION, ROLE_TYPE } from '../../../../constants';
import { moveToPage } from '../../navigation/navigationService';
import {
    GoogleMap,
    InfoWindow,
    TrafficLayer,
} from '@react-google-maps/api';
import {
    Tag,
    Modal,
    Input,
    Button,
    message,
    Descriptions,
} from "antd";

// Redux Actions
import { set_map_control } from '../../services/redux/actions/mapControl';
import { clear_selected_device } from "../../services/redux/actions/devices.js";
import { resolve_ticket_states } from '../../services/redux/actions/ticketStates';
import { clear_selected_tsid } from "../../services/redux/actions/transitRecord.js";
import { clear_selected_geofence_geoid } from '../../services/redux/actions/geofences';
import {
    clear_enabled_vehicle_pop_ups,
    clear_enabled_geofence_pop_ups,
} from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const GoogleMapsComponent = (props) => {
    // State
    const [mapRef, setMapRef] = useState(null);

    // Redux Store
    const user = useSelector(state => state.v2.user);
    const router = useSelector(state => state.router);
    const vehicles = useSelector(state => state.v2.vehicles);
    const mapControl = useSelector(state => state.v2.mapControl);
    const ticketStates = useSelector(state => state.v2.ticketStates);

    const dispatch = useDispatch()

    /**
     * Util function
     * Add params to page without overwriting state
     */
    function moveToPageWithState(state) {
        const newState = {
            ...router.location.state || {},
            ...state
        };

        dispatch(moveToPage(`/`, newState));
    }

    // Initial mount of component
    useEffect(() => {
        // console.log('new google maps component onMount');

        dispatch(clear_selected_device());
        dispatch(clear_enabled_vehicle_pop_ups());
        dispatch(clear_selected_geofence_geoid());
        dispatch(clear_enabled_geofence_pop_ups());
    }, [dispatch])

    /**
     * If map did not set location to geofence/vehicles within 5 seconds, (a sign of a new account)
     * default location & zoom would be set instead
     */
    useEffect(() => {
        if (!mapRef) return

        setTimeout(() => {
            if (!mapRef.center) {
                // console.log(`[GoogleMapsComponent] Auto setting map location`)

                mapRef.panTo(LOCATION)
                mapRef.setZoom(7)
            }
        }, 5 * 1000)
    })

    /**
     * If map hasn't set a location yet,
     * Pan to all map item's circumference location
     * Else if map already has a location, do nothing
     */
    useEffect(() => {
        if (!mapRef) return
        if (mapRef.center) return
        // if (props.style.isLoadingSubmit) return

        const vehicleLocations = Object.values(props.vehicles.byId).map(vehicle => {
            const device = vehicle && props.devices.byId[vehicle.dvid]
            const location = device && device.location
            return location && {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lon)
            }
        })
            .filter(l => l)

        const geofenceLocations = 
            Object.values(props.geofences.byId)
            .map(geofence => geofence.coordinates)
            .reduce((a, b) => [...a, ...b], [])
            .filter(l => l)

        const allLocations = [...vehicleLocations, ...geofenceLocations]

        if (allLocations.length) {
            const bounds = new window.google.maps.LatLngBounds();

            allLocations.map(c => {
                bounds.extend(c)
                return null;
            })

            // console.log(`[GoogleMapsComponent] Setting map location by vehicles & geofences`);

            mapRef.fitBounds(bounds, [10]);
        }

    }, [props.vehicles, props.geofences, props.devices, mapRef, props.style])

    /**
     * Render geofence, vehicles, etc
     */
    useEffect(() => {
        const {
            geofences,
            mapControl
        } = props;

        let zoom, location;

        /**
         * Pan to selected transit status
         */
        if (
            geofences.selectedId
            && geofences.byId[geofences.selectedId]
            && geofences.byId[geofences.selectedId].coordinates.length > 0
            && mapControl.mapControl === 1
        ) {
            const selectedGeofence = geofences.byId[geofences.selectedId];

            // console.log("Selected Transit:", selectedTransit);

            if (
                selectedGeofence.coordinates.length
                && selectedGeofence.coordinates[0]
                && selectedGeofence.coordinates[0].lat && selectedGeofence.coordinates[0].lng
            ) {
                // console.log("Test 1");

                zoom = 20;

                location = {
                    lat: parseFloat(selectedGeofence.coordinates[0].lat),
                    lng: parseFloat(selectedGeofence.coordinates[0].lng),
                }

                if (mapRef) {
                    mapRef.setZoom(zoom);
                    mapRef.panTo(location);

                    props.dispatch(set_map_control(0));
                }
            }
            else {
                message.error("No location found for this transit status");

                props.dispatch(clear_selected_tsid());
                props.dispatch(set_map_control(0));
            }
        }
    })

    /**
     * Render ticket infowindow based on router.location.state.ticketId
     * @param {*} ticketId 
     */
    const getTicketInfoWindow = (ticketId) => {
        const ticketState = ticketStates.byTicketId[ticketId];

        if (!ticketState) return null

        const {
            vid, 
            // evid,
        } = ticketState;

        const event = ticketState.event;
        const vehicle = vehicles.byId[vid];
        // const event = events.byId[vid] && events.byId[vid][evid];
        const location = event && event.location && {
            lat: Number(event.location.lat),
            lng: Number(event.location.lon)
        }

        // console.log({
        //     ticketState,
        //     vehicle,
        //     event,
        //     location,
        // })

        if (!location) return null

        if (mapRef) {
            mapRef.panTo(location);
            mapRef.setZoom(20);
        }

        function getEventTypeColor(eventType) {
            eventType = eventType && eventType.toLowerCase()

            const EVENT_STATUS_FILTER_BUTTONS = {
                INFO: 'info',
                WARNING: 'warning',
                CRITICAL: 'critical'
            }

            let color = 'black';

            switch (eventType) {
                case EVENT_STATUS_FILTER_BUTTONS.INFO:
                    color = 'blue'
                    break
                case EVENT_STATUS_FILTER_BUTTONS.CRITICAL:
                    color = 'red'
                    break
                case EVENT_STATUS_FILTER_BUTTONS.WARNING:
                    color = 'yellow'
                    break
                default:
                    break
            }

            return color
        }

        return (
            <InfoWindow
                position = {location}
                onCloseClick = {() => {
                    moveToPageWithState({ ticketId: undefined })
                }}
            >
                <div>
                    <Descriptions size = {`default`} column = {1} style = {{ width: 300 }}>
                        <Descriptions.Item label = "Vehicle">
                            {vehicle && vehicle.vehiclePlate}
                        </Descriptions.Item>

                        <Descriptions.Item label = "Event Type">
                            <Tag color = {getEventTypeColor(event.eventType)}>
                                {event.eventType}
                            </Tag>
                        </Descriptions.Item>

                        <Descriptions.Item label = "Event">
                            {event && event.eventName}
                        </Descriptions.Item>
                    </Descriptions>

                    <div
                        style = {{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <h4 style = {{ color: 'grey' }}> Created on {ticketState && parseTime(ticketState.createdAt)}</h4>
                    </div>

                    <Button
                        onClick = {() => {
                            const ticketIds = [ticketId];

                            if (!ticketIds) return

                            const userName = user.userName;
                            let remark = `Tickets acknowledged on ${moment().format('LLLL')} by ${userName}`;

                            return (
                                Modal.confirm({
                                    width: 700,
                                    title: `Resolve tickets (${ticketIds.length})`,
                                    content: (
                                        <div>
                                            <h3>Remarks</h3>
                                            <Input.TextArea
                                                rows = {4}
                                                defaultValue = {remark}
                                                onChange = {e => {
                                                    remark = e.target.value
                                                }}
                                            />
                                        </div>
                                    ),
                                    onOk: () => {
                                        const thisTicketStates = ticketIds.map(ticketId => ticketStates.byTicketId[ticketId]);

                                        dispatch(resolve_ticket_states(thisTicketStates, remark, user.uid));
                                    },
                                    onCancel() { },
                                })
                            )
                        }}
                        style = {{ width: '100%' }}
                    >
                        Mark resolved
                    </Button>
                </div>
            </InfoWindow>
        ) || null
    }
    

    return (
        <GoogleMap
            id = 'example-map'
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragStart = {() => {
                dispatch(set_map_control(0));
                dispatch(clear_selected_device()); // Clear selected device so that if user clicks on vehicle, can zoom again
            }}
            // onZoomChanged = {() => {
            //     // console.log("Is following vehicle?:", mapControl.mapControl === devices.selectedId, mapControl.mapControl, devices.selectedId);

            //     // Stop following vehicle if mapControl.mapControl === devices.selectedId
            //     // devices.selectedId is set in VehicleMarkers component
            //     if (mapControl.mapControl === devices.selectedId) {
            //         dispatch(set_map_control(0));
            //         dispatch(clear_selected_device()); // Clear selected device so that if user clicks on vehicle, can zoom again
            //     }
            // }}
        >
            <POIGeofences mapRef = {mapRef} />
            <VehicleMarkers mapRef = {mapRef} />
            <PolygonGeofences mapRef = {mapRef} />

            {mapControl.uiControl.showLiveTraffic && <TrafficLayer />}

            <div className = "searchBar">
                <MapSearchBar />
            </div>

            <div className = "controlPanel">
                <MapControlPanel />
            </div>

            {/* Ticket module available only on dashboard */}
            {
                props.router.location.pathname === '/' 
                && 
                (
                    user.userRole === ROLE_TYPE.FLEET_OWNER
                    ||
                    user.userRole === ROLE_TYPE.FLEET_MANAGER
                    ||
                    user.userRole === ROLE_TYPE.FLEET_OPERATOR
                )
                &&
                (
                    <div
                        style = {{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            zIndex: 99,
                        }}
                    >
                        <Tickets/>
                    </div>
                )
            }

            {
                router.location.state && router.location.state.ticketId && (
                    getTicketInfoWindow(router.location.state.ticketId)
                )
            }
        </GoogleMap>
    )
}

const GoogleMapsComponentWithLoadScript = (props) => GoogleMapsLoadScript(GoogleMapsComponent, props)

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    router: state.router,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    mapControl: state.v2.mapControl,
});

// export default GoogleMapsComponentWithLoadScript;
export default connect(mapStateToProps)(GoogleMapsComponentWithLoadScript);