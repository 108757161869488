import React, { useState, useEffect } from "react";
import { 
    useDispatch,
    useSelector,
} from "react-redux";

import moment from 'moment';
import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import {
    moveToPage,
    goBackToPrev,
} from "../../../navigation/navigationService";
import {
    ROLE_TYPE,
    VEHICLE_TYPE,
} from '../../../../../constants';
import {
    PlusOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons';
import {
    Row,
    Col,
    Form,
    Input,
    Alert,
    Button,
    Select,
    message,
    DatePicker,
    InputNumber,
} from "antd";

// Redux Actions
import { add_new_vehicle } from "../../../services/redux/actions/vehicles";

// Style
import './index.css';

const FormItem = Form.Item;

const { Option } = Select;
const { RangePicker } = DatePicker

const fuelSensorTypes = [
    "dipstick",
    "ultrasonic",
];

const AddVehiclePage = () => {
    const dispatch = useDispatch();

    const router = useSelector(state => state.router);

    const user = useSelector(state => state.v2.user);
    const style = useSelector(state => state.v2.style);
    const devices = useSelector(state => state.v2.devices);
    const vehicles = useSelector(state => state.v2.vehicles);
    const hierarchy = useSelector(state => state.v2.hierarchy);
    const vehicleGroups = useSelector(state => state.v2.vehicleGroups);

    const [ targetUserUid, setTargetUserUid ] = useState(null)
    const [ vgids, setVgids ] = useState([])
    const [ gtids, setGtids ] = useState([])
    const [ rtids, setRtids ] = useState([])
    const [ newVehicle, setNewVehicle ] = useState({
        dvid: "",
        vehicleType: "",
        vehicleModel: "",
        vehiclePlate: "",
        vehicleDisplayName: "",

        serviceNextMileage: 0,
        engineServiceNextMileage: 0,
        rearAxleServiceNextMileage: 0,
        engineServiceNextWorkingHour: 0,
        transmissionServiceNextMileage: 0,

        subsStartDate: moment().startOf("day").valueOf(),
        subsEndDate: moment().startOf("day").add(3, "months").valueOf(),
        permitDueDate: moment().startOf("day").add(3, "months").valueOf(),
        serviceDueDate: moment().startOf("day").add(3, "months").valueOf(),
        engineServiceDueDate: moment().startOf("day").add(3, "months").valueOf(),
        rearAxleServiceDueDate: moment().startOf("day").add(3, "months").valueOf(),
        transmissionServiceDueDate: moment().startOf("day").add(3, "months").valueOf(),
    })

    const [ fuelTanks, setFuelTanks ] = useState([
        {
            portNumber: 0,
            fuelCapacity: 0,
            fuelLevelMin: 0,
            fuelLevelMax: 10,
            fuelLevelOperator: 'V',
            fuelSensorType: fuelSensorTypes[0]
        }
    ])

    const fuelTankSanityCheck = (fuelTanks) => {
        // port number must be unique

        fuelTanks.forEach((tank, i) => {
            // check invalidity
            ['fuelCapacity', 'fuelLevelMin', 'fuelLevelMax', 'fuelSensorType', 'fuelLevelOperator'].forEach(key => {
                if (tank[key] === null || tank[key] === undefined) {
                    throw new Error(`Error at tank ${i}. Make sure fields are filled in correctly`)
                }
            })

            // update port number
            tank.portNumber = i
        })
    }

    /**
     * Build newVgids, newGtids, newRtids based on
     * 1. targetUser's default groups/templates
     * 2. any additional groups/templates selected in form
     * 3. (special case if current user is distributor) current user's default vehicle group
     */
    const getGroups = (uid) => {
        const {
            vehicleGroups: HVehicleGroups = [],
            ruleTemplates: HRuleTemplates  = [],
            geofenceTemplates: HGeofenceTemplates = [],
        } = hierarchy.byId[uid];

        const targetUserDefaultVehicleGroup = HVehicleGroups.find(vg => vg.groupName === 'ALL');
        const targetUserDefaultVgid = targetUserDefaultVehicleGroup && targetUserDefaultVehicleGroup.vgid;

        const targetUserDefaultRulesTemplate = HRuleTemplates.find(rt => rt.templateName === 'DEFAULT');
        const targetUserDefaultRtid = targetUserDefaultRulesTemplate && targetUserDefaultRulesTemplate.rtid;

        const targetUserDefaultGeofenceTemplate = HGeofenceTemplates.find(gt => gt.templateName === 'UNGROUP');
        const targetUserDefaultGtid = targetUserDefaultGeofenceTemplate && targetUserDefaultGeofenceTemplate.gtid;

        let newGtids = [...gtids, targetUserDefaultGtid];
        let newRtids = [...rtids, targetUserDefaultRtid];
        let newVgids = [...vgids, targetUserDefaultVgid];

        /**
         * If current user is distributor, created vehicle would be added in current user's default vehicle group as well
         */
        switch (user.userRole) {
            case ROLE_TYPE.DISTRIBUTOR:
                const defaultVehicleGroup = Object.values(vehicleGroups.byVGID).find(vg => vg.groupName === 'ALL');
                const defaultVgid = defaultVehicleGroup && defaultVehicleGroup.vgid;

                newVgids.push(defaultVgid);

                break
            default:
                break
        }

        return {
            newGtids: [...new Set(newGtids)], // new Set() removes duplicates
            newRtids: [...new Set(newRtids)], // new Set() removes duplicates
            newVgids: [...new Set(newVgids)], // new Set() removes duplicates
        }
    }

    const submitForm = () => {
        let newSubmitVehicle = JSON.parse(JSON.stringify(newVehicle));

        let {
            dvid,
            vehicleType,
            vehicleModel,
            vehiclePlate,
            vehicleDisplayName,
        } = newSubmitVehicle;

        try {
            if (!dvid) throw new Error('Please select a device for this vehicle');
            if (!vehicleDisplayName) throw new Error('Please enter a vehicle name');
            if (!vehiclePlate) throw new Error('Please enter vehicle plate');
            if (!vehicleModel) throw new Error(`Please enter a vehicle model`);
            if (!vehicleType) throw new Error(`Please select vehicle type`);
            if (!targetUserUid) throw new Error(`Please select target user`);

            fuelTankSanityCheck(fuelTanks);

            /**Build fuel profile */
            newSubmitVehicle.fuelProfile = {};
            fuelTanks.forEach((tank) => {
                const key = tank.portNumber ? `fuel${tank.portNumber}` : `fuel`;
                newSubmitVehicle.fuelProfile[key] = tank;
                
                delete tank.portNumber;
            })

            const {
                newGtids,
                newRtids,
                newVgids,
            } = getGroups(targetUserUid);

            const {
                uid,
                userRole,
            } = hierarchy.byId[targetUserUid];

            const targetUser = {
                uid,
                userRole,
            }

            // console.log("Test:", newVehicle, vgids, gtids, rtids);
            // console.log("Adding new vehicle!:", newSubmitVehicle, newVgids, newGtids, newRtids);

            dispatch(add_new_vehicle(newSubmitVehicle, targetUser, newVgids, newGtids, newRtids));
        }
        catch (err) {
            message.error(err.message)
        }
    }

    const addFuelTank = () => {
        const newfuelTanks = JSON.parse(JSON.stringify(fuelTanks));
        const sampleFuelTank = {
            portNumber: 0,
            fuelCapacity: 0,
            fuelLevelMin: 0,
            fuelLevelMax: 10,
            fuelLevelOperator: 'V',
            fuelSensorType: fuelSensorTypes[0],
        }

        newfuelTanks.push(sampleFuelTank);

        setFuelTanks(newfuelTanks);
    }

    const removeFuelTank = (id) => {
        const newfuelTanks = JSON.parse(JSON.stringify(fuelTanks));
        newfuelTanks.splice(id, 1);

        setFuelTanks(newfuelTanks)
    }

    const fuelInfoForm = (index) => {
        const newfuelTanks = JSON.parse(JSON.stringify(fuelTanks));
        const fuelTank = { ...newfuelTanks[index] };
        const isLatest = index === fuelTanks.length - 1;

        return (
            <FormItem
                labelCol = {{ span: 6 }}
                wrapperCol = {{ span: 18 }}
            >
                Fuel Capacity (L)
                <InputNumber
                    value = {fuelTank.fuelCapacity}
                    onChange = {fuelCapacity => {
                        fuelTank.fuelCapacity = fuelCapacity;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                    style = {{ width: '100%' }}
                />

                Fuel Maximum Level
                <InputNumber
                    value = {fuelTank.fuelLevelMax}
                    onChange = {fuelLevelMax => {
                        fuelTank.fuelLevelMax = fuelLevelMax;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                    style = {{ width: '100%' }}
                />

                Fuel Minimum Level
                <InputNumber
                    value = {fuelTank.fuelLevelMin}
                    onChange = {fuelLevelMin => {
                        fuelTank.fuelLevelMin = fuelLevelMin;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                    style = {{ width: '100%' }}
                />

                Fuel Level Operator
                <Select
                    defaultValue = {fuelTank.fuelLevelOperator}
                    value = {fuelTank.fuelLevelOperator}
                    placeholder = "% or V"
                    onChange = {fuelLevelOperator => {
                        fuelTank.fuelLevelOperator = fuelLevelOperator;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                >
                    {
                        ['%', 'V'].map(operator => {
                            return (
                                <Option key = {operator} value = {operator}>
                                    {operator}
                                </Option>
                            );
                        })
                    }
                </Select>

                Fuel Sensor Type
                <Select
                    defaultValue = {fuelTank.fuelSensorType}
                    value = {fuelTank.fuelSensorType}
                    placeholder = "% or V"
                    onChange = {fuelSensorType => {
                        fuelTank.fuelSensorType = fuelSensorType;
                        newfuelTanks[index] = fuelTank;

                        setFuelTanks(newfuelTanks);
                    }}
                >
                    {
                        fuelSensorTypes.map(type => {
                            return (
                                <Option key = {type} value = {type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Option>
                            );
                        })
                    }
                </Select>

                <div style = {{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        type = "dashed"
                        ghost
                        disabled = {fuelTanks.length === 1}
                        onClick = {() => removeFuelTank(index)}
                        style = {{
                            color: 'red',
                            borderColor: 'red',
                            width: index === fuelTanks.length - 1 ? '48%' : '100%',
                        }}
                    >
                        <MinusCircleOutlined /> Remove
                    </Button>

                    {
                        index === fuelTanks.length - 1 &&
                            <Button
                                type = "dashed"
                                onClick = {() => addFuelTank()}
                                style = {{ width: isLatest ? '48%' : '100%' }}
                            >
                                <PlusOutlined /> Add
                            </Button>
                    }
                </div>
            </FormItem>
        )
    }

    useEffect(() => {
        const setUpFormDefaultValues = () => {
            const { uid } = router.location.state || {};

            setTargetUserUid(uid);
        }

        setUpFormDefaultValues();
    }, 
        [router.location.state]
    )

    const returnDeviceAttachedVehicle = (device) => Object.values(vehicles.byId).find(vehicle => vehicle.dvid === device.dvid);

    const deviceList = Object.values(devices.byId).sort((a, b) => {
        const dA = a.dvid.toLowerCase();
        const dB = b.dvid.toLowerCase();

        if (dA < dB) return -1;
        if (dA > dB) return 1;
        return 0;
    })

    const isNoUnassignedDevice = 
        Object.values(devices.byId)
        .filter(device => 
            !Object.values(vehicles.byId).find(vehicle => vehicle.dvid === device.dvid)
        ).length === 0

    /**Get vgids, gtids, rtids of target user */
    const userObject = hierarchy.byId[targetUserUid];

    // console.log(userObject);

    let HRuleTemplates = (userObject && userObject.ruleTemplates) || [];
    let HVehicleGroups = (userObject && userObject.vehicleGroups) || [];
    let HGeofenceTemplates = (userObject && userObject.geofenceTemplates) || [];

    return (
        <div className = "page-container">
            <Page title = "Add New Vehicle">
                {
                    isNoUnassignedDevice && (
                        <Alert
                            banner
                            closable
                            message = {<p>All devices are already assigned to a vehicle. {<button type = "button" className = "link-button" onClick = {() => dispatch(moveToPage('/UserManagement/AddDevice'))}> Create a device to proceed </button>}</p>}
                        />
                    )
                }

                <div style = {{ padding: "30px 20px 0px 20px" }}>
                    <Form className = "login-form">
                        <FormItem
                            required
                            label = "Vehicle Name"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "Car 1"
                                value = {newVehicle.vehicleDisplayName}
                                onFocus = {e => e.target.select()}
                                onChange = {e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        vehicleDisplayName: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Vehicle Plate"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "ABC123"
                                value = {newVehicle.vehiclePlate}
                                onFocus = {e => e.target.select()}
                                onChange = {e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        vehiclePlate: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Vehicle Model"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "Toyota Vios"
                                value = {newVehicle.vehicleModel}
                                onFocus = {e => e.target.select()}
                                onChange = {e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        vehicleModel: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Vehicle Type"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Select
                                placeholder = "Select a vehicle type"
                                onChange = {value => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        vehicleType: value
                                    })
                                }
                            >
                                {
                                    VEHICLE_TYPE.map(vehicleType => {
                                        return (
                                            <Option key = {vehicleType.value} value = {vehicleType.value}>
                                                {vehicleType.label}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>

                        <FormItem
                            label = "Vehicle Fuel Information"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Row>
                                {
                                    fuelTanks.length > 0 ?
                                        fuelTanks.map((fuelTank, i) => {
                                            return (
                                                <Col span = {12} key = {i}>
                                                    {fuelInfoForm(i)}
                                                </Col>
                                            )
                                        }) :
                                        <Button
                                            type = "dashed"
                                            onClick = {() => addFuelTank()}
                                            style = {{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Add
                                        </Button>
                                }
                            </Row>
                        </FormItem>

                        {/* Vehicle extra information */}
                        <FormItem
                            required
                            label = "Engine Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {newVehicle.engineServiceNextMileage || 0} 
                                onChange = { e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        engineServiceNextMileage: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Engine Next Running Hours (hrs)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {newVehicle.engineServiceNextWorkingHour || 0} 
                                onChange = { e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        engineServiceNextWorkingHour: e.target.value,
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            label = "Engine Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                            required
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(newVehicle.engineServiceDueDate)}
                                onChange = {(date) => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        engineServiceDueDate: date.startOf("day").valueOf()
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            label = "Transmission Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                            required
                        >
                            <Input 
                                type = 'number' 
                                value = {newVehicle.transmissionServiceNextMileage || 0} 
                                onChange = { e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        transmissionServiceNextMileage: e.target.value
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Transmission Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(newVehicle.transmissionServiceDueDate)} 
                                onChange = {(date) => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        transmissionServiceDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Rear Axle Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {newVehicle.rearAxleServiceNextMileage || 0} 
                                onChange = { e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        rearAxleServiceNextMileage: e.target.value
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Rear Axle Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker
                                allowClear = {false}
                                value = {moment(newVehicle.rearAxleServiceDueDate)} 
                                onChange = {(date) => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        rearAxleServiceDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Service Next Odometer (km)"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                type = 'number' 
                                value = {newVehicle.serviceNextMileage || 0} 
                                onChange = { e => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        serviceNextMileage: e.target.value
                                    })
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Service Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(newVehicle.serviceDueDate)} 
                                onChange = {(date) => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        serviceDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Permit Due Date"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <DatePicker 
                                allowClear = {false}
                                value = {moment(newVehicle.permitDueDate)} 
                                onChange = {(date) => 
                                    setNewVehicle({
                                        ...newVehicle,
                                        permitDueDate: date.startOf("day").valueOf()
                                    })
                                } 
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Subscription Duration"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <RangePicker 
                                allowClear = {false}
                                value = {[moment(newVehicle.subsStartDate), moment(newVehicle.subsEndDate)]} 
                                onChange = {(date) =>
                                    setNewVehicle({
                                        ...newVehicle,
                                        subsEndDate: date[1].startOf("day").valueOf(),
                                        subsStartDate: date[0].startOf("day").valueOf(),
                                    })
                                } 
                            />
                        </FormItem>

                        {/* This should be a list of all devices under currently logged in user */}
                        <FormItem
                            required
                            label = "Device"
                            // help = {`Cannot be edited once set`}
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Select
                                disabled = {!Object.values(devices.byId).length}
                                showSearch
                                placeholder = {`Select a device`}
                                optionFilterProp = "children"
                                filterOption = {(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange = {dvid =>
                                    setNewVehicle({
                                        ...newVehicle,
                                        dvid
                                    })
                                }
                            >
                                {
                                    [
                                        ...deviceList.filter((currDevice) => !returnDeviceAttachedVehicle(currDevice)),
                                        ...deviceList.filter((currDevice) => returnDeviceAttachedVehicle(currDevice)),
                                    ]
                                    .map((currDevice) => {
                                        const attachedVehicle = returnDeviceAttachedVehicle(currDevice)

                                        return (
                                            <Option
                                                key = {currDevice.dvid}
                                                disabled = {attachedVehicle} // Check if device has a vehicle
                                                value = {currDevice.dvid}
                                            >
                                                {`${currDevice.dvid} ${(attachedVehicle && `(${attachedVehicle.vehicleDisplayName})`) || ''}`}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>

                        {/* 
                            Not allowed to select fleet operator as target user.
                            To assign fleet operator a vehicle,
                            1. log in as fleet owner
                            2. go to user management
                            3. click on designated fleet operator
                            4. configure user
                        */}
                        <FormItem
                            required
                            label = "Target User"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <div style = {{ fontWeight: "bold" }}>{user && `${user.userName} (${user.userRole})`}</div>
                        </FormItem>

                        {
                            targetUserUid && (
                                <div style = {{ marginTop: 20 }}>
                                    {/* To decide which vehicle group of the target user this vehicle should be assigned to */}
                                    <FormItem
                                        label = "Vehicle Groups"
                                        help = {targetUserUid ? '' : 'Select a target user first'}
                                        labelCol = {{ span: 7 }}
                                        wrapperCol = {{ span: 15 }}
                                    >
                                        {
                                            <Select
                                                mode = "multiple"
                                                showSearch
                                                placeholder = "Select vehicle groups to assign vehicle to (Optional)"
                                                value = {vgids}
                                                onChange = {(value) => setVgids(value)}
                                                style = {{ width: '100%' }}
                                            >
                                                {
                                                    HVehicleGroups
                                                        .filter(vg => vg.groupName !== 'ALL')
                                                        .map(vg => {
                                                            return (
                                                                (
                                                                    vg
                                                                    &&
                                                                    vg.subName
                                                                    &&
                                                                    <Option key = {vg.vgid}>{vg.groupName} [${vg.subName}]</Option>
                                                                )
                                                                ||
                                                                <Option key = {vg.vgid}>{vg.groupName}</Option>
                                                            )
                                                        })
                                                }
                                            </Select>
                                        }
                                    </FormItem>

                                    {/* Important for rule engine. */}
                                    <FormItem
                                        label = "Geofence Templates"
                                        help = {targetUserUid ? '' : 'Select a target user first'}
                                        labelCol = {{ span: 7 }}
                                        wrapperCol = {{ span: 15 }}
                                    >
                                        {
                                            <Select
                                                mode = "multiple"
                                                showSearch
                                                placeholder = "Select geofence templates to assign vehicle to (Optional)"
                                                value = {gtids}
                                                onChange = {(new_gtids) => setGtids(new_gtids)}
                                                style = {{ width: '100%' }}
                                            >
                                                {
                                                    HGeofenceTemplates
                                                        .filter(gt => gt.templateName !== 'UNGROUP')
                                                        .map(gt => {
                                                            return <Option key = {gt.gtid}>{gt.templateName}</Option>
                                                        })
                                                }
                                            </Select>
                                        }
                                    </FormItem>

                                    {/* Important for rule engine. */}
                                    <FormItem
                                        label = "Rule Templates"
                                        help = {targetUserUid ? '' : 'Select a target user first'}
                                        labelCol = {{ span: 7 }}
                                        wrapperCol = {{ span: 15 }}
                                    >
                                        {
                                            <Select
                                                mode = "multiple"
                                                showSearch
                                                placeholder = "Select rule templates to assign vehicle to (Optional)"
                                                value = {rtids}
                                                onChange = {(new_rtids) => setRtids(new_rtids)}
                                                style = {{ width: '100%' }}
                                            >
                                                {
                                                    HRuleTemplates
                                                        .filter(rt => rt.templateName !== 'DEFAULT')
                                                        .map(rt => {
                                                            return <Option key = {rt.rtid}>{rt.templateName}</Option>
                                                        })
                                                }
                                            </Select>
                                        }
                                    </FormItem>
                                </div>
                            )
                        }

                        <div
                            style = {{
                                display: "flex",
                                justifyContent: "flex-end",

                                padding: 5,
                                marginBottom: 10,
                            }}
                        >
                            <Button
                                className = "login-form-button"
                                type = "danger"
                                disabled = {style.isLoadingSubmit}
                                onClick = {() => dispatch(goBackToPrev())}
                            >
                                Cancel
                            </Button>

                            <PrimaryButton
                                loading = {style.isLoadingSubmit}
                                onClick = {submitForm}
                                style = {{ marginLeft: 10 }}
                            >
                                Add
                            </PrimaryButton>
                        </div>
                    </Form>
                </div>
            </Page>
        </div>
    )
}

export default showSecondarySidebar(false)(AddVehiclePage);