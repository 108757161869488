import * as actionType from "../action-types/notifications";

const defaultState = {
    byNfID: {},
    allNfIDs: [],

    notificationLog: [],
    selectedNfID: "",
};

export const notifications = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actionType.GET_NOTIFICATIONS: {
            action.notifications.forEach(currNotification => {
                if (!newState.allNfIDs.includes(currNotification.nfid)) newState.allNfIDs.push(currNotification.nfid);
                newState.byNfID[currNotification.nfid] = currNotification;
            })

            return newState;
        }

        case actionType.ADD_NOTIFICATION: {
            newState.allNfIDs.push(action.notification.nfid);
            newState.byNfID[action.notification.nfid] = action.notification;

            return newState;
        }

        case actionType.EDIT_NOTIFICATION: {
            newState.byNfID[action.notification.nfid] = action.notification;

            return newState;
        }

        case actionType.GET_NOTIFICATION_LOG_BY_TIMEFRAME: {
            // console.log("Get Notification Log By Timeframe Data:", action.notificationLog);

            return {
                ...newState,
                notificationLog: action.notificationLog,
            };
        }

        case actionType.CLEAR_SELECTED_NFID: {
            return {
                ...newState,
                notificationLog: [],
            };
        }

        case actionType.SET_SELECTED_NFID: {
            // console.log("Set Selected NfID Data:", action.selectedNfID);

            return {
                ...newState,
                selectedNfID: action.selectedNfID,
            };
        }
        default: {
            return state;
        }
    }
}