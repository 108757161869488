import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import EventStatusIcon from "../../../components/EventStatusIcon";

import { parseTime } from '../../../util/time';
import { moveToPage } from "../../../navigation/navigationService";
import {
    Col,
    Row,
} from 'antd';
import {
    ReloadOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import {
    Marker,
    InfoWindow,
    OverlayView,
} from '@react-google-maps/api';
import {
    VEHICLE_COLOR,
    VEHICLE_STATUS,
    DEFAULT_GEOFENCE_HEXCODE,
    DEFAULT_VEHICLE_GROUP_HEXCODE,
} from "../../../../../constants";

// Handlers
import { vehicles_handler } from '../../../handlers';

// Redux Actions
import { storeSearchDetails } from "../../../services/redux/actions/tripRecordControl.js";
import { temp_update_vehicle_address_2 } from "../../../services/redux/actions/vehicles.js";
import {
    set_selected_device,
    clear_selected_device,
} from "../../../services/redux/actions/devices.js";
import {
    set_map_control,
    set_new_geofence_from_map,
    add_enabled_vehicle_pop_up,
    clear_enabled_vehicle_pop_ups,
    remove_enabled_vehicle_pop_up,
} from "../../../services/redux/actions/mapControl";

import "../gmaps.css";

const { showVehicleTransitStatusChangeDuration } = vehicles_handler;

const VehicleMarkers = (props) => {
    const { mapRef } = props;

    // State
    const [isUpdateAddressLoading, setIsUpdateAddressLoading] = useState(false);

    // Redux Store
    const devices = useSelector(state => state.v2.devices);
    const vehicles = useSelector(state => state.v2.vehicles);
    const geofences = useSelector(state => state.v2.geofences);
    const mapControl = useSelector(state => state.v2.mapControl);
    const vehicleGroups = useSelector(state => state.v2.vehicleGroups);
    const geofenceTemplates = useSelector(state => state.v2.geofenceTemplate);

    const dispatch = useDispatch();

    const returnLatestGeofenceTemplateColorForGeoID = (geoID) => {
        let latestTemplate = {};

        Object.values(geofenceTemplates.byId)
            .filter((currTemplate) => currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
            .forEach((currTemplate) => {
                if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                    latestTemplate = currTemplate;
                }
            })

        // console.log("Color:", latestTemplate.colorHexCode);

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    // Only update map zoom if devices.selectedId has been updated
    // set_map_control() is called here to prevent the map from not following the vehicle the moment the map zooms into the newly targetted vehicle. This is because the map's onZoomChanged()
    useEffect(() => {
        if (mapRef && devices.selectedId) {
            mapRef.setZoom(17);
            
            dispatch(set_map_control(devices.selectedId)); // Set map control value here so that maps can zoom to vehicle without accidentally stop following the vehicle
        }
    },
        [devices.selectedId, dispatch, mapRef]
    )

    // ComponentDidUpdate
    // Pan to selected device
    useEffect(() => {
        if (devices.selectedId) {
            const selectedDevice = devices.byId[devices.selectedId];

            // console.log("selectedDevice:", selectedDevice);
            // console.log("mapRef:", mapRef);
            
            if (
                selectedDevice
                && selectedDevice.location
                && selectedDevice.location.lat
                && selectedDevice.location.lon
                && mapControl.mapControl
                && mapControl.mapControl === devices.selectedId
            ) {
                // console.log("Test:", mapControl.mapControl, devices.selectedId);

                const location = {
                    lat: parseFloat(selectedDevice.location.lat),
                    lng: parseFloat(selectedDevice.location.lon),
                }

                if (mapRef) {
                    // console.log("Panning!");

                    mapRef.panTo(location);
                }
            }
        }
    },
        [devices, mapControl, mapRef]
    )

    // Initial mount of component - ComponentDidMount()
    useEffect(() => {
        dispatch(clear_selected_device());
        dispatch(clear_enabled_vehicle_pop_ups());
    },
        [dispatch]
    )

    const getVehicleMarker = (thisVehicle, thisDevice) => {
        if (
            thisDevice &&
            thisVehicle &&
            thisDevice.location
        ) {
            const paddingInt = 0;
            const vid = thisVehicle.vid;

            // if (thisVehicle.vid === "vhc#039c0bd0-0128-11eb-b548-27ef58eba1dd") console.log("thisVehicle:", thisVehicle);

            const inGeofenceGeoid = thisVehicle.geofenceProfile && Object.keys(thisVehicle.geofenceProfile).find((currGeoID) => thisVehicle.geofenceProfile[currGeoID] === 1);
            const inGeofence = inGeofenceGeoid ? geofences.byId[inGeofenceGeoid] : null;

            const getVehicleTransitColor = (dvid) => {
                const vid = vehicles.allIds.filter((vid) => vehicles.byId[vid] && vehicles.byId[vid].dvid === dvid);
        
                if (vid.length > 0) {
                    return VEHICLE_COLOR[vehicles.byId[vid[0]].transitStatus]
                }
                else return VEHICLE_COLOR[null];
            }

            const returnLatestVehicleGroupColorForVID = (vid) => {
                let latestGroup = {};
        
                Object.values(vehicleGroups.byVGID)
                    .filter((currGroup) => currGroup.vehicles.includes(vid)) // Only check geofence templates containing this geofence ID
                    .forEach((currGroup) => {
                        if (!latestGroup.createdAt || currGroup.createdAt > latestGroup.createdAt) {
                            latestGroup = currGroup;
                        }
                    })
        
                // console.log("Color:", latestGroup.colorHexCode);
        
                return latestGroup.colorHexCode ? latestGroup.colorHexCode : DEFAULT_VEHICLE_GROUP_HEXCODE;
            }

            return (
                <Marker
                    key = {vid}
                    icon = {{
                        path: window.google && window.google.maps && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 5,
                        strokeColor: getVehicleTransitColor(thisDevice.dvid),
                        rotation: Number(thisDevice.heading),
                        fillOpacity: 1,

                        // 24 is sprite width and 28 is sprite height. Make sure to match future anchors accordingly
                        // anchor: new window.google.maps.Point(24 / 2, 28 / 2),
                    }}
                    position = {{
                        lat: parseFloat(thisDevice.location.lat),
                        lng: parseFloat(thisDevice.location.lon)
                    }}
                    onClick = {() => {
                        // console.log(device.location);

                        dispatch(add_enabled_vehicle_pop_up(vid));
                        dispatch(set_selected_device(thisDevice.dvid));
                        dispatch(set_map_control(thisDevice.dvid));
                    }}
                >
                    {
                        mapControl.uiControl.showInfoWindowVehicles.includes(thisVehicle.vid)
                        && devices.selectedId
                        && devices.selectedId === thisVehicle.dvid ?
                            <InfoWindow
                                zIndex = {1000}
                                options = {{ disableAutoPan: true }}
                                mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                position = {{
                                    lat: parseFloat(thisDevice.location.lat),
                                    lng: parseFloat(thisDevice.location.lon)
                                }}
                                onCloseClick = {() => {
                                    dispatch(remove_enabled_vehicle_pop_up(thisVehicle.vid));
                                }}
                            >
                                <div 
                                    style = {{ 
                                        width: 250 + paddingInt,
                                        // backgroundColor: returnLatestVehicleGroupColorForVID(thisVehicle.vid), 

                                        padding: paddingInt,
                                    }}
                                >
                                    <h1>{thisVehicle.vehicleDisplayName}</h1>

                                    <p style = {{ color: thisVehicle.transitStatus ? VEHICLE_COLOR[thisVehicle.transitStatus] : VEHICLE_COLOR["NULL"] }}>
                                        {showVehicleTransitStatusChangeDuration(thisVehicle)}
                                    </p>

                                    <p>
                                        {
                                            thisVehicle.hasOwnProperty('updatedAt') ?
                                                `Last Updated: ${parseTime(thisVehicle.updatedAt)}` :
                                                thisVehicle.gpsStatus ?
                                                    `Last Updated: ${parseTime(thisVehicle.updatedAt)}` :
                                                    thisVehicle.isAlive ?
                                                        `Last Updated: ${parseTime(thisVehicle.noGPSAt)}` :
                                                        `Last Updated: ${parseTime(thisVehicle.deviceTime)}`
                                        }
                                    </p>
                                    
                                    {<p>Speed: {`${thisVehicle.transitStatus === VEHICLE_STATUS.MOVING ? (!isNaN(thisDevice.speed) && thisDevice.speed.toFixed(1) )|| '0.0' : "0.0"} KM/H`}</p>}

                                    {/* Geofence Details */}
                                    {
                                        inGeofence && thisVehicle.transitStatus !== VEHICLE_STATUS.DISCONNECTED &&
                                            <div style = {{ marginBottom: 5 }}>
                                                <Row style = {{ margin: 1 }}>
                                                    <Col
                                                        span = {7}
                                                        className = "POI"
                                                        style = {{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",

                                                            backgroundColor: returnLatestGeofenceTemplateColorForGeoID(inGeofence.geoid),
                                                        }}
                                                    >
                                                        {/* {inGeofence.geoType} */}
                                                        {inGeofence.geoType === "Polygon" ? "Zone" : inGeofence.geoType}
                                                    </Col>

                                                    <Col
                                                        className = "POI_Description"
                                                        span = {16}
                                                        offset = {1}
                                                        style = {{ 
                                                            // color: "black", 
                                                            color: returnLatestGeofenceTemplateColorForGeoID(inGeofence.geoid), 
                                                        }}
                                                    >
                                                        {inGeofence.geofenceName}
                                                    </Col>
                                                </Row>
                                            </div>
                                    }
                                    
                                    {
                                        isUpdateAddressLoading ?
                                            <div
                                                style = {{
                                                    display: "flex",
        
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <LoadingOutlined style = {{ marginRight: 5 }}/>

                                                <span>Updating Address...</span>
                                            </div> : 
                                            <div
                                                style = {{
                                                    display: "flex",
        
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <span
                                                    className = "ButtonLink"
                                                    onClick = {() => {
                                                        setIsUpdateAddressLoading(true)
                                                        dispatch(temp_update_vehicle_address_2(thisVehicle.vid, thisDevice.location.lat, thisDevice.location.lon));
        
                                                        setTimeout(() => setIsUpdateAddressLoading(false), 1 * 1000)
                                                    }}
                                                    style = {{ marginRight: 5 }}
                                                >
                                                    <ReloadOutlined/>
                                                </span>
        
                                                <span style = {{ fontWeight: "bold" }}>
                                                    {thisVehicle.address || "Address not found"}
                                                </span>
                                            </div>
                                    }

                                    <br />

                                    <div
                                        style = {{
                                            display: "flex",
                                            marginBottom: 5,
                                        }}
                                    >
                                        <EventStatusIcon deviceId = {thisDevice.dvid}/>
                                    </div>

                                    <div
                                        style = {{
                                            display: "flex",

                                            alignItems: "center",
                                            justifyContent: "center",

                                            width: "100%",
                                        }}
                                    >
                                        <span
                                            className = "ButtonLink"
                                            onClick = {() => {
                                                const currCoor = {
                                                    lat: parseFloat(thisDevice.location.lat),
                                                    lng: parseFloat(thisDevice.location.lon)
                                                }

                                                // console.log("Adding new POI from vehicle:", thisDevice.dvid, currDevice.location);

                                                dispatch(set_new_geofence_from_map({
                                                    ...mapControl.geofence,
                                                    coordinates: [currCoor],
                                                }));

                                                dispatch(moveToPage("/Geofence/AddNewPOI"), { 'coordinates': [currCoor] });
                                            }}
                                            style = {{
                                                flex: 1,
                                                fontSize: 12,
                                            }}
                                        >
                                            Add as POI
                                        </span>

                                        <a
                                            className = "ButtonLink"
                                            target = "_blank"
                                            rel = "noopener noreferrer"
                                            href = {`https://www.google.com.my/maps/place/${thisDevice.location.lat},${thisDevice.location.lon}`}
                                            style = {{
                                                flex: 1,
                                                fontSize: 12,
                                            }}
                                        >
                                            View on Map
                                        </a>

                                        <span
                                            className = "ButtonLink"
                                            onClick = {() => {
                                                dispatch(storeSearchDetails(thisDevice.dvid, vid, moment().startOf("day"), moment().startOf("day").add(1, "day")));

                                                dispatch(moveToPage("/TripRecord", {
                                                    vid,
                                                    startTime: moment().startOf("day").valueOf(),
                                                    endTime: moment().endOf("day").valueOf(),
                                                }));
                                            }}
                                            style = {{
                                                flex: 1,
                                                fontSize: 12,
                                            }}
                                        >
                                            View Playback
                                        </span>
                                    </div>
                                </div>
                            </InfoWindow> :
                            mapControl.uiControl.showAllVehiclesInfoWindow &&
                                <OverlayView
                                    mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                    options = {{
                                        disableAutoPan: true,
                                        maxWidth: 250
                                    }}
                                    position = {{
                                        lat: parseFloat(thisDevice.location.lat),
                                        lng: parseFloat(thisDevice.location.lon)
                                    }}
                                    getPixelPositionOffset = {(width, height) => ({
                                        x: -(width / 2),
                                        y: -height - 5,
                                    })}
                                >
                                    <div
                                        style = {{
                                            background: 'white',

                                            border: '1px solid #ccc',
                                            borderRadius: '10px',

                                            backgroundColor: returnLatestVehicleGroupColorForVID(thisVehicle.vid), 

                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            paddingTop: 8,
                                            paddingBottom: 1,
                                        }}
                                    >
                                        <h1>{thisVehicle.vehicleDisplayName}</h1>
                                    </div>
                                </OverlayView>
                    }
                </Marker>
            )
        }
    }

    return (
        vehicles &&
        vehicles.allIds.map(vid => {
            const thisVehicle = vehicles.byId[vid];
            const thisDevice = (thisVehicle && thisVehicle.dvid && devices.byId[thisVehicle.dvid]) || null;

            return thisDevice && getVehicleMarker(thisVehicle, thisDevice)
        })
    )
}

export default VehicleMarkers