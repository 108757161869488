// Services
import {
    // timeDiff,
    durationFormat
} from '../../services/util'

import {
    STATUS,
    GROUP
} from '../../constants'

const {
    VEHICLE_TRNASIT_STATUS_OBJECT
} = STATUS

const {
    DEFAULT_VEHICLE_GROUP_HEXCODE
} = GROUP

export const showVehicleTransitStatusChangeDuration = (vehicle) => {
   
    const returnTime = 
        vehicle.transitStatus === VEHICLE_TRNASIT_STATUS_OBJECT.DISCONNECTED
        ?
            (
                `${vehicle.transitStatus} For ${durationFormat(Date.now() - vehicle.updatedAt)}`
            )
        :
            (
                `${vehicle.transitStatus} For ${durationFormat(Date.now() - vehicle.transitFrom)}`
            )

    return returnTime
}


export const showVehicleTransitStatusChangeDuration_WithoutLabel = (vehicle) => {
    const returnTime = 
        vehicle.transitStatus === VEHICLE_TRNASIT_STATUS_OBJECT.DISCONNECTED
        ?
            (
                `${durationFormat(Date.now() - vehicle.updatedAt)}`
            )
        :
            (
                `${durationFormat(Date.now() - vehicle.transitFrom)}`
            )

    return returnTime
}

export const returnLatestVehicleGroupColorByVGID = (vgid, vehicleGroups) => {
    const currGroup = vehicleGroups[vgid];
    return currGroup.colorHexCode ? currGroup.colorHexCode : DEFAULT_VEHICLE_GROUP_HEXCODE;
}