import * as API from "../../api";
import * as actionType from "../action-types/ticketStates";

import { message } from "antd";
import {
	setLoadingSubmit,
	unsetLoadingSubmit,
} from './style';

export const get_ticket_states = (vids) => (dispatch) => {
	dispatch(setLoadingSubmit());

	return (
		API.getTicketStates(vids)
		.then(data => {
			const {
				err,
				status,
				ticketList: ticketStates,
			} = data;
	
			if (!status || status !== 200) {
				message.error(err || 'Unable to get tickets');
			} 
			else {
				dispatch(get_ticket_states_success(ticketStates));
			}

			return status;
		})
		.finally(() => unsetLoadingSubmit())
	);
}

export const get_ticket_states_success = (ticketStates) => {
	return {
		type: actionType.GET_TICKET_STATES,
		ticketStates: ticketStates
	}
}

export const resolve_ticket_states = (ticketStates, remark, uid) => (dispatch) => {
	dispatch(setLoadingSubmit());

	API.resolveTicketStates(ticketStates, remark, uid)
	.then((data) => {
		const {
			err,
			status,
			ticketIds,
		} = data;

		if (status !== 200) {
			message.error(err || 'Unable to resolve tickets');
		} 
		else {
			dispatch(resolve_ticket_states_success(ticketIds));
		}
	})
	.finally(() => {
		dispatch(unsetLoadingSubmit());
	})
}

export const resolve_ticket_states_success = (ticketIds) => {
	return {
		type: actionType.RESOLVE_TICKET_STATES,
		ticketIds: ticketIds
	}
}