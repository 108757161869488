import 
    React, 
    {  
        useState,
        useEffect,
        useCallback
    }
from "react";
import { 
    useDispatch,
    useSelector
 } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { DEVICE_MODEL } from '../../../../../constants';
import { PrimaryButton } from "../../../components/Button";
import { moveToPage } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { edit_device } from "../../../services/redux/actions/devices";

const FormItem = Form.Item;

const DeviceInfoTemplate = {
    dvid: "",
    simCard: "",
    deviceImei: "",
    deviceModel: "",
    deviceBrand: "",
}

const EditDevice = (props) => {

    const dispatch = useDispatch()

    const {
        device: routerDevice,
        targetUser: routerTargetUser
    } = (props.location && props.location.state) || {};

    const hierarchy = useSelector(state => state.v2.hierarchy)
    const devices = useSelector(state => state.v2.devices)
    const user = useSelector(state => state.v2.user)

    const [ isLoading, setLoading ] = useState(false)
    const [ deviceInfo, setDeviceInfo ] = useState(DeviceInfoTemplate)
    const [ targetUser, setTargetUser ] = useState(null)

    const isSimCardExist = (simCard, editedDevice) => {
        if (editedDevice.simCard === simCard) return false
        return Object.values(devices.byId).find(device => device.simCard === simCard)
    }

    const isDeviceExist = (deviceModel, deviceImei, editedDevice) => {
        const dvid = `${deviceModel}#${deviceImei}`;

        if (editedDevice.dvid === dvid) return false
        return devices.byId[dvid]
    }

    const submitForm = () => {
        const {
            dvid,
            simCard,
            deviceImei,
            deviceModel,
            deviceBrand,
        } = deviceInfo

        try {
            setLoading(true)

            if (!deviceImei) throw new Error(`Device Imei cannot be blank`)
            if (!deviceModel) throw new Error(`Device Model cannot be blank`)
            if (isDeviceExist(deviceModel, deviceImei, deviceInfo)) throw new Error(`Device already exist`)
            if (isSimCardExist(simCard, deviceInfo)) throw new Error(`Sim Card is already in use`)

            const newDevice = {
                dvid: dvid,
                imei: deviceImei,
                simCard: simCard,
                model: deviceModel,
                brand: deviceBrand,
            }

            const uid = user.uid

            dispatch(edit_device(uid, newDevice));
        }
        catch (err) {
            (err.hasOwnProperty('message') && message.error(err.message)) || message.error(err)
        }
    }

    const setFormDefaultValues = useCallback(() => {

        if( 
            routerDevice 
            && 
            Object.keys(routerDevice).length
            &&
            routerTargetUser
        ) {
            setDeviceInfo({
                dvid: routerDevice.dvid,
                simCard: routerDevice.simCard,
                deviceImei: routerDevice.dvid.split("#")[1],
                deviceModel: routerDevice.dvid.split("#")[0],
                deviceBrand: routerDevice.brand,
            })
            setTargetUser(routerTargetUser)
        }
        // eslint-disable-next-line
    }, [routerDevice, routerTargetUser])

    useEffect(() => {
        // eslint-disable-next-line
        setFormDefaultValues()
        return () => {}
    }, [])

    return(
        <div className = "page-container">
            <Page title = "Edit Device Information">
                <div style = {{ padding: "30px 20px 0px 20px" }}>
                    <Form className = "login-form">
                        <FormItem
                            required
                            help = {`*Cannot be changed once created`}
                            label = "Device Imei"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                disabled
                                placeholder = "IMEI Series No."
                                value = {deviceInfo.deviceImei}
                                onChange = {e =>
                                    setDeviceInfo(
                                        {
                                            ...deviceInfo,
                                            deviceImei: e.target.value
                                        }
                                    )
                                }
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Device Model"
                            help = {`*Cannot be changed once created`}
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Select
                                disabled
                                placeholder = "Select a device model"
                                value = {deviceInfo.deviceModel}
                                onChange = {value =>
                                    setDeviceInfo(
                                        {
                                            ...deviceInfo,
                                            deviceModel: value
                                        }
                                    )
                                }
                            >
                                {
                                    Object.keys(DEVICE_MODEL).map((currModel) =>
                                        <Select.Option key = {currModel} value = {currModel}>
                                            {currModel}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </FormItem>

                        <FormItem
                            label = "Device Brand"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "Select a device brand (Optional)"
                                value = {deviceInfo.deviceBrand}
                                onChange = {e => {
                                    setDeviceInfo(
                                        {
                                            ...deviceInfo,
                                            deviceBrand: e.target.value
                                        }
                                    )
                                }}
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Sim Card"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                placeholder = "+60123456789"
                                value = {deviceInfo.simCard}
                                onChange = {e => {
                                    if (e.target.value.length < 2) {
                                        setDeviceInfo(
                                            {
                                                ...deviceInfo,
                                                simCard: "+6",
                                            }
                                        )
                                    }
                                    else {
                                        setDeviceInfo(
                                            {
                                                ...deviceInfo,
                                                simCard: e.target.value
                                            }
                                        )
                                    }
                                }}
                            />
                        </FormItem>

                        <FormItem
                            required
                            label = "Target user"
                            help = {`Target user cannot be changed once set`}
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Select
                                disabled
                                showSearch
                                optionFilterProp = "children"
                                filterOption = {(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder = "Select a user"
                                value = {targetUser}
                                onChange = {value => {
                                    setTargetUser(value)
                                }}
                            >
                                {
                                    Object.values(hierarchy.byId).map(u => {
                                        return (
                                            <Select.Option
                                                key = {u.uid}
                                                value = {u.uid}
                                            >
                                                {`${u.userName} (${u.userRole})`}
                                            </Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>

                        <div style = {{ position: "absolute", right: "100px" }}>
                            <Button
                                className = "login-form-button"
                                type = "danger"
                                disabled = {isLoading}
                                onClick = {() => dispatch(moveToPage("/Management/Device"))}
                                style = {{ marginRight: 10 }}
                            >
                                Cancel
                            </Button>

                            <PrimaryButton
                                loading = {isLoading}
                                onClick = {submitForm}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </Form>
                </div>
            </Page>
        </div>
    )
}

const ConnectedEditDevice = React.memo(EditDevice);
export default showSecondarySidebar(false)(ConnectedEditDevice)