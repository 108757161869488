import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

import {v2Reducer} from '../../../containers/v2/services/redux/reducers'
import { user } from './user';
import { style } from './style';

export const createRootReducer = (history) => combineReducers({
    v2: v2Reducer,
    style,
    user,
    router: connectRouter(history)
      // ... // rest of your reducers
})