import * as API from "../../api";
import * as ActionTypes from "../action-types/fuelRecord";

import { message } from "antd";

export const get_vehicle_fuel_analysis_by_timeframe_request = (vid, startTime, endTime, isReturnDeviceLog, fuelTankNumber) => dispatch => {

    return (
        API
        .getVehicleFuelAnalysisTimeframeSync(vid, startTime, endTime, isReturnDeviceLog, fuelTankNumber)
        .then((data) => {
            const {
                status,
                fuelTankNumber,
                refuels = [],
                deviceLogs = [],
                fuelThefts = [],
                fuelConsumption = {},
            } = data;

            if (status !== 200) {
                message.error(data.message || `Get Fuel Record 3 error`);
            }
            else {
                dispatch(get_vehicle_fuel_analysis_by_timeframe_success(refuels, fuelThefts, fuelConsumption, fuelTankNumber, deviceLogs));
            }

            // dispatch(unsetLoadingSubmit());

            return data.status;
        })
        .catch(err => {
            message.error((err && err.toString()) || `Get Fuel Record 3 error`);
        })
    );
}

const get_vehicle_fuel_analysis_by_timeframe_success = (refuels, fuelThefts, fuelConsumption, fuelTankNumber, deviceLogs) => {
    return {
        type: ActionTypes.GET_FUEL_RECORD,
        refuels,
        fuelThefts,
        fuelConsumption,
        fuelTankNumber,
        deviceLogs,
    }
}