
const GEOFENCE_COLORS = [
    "#5C1A8E", // This is default and therefore first
    "#FF0000",
    "#00FF00",
    "#00FFE8",
    "#FF00FF",
    "#FFA500",
    "#FFFF00",
]

const DEFAULT_GEOFENCE_COLOR = GEOFENCE_COLORS[0];
const DEFAULT_GEOFENCE_HEXCODE = GEOFENCE_COLORS[0];

const VEHICLE_GROUP_COLORS = [
    "#FFFFFF", // This is default and therefore first
    "#FFCCCC",
    "#E6ECFF",
    "#E6FFE6",
    "#FFFFB3",
    "#FFE6FF",
    "#F2E6FF",
]

const DEFAULT_VEHICLE_GROUP_COLOR = VEHICLE_GROUP_COLORS[0];
const DEFAULT_VEHICLE_GROUP_HEXCODE = VEHICLE_GROUP_COLORS[0];

export {
    DEFAULT_GEOFENCE_COLOR,
    DEFAULT_GEOFENCE_HEXCODE,
    DEFAULT_VEHICLE_GROUP_COLOR,
    DEFAULT_VEHICLE_GROUP_HEXCODE,
    GEOFENCE_COLORS,
    VEHICLE_GROUP_COLORS
}