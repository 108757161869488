const deviceState = {
  byId: {},
  byStatus: {
    "ALL": [],
    "MOVING": [],
    "IDLING": [],
    "PARKING": [],
    "DISCONNECTED": []
  },
  allIds: [],
  listIds: [],
  selectedId: null,
  infoBoxList: {}
};

export const devices = (state = deviceState, action) => {

  let newState = JSON.parse(JSON.stringify(state))


  const convertLocationToString = (locationObj) => {
    if (!locationObj) return
    const newLoc = {
      lat: locationObj.lat.toString(),
      lon: locationObj.lon.toString(),
    }

    return newLoc;
  }
  
  switch (action.type) {
    case 'GET_DEVICES': {

      const devices = action.devices

      if (devices && devices.length) {
        devices
        .filter((v, i, a) => a.map(ad => ad.dvid).indexOf(v.dvid) === i)
        .map(device => {

          const dvid = device.dvid

          if (dvid) {
            device.location = convertLocationToString(device.location);

            newState.byId[dvid] = device;

            if (!newState.allIds.includes(dvid)) {
              newState.allIds.push(dvid);
            }

            if (!newState.listIds.includes(dvid)) {
              newState.listIds.push(dvid);
            }
          }

          return null
        })
      }

      // console.log("ALL in GET_DEVICES",newState.byStatus["ALL"]);

      return newState;
    }

    case 'UPDATE_DEVICE': {
      let newState = JSON.parse(JSON.stringify(state));
      // console.log("Before checking", newState.allIds);

      // console.log(action.device)

      if (action.device.hasOwnProperty('location')) {
        action.device.location = convertLocationToString(action.device.location);
      }

      if(action.device.hasOwnProperty('dvid')){
        newState.byId[action.device.dvid] = {
          ...newState.byId[action.device.dvid],
          ...action.device
        };

        if (!newState.allIds.includes(action.device.dvid)) {
          newState.allIds.push(action.device.dvid)
        }
      }

      return newState;
    }

    case 'ADD_NEW_DEVICE_SUCCESS': {
      let addDeviceObject = JSON.parse(JSON.stringify(state));

      action.device.location = convertLocationToString(action.device.location);

      addDeviceObject.byId[action.device.dvid] = action.device;

      if (!addDeviceObject.allIds.includes(action.device.dvid)) {
        addDeviceObject.allIds.push(action.device.dvid);
      }

      if (!addDeviceObject.listIds.includes(action.device.dvid)) {
        addDeviceObject.listIds.push(action.device.dvid);
      }

      return addDeviceObject;
    }

    case 'EDIT_DEVICE_SUCCESS': {
      let editDeviceObject = JSON.parse(JSON.stringify(state));

      if (action.device.location) {
        action.device.location = convertLocationToString(action.device.location);
      }

      editDeviceObject.byId[action.device.dvid] = {
        ...editDeviceObject.byId[action.device.dvid],
        ...action.device
      };

      return editDeviceObject;
    }

    // case 'GET_DEVICES_ADMIN': {
    //   let newState = JSON.parse(JSON.stringify(state));

    //   if(action.devices && action.devices.length > 0) {
    //     action.devices.forEach(device => newState.byId[device.dvid] = device)
    //   }

    //   // console.log("NewObject",newState);

    //   return newState;
    // }

    case 'SET_SELECTED_DEVICE': {
      // console.log("select Id", action.dvid);

      return {
        ...state,
        selectedId: action.dvid
      }
    }

    case 'CLEAR_SELECTED_DEVICE': {
      return {
        ...state,
        selectedId: null
      }
    }

    default:
      return state;
  }
}