import * as actionType from "../action-types/fuelRecord";

const defaultState = {
    byFuelTank: {

    }
};

export const fuelRecord = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actionType.GET_FUEL_RECORD: {

            if (action.hasOwnProperty('fuelTankNumber')) {
                const key = `fuel${action.fuelTankNumber}`
                newState.byFuelTank[key] = {
                    ...action,
                }
            } else {
                newState = {
                    ...newState,
                    ...action,
                }
            }
            return newState
        }
        default: {
            return state;
        }
    }
}