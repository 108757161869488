import SVG_GEOFENCE_MAKER_AVAILABLE from '../assets/svg/Map_Filter_Icons/geofenceMarker_Yay.svg' ;
import SVG_GEOFENCE_MAKER_UNAVAILABLE from '../assets/svg/Map_Filter_Icons/geofenceMarker_Nay.svg' ;
import SVG_GEOFENCE_MAKER_INFOBOX_AVAILABLE from '../assets/svg/Map_Filter_Icons/geofenceMarkerCallout_Yay.svg' ;
import SVG_GEOFENCE_MAKER_INFOBOX_UNAVAILABLE from '../assets/svg/Map_Filter_Icons/geofenceMarkerCallout_Nay.svg';
import SVG_VEHICLE_MAKER_INFOBOX_AVAILABLE from '../assets/svg/Map_Filter_Icons/vehicleMarkerCallout_Yay.svg' ;
import SVG_VEHICLE_MAKER_INFOBOX_UNAVAILABLE from '../assets/svg/Map_Filter_Icons/vehicleMarkerCallout_Nay.svg';
import SVG_LIVE_TRAFFIC_AVAILABLE from '../assets/svg/Map_Filter_Icons/liveTraffic_Yay.svg' ;
import SVG_LIVE_TRAFFIC_UNAVAILABLE from '../assets/svg/Map_Filter_Icons/liveTraffic_Nay.svg';

import SVG_POI_00FF00 from '../assets/POI_Markers/00FF00.svg'
import SVG_POI_00FFE8 from '../assets/POI_Markers/00FFE8.svg'
import SVG_POI_5C1A8E from '../assets/POI_Markers/5C1A8E.svg'
import SVG_POI_FF0000 from '../assets/POI_Markers/FF0000.svg'
import SVG_POI_FFA500 from '../assets/POI_Markers/FFA500.svg'
import SVG_POI_FFFF00 from '../assets/POI_Markers/FFFF00.svg'

const MAP_CONTROL_PANEL_ICON = {
    SVG_GEOFENCE_MAKER_AVAILABLE,
    SVG_GEOFENCE_MAKER_UNAVAILABLE,
    SVG_GEOFENCE_MAKER_INFOBOX_AVAILABLE,
    SVG_GEOFENCE_MAKER_INFOBOX_UNAVAILABLE,
    SVG_VEHICLE_MAKER_INFOBOX_AVAILABLE,
    SVG_VEHICLE_MAKER_INFOBOX_UNAVAILABLE,
    SVG_LIVE_TRAFFIC_AVAILABLE,
    SVG_LIVE_TRAFFIC_UNAVAILABLE
}

const PREFERENCE_MAP_LOCATION = {
    lat: 4.527157,
    lng: 102.213207
}

const REFERENCE_MAP_ZOOM_SIZE = 7;
const REFERENCE_MAP_ZOOM_IN_COMPONENT_SIZE = 20;

const COLOR_CODE = {
    'LIGHT_GREEN': '00FF00',
    'CYAN': '00FFE8',
    'PURPLE': '5C1A8E',
    'RED': 'FF0000',
    'ORANGE': 'FFA500',
    'YELLOW': 'FFFF00'
} 

const POI_MARKER_BY_COLOR = {
    [COLOR_CODE.LIGHT_GREEN]: SVG_POI_00FF00,
    [COLOR_CODE.CYAN] : SVG_POI_00FFE8,
    [COLOR_CODE.PURPLE] : SVG_POI_5C1A8E,
    [COLOR_CODE.RED] : SVG_POI_FF0000,
    [COLOR_CODE.ORANGE] : SVG_POI_FFA500,
    [COLOR_CODE.YELLOW] : SVG_POI_FFFF00
}

export {
    MAP_CONTROL_PANEL_ICON,
    PREFERENCE_MAP_LOCATION,
    REFERENCE_MAP_ZOOM_SIZE,
    REFERENCE_MAP_ZOOM_IN_COMPONENT_SIZE,
    POI_MARKER_BY_COLOR
}