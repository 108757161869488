import * as ActionTypes from "../action-types/vehicles";
// import moment from "moment"
// listIds is to seperate the vehicles that has unassigned device, while the allIds is keep tracking only vehicles with assigned devices. 
const defaultState = {
  byId: {},
  byStatus: {
    "ALL": [] ,
    "MOVING": [],
    "IDLING": [],
    "PARKING": [], 
    "DISCONNECTED": [],
  }, 
  allIds: [],
  listIds: [], 
  selectedId: null,

  tempNewVID: "", // Used to keep track of newly added/edited vehicles for updates later
};

export const vehicles = (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch(action.type) {
    case 'GET_VEHICLES': {

      const vehicles = action.vehicles
      
      if (vehicles && vehicles.length) {
        vehicles
        .filter((v) => v.vid)
        .filter((v, i, a) => a.map(ad => ad.vid).indexOf(v.vid) === i)
        .map(vehicle => {
          const vid = vehicle.vid
          
          newState.byId[vid] = vehicle;

          vehicle.transitStatus ? 
            newState.byStatus[vehicle.transitStatus].push(vid) : 
            newState.byStatus["DISCONNECTED"].push(vid);

          if(!newState.allIds.includes(vid)) {
            newState.allIds.push(vid);
            newState.listIds.push(vid);
          }

          return null
        })
      }
      else {
        newState.byId = {};
        newState.listIds = [];
      }

      newState.byStatus["ALL"] = newState.allIds;

      return newState;
    }

    case 'ADD_NEW_VEHICLE_SUCCESS': {
      newState.tempNewVID = action.vehicle.vid;
      newState.byId[action.vehicle.vid] = action.vehicle;

      if(!newState.listIds.includes(action.vehicle.vid)) {
        newState.listIds.push(action.vehicle.vid);
        
        if (action.vehicle.dvid && action.vehicle.dvid !== '-') {
          newState.allIds.push(action.vehicle.vid);
        }
      }

      return newState;
    }

    case 'EDIT_VEHICLE_SUCCESS':
      // console.log(action);

      newState.tempNewVID = action.editedVehicle.vid;
      newState.byId[action.editedVehicle.vid] = action.editedVehicle;

      return newState;
    
    case 'UPDATE_VEHICLE': {
      if (action.vehicle.geofenceProfileChanged) {
        delete action.vehicle.geofenceProfileChanged;

        newState.byId[action.vehicle.vid].geofenceProfile = {
          ...newState.byId[action.vehicle.vid].geofenceProfile,
          ...action.vehicle.geofenceProfile
        }

        // console.log(newState.byId[action.vehicle.vid].geofeonceProfile);
      } else {
        newState.byId[action.vehicle.vid] = {
          ...newState.byId[action.vehicle.vid],
          ...action.vehicle
        };
      }

      // Updating byStatus
      if (action.vehicle.transitStatus) {
        Object.keys(newState.byStatus).map((currStatus) => {
          // Remove all instances of edited vehicle's UID
          newState.byStatus[currStatus] = newState.byStatus[currStatus].filter((currVID) => currVID !== action.vehicle.vid);
          return null;
        })

        // Add edited vehicle's VID to correct status
        newState.byStatus[action.vehicle.transitStatus].push(action.vehicle.vid);
        
        newState.byStatus["ALL"] = newState.allIds;
      }

      return newState;
    }

    case 'SET_SELECTED_VEHICLE': {
      return {
        ...newState, 
        selectedId: action.vid
      };
    }

    case ActionTypes.CLEAR_SELECTED_VEHICLE: {
      return {
        ...newState, 
        selectedId: null
      };
    }

    case ActionTypes.CLEAR_TEMP_NEW_VID: {
      return {
        ...newState, 
        tempNewVID: null
      };
    }

    case ActionTypes.TEMP_UPDATE_VEHICLE_ADDRESS: {
      const vehicle = newState.byId[action.vid]
      if(vehicle){
        vehicle.address = action.newAddressText;
      }

      return newState;
    }

    default: {
      return state;
    }
  }
}