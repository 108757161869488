import React from 'react';
import { connect } from 'react-redux';

import { 
    TreeSelect, 
} from 'antd';

import "../index.css";

const {
    TreeNode,
    SHOW_PARENT
} = TreeSelect;

const defaultFilter = "All";

// Requires props:
// - tempTable
// - selectedVIDs
// - selectedGeoIDs
// - defaultFilter
// - onVIDChange
// - onGeoIDChange
class SearchFields extends React.Component {
    returnSearchFields = () => {
        const { vehicles } = this.props;

        switch(this.props.tempTable) {
            case "DETAIL": {
                return (
                    <div className = "formField">
                        <span className = "formFieldLabelLeft">Vehicles: </span>

                        <TreeSelect
                            allowClear
                            treeCheckable
                            showCheckedStrategy = {SHOW_PARENT}
                            placeholder = {Object.keys(vehicles.byId).length ? 'Select Vehicles' : "No Vehicles Found"}
                            disabled = {!Object.keys(vehicles.byId).length}
                            value = {this.props.selectedVIDs}
                            style = {{ width: 175 }}
                            onChange = {(value) => {
                                this.props.onVIDChange(
                                    !value.includes(defaultFilter) ?
                                        value :
                                        Object.values(vehicles.byId)
                                            .filter((currVehicle) => currVehicle.dvid) // Vehicles in the detail report search must have a device attached
                                            .filter((currVehicle) => currVehicle.vehicleDisplayName)
                                            .sort((a, b) => {
                                                const vA = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                                                const vB = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();
                                        
                                                if (vA < vB) return -1;
                                                if (vA > vB) return 1;
                                                return 0;
                                            })
                                            .map((currVehicle) => currVehicle.vid)
                                );
                            }}
                        >
                            {
                                <TreeNode
                                    key = {defaultFilter}
                                    value = {defaultFilter}
                                    title = {"All Vehicles"}
                                >
                                    {
                                        Object.values(vehicles.byId)
                                        .filter((currVehicle) => currVehicle.dvid) // Vehicles in the detail report search must have a device attached
                                        .filter((currVehicle) => currVehicle.vehicleDisplayName)
                                        .sort((a, b) => {
                                            const vA = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                                            const vB = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();
                                    
                                            if (vA < vB) return -1;
                                            if (vA > vB) return 1;
                                            return 0;
                                        })
                                        .map((currVehicle) => 
                                            <TreeNode
                                                key = {currVehicle.vid}
                                                value = {currVehicle.vid}
                                                title = {currVehicle.vehicleDisplayName}
                                            />
                                        )
                                    }
                                </TreeNode>
                            }
                        </TreeSelect>
                    </div>
                );
            }
            
            case "GEOFENCE": {
                return (
                    <div className = "formField">
                        <span className = "formFieldLabelLeft">Vehicles: </span>

                        <TreeSelect
                            allowClear
                            treeCheckable
                            showCheckedStrategy = {SHOW_PARENT}
                            placeholder = {Object.keys(vehicles.byId).length ? 'Select Vehicles' : "No Vehicles Found"}
                            disabled = {!Object.keys(vehicles.byId).length}
                            value = {this.props.selectedVIDs}
                            style = {{ width: 175 }}
                            onChange = {(value) => {
                                this.props.onVIDChange(
                                    !value.includes(defaultFilter) ?
                                        value :
                                        Object.values(vehicles.byId)
                                            .filter((currVehicle) => currVehicle.vehicleDisplayName)
                                            .sort((a, b) => {
                                                const vA = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                                                const vB = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();
                                        
                                                if (vA < vB) return -1;
                                                if (vA > vB) return 1;
                                                return 0;
                                            })
                                            .map((currVehicle) => currVehicle.vid)
                                );
                            }}
                        >
                            {
                                <TreeNode
                                    key = {defaultFilter}
                                    value = {defaultFilter}
                                    title = {"All Vehicles"}
                                >
                                    {
                                        Object.values(vehicles.byId)
                                        .filter((currVehicle) => currVehicle.vehicleDisplayName)
                                        .sort((a, b) => {
                                            const vA = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                                            const vB = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();
                                    
                                            if (vA < vB) return -1;
                                            if (vA > vB) return 1;
                                            return 0;
                                        })
                                        .map((currVehicle) =>
                                            <TreeNode
                                                key = {currVehicle.vid}
                                                value = {currVehicle.vid}
                                                title = {currVehicle.vehicleDisplayName}
                                            />
                                        )
                                    }
                                </TreeNode>
                            }
                        </TreeSelect>
                    </div>
                );
            }

            default: {
                return (
                    <div className = "formField">
                        <span className = "formFieldLabelLeft">Vehicles: </span>

                        <TreeSelect
                            allowClear
                            treeCheckable
                            showCheckedStrategy = {SHOW_PARENT}
                            placeholder = {Object.keys(vehicles.byId).length ? 'Select Vehicles' : "No Vehicles Found"}
                            disabled = {!Object.keys(vehicles.byId).length}
                            value = {this.props.selectedVIDs}
                            style = {{ width: 175 }}
                            onChange = {(value) => {
                                this.props.onVIDChange(
                                    !value.includes(defaultFilter) ?
                                        value :
                                        Object.values(vehicles.byId)
                                            .filter((currVehicle) => currVehicle.vehicleDisplayName)
                                            .sort((a, b) => {
                                                const vA = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                                                const vB = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();
                                        
                                                if (vA < vB) return -1;
                                                if (vA > vB) return 1;
                                                return 0;
                                            })
                                            .map((currVehicle) => currVehicle.vid)
                                );
                            }}
                        >
                            {
                                <TreeNode
                                    key = {defaultFilter}
                                    value = {defaultFilter}
                                    title = {"All Vehicles"}
                                >
                                    {
                                        Object.values(vehicles.byId)
                                        .filter((currVehicle) => currVehicle.vehicleDisplayName)
                                        .sort((a, b) => {
                                            const vA = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                                            const vB = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();
                                    
                                            if (vA < vB) return -1;
                                            if (vA > vB) return 1;
                                            return 0;
                                        })
                                        .map((currVehicle) => 
                                            <TreeNode
                                                key = {currVehicle.vid}
                                                value = {currVehicle.vid}
                                                title = {currVehicle.vehicleDisplayName}
                                            />
                                        )
                                    }
                                </TreeNode>
                            }
                        </TreeSelect>
                    </div>
                );
            }
        }
    }

    render() {    
        return this.returnSearchFields();
    }
}

const mapStateToProps = (state) => ({
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
});

export default connect(mapStateToProps)(SearchFields);