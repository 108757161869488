import
React,
{
  useState
}
  from 'react';
import {
  //  useDispatch, 
  useSelector
} from 'react-redux'
import Layout from "./v2/components/Layout";
import AppRoutes from "./v2/navigation/AppRoutes";
import MainRoutes from "./v2/navigation/MainRoutes";

import BackgroundTask from './v2/components/BackgroundTask'

// import { Menu } from "antd";
// import { moveToMenuSelection } from "./v2/navigation/navigationService";
import {
  // ROLE_TYPE,
  SCREENLAYOUT
} from '../constants';

import '../App.css';
import '../index.css';
import { 
  Alert, 
  // message 
} from 'antd';

const AppV2 = (props) => {

  // const [_loaded, setLoaded] = useState(false);
  // const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  // const [roleType, setRoleType] = useState(ROLE_TYPE.UNKNOWN);
  // const [currentStatusList, setCurrentStatusList] = useState([]);

  // const dispatch = useDispatch()

  const user = useSelector(state => state.v2.user);
  const style = useSelector(state => state.style);

  const [isOffline, setIsOffline] = useState(null)

  // const menu = button => (
  //   <Menu>
  //     {
  //       button.list.map((menuItem, index) => (
  //         <Menu.Item key = {index}>
  //           <div
  //             className = "ButtonLink"
  //             id = {menuItem}
  //             rel = "noopener noreferrer"
  //             onClick = {e => dispatch(moveToMenuSelection(menuItem))}
  //           >
  //             {menuItem}
  //           </div>
  //         </Menu.Item>
  //       ))
  //     }
  //   </Menu>
  // );

  const networkChange = () => {

    switch (isOffline) {
      case true:
        return <Alert
          type="error"
          message="Network error. Refresh page ?"
          banner
          closable
        />

      case false:
        return <Alert
          type="success"
          message="Back online."
          banner
          closable
        />

      default:
        return null
    }

  }


  /**Check network issues */
  window.addEventListener('offline', () => {
    console.log(`offline`)
    setIsOffline(true)

  })

  window.addEventListener('online', () => {
    setIsOffline(false)
  })

  return (
    <div>
      <BackgroundTask />
      <Layout user={(user && user.user) || {}}>
        <div className="app-frame">
          {
            style && style.hasOwnProperty('isSidebarCollapsed') && !style.isSidebarCollapsed &&
            <div
              className="sidebar card animate-left"
              id="SideBar"
              style={{ width: SCREENLAYOUT.FULL_SIDEBAR_WIDTH }}
            >
              <div className="sidebar-content-container">
                <AppRoutes />
              </div>
            </div>
          }

          <div className="app-content">
            {
              networkChange()
            }
            <MainRoutes {...props} />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default AppV2;