import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import FuelAnalysisTimelineChart from "./TimelineChart";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../components/Button";
import { 
    parseTime,
    parseDuration,  
} from '../../util/time';
import {
    Form,
    Table,
    Input,
    Select,
    message,
    DatePicker,
} from "antd";

// Redux Actions
import { get_vehicle_fuel_analysis_by_timeframe_request } from "../../services/redux/actions/cep";

class FuelAnalysisPage extends React.Component {
    state = {
        isLoading: true,

        selectedVID: '',
        userFuelUnitPrice: 2.00,

        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        // Tables Stuff
        refuelDataSource: [],
        summaryDataSource: [],
        fuelTheftDataSource: [],
    }

    submitForm = () => {
        const {
            endTime,
            startTime,
            selectedVID,
        } = this.state;

        try {
            if (!selectedVID || !startTime || !endTime) {
                throw new Error(`Please fill up all the fields!`)
            }

            this.setState({ 
                isLoading: true, 
            },
                () => {
                    const vehicle = this.props.vehicles.byId[this.state.selectedVID];
                    const fuelProfile = (vehicle && vehicle.fuelProfile) || {};
        
                    Promise.all(
                        Object.keys(fuelProfile).map((profile, i) => 
                            this.props.dispatch(get_vehicle_fuel_analysis_by_timeframe_request(selectedVID, startTime, endTime, true, i + 1))
                        )
                    )
                    .then(() => {
                        this.setState({ isLoading: false });
                    })
                }
            )
        } 
        catch (err) {
            message.error(err)
        }
    }

    getSummaryDataSource = () => {
        if (Object.keys(this.props.fuelRecord.byFuelTank).length === 0) return []

        const vehicle = this.props.vehicles.byId[this.state.selectedVID];

        const cumulativeSummary = {
            totalFuelUseInLitre: 0,
            totalIdlingDuration: 0,
            totalMovingDistance: 0,
            totalParkingDuration: 0,
            totalIdlingFuelConsumptioninLitre: 0,
            totalMovingFuelConsumptioninLitre: 0,
        }

        // If vehicle doesn't have fuel profile, there won't be any data anyway
        if (vehicle && vehicle.fuelProfile) {
            const summaries = Object.values(vehicle.fuelProfile).map((fuelProfile, i) => {
                const fuelCapacity = (fuelProfile && fuelProfile.fuelCapacity) || 0
                const fuelTankFuelRecord = this.props.fuelRecord.byFuelTank[`fuel${i + 1}`] || {}

                // console.log("fuelTankFuelRecord:", fuelTankFuelRecord);
    
                const {
                    totalIdlingDuration,
                    totalMovingDistance,
                    totalParkingDuration,
                    totalIdlingFuelConsumption,
                    totalMovingFuelConsumption,
                    totalParkingFuelConsumption,
                } = fuelTankFuelRecord.fuelConsumption || {}
    
                const totalIdlingFuelConsumptioninLitre = fuelCapacity && totalIdlingFuelConsumption && (fuelCapacity * totalIdlingFuelConsumption);
                const totalMovingFuelConsumptioninLitre = fuelCapacity && totalMovingFuelConsumption && (fuelCapacity * totalMovingFuelConsumption);
                const totalParkingFuelConsumptioninLitre = fuelCapacity && totalParkingFuelConsumption && (fuelCapacity * totalParkingFuelConsumption);
    
                const totalFuelUseInLitre = totalMovingFuelConsumptioninLitre + totalParkingFuelConsumptioninLitre + totalIdlingFuelConsumptioninLitre;
    
                return {
                    totalFuelUseInLitre: totalFuelUseInLitre || 0,
                    totalIdlingDuration: totalIdlingDuration || 0,
                    totalMovingDistance: totalMovingDistance || 0,
                    totalParkingDuration: totalParkingDuration || 0,
                    totalMovingFuelConsumptioninLitre: totalMovingFuelConsumptioninLitre || 0,
                    totalIdlingFuelConsumptioninLitre: totalIdlingFuelConsumptioninLitre || 0,
                }
            })
    
            summaries.forEach((summary) => {
                cumulativeSummary.totalFuelUseInLitre += summary.totalFuelUseInLitre;
                cumulativeSummary.totalIdlingDuration += summary.totalIdlingDuration;
                cumulativeSummary.totalMovingDistance += summary.totalMovingDistance;
                cumulativeSummary.totalParkingDuration += summary.totalParkingDuration;
                cumulativeSummary.totalIdlingFuelConsumptioninLitre += summary.totalIdlingFuelConsumptioninLitre;
                cumulativeSummary.totalMovingFuelConsumptioninLitre += summary.totalMovingFuelConsumptioninLitre;
            })

            // console.log("summaries:", summaries);

            // Need to divide the idling and parking durations by number of fuel tanks or they will total up to more than 24 hours
            cumulativeSummary.totalIdlingDuration /= Object.keys(vehicle.fuelProfile).length;
            cumulativeSummary.totalParkingDuration /= Object.keys(vehicle.fuelProfile).length;
        }

        this.setState({ 
            summaryDataSource: [{
                key: 0,
                ...cumulativeSummary,
            }]
        });
    }

    getRefuelDataSource = () => {
        if (Object.keys(this.props.fuelRecord.byFuelTank).length === 0) return []

        const vehicle = this.props.vehicles.byId[this.state.selectedVID];

        // console.log("Current Vehicle:", vehicle);

        const refuels = 
            Object.values((vehicle && vehicle.fuelProfile) || {})
            .map((fuelProfile, i) => {
                const key = `fuel${i + 1}`;

                const fuelCapacity = (fuelProfile && fuelProfile.fuelCapacity) || 0;
                const fuelTankFuelRecord = this.props.fuelRecord.byFuelTank[key] || {};
                const refuels = (fuelTankFuelRecord && fuelTankFuelRecord.refuels)|| [];

                // console.log("fuelTankFuelRecord:", key, fuelTankFuelRecord);

                return (
                    refuels.map((refuel) => {
                        const { 
                            endLog,
                            startLog, 
                        } = refuel
        
                        const deviceFuelUse = (endLog && startLog && (endLog.fuel - startLog.fuel)) || 0;
                        const deviceFuelUseLitre = deviceFuelUse / 100 * fuelCapacity;
        
                        return {
                            startDate: startLog && startLog.deviceTime,
                            endDate: endLog && endLog.deviceTime,
                            startFuel: startLog && startLog.fuel,
                            endFuel: endLog && endLog.fuel,
                            address: startLog && startLog.location,
                            deviceFuelUse: deviceFuelUseLitre,
                            deviceTotalCost: deviceFuelUseLitre * this.state.userFuelUnitPrice,
                            fuelTank: i,
                            ...refuel
                        }
                    })
                );
            })
            .reduce((a, b) => [...a, ...b], [])
            .map((refuel, i) => {
                return {
                    key: i,
                    ...refuel,
                }
            })

        // return refuels

        this.setState({ refuelDataSource: refuels });
    }

    getFuelTheftDataSource = () => {
        if (Object.keys(this.props.fuelRecord.byFuelTank).length === 0) return []

        const vehicle = this.props.vehicles.byId[this.state.selectedVID];

        const fuelThefts = 
            Object.values((vehicle && vehicle.fuelProfile) || {})
            .map((fuelProfile, i) => {
                const key = `fuel${i + 1}`;

                const fuelCapacity = (fuelProfile && fuelProfile.fuelCapacity) || 0;

                const fuelTankFuelRecord = this.props.fuelRecord.byFuelTank[key] || {};
                const fuelThefts = (fuelTankFuelRecord && fuelTankFuelRecord.fuelThefts) || [];

                return fuelThefts.map((fuelTheft) => {
                    const { startLog, endLog } = fuelTheft

                    const deviceFuelUse = (endLog && startLog && (startLog.fuel - endLog.fuel)) || 0;
                    const deviceFuelUseLitre = deviceFuelUse / 100 * fuelCapacity;

                    return {
                        startDate: startLog && startLog.deviceTime,
                        endDate: endLog && endLog.deviceTime,
                        startFuel: startLog && startLog.fuel,
                        endFuel: endLog && endLog.fuel,
                        address: startLog && startLog.location,
                        deviceFuelUse: deviceFuelUseLitre,
                        fuelTank: i,
                        deviceTotalCost: deviceFuelUseLitre * this.state.userFuelUnitPrice,
                        ...fuelTheft
                    }
                })
            })
            .reduce((a, b) => [...a, ...b], [])
            .map((fuelThefts, i) => {
                return {
                    key: i,
                    ...fuelThefts,
                }
            })

        // return fuelThefts

        this.setState({ fuelTheftDataSource: fuelThefts });
    }

    componentDidMount = () => {
        if (Object.keys(this.props.vehicles.byId).length) {
            this.setState({ isLoading: false });
        }
    }

    componentDidUpdate = (prevProps) => {
        const { selectedVID } = this.state;

        const { 
            vehicles,
            fuelRecord
        } = this.props;
        
        if (JSON.stringify(prevProps.vehicles) !== JSON.stringify(vehicles) && Object.keys(vehicles.byId).length) {
            this.setState({ isLoading: false });
        }

        if (
            selectedVID
            && (
                JSON.stringify(prevProps.fuelRecord) !== JSON.stringify(fuelRecord)
                || JSON.stringify(prevProps.vehicles.byId[selectedVID]) !== JSON.stringify(vehicles.byId[selectedVID])
            )
            && Object.keys(vehicles.byId).length
            && Object.keys(fuelRecord.byFuelTank).length
        ) {
            this.getRefuelDataSource();
            this.getSummaryDataSource();
            this.getFuelTheftDataSource();
        }
    }

    render() {
        // window.props = this.props

        const {
            endTime,
            isLoading,
            startTime,
            selectedVID,
        } = this.state;

        const { vehicles } = this.props;

        const fuelTankInfoColumns = [
            {
                title: "No.",
                dataIndex: "key",
            },
            {
                title: 'Fuel Capacity',
                dataIndex: 'fuelCapacity',
            },
            {
                title: 'Fuel Level Min',
                dataIndex: 'fuelLevelMin',
            },
            {
                title: 'Fuel Level Max',
                dataIndex: 'fuelLevelMax',
            },
            {
                title: 'Fuel Level Operator',
                dataIndex: 'fuelLevelOperator',
            },
            {
                title: "Fuel Sensor Type",
                dataIndex: "fuelSensorType",
            },
        ];

        const summaryColumns = [
            {
                title: "Total Fuel Usage (L)",
                dataIndex: "totalFuelUseInLitre",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                title: "Total Mileage Travelled (km)",
                dataIndex: "totalMovingDistance",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                title: "Total Moving Fuel Consumption (L)",
                dataIndex: "totalMovingFuelConsumptioninLitre",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                title: "Total Parking Duration ",
                dataIndex: "totalParkingDuration",
                render: (duration) => parseDuration(duration),
            },
            {
                title: "Total Idling Duration ",
                dataIndex: "totalIdlingDuration",
                render: (duration) => parseDuration(duration),
            },
            {
                title: "Total Idling Fuel Consumption (L)",
                dataIndex: "totalIdlingFuelConsumptioninLitre",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                title: "Total Fuel Cost (RM)",
                dataIndex: "totalFuelUseInLitre",
                render: (litre) => {
                    const cost = litre * this.state.userFuelUnitPrice;

                    return isNaN(cost) ? cost : cost.toFixed(2)
                }
            },
        ]

        const refuelColumns = [
            {
                title: "No.",
                dataIndex: "key",
                render: key => key + 1
            },
            {
                title: "Fuel Tank",
                dataIndex: "fuelTank",
                render: fuelTank => `Fuel Tank #${fuelTank + 1}`
            },
            {
                title: "Refuel Start Time",
                dataIndex: "startDate",
                render: time => parseTime(time)
            },
            {
                title: "Refuel End Time",
                dataIndex: "endDate",
                render: time => parseTime(time)
            },
            {
                key: 'deviceFuelUse',
                title: "Estimated Refuel (L)",
                dataIndex: "deviceFuelUse",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                key: 'deviceTotalCost',
                title: "Estimated Fuel Cost (RM)",
                dataIndex: "deviceTotalCost",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                key: 'address',
                title: "Address",
                dataIndex: "address",
                render: (location) => {
                    return (
                        location 
                        && location.lat 
                        && location.lon ?
                            <a
                                href = {`https://www.google.com.my/maps/place/${parseFloat(location.lat)},${parseFloat(location.lon)}`}
                                target = "_blank"
                                rel = "noopener noreferrer"
                            >
                                {`${location.lat}, ${location.lon}`}
                            </a> :
                            '-'
                    )
                }
            },
        ];

        const fuelTheftColumns = [
            {
                title: "No.",
                dataIndex: "key",
                render: key => key + 1
            },
            {
                title: "Fuel Tank",
                dataIndex: "fuelTank",
                render: fuelTank => `Fuel Tank #${fuelTank + 1}`
            },
            {
                title: "Fuel Loss Start Time",
                dataIndex: "startDate",
                render: time => parseTime(time)
            },
            {
                title: "Fuel Loss End Time",
                dataIndex: "endDate",
                render: time => parseTime(time)
            },
            {
                key: 'deviceFuelUse',
                title: "Estimated Fuel Loss (L)",
                dataIndex: "deviceFuelUse",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                key: 'deviceTotalCost',
                title: "Estimated Fuel Loss Cost (RM)",
                dataIndex: "deviceTotalCost",
                render: (number) => isNaN(number) ? number : number.toFixed(2),
            },
            {
                key: 'address',
                title: "Address",
                dataIndex: "address",
                render: (location) => {
                    return (
                        location 
                        && location.lat 
                        && location.lon ?
                            <a
                                href = {`https://www.google.com.my/maps/place/${parseFloat(location.lat)},${parseFloat(location.lon)}`}
                                target = "_blank"
                                rel = "noopener noreferrer"
                            >
                                {`${location.lat}, ${location.lon}`}
                            </a> :
                            '-'
                    )
                }
            },
        ];

        const vehicle = vehicles.byId[this.state.selectedVID];
        const fuelProfile = (vehicle && vehicle.fuelProfile) || {};

        return (
            <div>
                <Page title = "Fuel Analysis">
                    <div
                        style = {{
                            display: "block",
                            minHeight: "95px",
                            padding: "10px 10px 10px 10px",
                        }}
                    >
                        <Form layout = "inline">
                            <Form.Item>
                                <span style = {{ marginLeft: '10px' }}>Vehicles: </span>

                                <Select
                                    showSearch
                                    optionFilterProp = "children"
                                    filterOption = {(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled = {isLoading || !Object.keys(vehicles.byId).length}
                                    placeholder = "Select a vehicle"
                                    value = {selectedVID ? selectedVID : undefined}
                                    onChange = {(selectedVID) => {
                                        const dvid = vehicle && vehicle.dvid;

                                        this.setState({
                                            dvid,
                                            selectedVID,
                                        })
                                    }}
                                    style = {{ width: 200 }}
                                >
                                    {
                                        Object.values(vehicles.byId)
                                        .sort((a, b) => {
                                          const A = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                                          const B = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();
                        
                                          if (A < B) return -1;
                                          if (A > B) return 1;
                                          return 0;
                                        })
                                        .map((vehicle) => 
                                            <Select.Option
                                                key = {vehicle.vid}
                                                value = {vehicle.vid}
                                            >
                                                {vehicle.vehicleDisplayName}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <span style = {{ marginLeft: '10px' }}>Start Date: </span>

                                <DatePicker
                                    showTime
                                    allowClear = {false}
                                    defaultValue = {startTime}
                                    onChange = {(value) => 
                                        this.setState({
                                            ...this.state,
                                            startTime: value,
                                        })
                                    }
                                    style = {{ width: '177px' }}
                                />

                                <span style = {{ marginLeft: '10px' }}>End Date: </span>

                                <DatePicker
                                    showTime
                                    allowClear = {false}
                                    defaultValue = {endTime}
                                    onChange = {(value) => 
                                        this.setState({
                                            ...this.state,
                                            endTime: value,
                                        })
                                    }
                                    style = {{ width: '177px' }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <span style = {{ marginLeft: '10px' }}>Fuel Price (RM): </span>

                                <Input
                                    type = {"number"}
                                    min = {0}
                                    value = {this.state.userFuelUnitPrice}
                                    onChange = {event =>
                                        this.setState({
                                            ...this.state,
                                            userFuelUnitPrice: event.target.value,
                                        })
                                    }
                                    style = {{ width: 100 }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <PrimaryButton
                                    loading = {isLoading}
                                    disabled = {!this.state.selectedVID}
                                    onClick = {this.submitForm}
                                >
                                    Submit
                                </PrimaryButton>
                            </Form.Item>
                        </Form>
                    </div>

                    {
                        Object.values(fuelProfile).map((fuelProfile, i) => {
                            const key = `fuel${i + 1}`;

                            const fuelTankInfoDataSource = [
                                {
                                    key: i,
                                    ...fuelProfile
                                }
                            ]

                            return (
                                <div key = {i}>
                                    <h3>
                                        <b>Vehicle Fuel Tank Info #{i + 1}</b>
                                    </h3>

                                    <Table
                                        bordered
                                        pagination = {false}
                                        columns = {fuelTankInfoColumns}
                                        dataSource = {fuelTankInfoDataSource}
                                        scroll = {{
                                            x: fuelTankInfoColumns && fuelTankInfoColumns.length * 150
                                        }}
                                    />

                                    <FuelAnalysisTimelineChart fuelProfile = {fuelProfile} fuelTank = {key} vid = {this.state.selectedVID} />
                                </div>
                            )
                        })
                    }

                    <div>
                        <h3>
                            <b>Summary Table</b>
                        </h3>

                        <Table
                            bordered
                            pagination = {false}
                            columns = {summaryColumns}
                            dataSource = {this.state.summaryDataSource}
                            scroll = {{
                                x: summaryColumns && summaryColumns.length * 150
                            }}
                        />
                    </div>

                    <div>
                        <h3>
                            <b>Refuels Table</b>
                        </h3>

                        <Table
                            bordered
                            pagination = {false}
                            columns = {refuelColumns}
                            dataSource = {this.state.refuelDataSource}
                            scroll = {{
                                x: refuelColumns && refuelColumns.length * 150
                            }}
                        />
                    </div>

                    <div>
                        <h3>
                            <b>Potential Fuel Loss Table</b>
                        </h3>

                        <Table
                            bordered
                            pagination = {false}
                            columns = {fuelTheftColumns}
                            dataSource = {this.state.fuelTheftDataSource}
                            scroll = {{
                                x: fuelTheftColumns && fuelTheftColumns.length * 150
                            }}
                        />
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state,
    vehicles: state.v2.vehicles,
    fuelRecord: state.v2.fuelRecord,
});

const ConnectedPage = connect(mapStateToProps)(FuelAnalysisPage);
export default showSecondarySidebar(false)(ConnectedPage);