import React from "react";
import PropTypes from "prop-types";
import { Layout as LayoutAntd } from "antd";
import Sidebar from "./Sidebar";

const Layout = props => {
  return (
    <LayoutAntd>
      <Sidebar
        items={props.sidebarItems}
        onClick={props.onClickSidebarItem}
        selectedKeys={props.selectedKeys}
        themeColor={props.themeColor}
      />
      <LayoutAntd>{props.children}</LayoutAntd>
    </LayoutAntd>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  sidebarItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onClickSidebarItem: PropTypes.func,
  selectedKeys: PropTypes.string,
  themeColor: PropTypes.string
};

Layout.defaultProps = {
  sidebarItems: [],
  onClickSidebarItem: () => {}
};

export default Layout;
