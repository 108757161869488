import { api, headers } from '../constants'

export const mapGeoCode = (lat, lon) => (
    fetch(`${api}/geocode`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        lat, lon
      })
    })
      .then(res => res.json())
)