import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { ROLE_TYPE } from '../../../../../constants';
import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { configure_user } from "../../../services/redux/actions/user";
import {
    clear_temp_new_uid,
    select_user,
    update_groups_to_user,
} from "../../../services/redux/actions/hierarchy";

const FormItem = Form.Item;
const { Option } = Select;

class EditCustomer extends Component {
    state = {
        // Edited User Stuff
        //----------------------------------------------------------------------------------------------------------
        selectedUID: "",

        userName: "",
        userRole: ROLE_TYPE.FLEET_OPERATOR, // Configure User page is only for editing Fleet Operators
        userEmail: "",
        userContact: "",
        userAddress: "",
        userPhonePrefix: "+60",

        phonePrefixOptions: ["+60"],

        selectedVGObjList: [],
        selectedGTObjList: [],
        selectedRTObjList: [],
        //----------------------------------------------------------------------------------------------------------

        // Filter stuff
        vgidListFilterString: "",
        gtidListFilterString: "",
        rtidListFilterString: "",
    }

    submitForm = () => {
        // console.log('formData ', formData);

        const {
            selectedUID,
            userName,
            userRole,
            userEmail,
            userAddress,
            userContact,
            userPhonePrefix,
        } = this.state;

        if (
            userName &&
            userRole &&
            userEmail &&
            userAddress &&
            userContact &&
            userPhonePrefix
        ) {
            const editedUser = {
                uid: selectedUID,
                userName,
                userRole,
                userEmail,
                userAddress,
                userContact: userPhonePrefix + userContact,
            }

            // console.log(`edit_user_request`, editedUser)
            const vgid = this.state.vgid
            console.log({ vgid, editedUser })

            this.props.dispatch(configure_user(editedUser, vgid));
        }
        else {
            message.error('Fields cannot be empty');
        }
    }

    setUpEditedUserDetails = (selectedUID) => {
        const { hierarchy } = this.props;
        const selectedUser = hierarchy.byId[selectedUID];

        // console.log("Selected User:", selectedUser);

        if (selectedUser) {
            this.setState({
                ...this.state,
                selectedUID: selectedUID,

                userName: selectedUser.userName,
                userRole: selectedUser.userRole,
                userEmail: selectedUser.userEmail,
                userAddress: selectedUser.userAddress,
                userContact: (selectedUser.userContact && selectedUser.userContact.slice(3, selectedUser.userContact.length)) || '',
                userPhonePrefix: (selectedUser.userContact && selectedUser.userContact.slice(0, 3)) || this.state.userPhonePrefix,
                vgid: selectedUser.vehicleGroups[0] && selectedUser.vehicleGroups[0].vgid,



                selectedVGObjList: selectedUser.vehicleGroups &&
                    selectedUser.vehicleGroups.map((currVGObj) => {
                        return {
                            key: currVGObj.vgid,
                            label: currVGObj.groupName,
                        }
                    }),

                selectedGTObjList: selectedUser.geofenceTemplates &&
                    selectedUser.geofenceTemplates.map((currGTObj) => {
                        return {
                            key: currGTObj.gtid,
                            label: currGTObj.templateName,
                        }
                    }),

                selectedRTObjList: selectedUser.ruleTemplates &&
                    selectedUser.ruleTemplates.map((currRTObj) => {
                        return {
                            key: currRTObj.rtid,
                            label: currRTObj.templateName,
                        }
                    })
            },
                // () => console.log("New State:", this.state)
            )
        }
    }

    componentDidMount = () => {
        const selectedUID = this.props.location.state;

        if (selectedUID) {

            this.setUpEditedUserDetails(selectedUID);
            const parentUid = this.getDirectParentUid(selectedUID)
            const parent = this.props.hierarchy.byId[parentUid]
            parentUid && this.props.dispatch(select_user(parent))
        }
    }

    componentDidUpdate = (prevProps) => {
        const selectedUID = this.props.location.state;

        const {
            user,
            hierarchy,
        } = this.props;

        if (prevProps.hierarchy !== this.props.hierarchy) {
            this.setUpEditedUserDetails(this.props.location.state)
            if (!this.isSelectUserAPICalled) {

                const parentUid = this.getDirectParentUid(selectedUID)
                const parent = this.props.hierarchy.byId[parentUid]
                parentUid && this.props.dispatch(select_user(parent))
                this.isSelectUserAPICalled = true
            }
        }

        if ((user !== prevProps.user || hierarchy !== prevProps.hierarchy)
            && hierarchy.tempNewUID
            && hierarchy.tempNewUID !== prevProps.hierarchy.tempNewUID) {
            const {
                // selectedUID,
                selectedVGObjList,
                selectedGTObjList,
                selectedRTObjList
            } = this.state;

            if (user.userRole === ROLE_TYPE.FLEET_OWNER) {
                const { hierarchy } = this.props;

                const srcUser = {
                    uid: user.uid,
                    userRole: user.userRole,
                };

                const destUser = {
                    uid: hierarchy.tempNewUID,
                    userRole: hierarchy.byId[hierarchy.tempNewUID].userRole,
                };

                const groupIDList = [
                    ...selectedVGObjList.map((currVGIDObj) => currVGIDObj.key),
                    ...selectedGTObjList.map((currGTIDObj) => currGTIDObj.key),
                    ...selectedRTObjList.map((currRTIDObj) => currRTIDObj.key),
                ];
                this.props.dispatch(update_groups_to_user(srcUser, destUser, groupIDList));
            }
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clear_temp_new_uid()); // Clear hierarchy's tempNewUID when leaving page
    }

    getDirectParentUid = (uid) => {
        const thisOperator = this.props.hierarchy.byId[uid]
        if (!thisOperator) return null

        const userHierarchy = thisOperator && thisOperator.userHierarchy
        let parentHierarchy = (userHierarchy && userHierarchy.split('#')) || []
        parentHierarchy.pop()
        parentHierarchy = parentHierarchy && parentHierarchy.join('#')

        const parent = parentHierarchy && Object.values(this.props.hierarchy.byId)
            .find(user => user.userHierarchy === parentHierarchy)


        return parent && parent.uid
    }

    render() {
        const { selectedUID } = this.state;

        const parentUid = this.getDirectParentUid(selectedUID)

        const parent = this.props.hierarchy.byId[parentUid]

        window.parent = parent
        window.props = this.props
        window.state = this.state

        const vehicleGroup = this.state.vgid && parent && parent.vehicleGroups.find(vg => vg.vgid === this.state.vgid)


        return (
            <div className="page-container">
                <Page title="Configure User">
                    <Form className="login-form">
                        <FormItem
                            label="User Name"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                disabled={!selectedUID}
                                placeholder={"e.g. ABC Sdn. Bhd."}
                                value={this.state.userName}
                                onChange={e => this.setState({ userName: e.target.value })}
                            />
                        </FormItem>

                        <FormItem
                            label="Email address"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                disabled={!selectedUID}
                                placeholder={"e.g. user@company.com"}
                                value={this.state.userEmail}
                                onChange={e => this.setState({ userEmail: e.target.value })}
                            />
                        </FormItem>

                        <FormItem
                            label="User Role"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                disabled={!selectedUID}
                                value={this.state.userRole}
                            />
                        </FormItem>

                        <FormItem
                            label="Contact Number"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            {
                                selectedUID ?
                                    <Input
                                        placeholder={"123456789"}
                                        value={this.state.userContact}
                                        addonBefore={
                                            <Select
                                                value={this.state.userPhonePrefix}
                                                onChange={e => this.setState({ userPhonePrefix: e.target.value })}
                                            >
                                                {
                                                    this.state.phonePrefixOptions.map((prefix, i) => (
                                                        <Option key={i} value={prefix}>{prefix}</Option>)
                                                    )
                                                }
                                            </Select>
                                        }
                                        onChange={e => this.setState({ userContact: e.target.value })}
                                    /> :
                                    <Input disabled={true} placeholder={"Select target user first!"} />
                            }
                        </FormItem>

                        <FormItem
                            label="Office Address"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                disabled={!selectedUID}
                                placeholder={"Office Address"}
                                value={this.state.userAddress}
                                onChange={e => this.setState({ userAddress: e.target.value })}
                            />
                        </FormItem>

                        {
                            parent && (
                                <>
                                    <FormItem label='Parent'
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <span>{parent.userEmail} ({parent.userRole})</span>
                                    </FormItem>

                                    <FormItem
                                        label="Vehicle Group"
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 15 }}
                                        help={`Only parent's vehicle groups can be linked to fleet operator. To link vehicles individually, first create a vehicle group with desired vehicles under parent.`}
                                    >
                                        {
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder={'Select vehicle groups'}
                                                value={this.state.vgid}
                                                style={{ width: '100%' }}
                                                onChange={(vgid) => {
                                                    this.setState({ vgid })
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    (parent.vehicleGroups || [])
                                                        .map(vg => {
                                                            return <Option key={vg.vgid}>{vg.groupName}</Option>
                                                        })
                                                }
                                            </Select>
                                        }
                                    </FormItem>
                                    <FormItem
                                        label="Vehicles selected"
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <ol>
                                            {
                                                vehicleGroup &&
                                                vehicleGroup
                                                    .vehicles.map(vid => {
                                                        const vehicle = parent.vehicles.find(vehicle => vehicle.vid === vid)
                                                        return vehicle && <li key={vehicle.vid}>{vehicle.vehiclePlate}</li>

                                                    })
                                            }
                                        </ol>
                                    </FormItem>

                                </>
                            )
                        }


                        {/* <FormItem
                            label="Vehicle Groups"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                            help={`Feature will be available soon`}
                        >
                            {
                                Object.keys(vehicleGroups.byVGID).length > 0 ?
                                    <Select
                                        labelInValue
                                        mode="multiple"
                                        disabled={!selectedUID}
                                        placeholder={"Select new vehicle groups (Optional)"}
                                        value={this.state.selectedVGObjList}
                                        filterOption={false} // Keep this disabled or filtering will be weird
                                        style={{ width: '100%' }}
                                        onSearch={(searchedString) => {
                                            this.setState({
                                                ...this.state,
                                                vgidListFilterString: searchedString,
                                            },
                                                // () => console.log("VGID List Filter String:", this.state.vgidListFilterString)
                                            )
                                        }}
                                        onChange={(value) => {
                                            this.setState({
                                                ...this.state,
                                                selectedVGObjList: value,
                                            },
                                                // () => console.log("Selected VG Obj List:", this.state.selectedVGObjList)
                                            )
                                        }}
                                    // disabled
                                    >
                                        {
                                            Object.keys(vehicleGroups.byVGID)
                                                .filter(currVGID => {
                                                    return vehicleGroups.byVGID[currVGID].groupName.toLowerCase().includes(this.state.vgidListFilterString.toLowerCase()) && currVGID
                                                })
                                                .map(currVGID => {
                                                    return <Option key={currVGID} value={currVGID}>{vehicleGroups.byVGID[currVGID].groupName}</Option>
                                                })
                                        }
                                    </Select> :
                                    <Select
                                        labelInValue
                                        mode="multiple"
                                        disabled={true}
                                        placeholder="No vehicle groups found"
                                        style={{ width: '100%' }}
                                    />
                            }
                        </FormItem>

                        <FormItem
                            label="Geofence Templates"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                            help={`Feature will be available soon`}
                        >
                            {
                                Object.keys(geofenceTemplate.byId).length > 0 ?
                                    <Select
                                        labelInValue
                                        mode="multiple"
                                        disabled={!selectedUID}
                                        placeholder={"Select new geofence templates (Optional)"}
                                        value={this.state.selectedGTObjList}
                                        filterOption={false} // Keep this disabled or filtering will be weird
                                        style={{ width: '100%' }}
                                        onSearch={(searchedString) => {
                                            this.setState({
                                                ...this.state,
                                                gtidListFilterString: searchedString,
                                            },
                                                // () => console.log("GTID List Filter String:", this.state.gtidListFilterString)
                                            )
                                        }}
                                        onChange={(value) => {
                                            this.setState({
                                                ...this.state,
                                                selectedGTObjList: value,
                                            },
                                                // () => console.log("Selected GT Obj List:", this.state.selectedGTObjList)
                                            )
                                        }}
                                    // disabled
                                    >
                                        {
                                            Object.keys(geofenceTemplate.byId)
                                                .filter(currGTID => {
                                                    return geofenceTemplate.byId[currGTID].templateName.toLowerCase().includes(this.state.gtidListFilterString.toLowerCase()) && currGTID
                                                })
                                                .map(currGTID => {
                                                    return <Option key={currGTID} value={currGTID}>{geofenceTemplate.byId[currGTID].templateName}</Option>
                                                })
                                        }
                                    </Select> :
                                    <Select
                                        labelInValue
                                        mode="multiple"
                                        disabled={true}
                                        placeholder="No geofence templates found"
                                        style={{ width: '100%' }}
                                    />
                            }
                        </FormItem>

                        <FormItem
                            label="Rule Templates"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                            help={`Feature will be available soon`}
                        >
                            {
                                Object.keys(ruleTemplate.byId).length > 0 ?
                                    <Select
                                        labelInValue
                                        mode="multiple"
                                        disabled={!selectedUID}
                                        placeholder={"Select new rule templates (Optional)"}
                                        value={this.state.selectedRTObjList}
                                        filterOption={false} // Keep this disabled or filtering will be weird
                                        style={{ width: '100%' }}
                                        onSearch={(searchedString) => {
                                            this.setState({
                                                ...this.state,
                                                rtidListFilterString: searchedString,
                                            },
                                                // () => console.log("RTID List Filter String:", this.state.rtidListFilterString)
                                            )
                                        }}
                                        onChange={(value) => {
                                            this.setState({
                                                ...this.state,
                                                selectedRTObjList: value,
                                            },
                                                // () => console.log("Selected RT Obj List:", this.state.selectedRTObjList)
                                            )
                                        }}
                                    // disabled
                                    >
                                        {
                                            Object.keys(ruleTemplate.byId)
                                                .filter(currRTID => {
                                                    return ruleTemplate.byId[currRTID].templateName.toLowerCase().includes(this.state.rtidListFilterString.toLowerCase()) && currRTID
                                                })
                                                .map(currRTID => {
                                                    return <Option key={currRTID} value={currRTID}>{ruleTemplate.byId[currRTID].templateName}</Option>
                                                })
                                        }
                                    </Select> :
                                    <Select
                                        labelInValue
                                        mode="multiple"
                                        disabled={true}
                                        placeholder="No rule templates found"
                                        style={{ width: '100%' }}
                                    />
                            }
                        </FormItem> */}


                        <div style={{ position: "absolute", right: "100px" }}>
                            <Button
                                type="danger"
                                style={{ marginLeft: "22px" }}
                                className="login-form-button"
                                // disabled = {this.props.style.isLoadingSubmit}
                                onClick={() => this.props.dispatch(goBackToPrev())}
                            >
                                Cancel
                            </Button>

                            <PrimaryButton
                                onClick={this.submitForm}
                                loading={this.props.style.isLoading}
                            // disabled = {this.props.style.isLoadingSubmit}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </Form>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    hierarchy: state.v2.hierarchy,
    ruleTemplate: state.v2.ruleTemplate,
    vehicleGroups: state.v2.vehicleGroups,
    geofenceTemplate: state.v2.geofenceTemplate,
});

const ConnectedAddCustomer = connect(mapStateToProps)(EditCustomer);
export default showSecondarySidebar(false)(ConnectedAddCustomer);