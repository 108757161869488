import * as API from "../../api";
import * as actionType from "../action-types/escalations";

import { message } from "antd";
import { setLoadingSubmit, unsetLoadingSubmit } from './style';

export const get_escalation_records = (ecids, startTime, endTime) => (dispatch) => {
    API.getEscalationRecords(ecids, startTime, endTime)
        .then(data => {
            // console.log("Get Escalation Records Data:", data);

            if (data.status !== 200 && data.errorMessage) {
                message.error(data.errorMessage);
            }
            else {
                message.info(data.escalationCallRecords.length ? `${data.escalationCallRecords.length} Record Found!` : `No Records Found!`);

                dispatch(get_escalation_records_success(data.escalationCallRecords));
            }
        })
}

const get_escalation_records_success = (newRecords) => {
    return {
        type: actionType.GET_ESCALATION_RECORDS,
        newRecords: newRecords
    }
}

export const get_escalation_states = (nfids, startTime, endTime) => (dispatch) => {
    dispatch(setLoadingSubmit())
    API.getEscalationStates(nfids, startTime, endTime)
        .then(data => {
            // console.log("Get Escalation States Data:", data);

            if (data.status !== 200 && data.message) {
                message.error(data.message);
            }
            else {
                const ecids = data.escalationCallState.map(s => s.ecid);

                dispatch(get_escalation_records(ecids, startTime, endTime));
                dispatch(get_escalation_states_success(data.escalationCallState));
            }
        })
        .finally(() => dispatch(unsetLoadingSubmit()))
}

const get_escalation_states_success = (newStates) => {
    return {
        type: actionType.GET_ESCALATION_STATES,
        newStates: newStates
    }
}

export const update_escalation_states = (editedState) => (dispatch) => {
    API.updateEscalationState(editedState)
        .then(data => {
            // console.log("Update Escalation States Data:", data);

            if (data.status !== 200 && data.message) {
                message.error(data.message);
            }
            else {
                dispatch(update_escalation_states_success(data.escalationCallState));
            }
        })
}

const update_escalation_states_success = (editedState) => {
    return {
        type: actionType.UPDATE_ESCALATION_STATES,
        editedState: editedState
    }
}