import { api, headers } from '../constants'

function breakDownByDayQuery(startTime, endTime, callBack) {
  if (!startTime || !endTime || !callBack) throw new Error(`Missing params`)

  const DAYS = 1
  const MS_IN_DAYS = 86400000
  const QUERY_DURATION = DAYS * MS_IN_DAYS

  const duration = endTime - startTime
  const remainingDuration = (duration % QUERY_DURATION)
  const tempEndTime = endTime - remainingDuration

  let promises = []
  let miniStartTime, miniEndTime
  for (let i = startTime; i < tempEndTime; i += QUERY_DURATION) {
    miniStartTime = i
    miniEndTime = miniStartTime + QUERY_DURATION
    promises.push(callBack(miniStartTime, miniEndTime))
  }

  if (endTime - tempEndTime) {
    promises.push(callBack(tempEndTime, endTime))
  }

  return promises
}

export const addDevice = (dgid, device) => (
  fetch(`${api}/device/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dgid": dgid,
      "device": device
    })
  })
    .then(res => res.json())
)

export const addVehicle = (newVehicle, targetUserDetails, vgidList, gtidList, rtidList) => (
  fetch(`${api}/vehicle/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vehicle": newVehicle,
      "user": targetUserDetails,
      "vgids": vgidList,
      "gtids": gtidList,
      "rtids": rtidList,
    })
  })
    .then(res => res.json())
)

export const editDevice = (device) => (
  fetch(`${api}/device/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "device": device
    })
  })
    .then(res => res.json())
)

// export const editPOI = (geofence) =>
//   (
//     fetch(`${api}/geofence/edit`, {
//       method: 'POST',
//       headers,
//       body: JSON.stringify({
//         ...geofence
//       })
//     })
//       .then(res => res.json())
//   )

// export const editPolygon = (geofence) =>
//   (
//     fetch(`${api}/geofence/edit`, {
//       method: 'POST',
//       headers,
//       body: JSON.stringify({
//         ...geofence
//       })
//     })
//       .then(res => res.json())
//   )

export const editVehicle = (editedVehicle, currUserDetails, vgidList, gtidList, rtidList) => (
  fetch(`${api}/vehicle/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vehicle": editedVehicle,
      "user": currUserDetails,
      "vgids": vgidList,
      "gtids": gtidList,
      "rtids": rtidList,
    })
  })
    .then(res => res.json())
)

export const getFuelRecordByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/fuelrecord/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Fuel Record API Data:", data);

      return data;
    })
)

export const getFuelRecordByTimeframe2 = async (vid, startTime, endTime, isReturnDeviceLog) => {


  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return getFuelRecordByTimeframe2API(vid, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  let refuels = []
  let deviceLogs = []
  let fuelThefts = []

  returnDatas
    .map(returnData => {
      if (returnData.status !== 'fulfilled') return null
      if (returnData.value.status !== 200) return null

      refuels = [...refuels, ...returnData.value.refuels]
      deviceLogs = [...deviceLogs, ...returnData.value.deviceLogs]
      fuelThefts = [...fuelThefts, ...returnData.value.fuelThefts]

      return null;
    })

  refuels = refuels.sort((a, b) => {
    const aCreatedAt = a && a.startLog && a.startLog.createdAt
    const bCreatedAt = b && b.startLog && b.startLog.createdAt
    return aCreatedAt - bCreatedAt
  })

  deviceLogs = deviceLogs.sort((a, b) => a.createdAt - b.createdAt)

  fuelThefts = fuelThefts.sort((a, b) => {
    const aCreatedAt = a && a.startLog && a.startLog.createdAt
    const bCreatedAt = b && b.startLog && b.startLog.createdAt
    return aCreatedAt - bCreatedAt
  })

  const returnData = {
    status: 200,
    refuels,
    fuelThefts,
    deviceLogs,
    vehicle: (returnDatas[0] && returnDatas[0].vehicle )|| {},
    fuelConsumption: {}
  }

  console.log(returnData)

  return returnData

  function getFuelRecordByTimeframe2API(vid, startTime, endTime) {
    return fetch(`${api}/fuelrecord/get2`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        "vid": vid,
        "startTime": Number(startTime),
        "endTime": Number(endTime),
        isReturnDeviceLog,
      })
    })
      .then(res => res.json())

  }
}

export const getFuelRecordByTimeframe3 = (vid, startTime, endTime, isReturnDeviceLog, fuelTankNumber) => (
  fetch(`${api}/fuelrecord/get3`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime),
      isReturnDeviceLog,
      fuelTankNumber,
    })
  })
    .then(res => res.json())
)

export const updateSelectedFuelRecord = (
  vid,
  fuelStartAt,
  fuelUnitPrice,
  fuelType,
  receiptID,
  receiptDateTime,
  receiptRefuelLevel,
  receiptRemark,
) => (
  fetch(`${api}/fuelrecord/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fuelRecord: {
        "vid": vid,
        "fuelStartAt": fuelStartAt,
        "fuelUnitPrice": fuelUnitPrice,
        "fuelType": fuelType,
        "receiptID": receiptID,
        "receiptDateTime": receiptDateTime,
        "receiptRefuelLevel": receiptRefuelLevel,
        "receiptRemark": receiptRemark
      }
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Update Fuel Record API Data:", data);

      return data;
    })
)

export const getDeviceLogByTimeframe = (dvid, startTime, endTime) => (
  fetch(`${api}/deviceLog/getdvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dvid": dvid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Device Log Data:", data);

      return data.Items ?
        data.Items :
        data.Item ?
          data.Item :
          data;
    })
)

export const getDeviceLogDebugByTimeframe = (dvid, startTime, endTime) => (
  fetch(`${api}/deviceLog/debug/getdvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dvid": dvid,
      "startTime": (startTime),
      "endTime": (endTime),
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Device Log Debug Data:", data);

      return data.Items;
    })
)

export const getVehicleLogByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/vehicleLog/getvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": (startTime),
      "endTime": (endTime),
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Vehicle Log Data:", data);

      return data.Items || data;
    })
)

export const getTripRecordByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/tripRecord/timeFrame`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

export const getTripRecordByTimeframe_Teltonika = (vid, startTime, endTime) => (
  fetch(`${api}/tripRecord/timeFrame/debug`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime),
      "deviceType": "TEL100"
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

export const getTransitRecordByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/transitRecord/timeFrame`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

// export const getTransitRecordByTrids = (trids) => (
//   fetch(`${api}/transitRecord/getbytrid`, {
//     method: 'POST',
//     headers,
//     body: JSON.stringify({
//       trids,
//     })
//   })
//     .then(res => res.json())
// )

export const getTransitRecordByTimeframe_Teltonika = (vid, startTime, endTime) => (
  fetch(`${api}/transitRecord/timeFrame/debug`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime),
      "deviceType": "TEL100"
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

export const getPDFTransitRecordByTimeframe = (vid, startTime, endTime, status) => (
  fetch(`${api}/transitRecord/timeFrame/pdf`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/pdf"
    },
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime),
      "status": status
    })
  })
    .then(res => res.json())
)

export const getEventByTimeframe = (vids, startTime, endTime) => (
  fetch(`${api}/event/getvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vids,
      startTime,
      endTime
    })
  })
    .then(res => res.json())
)

export const getDeviceByVGIDs = (uid, vgids) => (
  fetch(`${api}/device/vgid/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "vgids": vgids
    })
  })
    .then(res => res.json())
)

export const getDeviceByGroup = (dgids, uid) => (
  fetch(`${api}/device/group/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dgids": dgids,
      "uid": uid
    })
  })
    .then(res => res.json())
)

export const getVehicleByGroup = (vgids, uid) => (
  fetch(`${api}/vehicle/group/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vgids": vgids,
      "uid": uid
    })
  })
    .then(res => res.json())
)

export const addVehicleGroup = (uid, groupName, colorHexCode) => (
  fetch(`${api}/vehicle/group/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "colorHexCode": colorHexCode,
      "vehicleGroupName": groupName,
    })
  })
    .then(res => res.json())
)

export const editVehicleGroup = (editedVG) => (
  fetch(`${api}/vehicle/group/edit`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vehicleGroup": editedVG
    })
  })
    .then(res => res.json())
)

export const updateVehiclesInVehicleGroup = (vid, srcUID, srcVGID, destUID, destVGID) => (
  fetch(`${api}/vehicle/group/updatevehicles`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "srcUid": srcUID,
      "srcVgid": srcVGID,
      "destUid": destUID,
      "destVgid": destVGID,
    })
  })
    .then(res => res.json())
)

export const getGeofenceByTemplate = (gtids, uid) => (
  fetch(`${api}/geofence/template/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "gtids": gtids,
      'uid': uid,
    })
  })
    .then(res => res.json())
)

export const addGeofenceTemplate = (uid, colorHexCode, gtName) => (
  fetch(`${api}/geofence/template/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      'uid': uid,
      "colorHexCode": colorHexCode,
      "geofenceTemplateName": gtName,
    })
  })
    .then(res => res.json())
)

export const editGeofenceTemplate = (geofenceTemplate) => (
  fetch(`${api}/geofence/template/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "geofenceTemplate": geofenceTemplate,
    })
  })
    .then(res => res.json())
)

export const assignGeofencesToGeofenceTemplate = (uid, gtid, geoidList) => (
  fetch(`${api}/geofence/template/assign/geofence`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      'uid': uid,
      "gtid": gtid,
      "geoids": geoidList,
    })
  })
    .then(res => res.json())
)

export const unassignGeofencesFromGeofenceTemplate = (uid, selectedGTID, geoidList) => {
  return fetch(`${api}/geofence/template/unassign/geofence`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "gtid": selectedGTID,
      "geoids": geoidList,
    })
  })
    .then(res => res.json())
}

export const getGeofenceRecordByTimeframe = (vids, geoid, startTime, endTime) => (
  fetch(`${api}/geofenceRecord/timeFrame`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vids": vids,
      "geoid": geoid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
)

export const getRuleTemplate = (rtids) => (
  fetch(`${api}/rule/template/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "rtids": rtids
    })
  })
    .then(res => res.json())
)

export const createRuleTemplate = (uid, ruleTemplateName) => (
  fetch(`${api}/rule/template/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "createdBy": uid,
      "ruleTemplateName": ruleTemplateName
    })
  })
    .then(res => res.json())
)
export const updateRuleTemplate = (uid, ruleTemplate) => {
  return fetch(`${api}/rule/template/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      ruleTemplate
    })
  })
    .then(res => res.json())
}

export const createGeofence = (uid, geofence, gtidList) => (
  fetch(`${api}/geofence/create`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "geofence": geofence,
      "gtids": gtidList,
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Create Geofence Data: ", data);

      return data;
    })
)

export const editGeofence = (editedGeofence) => (
  fetch(`${api}/geofence/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "geofence": editedGeofence,
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Edit Geofence Data: ", data);

      return data;
    })
)

export const addGroupsToUser = (srcUser, destUser, groupIDList) => (
  fetch(`${api}/userrelation/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "srcUser": srcUser,
      "destUser": destUser,
      "ids": groupIDList,
    })
  })
    .then(res => res.json())
)

export const updateGroupsToUser = (srcUser, destUser, groupIDList) => (
  fetch(`${api}/userrelation/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "srcUser": srcUser,
      "destUser": destUser,
      "ids": groupIDList,
    })
  })
    .then(res => res.json())
)

export const addRule = (selectedRTID, newRule) => (
  fetch(`${api}/rule/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "rtid": selectedRTID,
      "rule": newRule,
    })
  })
    .then(res => res.json())
)

export const editRule = (editedRule) => (
  fetch(`${api}/rule/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "rule": editedRule,
    })
  })
    .then(res => res.json())
)

export const getNotifications = (nfidList) => (
  fetch(`${api}/notification/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "nfids": nfidList
    })
  })
    .then(res => res.json())
)

export const addNotification = (newNotification) => (
  fetch(`${api}/notification/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "notification": newNotification
    })
  })
    .then(res => res.json())
)

export const editNotification = (editedNotification) => (
  fetch(`${api}/notification/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "notification": editedNotification
    })
  })
    .then(res => res.json())
)

export const getDeviceLogDebugDeviceByTimeframe = (dvid, startTime, endTime) => {
  return fetch(`${api}/deviceLog/debug/devicegetdvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dvid": dvid,
      "startTime": startTime,
      "endTime": endTime,
      "deviceType": "TEL100",
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Device Log Debug Data:", data);

      return data.Items;
    })
  // .catch(err=>console.log('err at getDeviceLogDebugDeviceByTimeframe', err))
}

export const reassignGeofenceTemplates = (uid, selectedGeoID, gtidList) => {
  return fetch(`${api}/geofence/template/reassign/geofence`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "geoid": selectedGeoID,
      "gtids": gtidList, // This array cannot be empty
    })
  })
    .then(res => res.json())
}

export const getNotificationLogByTimeframe = (nfid, startTime, endTime) => {
  return fetch(`${api}/notificationlog/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "nfid": nfid,
      "startTime": startTime,
      "endTime": endTime,
    })
  })
    .then(res => res.json())
}

export const reassignVehicleGroups = (uid, selectedVID, vgidList) => (
  fetch(`${api}/vehicle/group/reassign/vehicle`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "vid": selectedVID,
      "vgids": vgidList, // This array cannot be empty
    })
  })
    .then(res => res.json())
)

export const assignVehicleGroups = (uid, selectedVGID, vidList) => (
  fetch(`${api}/vehicle/group/assign/vehicle`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "vgid": selectedVGID,
      "vids": vidList,
    })
  })
    .then(res => res.json())
)

export const getEscalationStates = (nfids, startTime, endTime) => (
  fetch(`${api}/escalationcallstate/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      nfids,
      startTime,
      endTime
    })
  })
    .then(res => res.json())
)

export const updateEscalationState = (edittedState) => (
  fetch(`${api}/escalationcallstate/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      escalationCallState: edittedState
    })
  })
    .then(res => res.json())
)

export const getEscalationRecords = (ecids, startTime, endTime) => (
  fetch(`${api}/escalationcallrecord/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ecids,
      startTime,
      endTime
    })
  })
    .then(res => res.json())
)

export const getTicketStates = (vids) => {
  return fetch(`${api}/ticket/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vids
    })
  })
    .then(res => res.json())
}

export const resolveTicketStates = (ticketStates, remark, uid) => {

  return fetch(`${api}/ticket/acknowledge`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ticketStates, remark, uid
    })
  })
    .then(res => res.json())
}

export const getVehiclePackages = (vids) => {
  return fetch(`${api}/vehiclepackage/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vids
    })
  })
    .then(res => res.json())
}

export const addVehiclePackage = (vid, uid, startAt, endAt, contractType) => {

  return fetch(`${api}/vehiclepackage/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vid, uid, startAt, endAt, contractType
    })
  })
    .then(res => res.json())
}

export const editVehiclePackage = (vehiclePackage) => {
  return fetch(`${api}/vehiclepackage/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...vehiclePackage
    })
  })
    .then(res => res.json())
}

export const renewVehiclePackage = (renewParams) => {
  return fetch(`${api}/vehiclepackage/renew`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...renewParams
    })
  })
    .then(res => res.json())
}

export const suspendVehiclePackage = (vid) => {
  return fetch(`${api}/vehiclepackage/suspend`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vid
    })
  })
    .then(res => res.json())
}

export const unsuspendVehiclePackage = (vid) => {
  return fetch(`${api}/vehiclepackage/unsuspend`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vid
    })
  })
    .then(res => res.json())
}

export const terminateVehiclePackage = (vid) => {
  return fetch(`${api}/vehiclepackage/terminate`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vid
    })
  })
    .then(res => res.json())
}

export const getVehiclePackageLogs = (vids) => {
  return fetch(`${api}/vehiclepackagelog/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vids
    })
  })
}

export const getTripRecordByTimeframe2 = (vid, startTime, endTime) => {
  return fetch(`${api}/tripRecord/timeframe2`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
}

export const reportMileages = async (vidList, startTime, endTime) => {
  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportMileageAPI(vidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  // console.log("returnDatas:", returnDatas);

  const mileageReports = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => Array.isArray(returnData.value.mileageReport) ? returnData.value.mileageReport : [])
    .reduce((a, b) => [...a, ...b], [], [])

  return {
    status: 200,
    mileageReports: mileageReports
  }

  function reportMileageAPI(vidList, startTime, endTime) {
    return fetch(`${api}/report/mileage`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
      .then(mileageReport => {
        return {
          status: mileageReport ? 200 : 402,
          mileageReport: mileageReport || []
        }
      })
  }
}

export const reportMileage = async (vidList, startTime, endTime) => {
  const { mileageReports } = await reportMileages(vidList, startTime, endTime);

  // console.log("reportMileage:", mileageReports);

  let combinedMileageReportByVid = {}

  mileageReports.forEach(report => {
    if (!combinedMileageReportByVid[report.vid]) {
      combinedMileageReportByVid[report.vid] = {
        "vid": report.vid,
        "numberOfTrips": 0,
        "totalMileage": 0,
        "totalEngineDuration": 0,
        "totalMovingDuration": 0,
        "totalIdlingDuration": 0,
        "totalParkingDuration": 0
      }
    }

    combinedMileageReportByVid[report.vid].totalMileage += report.totalMileage;
    combinedMileageReportByVid[report.vid].numberOfTrips += report.numberOfTrips;
    combinedMileageReportByVid[report.vid].totalEngineDuration += report.totalEngineDuration;
    combinedMileageReportByVid[report.vid].totalIdlingDuration += report.totalIdlingDuration;
    combinedMileageReportByVid[report.vid].totalMovingDuration += report.totalMovingDuration;
    combinedMileageReportByVid[report.vid].totalParkingDuration += report.totalParkingDuration;
  })

  const combinedMileageReport = Object.values(combinedMileageReportByVid)

  return {
    status: 200,
    mileageReport: combinedMileageReport
  }
}

export const reportTrip = async (vidList, startTime, endTime) => {
  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportTripAPI(vidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  const tripReport = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => returnData.value.tripReport || [])
    .reduce((a, b) => [...a, ...b], [])

  return {
    status: 200,
    tripReport
  }

  function reportTripAPI(vidList, startTime, endTime) {
    return fetch(`${api}/report/trip`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }
}

export const reportTransit = async (vidList, startTime, endTime) => {
  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportTransitAPI(vidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  const transitReport = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => returnData.value.transitReport || [])
    .reduce((a, b) => [...a, ...b], [])

  return {
    status: 200,
    transitReport
  }

  function reportTransitAPI(vidList, startTime, endTime) {
    return fetch(`${api}/report/transit`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }
}

export const reportDetail = async (dvidList, startTime, endTime) => {
  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportDetailAPI(dvidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  const detailReport = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => returnData.value.detailReport || [])
    .reduce((a, b) => [...a, ...b], [])
    
  return {
    status: 200,
    detailReport
  }

  function reportDetailAPI(dvidList, startTime, endTime,) {
    return fetch(`${api}/report/detail`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        dvidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }

}

export const reportDisconnect = async (vidList, startTime, endTime) => {
  const reportDisconnectAPI = (vidList, startTime, endTime) => 
    fetch(`${api}/report/disconnect`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        startTime,
        endTime
      })
    })
      .then(res => res.json())

  /** temporary disable date split */
  // const breakDownCallback = (_startTime, _endTime) => reportDisconnectAPI(vidList, _startTime, _endTime)
  // const disconnectReportPromises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => breakDownCallback(_startTime, _endTime))
  // const returnDatas = await Promise.allSettled(disconnectReportPromises)

  const returnDatas = await Promise.allSettled([reportDisconnectAPI(vidList, startTime, endTime)])

  const disconnectReport = returnDatas
    .filter(returnData => (
      returnData.status === 'fulfilled' &&
      returnData.value.status === 200
    ))
    .map(returnData => returnData.value.disconnectReport || [])
    .reduce((a, b) => [...a, ...b], [])  

  return {
    status: 200,
    disconnectReport
  }
}

export const reportGeofence = async (vidList, geoidList, startTime, endTime) => {


  const promises = []
  vidList.map(vid => {
    const vehiclePromises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
      return reportGeofenceAPI([vid], geoidList, _startTime, _endTime)
    })

    for (let p of vehiclePromises) {
      promises.push(p)
    }

    return null
  })

  const returnDatas = await Promise.allSettled(promises)

  const geofenceReport = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => returnData.value.geofenceReport || [])
    .reduce((a, b) => [...a, ...b], [])

  return {
    status: 200,
    geofenceReport
  }

  function reportGeofenceAPI(vidList, geoidList, startTime, endTime,) {
    return fetch(`${api}/report/geofence`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        geoidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }
}

export const reportEvent = async (vidList, startTime, endTime) => {
  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportEventAPI(vidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  const eventReport = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => returnData.value.eventReport || [])
    .reduce((a, b) => [...a, ...b], [])

  return {
    status: 200,
    eventReport
  }

  function reportEventAPI(vidList, startTime, endTime,) {
    return fetch(`${api}/report/event`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }


}

export const reportRefuel = async (vidList, startTime, endTime) => {
  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportFuelAPI(vidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  const reportFuel = 
    returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false

      return true
    })
    .map(returnData => {
      if (!returnData.value.fuelAnalyses) return []

      const refuelsPerTank = returnData.value.fuelAnalyses.map((currFA) => ({
        vid: currFA.vid,
        fuelTankNumber: currFA.fuelTankNumber,
        refuels: currFA.refuels
      }))

      return refuelsPerTank
    })
    .reduce((a, b) => [...a, ...b], []) // Compress all the different API returns into 1 array
    .filter((currRefuelsPerTank) => currRefuelsPerTank.refuels && currRefuelsPerTank.refuels.length) // Clear out any data without any refuels

  return {
    status: 200,
    fuelAnalyses: reportFuel,
  }

  function reportFuelAPI(vidList, startTime, endTime,) {
    return fetch(`${api}/report/fuel`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }
}

export const reportDailyFuelAnalysis = async (vidList, startTime, endTime) => {

  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportFuelAPI(vidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  const fuelAnalyses = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => returnData.value.fuelAnalyses || [])
    .reduce((a, b) => [...a, ...b], [])

  const reportByVid = {}
  fuelAnalyses.map(report => {
    if (!reportByVid[report.vid]) {
      reportByVid[report.vid] = {}
      reportByVid[report.vid].fuelConsumptions = []
      reportByVid[report.vid].vid = report.vid
    }
    reportByVid[report.vid].fuelConsumptions.push(report.fuelConsumption)

    return null
  })

  return {
    status: 200,
    fuelAnalyses: fuelAnalyses
  }

  function reportFuelAPI(vidList, startTime, endTime,) {
    return fetch(`${api}/report/fuel`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }

}

export const reportFuelAnalysis = async (vidList, startTime, endTime) => {

  const promises = breakDownByDayQuery(startTime, endTime, (_startTime, _endTime) => {
    return reportFuelAPI(vidList, _startTime, _endTime)
  })

  const returnDatas = await Promise.allSettled(promises)

  const reportFuel = returnDatas
    .filter(returnData => {
      if (returnData.status !== 'fulfilled') return false
      if (returnData.value.status !== 200) return false
      return true
    })
    .map(returnData => returnData.value.fuelAnalyses && returnData.value.fuelAnalyses)
    .reduce((a, b) => [...a, ...b], [])

  const reportByVid = {}
  reportFuel.map(report => {
    if (!reportByVid[report.vid]) {
      reportByVid[report.vid] = {}
      reportByVid[report.vid].fuelConsumptions = []
      reportByVid[report.vid].vid = report.vid
    }
    reportByVid[report.vid].fuelConsumptions.push(report.fuelConsumption)

    return null
  })

  const fuelAnalyses = []

  for (let vid of Object.keys(reportByVid)) {

    const { mileageReport } = await reportMileage([vid], startTime, endTime)

    const report = reportByVid[vid];

    /**Get moving distance, idling duration, parking duration from mileage report */
    let fuelAnalysis = {
      totalMovingDistance: 0,
      totalIdlingDuration: 0,
      totalParkingDuration: 0,
      totalMovingFuelConsumption: 0,
      totalIdlingFuelConsumption: 0,
      totalParkingFuelConsumption: 0,
    }

    mileageReport.map(report => {
      fuelAnalysis.totalMovingDistance += report.totalMileage
      fuelAnalysis.totalIdlingDuration += report.totalIdlingDuration
      fuelAnalysis.totalParkingDuration += report.totalParkingDuration
      return null
    })

    /**Get fuel-related information from api */
    report.fuelConsumptions.map((a) => {

      fuelAnalysis.totalMovingFuelConsumption += a.totalMovingFuelConsumption
      fuelAnalysis.totalIdlingFuelConsumption += a.totalIdlingFuelConsumption
      fuelAnalysis.totalParkingFuelConsumption += a.totalParkingFuelConsumption

      return null
    })

    fuelAnalysis.movingFuelConsumptionRate = fuelAnalysis.totalMovingFuelConsumption / fuelAnalysis.totalMovingDistance
    fuelAnalysis.idlingFuelConsumptionRate = fuelAnalysis.totalIdlingFuelConsumption / fuelAnalysis.totalIdlingDuration
    fuelAnalysis.parkingFuelConsumptionRate = fuelAnalysis.totalParkingFuelConsumption / fuelAnalysis.totalParkingDuration

    fuelAnalyses.push({
      vid,
      fuelConsumption: fuelAnalysis
    })
  }

  return {
    status: 200,
    fuelAnalyses: fuelAnalyses
  }

  function reportFuelAPI(vidList, startTime, endTime,) {
    return fetch(`${api}/report/fuel`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        vidList,
        endTime,
        startTime,
      })
    })
      .then(res => res.json())
  }

}

export const getAllUserStages = () => {
  return fetch(`${api}/user/getstages`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
    })
  })
    .then(res => res.json())
}

export const migrateUser = (uid, sourceStage, targetStage) => {

  return fetch(`${api}/user/migrate`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "sourceStage": sourceStage,
      "targetStage": targetStage,
    })
  })
    .then(res => res.json())
}

export const fakeAPI = () => {
  return new Promise((res, rej) => {
    setTimeout(() => res(), 1 * 1000)
  })
}

const reportOverspeedingIndvividualProc = (vid, dvid, erd, drd, rule) => {

  const ierd = erd.filter(e => e.vid === vid)
  const idrd = drd.filter(d => d.dvid === dvid)

  const latchSpeeding = idrd 
  .filter(
    (detail_report) =>
      ierd
      .filter((report) => report.eventResult === 1 && report.ruid === rule)
      .find(
        (s_e_r) =>
          s_e_r.location.lat === detail_report.location.lat &&
          s_e_r.location.lon === detail_report.location.lon
      ) 
  )
  .sort((a, b) => a.deviceTime - b.deviceTime)

  const unlatchSpeeding = idrd 
  .filter(
    (detail_report) =>
      ierd
      .filter((report) => report.eventResult === 0 && report.ruid === rule)
      .find(
        (s_e_r) =>
          s_e_r.location.lat === detail_report.location.lat &&
          s_e_r.location.lon === detail_report.location.lon
      ) 
  )
  .sort((a, b) => a.deviceTime - b.deviceTime)

  // Prioritize
  const speedReport = latchSpeeding.map(ls => {
    const eventStarTime = ls.deviceTime
    const uls = unlatchSpeeding.find(u => u.deviceTime > eventStarTime)

    if(uls && Object.keys(uls) && Object.keys(uls).length) {

      const lat1Rad = (ls.location.lat * Math.PI) / 180;
      const lon1Rad = (ls.location.lon * Math.PI) / 180;
      const lat2Rad = (uls.location.lat * Math.PI) / 180;
      const lon2Rad = (uls.location.lon * Math.PI) / 180;
  
      // Radius of the Earth in kilometers (mean value)
      const earthRadius = 6371.0;
  
      // Haversine formula
      const dlon = lon2Rad - lon1Rad;
      const dlat = lat2Rad - lat1Rad;
      const a =
        Math.sin(dlat / 2) ** 2 +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const calculatedDistance = (earthRadius * c || 0).toFixed(3);
      
      return (
        {
          vid: vid,
          speed: ls.speed,
          startTime: eventStarTime,
          endTime: uls.deviceTime,
          duration: uls.deviceTime - eventStarTime,
          startLocation: ls.location,
          endLocation: uls.location,
          distance: calculatedDistance
        }
      )

    } else {
      return null
    }
  })
  .filter(s => s)

  return speedReport
}

export const reportOverspeeding = async (vehicles, detailData, eventData, ruleData) => {

  const rule = ruleData
    .reduce((acc, d) => {
      const cond = d.conditions.filter(({ parameter }) => parameter === "speed")
      if (cond.length) {
        acc.push({ ruid: d.ruid });
      }
      return acc;
    }, [])
    .map((obj)=>obj.ruid).toString()

  const eventReportData = Object.values(eventData.eventReport)
  const detailReportData = Object.values(detailData.detailReport)

  const srptByVID = Object.fromEntries(vehicles.map(vehicle => [vehicle.vid, reportOverspeedingIndvividualProc(vehicle.vid, vehicle.dvid, eventReportData, detailReportData , rule)]))

  const overspeedingReport = Object.values(srptByVID).flat()

  return {
    status: 200,
    overspeedingReport
  }
}