import { api, headers } from '../constants'

export const archiveGeofence = (geoid) => (
    fetch(`${api}/geofence/archive`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        geoid
      })
    })
      .then(res => res.json())
)