import { api_next, headers } from '../constants'

export const getRoutePlaybackTimeframeSync = (vid, startTime, endTime) => {
    return fetch(`${api_next}/routePlayback/timeframe/get/sync`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        "vid": vid,
        "startTime": Number(startTime),
        "endTime": Number(endTime)
      })
    })
      .then(res => res.json())
  }