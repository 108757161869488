import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    ROLE_TYPE,
    DISPLAY_USER_ROLE,
} from '../../../../../constants';
import {
    Form,
    Input,
    Button,
    Select,
    Tooltip,
    message,
    TreeSelect,
} from "antd";

import {
    ExclamationCircleOutlined,
    CheckOutlined
} from '@ant-design/icons'

// Redux Actions
import { create_new_user_request } from "../../../services/redux/actions/user";
import {
    add_groups_to_user,
    clear_temp_new_uid,
} from "../../../services/redux/actions/hierarchy";

const FormItem = Form.Item;
const { Option } = Select;

class AddCustomer extends Component {
    state = {
        creatorUID: "",
        // selectedVID: "",

        // New User Stuff
        userName: "",
        userContact: "",
        userEmail: "",
        userRole: "",
        userAddress: "",
        userPhonePrefix: "+60",
        userPassword: "",
        _userPassword: "",
        isUserPasswordMatch: 'unknown',
        phonePrefixOptions: ["+60"],

        userOptions: [],
        // vehicleOptions: [],
        userRoleOptions: [],

        // Filter stuff
        vgidListFilterString: "",
        gtidListFilterString: "",
        rtidListFilterString: "",

        // New Vehicle Stuff
        selectedVGObjList: [],
        selectedGTObjList: [],
        selectedRTObjList: [],
    }

    submitForm = () => {
        this.setState({ isLoading: true })
        // console.log('formData ', formData);

        const {
            userName,
            userRole,
            userEmail,
            userAddress,
            userContact,
            userPassword,
            userPhonePrefix,
            creatorUID,
            isUserPasswordMatch,
        } = this.state

        if (
            userName &&
            userRole &&
            userEmail &&
            userAddress &&
            userContact &&
            userPassword &&
            userPhonePrefix &&
            creatorUID
        ) {
            const parent = {
                uid: creatorUID,
                userHierarchy: this.props.hierarchy.byId[creatorUID].userHierarchy
            }

            const newUser = {
                userName,
                userRole,
                userEmail,
                userAddress,
                userPassword,
                userContact: userPhonePrefix + userContact,
            }

            const uid = this.props.user && this.props.user.uid

            console.log(`create_new_user_request`, {
                parent, newUser, uid
            })
            
            this.props.dispatch(create_new_user_request(parent, newUser, uid));
        }
        else if (!isUserPasswordMatch) {
            message.error('Passwords do not match');
        }
        else {
            message.error('Fields cannot be empty');
        }
    }

    // setUpDVIDList = () => {
    //     const { selectedUID } = this.state;
    //     const { hierarchy } = this.props;

    //     const selectedUser = hierarchy.byId[selectedUID].userRole === "fleetOwner" ?
    //         hierarchy.byId[hierarchy.byId[selectedUID].parent.uid] :
    //         hierarchy.byId[selectedUID];

    //     // console.log("Selected UID: ", selectedUID);
    //     // console.log("Selected User: ", selectedUser);

    //     // Set up dvidList
    //     //===================================================================================================
    //     const newDVIDList = selectedUser.deviceGroups.devices
    //         .filter((currDevice) => {
    //             for (let i = 0; i < selectedUser.deviceGroups.devices.length; i++) {
    //                 return selectedUser.deviceGroups.devices[i].assignedStatus !== 1 && currDevice;
    //             }
    //             return 0;
    //         })
    //         .map((currDevice) => currDevice.dvid);

    //     // console.log("New DVID List: ", newDVIDList);
    //     //===================================================================================================

    //     this.setState({
    //         ...this.state,
    //         dvidList: newDVIDList,
    //     },
    //         () => {
    //             // console.log("New DVID List:", this.state.dvidList);
    //         }
    //     )
    // }

    confirmPassword = () => {
        if (this.state.isUserPasswordMatch !== 'unknown') {
            if (this.state.userPassword === this.state._userPassword) {
                this.setState({ isUserPasswordMatch: true });
            }
            else {
                this.setState({ isUserPasswordMatch: false });

                message.error('Passwords do not match');
            }
        }
    }

    recursiveHierarchyTreeDig_UserOptions = (tempObj, currUID, currTierInt) => {
        const { hierarchy } = this.props;

        // console.log("Current UID:", currUID);

        if (hierarchy.byId[currUID].userRole !== ROLE_TYPE.FLEET_OPERATOR) {
            tempObj.push({
                key: currUID,
                value: currUID,
                title: `${hierarchy.byId[currUID].userName} (${DISPLAY_USER_ROLE(hierarchy.byId[currUID].userRole)})`,
            });
        }

        if (hierarchy.hierarchyTree[currTierInt] && hierarchy.hierarchyTree[currTierInt][currUID]) {
            tempObj[tempObj.length - 1] = {
                ...tempObj[tempObj.length - 1],
                children: []
            }

            hierarchy.hierarchyTree[currTierInt][currUID].forEach((newUID) => {
                this.recursiveHierarchyTreeDig_UserOptions(tempObj[tempObj.length - 1].children, newUID, (Number(currTierInt) + 1).toString());
            })
        }
    }

    returnNewUserOptionsArray = () => {
        let newUserOptions = [];

        this.recursiveHierarchyTreeDig_UserOptions(newUserOptions, this.props.user.uid, 0);

        // console.log("New User Options:", newUserOptions);

        return newUserOptions;
    }

    returnNewVehicleOptionsArray = () => {
        let newVehicleOptions = [];

        Object.keys(this.props.vehicleGroups.byVGID).forEach((currVGID) => {
            const currentVG = this.props.vehicleGroups.byVGID[currVGID];

            if (currentVG.vehicles.length > 0) {
                newVehicleOptions.push({
                    key: currVGID,
                    title: currentVG.groupName,
                    children: [
                        currentVG.vehicles.map((currVehicle) => ({
                            key: currVehicle.vid,
                            value: currVehicle.vid,
                            title: currVehicle.vehicleDisplayName,
                        }))
                    ],
                });
            }
        })

        // console.log("New Vehicle Options:", newVehicleOptions);

        return newVehicleOptions;
    }

    setUpUserRoleArray = (currUID) => {
        const currUserRole = this.props.hierarchy.byId[currUID].userRole;
        let newUserRoleArray = [];

        // console.log(`Test 1: ${currUserRole}`);

        if (currUserRole === ROLE_TYPE.FLEET_OWNER) {
            newUserRoleArray = [ROLE_TYPE.FLEET_OPERATOR];
        }
        else if (currUserRole === ROLE_TYPE.DISTRIBUTOR || currUserRole === ROLE_TYPE.SUPER) {
            newUserRoleArray = [ROLE_TYPE.DISTRIBUTOR, ROLE_TYPE.FLEET_OWNER];
        }

        this.setState({
            ...this.state,
            userRoleOptions: newUserRoleArray,

            // Set userRole if there's only 1 user option
            userRole: newUserRoleArray.length === 1 ? newUserRoleArray[0] : this.state.userRole,
        },
            // () => console.log(`Test 2: ${this.state.newUserRoleArray}`)
        )
    }

    setUpState = () => {
        const { user } = this.props;

        if (user.userRole === ROLE_TYPE.FLEET_OWNER || user.userRole === ROLE_TYPE.FLEET_OPERATOR) {
            this.setState({
                ...this.state,
                userRole: ROLE_TYPE.FLEET_OPERATOR,
                creatorUID: user.uid,
                userOptions: this.returnNewUserOptionsArray(),
                // vehicleOptions: this.returnNewVehicleOptionsArray(),
            })
        }
        else {
            this.setState({
                ...this.state,
                userOptions: this.returnNewUserOptionsArray(),
            })
        }
    }

    componentDidMount = () => {
        const {
            user,
            hierarchy,
            vehicleGroups
        } = this.props;

        if (user.userRole
            && hierarchy.allIds.length > 0
            && Object.keys(hierarchy.byId).length > 0
            && Object.keys(vehicleGroups.byVGID)) {
            this.setUpState();
        }
    }

    componentDidUpdate = (prevProps) => {
        const {
            user,
            hierarchy,
            vehicleGroups
        } = this.props;

        if ((user !== prevProps.user || hierarchy !== prevProps.hierarchy || vehicleGroups !== prevProps.vehicleGroups)
            && user.userRole
            && hierarchy.allIds.length > 0
            && Object.keys(hierarchy.byId).length > 0
            && Object.keys(vehicleGroups.byVGID)) {
            this.setUpState();
        }

        if ((user !== prevProps.user || hierarchy !== prevProps.hierarchy)
            && hierarchy.tempNewUID
            && hierarchy.tempNewUID !== prevProps.hierarchy.tempNewUID) {
            const {
                creatorUID,
                selectedVGObjList,
                selectedGTObjList,
                selectedRTObjList
            } = this.state;

            if (user.userRole === ROLE_TYPE.FLEET_OWNER
                && (selectedVGObjList.length > 0 || selectedGTObjList.length > 0 || selectedRTObjList.length > 0)) {
                const { hierarchy } = this.props;

                const srcUser = {
                    uid: creatorUID,
                    userRole: hierarchy.byId[creatorUID].userRole,
                };

                const destUser = {
                    uid: hierarchy.tempNewUID,
                    userRole: hierarchy.byId[hierarchy.tempNewUID].userRole,
                };

                const groupIDList = [
                    ...selectedVGObjList.map((currVGIDObj) => currVGIDObj.key),
                    ...selectedGTObjList.map((currGTIDObj) => currGTIDObj.key),
                    ...selectedRTObjList.map((currRTIDObj) => currRTIDObj.key),
                ];

                // console.log(`Adding Groups To User:
                // - Src User: ${JSON.stringify(srcUser)}, 
                // - Dest User: ${JSON.stringify(destUser)},
                // - Group ID List: ${JSON.stringify(groupIDList)}`
                // );

                this.props.dispatch(add_groups_to_user(srcUser, destUser, groupIDList));
            }
            else {
                this.props.dispatch(goBackToPrev());
            }
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clear_temp_new_uid()); // Clear hierarchy's tempNewUID when leaving page
    }

    render() {
        const {
            user,
            hierarchy,
            ruleTemplate,
            vehicleGroups,
            geofenceTemplate,
        } = this.props;

        window.props = this.props
        window.state = this.state
        return (
            <div className="page-container">
                <Page title="Add New User">
                    <Form className="login-form">
                        <FormItem
                            label="User Name"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                placeholder="e.g. ABC Sdn. Bhd."
                                value={this.state.userName}
                                onChange={e => this.setState({ userName: e.target.value })}
                            />
                        </FormItem>

                        <FormItem
                            label="Email address"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                placeholder="e.g. user@company.com"
                                value={this.state.userEmail}
                                onChange={e => this.setState({ userEmail: e.target.value })}
                            />
                        </FormItem>

                        {
                            !this.props.thisUser &&
                            <Form.Item
                                label="Password"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >

                                <Input
                                    onBlur={this.confirmPassword}
                                    type="password"
                                    value={this.state.userPassword}
                                    onChange={e => this.setState({ userPassword: e.target.value })}
                                />
                            </Form.Item>
                        }

                        <Form.Item
                            label="Confirm Password"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                value={this.state._userPassword}
                                type="password"
                                onChange={e => this.setState({
                                    _userPassword: e.target.value,
                                    isUserPasswordMatch: null
                                })}
                                onBlur={this.confirmPassword}
                                suffix={
                                    this.state.isUserPasswordMatch === false ?
                                        (
                                            <Tooltip title="Password does not match">
                                                <ExclamationCircleOutlined style={{ color: 'red' }}/>
                                            </Tooltip>

                                        ) :
                                        this.state.isUserPasswordMatch === true ?
                                            <CheckOutlined style={{ color: 'green' }}/>
                                            : <span />
                                }
                            />
                        </Form.Item>

                        {
                            user.userRole !== ROLE_TYPE.FLEET_OWNER
                            && user.userRole !== ROLE_TYPE.FLEET_OPERATOR &&
                            <FormItem
                                label="Assigned User"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <TreeSelect
                                    style={{ width: '100%' }}
                                    value={this.state.creatorUID}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={this.state.userOptions}
                                    placeholder="Please select"
                                    treeDefaultExpandAll
                                    onChange={uid =>
                                        this.setState({
                                            ...this.state,
                                            creatorUID: uid,

                                            // If new target user has same user role as old target user, maintain selected user role
                                            userRole:
                                                this.state.creatorUID
                                                    && hierarchy.byId[uid].userRole === hierarchy.byId[this.state.creatorUID].userRole ?
                                                    this.state.userRole :
                                                    "",

                                        },
                                            () => {
                                                // console.log("Swapping target user to", hierarchy.byId[this.state.creatorUID].userName);

                                                this.setUpUserRoleArray(this.state.creatorUID);
                                            }
                                        )
                                    }
                                />
                            </FormItem>
                        }

                        {
                            user.userRole !== ROLE_TYPE.FLEET_OWNER
                            && user.userRole !== ROLE_TYPE.FLEET_OPERATOR &&
                            <FormItem
                                label="User Role"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                {
                                    !this.state.creatorUID ?
                                        <Select
                                            value={"Please select a user first!"}
                                            disabled
                                        /> :
                                        this.state.userRoleOptions.length > 1 ?
                                            <Select
                                                value={this.state.userRole}
                                                onChange={value => this.setState({ userRole: value })}
                                            >
                                                {
                                                    this.state.userRoleOptions.map(currRole =>
                                                        <Option key={currRole} value={currRole}>
                                                            {DISPLAY_USER_ROLE(currRole)}
                                                        </Option>
                                                    )
                                                }
                                            </Select> :
                                            this.state.userRoleOptions.length > 0 ?
                                                <Select
                                                    value={DISPLAY_USER_ROLE(this.state.userRoleOptions[0])}
                                                    disabled
                                                /> :
                                                <Select
                                                    value={"No user roles found"}
                                                    disabled
                                                />
                                }
                            </FormItem>
                        }

                        <FormItem
                            label="Contact Number"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                addonBefore={
                                    <Select
                                        value={this.state.userPhonePrefix}
                                        onChange={e => this.setState({ userPhonePrefix: e.target.value })}
                                    >
                                        {
                                            this.state.phonePrefixOptions.map((prefix, i) => (
                                                <Option key={i} value={prefix}>{prefix}</Option>
                                            ))
                                        }
                                    </Select>
                                }
                                placeholder="123456789"
                                value={this.state.userContact}
                                onChange={e => this.setState({ userContact: e.target.value })}
                            />
                        </FormItem>

                        <FormItem
                            label="Office Address"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                placeholder="Office Address"
                                value={this.state.userAddress}
                                onChange={e => this.setState({ userAddress: e.target.value })}
                            />
                        </FormItem>

                        {
                            (user.userRole === ROLE_TYPE.FLEET_OWNER || user.userRole === ROLE_TYPE.FLEET_OPERATOR) &&
                            <FormItem
                                label="Vehicle Groups"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                {
                                    Object.keys(vehicleGroups.byVGID).length > 0 ?
                                        <Select
                                            labelInValue
                                            mode="multiple"
                                            placeholder="Select default vehicle groups (Optional)"
                                            value={this.state.selectedVGObjList}
                                            filterOption={false} // Keep this disabled or filtering will be weird
                                            style={{ width: '100%' }}
                                            onSearch={(searchedString) => {
                                                this.setState({
                                                    ...this.state,
                                                    vgidListFilterString: searchedString,
                                                },
                                                    // () => console.log("VGID List Filter String:", this.state.vgidListFilterString)
                                                )
                                            }}
                                            onChange={(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    selectedVGObjList: value,
                                                },
                                                    // () => console.log("Selected VG Obj List:", this.state.selectedVGObjList)
                                                )
                                            }}
                                        >
                                            {
                                                Object.keys(vehicleGroups.byVGID)
                                                    .filter(currVGID => {
                                                        return vehicleGroups.byVGID[currVGID].groupName.toLowerCase().includes(this.state.vgidListFilterString.toLowerCase()) && currVGID
                                                    })
                                                    .map(currVGID => {
                                                        return (
                                                            (
                                                                currVGID
                                                                &&
                                                                vehicleGroups.byVGID[currVGID].subName
                                                                &&
                                                                <Option key = {currVGID} value={currVGID}>{vehicleGroups.byVGID[currVGID].groupName} [${vehicleGroups.byVGID[currVGID].subName}]</Option>
                                                            )
                                                            ||
                                                            <Option key = {currVGID} value={currVGID}>{vehicleGroups.byVGID[currVGID].groupName}</Option>
                                                        )
                                                    })
                                            }
                                        </Select> :
                                        <Select
                                            labelInValue
                                            mode="multiple"
                                            disabled={true}
                                            placeholder="No vehicle groups found"
                                            style={{ width: '100%' }}
                                        />
                                }
                            </FormItem>
                        }

                        {
                            (user.userRole === ROLE_TYPE.FLEET_OWNER || user.userRole === ROLE_TYPE.FLEET_OPERATOR) &&
                            <FormItem
                                label="Geofence Templates"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                {
                                    Object.keys(geofenceTemplate.byId).length > 0 ?
                                        <Select
                                            labelInValue
                                            mode="multiple"
                                            placeholder="Select default geofence templates (Optional)"
                                            value={this.state.selectedGTObjList}
                                            filterOption={false} // Keep this disabled or filtering will be weird
                                            style={{ width: '100%' }}
                                            onSearch={(searchedString) => {
                                                this.setState({
                                                    ...this.state,
                                                    gtidListFilterString: searchedString,
                                                },
                                                    // () => console.log("GTID List Filter String:", this.state.gtidListFilterString)
                                                )
                                            }}
                                            onChange={(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    selectedGTObjList: value,
                                                },
                                                    // () => console.log("Selected GT Obj List:", this.state.selectedGTObjList)
                                                )
                                            }}
                                        >
                                            {
                                                Object.keys(geofenceTemplate.byId)
                                                    .filter(currGTID => {
                                                        return geofenceTemplate.byId[currGTID].templateName.toLowerCase().includes(this.state.gtidListFilterString.toLowerCase()) && currGTID
                                                    })
                                                    .map(currGTID => {
                                                        return <Option key={currGTID} value={currGTID}>{geofenceTemplate.byId[currGTID].templateName}</Option>
                                                    })
                                            }
                                        </Select> :
                                        <Select
                                            labelInValue
                                            mode="multiple"
                                            disabled={true}
                                            placeholder="No geofence templates found"
                                            style={{ width: '100%' }}
                                        />
                                }
                            </FormItem>
                        }

                        {
                            (user.userRole === ROLE_TYPE.FLEET_OWNER || user.userRole === ROLE_TYPE.FLEET_OPERATOR) &&
                            <FormItem
                                label="Rule Templates"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                {
                                    Object.keys(ruleTemplate.byId).length > 0 ?
                                        <Select
                                            labelInValue
                                            mode="multiple"
                                            placeholder="Select default rule templates (Optional)"
                                            value={this.state.selectedRTObjList}
                                            filterOption={false} // Keep this disabled or filtering will be weird
                                            style={{ width: '100%' }}
                                            onSearch={(searchedString) => {
                                                this.setState({
                                                    ...this.state,
                                                    rtidListFilterString: searchedString,
                                                },
                                                    // () => console.log("RTID List Filter String:", this.state.rtidListFilterString)
                                                )
                                            }}
                                            onChange={(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    selectedRTObjList: value,
                                                },
                                                    // () => console.log("Selected RT Obj List:", this.state.selectedRTObjList)
                                                )
                                            }}
                                        >
                                            {
                                                Object.keys(ruleTemplate.byId)
                                                    .filter(currRTID => {
                                                        return ruleTemplate.byId[currRTID].templateName.toLowerCase().includes(this.state.rtidListFilterString.toLowerCase()) && currRTID
                                                    })
                                                    .map(currRTID => {
                                                        return <Option key={currRTID} value={currRTID}>{ruleTemplate.byId[currRTID].templateName}</Option>
                                                    })
                                            }
                                        </Select> :
                                        <Select
                                            labelInValue
                                            mode="multiple"
                                            disabled={true}
                                            placeholder="No rule templates found"
                                            style={{ width: '100%' }}
                                        />
                                }
                            </FormItem>
                        }

                        <div style={{ position: "absolute", right: "100px" }}>
                            <Button
                                type="danger"
                                className="login-form-button"
                                style={{ marginLeft: "22px" }}
                                // disabled = {this.props.style.isLoadingSubmit}
                                onClick={() => this.props.dispatch(goBackToPrev())}
                            >
                                Cancel
                            </Button>
                            <PrimaryButton
                                onClick={this.submitForm}
                                loading={this.props.style.isLoadingSubmit && this.state.isLoading}
                            >
                                Add
                            </PrimaryButton>
                        </div>
                    </Form>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    hierarchy: state.v2.hierarchy,
    ruleTemplate: state.v2.ruleTemplate,
    vehicleGroups: state.v2.vehicleGroups,
    geofenceTemplate: state.v2.geofenceTemplate,
});

// export default  connect(mapStateToProps)(AddCustomer)
const ConnectedAddCustomer = connect(mapStateToProps)(AddCustomer);
export default showSecondarySidebar(false)(ConnectedAddCustomer)