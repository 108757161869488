import React, { Component } from "react";
import { connect } from "react-redux";

import EventStatus from "./EventStatus";

import { Radio } from "antd";

// Redux Actions
import { select_event_type } from "../../../services/redux/actions/statusBar";

const EVENT_TYPES = {
  ALL: "ALL",
  INFO: "INFO",
  WARNING: "WARNING",
  CRITICAL: "CRITICAL",
  CONTROL: "CONTROL"
};

class EventStatusBar extends Component {
  render() {
    return (
      <Radio.Group
        defaultValue = {EVENT_TYPES.ALL}
        value = {this.props.statusBar.selectedEventType}
        onChange = {e => this.props.dispatch(select_event_type(e.target.value))}
        
        // Not sure why need to declaring the style works instead of using a classname
        // Somehow classname is lower priority than Radio.Group's .css on this page but not in Dashboard
        style = {{
          display: "inline-flex",
          borderRadius: 1,
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
          overflow: "hidden",
        }}
      >
        {
          Object.keys(EVENT_TYPES).map((eventType) =>
            <EventStatus
              key = {eventType}
              eventType = {eventType}
              count = {this.props.vehicles.selectedId ? this.props.events.byType[eventType].length : 0}
            />
          )
        }
      </Radio.Group>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.v2.events,
  vehicles: state.v2.vehicles,
  statusBar: state.v2.statusBar,
})

export default connect(mapStateToProps)(EventStatusBar);