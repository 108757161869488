export const statusBar = (state = {selectedStatus: "ALL", selectedEventType: "ALL"}, action) => {

    switch (action.type) {

        case 'SELECT_STATUS':
            return {...state, selectedStatus: action.status}

        case 'SELECT_EVENT_TYPE':
            return {...state, selectedEventType: action.eventType}

        default:
            return state
    }
}
