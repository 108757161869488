import React from "react";
import { connect } from "react-redux";

import moment from "moment";
import ExportExcelButton from "../ExportExcelButton";

import { PrimaryButton } from "../Button";
import { parseDuration } from "../../util/time";
import { EVENT_TYPES } from "../../../../constants";
import {
  Row,
  Col,
  Select,
  message,
  DatePicker as DatePickerAntd,
} from "antd";

// Redux Actions
import { clear_selected_device } from "../../services/redux/actions/devices";
import { clear_selected_vehicle } from "../../services/redux/actions/vehicles";

import "./index.css";
import { parseTime } from '../../util/time';

class VehicleSelectForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedId: props.tripRecordControl.selectedVID,
        // props.tripRecordControl.selectedVID ?
        //   `${props.vehicles.byId[props.tripRecordControl.selectedVID].dvid}||${props.vehicles.byId[props.tripRecordControl.selectedVID].vehiclePlate}||${props.tripRecordControl.selectedVID}` :
        //   undefined,
      startTime: props.tripRecordControl.searchStartTime ? moment(props.tripRecordControl.searchStartTime) : moment().startOf("day"),
      endTime: props.tripRecordControl.searchEndTime ? moment(props.tripRecordControl.searchEndTime) : moment().startOf("day").add(1, "day"),
      status: Object.keys(props.vehicles.byStatus).filter(k => k !== 'ALL')
    }
  }

  submitForm = () => {
    let {
      endTime,
      startTime,
      selectedId,
    } = this.state;

    this.props.onSubmit(startTime, endTime, selectedId);
  };

  returnEventExportButton = (selectedVID) => {
    const vehicle = this.props.vehicles.byId[selectedVID]
    if (!vehicle) return null

    const {
      endTime,
      startTime,
      // selectedId,
    } = this.state;

    const {
      events,
      // vehicles,
      statusBar,
    } = this.props;


    const isAllEvents = statusBar.selectedEventType === EVENT_TYPES.ALL;
    const newSheetData = Object.values(events.byId[selectedVID] || {}).filter((currEvent) => isAllEvents || currEvent.eventType === statusBar.selectedEventType);

    // console.log("Test:", selectedVID, statusBar.selectedEventType, isAllEvents, newSheetData);

    return (
      <ExportExcelButton
        filename={`${vehicle.vehiclePlate} - Events${!isAllEvents ? ` (${statusBar.selectedEventType})` : ""} - ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
        sheetData={newSheetData}
        sheetName={`${vehicle.vehiclePlate} Events${!isAllEvents ? ` (${statusBar.selectedEventType})` : null}`}
        sheetRow={[
          { label: 'Event Type', formatter: value => value.eventType ? value.eventType : '-' },
          { label: 'Event Name', formatter: value => value.eventName ? value.eventName : '-' },
          { label: 'Location', formatter: value => value.location ? `${value.location.lat}, ${value.location.lon}` : '-' },
          { label: 'Created At', formatter: value => value.createdAt ? moment(value.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-' },
        ]}
      />
    );
  }

  returnTripRecordExportButton = () => {

    const {
      selectedId, startTime, endTime,
    } = this.state

    const vehicle = this.props.vehicles.byId[selectedId]
    
    
    if(!this.props.tripRecord.formattedData) return null
    if(!this.props.tripRecord.formattedData.tripSummarys) return null
    if (!vehicle) return null

    const trips = Object.values(this.props.tripRecord.formattedData.tripSummarys)
    .map(summary=>{
      const {
        tripRecord = {},
        firstTransitRecordUnderThisTrip = {},
        lastTransitRecordUnderThisTrip = {},
      } = summary
      return {
        key: tripRecord.trid,
        tripStatus: tripRecord.tripStatus,
        startTime: firstTransitRecordUnderThisTrip.startTime,
        endTime: lastTransitRecordUnderThisTrip.endTime,
        startAddress: firstTransitRecordUnderThisTrip.startAddress,
        endAddress: lastTransitRecordUnderThisTrip.endAddress,
        distance: summary.totalDistance,
        topSpeed: summary.topSpeed,
        movingDuration: summary.totalMovingDuration,
        idlingDuration: summary.totalIdlingDuration,
        totalEvents: summary.numberOfEvents,

      }
    })

    return (
      <ExportExcelButton
        filename={`${vehicle.vehiclePlate} - Trip Records - ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
        sheetData={trips}
        sheetName='Trip Records'
        sheetRow={[
          {
            label: 'tripStatus',
            formatter: value => value.tripStatus || '-',
          },
          {
            label: 'startTime',
            formatter: value => parseTime(value.startTime) || '-',
          },
          {
            label: 'endTime',
            formatter: value => parseTime(value.endTime) || '-',
          },
          {
            label: 'startAddress',
            formatter: value => value.startAddress || '-',
          },
          {
            label: 'endAddress',
            formatter: value => value.endAddress || '-',
          },
          {
            label: 'distance (km)',
            formatter: value => {
              const distance = value.distance
              return (!isNaN(distance) && `${distance.toFixed(2)}`) || 0
            }
          },
          {
            label: 'topSpeed (km/h)',
            formatter: value => {
              const topSpeed = value.topSpeed
              return (!isNaN(topSpeed) && `${topSpeed.toFixed(2)}`) || 0
            }
          },
          {
            label: 'movingDuration',
            formatter: value => {
              const movingDuration = value.movingDuration
              return (!isNaN(movingDuration) && parseDuration(movingDuration)) || '-'
            }
          },
          {
            label: 'idlingDuration',
            formatter: value => {
              const idlingDuration = value.idlingDuration
              return (!isNaN(idlingDuration) && parseDuration(idlingDuration)) || '-'
            }
          },
          {
            label: 'totalEvents',
            formatter: value => value.totalEvents
          },
        ]} 
      />
    );
  }

  handleCalendarDisabledDate = () => moment().startOf("day").add(1, "day");

  componentDidUpdate = (prevProps) => {
    if (this.props.transitRecord !== prevProps.transitRecord && this.props.transitRecord.PDFstatus) {
      this.props.transitRecord.PDFstatus === 'done' && message.success('Report generated');
      this.props.transitRecord.PDFstatus === 'error' && message.error('Error in generating report. Try again?');
    }
  }

  componentWillUnmount = () => {
    this.props.dispatch(clear_selected_device());
    this.props.dispatch(clear_selected_vehicle());
  }

  render() {
    const {
      status,
      endTime,
      startTime,
      selectedId,
    } = this.state; 
    
    return (
      <div>
        <Row className="inputContainer">
          <Col span={8}>
            <span className="inputLabel">Vehicle:</span>
          </Col>

          <Col span={16}>
            <Select
              className="inputField"
              placeholder="Select a vehicle"
              value={selectedId}
              style={{ width: 206 }}
              onChange={(selectedId) => this.setState({ selectedId })}
            >
              {
                Object.values(this.props.vehicles.byId)
                .sort((a, b) => {
                  const vA = a.vehicleDisplayName && a.vehicleDisplayName.toLowerCase();
                  const vB = b.vehicleDisplayName && b.vehicleDisplayName.toLowerCase();

                  if (vA < vB) return -1;
                  if (vA > vB) return 1;
                  return 0;
                })
                .map((vehicle) => {
                  return (
                    <Select.Option key={vehicle.vid}>
                      {vehicle.vehicleDisplayName}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </Col>
        </Row>

        <Row className="inputContainer">
          <Col span={8}>
            <span className="inputLabel">Start Date:</span>
          </Col>

          <Col span={16}>
            <DatePickerAntd
              id="start-time"
              allowClear={false}
              value={startTime}
              className="inputField"
              format={'YYYY/MM/DD HH:mm:ss'}
              onChange={startTime => this.setState({ startTime })}
              showTime={{
                format: 'HH:mm:ss',
                inputReadOnly: false
              }}
            />
          </Col>
        </Row>

        <Row className="inputContainer">
          <Col span={8}>
            <span className="inputLabel">End Date:</span>
          </Col>

          <Col span={16}>
            <DatePickerAntd
              id="end-time"
              allowClear={false}
              value={endTime}
              className="inputField"
              format={'YYYY/MM/DD HH:mm:ss'}
              disabledTime={this.handleCalendarDisabledDate}
              onChange={endTime => this.setState({ endTime })}
              showTime={{
                format: 'HH:mm:ss',
                inputReadOnly: false
              }}
            />
          </Col>
        </Row>

        <div className="inputButtonContainer" style={{ flex: 1, justifyContent: "center", marginTop: 20 }}>
          <PrimaryButton
            onClick={this.submitForm}
            loading={this.props.style.isLoadingFleetRecord}
            disabled={!(selectedId && startTime && endTime && status)}
          >
            Submit
          </PrimaryButton>
          {
            this.props.pathname === '/TripRecord' ? (
              this.returnTripRecordExportButton(this.state.selectedId)
            ) :
              this.props.pathname === '/EventRecord' ? (
                this.returnEventExportButton(this.state.selectedId)
              ) : null
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  style: state.v2.style,
  events: state.v2.events,
  vehicles: state.v2.vehicles,
  statusBar: state.v2.statusBar,
  transitRecord: state.v2.transitRecord,
  tripRecordControl: state.v2.tripRecordControl,
  // vehicles: state.v2.vehicles,
  deviceLog: state.v2.deviceLog,
  tripRecord: state.v2.tripRecord,
});

export default connect(mapStateToProps)(VehicleSelectForm);