import * as THEME from '../assets/theme/color'
 
 /**
  * Color Theme
  */
const ThemeColor = THEME.color
const ThemeText = THEME.text

const color = {
    themeColor: ThemeColor,
    themeText: ThemeText
}


export const BRAND_PRIMARY = color.themeColor;
export const TEXT_COLOUR = color.themeText;