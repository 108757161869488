import React from "react";

import Highlighter from 'react-highlight-words';

import { 
    SearchOutlined,
    LoadingOutlined, 
} from '@ant-design/icons';
import {
    Col,
    Row,
    Input,
    Button,
} from "antd";

let searchText = "";

const LocationColumnSearchProps = (dataIndex) => {
    const searchInput = React.createRef();

    return {
        filterDropdown: ({ confirm, clearFilters, selectedKeys, setSelectedKeys }) => (
            <div style = {{ padding: 8 }}>
                <Input
                    ref = {searchInput}
                    value = {selectedKeys[0]}
                    placeholder = {`Search Location`}
                    onPressEnter = {() => {
                        confirm(); // Closes the dropdown for some reason
                        
                        searchText = selectedKeys[0];
                    }}
                    onChange = {(event) => setSelectedKeys(event.target.value ? [event.target.value] : [])}
                    style = {{ 
                        width: 188, 
                        marginBottom: 8, 
                        display: 'block' 
                    }}
                />

                <Button
                    size = "small"
                    icon = {<SearchOutlined/>}
                    type = "primary"
                    onClick = {() => {
                        confirm(); // Closes the dropdown for some reason
                        
                        searchText = selectedKeys[0];
                    }}
                    style = {{ 
                        width: 90, 
                        marginRight: 8 
                    }}
                >
                    Search
                </Button>

                <Button 
                    size = "small" 
                    onClick = {() => {
                        clearFilters();
                        
                        searchText = "";
                    }}
                    style = {{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) => {
            const { device, vehicle, inGeofences } = record[dataIndex];
            
            const inGeofence = inGeofences && inGeofences[0];
            const stringToFilterFor = device && device.location && inGeofence ? inGeofence.geofenceName : vehicle.address;

            return (
                stringToFilterFor ?
                    stringToFilterFor
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()) :
                    ""
            );
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: ({ device, vehicle, inGeofences }) => {
            if (!device || !vehicle) return <LoadingOutlined/>

            /**Prioritize showing geofence first */
            const inGeofence = inGeofences && inGeofences[0];

            return (
                <a
                    target = "_blank"
                    rel = "noopener noreferrer"
                    href = {`https://www.google.com.my/maps/place/${device.location.lat},${device.location.lon}`}
                >
                    {
                        device && device.location && inGeofence ?
                            <Row 
                                style = {{ 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    margin: 1, 
                                }}
                            >
                                <Col
                                    className = "POI"
                                    span = {7}
                                    style = {{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",

                                        height: 20,
                                        // backgroundColor: this.returnLatestGeofenceTemplateColorForGeoID(inGeofences[0].geoid),
                                    }}
                                >
                                    {/* {inGeofence.geoType} */}
                                    {inGeofence.geoType === "Polygon" ? "Zone" : inGeofence.geoType}
                                </Col>

                                <Col
                                    className = "POI_Description"
                                    span = {16}
                                    offset = {1}
                                >                                    
                                    <Highlighter
                                        autoEscape
                                        searchWords = {[searchText]}
                                        textToHighlight = {inGeofence.geofenceName}
                                        highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
                                    />
                                </Col>
                            </Row> :
                            <Highlighter
                                autoEscape
                                searchWords = {[searchText]}
                                textToHighlight = {vehicle.address}
                                highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
                            />
                    }
                </a>
            )
        },
    }
};

export default LocationColumnSearchProps;