import React from "react";
import { connect } from "react-redux";
import Chart from 'chart.js'
import { parseTime } from '../../../util/time';
import {
    VEHICLE_COLOR,
} from '../../../../../constants'


const COLORS = {
    FLAT_GREEN: '#b6ffb5',
    FLAT_RED: '#ff6666',
    FLAT_BLUE: '#9ccfff',
    FLAT_TURQUOISE: '#6effd6',
}


class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }
    componentDidUpdate() {
        this.myChart.data.labels = this.props.data.map(d => d.x).map(x => parseTime(x))
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.y1);
        this.myChart.data.datasets[1].data = this.props.data.map(d => d.y2);
        this.myChart.data.datasets[1].borderColor = this.props.data.map(d => d.color)
        this.myChart.data.datasets[1].pointBorderColor = this.props.data.map(d => d.color)
        this.myChart.data.datasets[1].pointBackgroundColor = this.props.data.map(d => d.color)

        this.myChart.update();
    }

    componentDidMount() {

        this.myChart = new Chart(this.canvasRef.current, {
            type: 'line',
            options: {
                maintainAspectRatio: false,
                scales: {

                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'hour'
                        }
                    }],
                    // yAxes: [
                    //   {
                    //     ticks: {
                    //       min: 0
                    //     }
                    //   }
                    // ]
                },
                tooltips: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    cornerRadius: 0,
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        afterLabel: (tooltipItem) => {
                            const index = tooltipItem.index
                            const data = this.props.data[index]
                            // console.log(data)
                            return `DEVICE STATUS: ${data.flag}`
                        }
                    }
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                legend: {
                    display: true,
                    fillStyle: COLORS.FLAT_TURQUOISE
                },
                async: true
            },
            data: {
                labels: this.props.data.map(d => d.y3),
                datasets: [
                    {
                        label: 'Speed (km/h)',
                        data: this.props.data.map(d => {
                            return !isNaN(d.y1) && d.y1.toFixed(2)
                        }),
                        fill: true,
                        pointRadius: 3,
                        pointHoverRadius: 10,
                        backgroundColor: COLORS.FLAT_TURQUOISE,
                        borderColor: COLORS.FLAT_TURQUOISE,
                        pointBorderColor: COLORS.FLAT_TURQUOISE,
                        pointBackgroundColor: COLORS.FLAT_TURQUOISE,
                        borderWidth: 1,
                        lineTension: 0,
                    },
                    {
                        label: 'Fuel (L)',
                        data: this.props.data.map(d => {
                            return !isNaN(d.y2) && d.y2.toFixed(2)
                        }),
                        fill: false,
                        pointRadius: 3,
                        pointHoverRadius: 10,
                        borderColor: this.props.data.map(d => d.color),
                        pointBorderColor: this.props.data.map(d => d.color),
                        pointBackgroundColor: this.props.data.map(d => d.color),
                        borderWidth: 1,
                        lineTension: 0,
                    }
                ]
            }
        })
    }

    render() {
        // console.log('render graph');

        return (
            <div style={{ width: '100%', overflow: 'scroll' }}>
                <canvas height={window.innerHeight * 0.5} ref={this.canvasRef} />
            </div>
        );
    }
}


class FuelAnalysisTimelineChart extends React.Component {

    state = {
        deviceLogs: []
    }

    shouldComponentUpdate = (prevProps) => {
        const vehicleStaticValues = ['vid', 'dvid', 'vehiclePlate', 'vehicleDisplayName', 'fuelProfile']

        const thisVehicles = getStaticValues(Object.values(this.props.vehicles.byId), vehicleStaticValues)
        const prevVehicles = getStaticValues(Object.values(prevProps.vehicles.byId), vehicleStaticValues)

        const isFuelRecord2Diff = JSON.stringify(this.props.fuelRecord) !== JSON.stringify(prevProps.fuelRecord)
        const isVehicleDiff = JSON.stringify(thisVehicles) !== JSON.stringify(prevVehicles)

        return (
            isFuelRecord2Diff || isVehicleDiff
        )

        function getStaticValues(objects, keys) {
            return objects.map(object => {
                Object.keys(object).map(key => {
                    if (!keys.includes(key)) {
                        delete object[key]
                    }
                    return null
                })
                return null
            })
        }
    }

    getDataSources = () => {
        const { fuelProfile, fuelTank } = this.props

        const fuelRecord = this.props.fuelRecord.byFuelTank[fuelTank]
        const deviceLogs = (fuelRecord && fuelRecord.deviceLogs) || []

        const thisFuelTankCapacity =( fuelProfile && fuelProfile.fuelCapacity / 100) || 0

        let deviceData = deviceLogs
            .map(g => {

                let color = getColor(g.flag)

                g = {
                    x: g.deviceTime ? g.deviceTime : 0,
                    y1: g.speed ? g.speed : 0,
                    y2: g.fuel && thisFuelTankCapacity ? g.fuel * thisFuelTankCapacity : 0,
                    y3: g.engineStatus ? 50 : 0,
                    flag: g.flag,
                    color,
                    g,
                }

                function getColor(flag) {
                    return VEHICLE_COLOR[flag]
                }

                return g
            })
            .sort((a, b) => a.x - b.x) || []

        return deviceData
    }

    render() {
        const deviceData = this.getDataSources()
        return (
            <div className="App">
                <div className="main chart-wrapper">
                    <LineChart
                        data={deviceData || []}
                        title={'Device Log'}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    deviceLog: state.v2.deviceLog,
    vehicles: state.v2.vehicles,
    fuelRecord: state.v2.fuelRecord,
});

export default connect(mapStateToProps)(FuelAnalysisTimelineChart);