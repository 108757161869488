import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NEWGoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../constants";
import { MAP } from '../../constants'
import {
    Marker,
    Circle,
    GoogleMap,
    OverlayView,
    DrawingManager,
} from '@react-google-maps/api';

// Redux Actions
import { set_new_geofence_from_map } from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const GoogleMapsComponentDrawPOI = (props) => {
    const dispatch = useDispatch();

    // State
    const [mapRef, setMapRef] = useState(null);
    const [circleRef, setCircleRef] = useState(null);

    // Redux Stores
    const mapControl = useSelector(state => state.v2.mapControl);
    const geofenceTemplates = useSelector(state => state.v2.geofenceTemplate);

    const returnLatestGeofenceTemplateColorByGTIDs = (gtidList) => {
        let latestTemplate = {};

        gtidList.forEach((currGTID) => {
            if (!latestTemplate.createdAt || geofenceTemplates.byId[currGTID].createdAt > latestTemplate.createdAt) {
                latestTemplate = geofenceTemplates.byId[currGTID];
            }
        })

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    const getMarker = (colorHexCode) =>  MAP.POI_MARKER_BY_COLOR[(colorHexCode || DEFAULT_GEOFENCE_HEXCODE).replace("#", "").toUpperCase()]
    
    useEffect(() => {
        if (circleRef && mapRef) {
            const bounds = circleRef.getBounds()
            mapRef.fitBounds(bounds)
        }
    }, [mapControl, circleRef, mapRef])

    return (
        <GoogleMap
            id = 'example-map'
            mapContainerStyle = {{
                height: window.innerHeight
            }}
            onLoad = {ref => setMapRef(ref)}
        >
            {
                Object.values(geofenceTemplates.byId).length // Just so POI won't render with default color
                && mapControl.geofence
                && mapControl.geofence.geoid
                && mapControl.geofence.coordinates
                && mapControl.geofence.coordinates[0] &&
                    <Marker
                        icon = {getMarker(returnLatestGeofenceTemplateColorByGTIDs(mapControl.selectedGeofenceTemplates))}
                        position = {mapControl.geofence.coordinates[0]}
                    >
                        {/* <InfoWindow position = {mapControl.geofence.coordinates[0]}>
                            <div>
                                <h4>{mapControl.geofence.geofenceName}</h4>

                                {mapControl.geofence.geofenceComment && <div>{mapControl.geofence.geofenceComment}</div>}
                            </div>
                        </InfoWindow> */}

                        <OverlayView
                            options = {{ disableAutoPan: true }}
                            position = {mapControl.geofence.coordinates[0]}
                            mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset = {(width, height) => ({
                                x: -(width / 2),
                                y: -height - 45,
                            })}
                        >
                            <div 
                                style = {{
                                    background: 'white',

                                    border: '1px solid #ccc',
                                    borderRadius: '10px',

                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 8,
                                    paddingBottom: 1,
                                }}
                            >
                                <h4>{mapControl.geofence.geofenceName}</h4>
                                <div>{mapControl.geofence.geofenceComment}</div>
                            </div>
                        </OverlayView>
                    </Marker>
            }

            {/* {
                mapControl.geofence
                && mapControl.geofence.coordinates
                && mapControl.geofence.coordinates[0] &&
                    <Marker position = {mapControl.geofence.coordinates[0]}>
                        <InfoWindow position = {mapControl.geofence.coordinates[0]}>
                            <div>
                                <h4>{mapControl.geofence.geofenceName}</h4>

                                {mapControl.geofence.geofenceComment && <div>{mapControl.geofence.geofenceComment}</div>}
                            </div>
                        </InfoWindow>
                    </Marker>
            } */}

            {
                Object.values(geofenceTemplates.byId).length // Just so POI won't render with default color
                && mapControl.geofence
                && mapControl.geofence.geoid
                && mapControl.geofence.coordinates
                && mapControl.geofence.coordinates[0] &&
                    <Circle
                        onLoad = {ref => setCircleRef(ref)}
                        center = {mapControl.geofence.coordinates[0]}
                        radius = {mapControl.geofence.geofenceRadius}
                        options = {{
                            strokeColor: returnLatestGeofenceTemplateColorByGTIDs(mapControl.selectedGeofenceTemplates),
                        }}
                    />
            }

            {
                <DrawingManager
                    drawingMode = {null}
                    options = {{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                window.google.maps.drawing.OverlayType.MARKER,
                            ],
                        },
                        markerOptions: {
                            visible: false,
                        },
                    }}
                    onMarkerComplete = {
                        (event) => {
                            // console.log("New Marker Positiion:", Number(event.position.lat()), Number(event.position.lng()));

                            dispatch(set_new_geofence_from_map({
                                ...mapControl.geofence,
                                coordinates: [{
                                    lat: Number(event.position.lat()),
                                    lng: Number(event.position.lng()),
                                }]
                            }));
                        }
                    }
                />
            }
        </GoogleMap>
    )
}

const GoogleMapsComponentWithLoadScript = (props) => NEWGoogleMapsLoadScript(GoogleMapsComponentDrawPOI, props)

export default GoogleMapsComponentWithLoadScript