import * as API from "../../api";
import * as ActionTypes from "../action-types/vehicleLog";

import { message } from "antd";
import {
  setLoadingSubmit, 
  unsetLoadingSubmit,
} from "./style";

export const get_vehicle_log_by_timeframe_request = (vid, startTime, endTime) => dispatch => {
    dispatch(setLoadingSubmit()); 
  
    API.getVehicleLogByTimeframe(vid, startTime, endTime)
    .then(data => {
      // console.log("get_vehicle_log_by_timeframe_request Data:", data);

      const error = data.errorMessage || data.message || data.err 

      if (error) {
        message.error(error.toString());
      }
      else {
        if (data.length < 1) {
          message.info("No vehicle logs found within this timeframe.");
        }
  
        dispatch(get_vehicle_log_by_timeframe_success(data));
      }
    })
    .finally(() => {
        dispatch(unsetLoadingSubmit());
    })
  }
  
  const get_vehicle_log_by_timeframe_success = (data) => {
    // console.log("Get Vehicle Log By Timeframe Success Data:", data);
  
    return {
      type: ActionTypes.GET_VEHICLE_LOG,
      data: data
    }
  }