import { api_next, headers } from '../constants'

export const getVehicleFuelAnalysisTimeframeSync = (vid, startTime, endTime, isReturnDeviceLog, fuelTankNumber) => {
    return fetch(`${api_next}/vehicle/analysis/timeframe/get/sync`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          "vid": vid,
          "startTime": Number(startTime),
          "endTime": Number(endTime),
          isReturnDeviceLog, 
          fuelTankNumber
        })
      })
        .then(res => res.json())
}