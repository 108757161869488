import { getClient } from './index';
import { config } from "../../../../config";

const wsURL = config.wsURL;
const wsReconnectInterval = config.wsReconnectInterval;

const closeWS = () => {
    try {
        // console.log(`closeWS`, client, new Date);

        const client = getClient();

        client.close(1000);
    } 
    catch (err) {
        console.log('unable to closeWS', err);
    }
}

const wsClient = (
    client,
    restart = () => { },
    cb = () => { }
) => {
    // console.log('websocket connect', new Date);
    // console.log('websocket client', client);

    if (client) {
        window.application = {
            close: () => closeWS(client),
            client: client,
        }

        client.onopen = async () => {}

        client.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);

            // console.log("websocket onmessage:", parsedData);

            cb(
                null,
                parsedData,
            );
        }

        client.onclose = async (e) => {
            // console.log(`onclose`, e, new Date);

            switch (e.code) {
                case 1000:	// CLOSE_NORMAL
                    // console.log('closed...')
                    break
                case 4500:
                default:	// Abnormal closure
                    // console.log(`restarting...`)
                    restart('reconnect', 0);
                    break;
            }
        }

        client.onerror = async (e) => {
            // console.log(`onerror`, e, new Date);

            switch (e.code) {
                case 'ECONNREFUSED':
                    restart('reconnect', 0);
                    break;
                default:
                    // console.log("[WS] Error", e);
                    break;
            }
        }
    }
}

export {
    closeWS,
    wsClient,
    wsReconnectInterval,
    wsURL
}