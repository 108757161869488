export const UPDATE_TREE_DATA = 'UPDATE_TREE_DATA';
export const GET_USER_HIERARCHY = 'GET_USER_HIERARCHY';
export const ADD_USER_HIERARCHY = 'ADD_USER_HIERARCHY';
export const EDIT_USER_HIERARCHY = 'EDIT_USER_HIERARCHY';

export const ADD_GROUPS_TO_USER = "ADD_GROUPS_TO_USER";
export const UPDATE_GROUPS_TO_USER = "UPDATE_GROUPS_TO_USER";

export const SELECT_USER_HIERARCHY = 'SELECT_USER_HIERARCHY';
export const SELECT_USER_HIERARCHY_VID = 'SELECT_USER_HIERARCHY_VID';
export const SELECT_USER_HIERARCHY_DVID = 'SELECT_USER_HIERARCHY_DVID';
export const UPDATE_USER_HIERARCHY_DEVICES = 'UPDATE_USER_HIERARCHY_DEVICES';
export const UPDATE_SELECTED_USER_HIERARCHY = 'UPDATE_SELECTED_USER_HIERARCHY';

export const CLEAR_TEMP_NEW_UID = 'CLEAR_TEMP_NEW_UID'; // Add happens on ADD_USER_HIERARCHY action