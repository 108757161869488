import React, { Component } from 'react';
import { connect } from 'react-redux';

import XLSX from 'xlsx';
import PropTypes from 'prop-types';

import {
    PlusOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {
    Tag,
    Menu,
    Modal,
    Table,
    Button,
    Dropdown,
} from 'antd';

const UI_COLUMNS = {
    uploadStatus: 'Status',
    message: 'Message',
}

const UPLOAD_STATUS = {
    ready: 'READY',
    success: 'SUCCESS',
    failed: 'FAILED',
    warning: 'WARNING',
    loading: 'LOADING'
}

class UploadExcelButton extends Component {
    state = {
        modalVisible: false,
        forceCancel: false,
        uploadedData: [],
        dataColumns: {},
        dataSource: [],
        title: 'Bulk upload',
        excelTemplateName: `Bulk upload template`,
        isLoading: false,
        isDone: false,

        disableUploadButton: true,
    }

    onClick = () => {
        this.refs.fileUploader.click();
    }

    onFileChange = (event) => {
        const files = event.target.files;

        if (files && files[0]) {
            this.convertExcelToJSON(files[0]);
        }
    }

    convertExcelToJSON = (uploadedFile) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        // Set up callback for when FileReader is done loading
        reader.onload = (event) => {
            /* Parse data */
            const bstr = event.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);

            // console.log('Converted Excel Data:', data);

            document.getElementById('uploadFile').value = ''; // Clearing stored file after complete upload

            this.sanitizeExcel(data);
        };

        // Call FileReader
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        }
        else {
            reader.readAsArrayBuffer(uploadedFile);
        };
    }

    sanitizeExcel = (uploadedData) => {
        const { dataColumns } = this.state

        try {
            if (uploadedData.length === 0) {
                throw new Error(`Excel has no rows of data`)
            }

            /**Sanitize excel formulaes */
            uploadedData = JSON.parse(JSON.stringify(uploadedData))

            /**Make sure columns are correct*/
            columnsSanitize(Object.keys(uploadedData[0]))

            // console.log(JSON.stringify(uploadedData, null, 2))

            /**Make sure values are correct*/
            const dataSource = uploadedData
                .map(data => rowsSanitize(data, uploadedData))

            // console.log(dataSource);

            this.setState({
                uploadedData,
                dataSource,
            })
        } 
        catch (e) {
            // console.log(e);

            Modal.error({
                title: 'Error in uploaded file',
                error: e.message,
            });
        }

        function columnsSanitize(excelColumns) {
            const labels = Object.values(dataColumns).filter(col => !col.optional).map(col => col.label);

            labels.map(dataColumn => {
                if (!excelColumns.includes(dataColumn)) {
                    throw new Error(`Missing column "${dataColumn}" in uploaded excel. Column schema should be ${JSON.stringify(labels)}`)
                }
                return null
            })
        }

        function rowsSanitize(excelRow, excelRows) {
            let status = UPLOAD_STATUS.ready;
            let message = undefined;

            Object.keys(excelRow).map(key => {
                try {
                    const value = excelRow[key]
                    const values = excelRows.map(data => data[key])
                    const rule = Object.values(dataColumns).find(col => col.label === key) && Object.values(dataColumns).find(col => col.label === key).rule

                    rule && rule(value, values)
                } 
                catch (err) {
                    // console.log(`[rowsSanitize] ${err.message}`);

                    status = UPLOAD_STATUS.failed
                    message = err.message
                }
                return null;
            })

            return {
                ...excelRow,
                [UI_COLUMNS.uploadStatus]: status,
                [UI_COLUMNS.message]: message
            }
        }
    }

    uploadExcel = async (excelDatas) => {
        const { dataColumns } = this.state;

        this.setState({
            isLoading: true,
            isDone: false
        })

        /**Add device in series, not parallel*/
        for (let i = 0; i < excelDatas.length; i++) {
            const excelData = excelDatas[i];

            excelData[UI_COLUMNS.uploadStatus] = UPLOAD_STATUS.loading;

            /**format excelData */
            const formattedExcelData = {};

            Object.keys(dataColumns).map(key => {
                const col = dataColumns[key]
                const label = col.label
                formattedExcelData[key] = excelData[label]
                return null;
            })

            const response = await this.props.uploadLoop(formattedExcelData)

            excelData[UI_COLUMNS.uploadStatus] = response.uploadStatus;
            excelData[UI_COLUMNS.message] = response.message;
        }

        this.setState({
            isLoading: false,
            isDone: true
        })
    }

    componentDidMount = () => {
        const {
            title,
            dataColumns,
            excelTemplateName,
        } = this.props;

        this.setState({
            title,
            dataColumns,
            excelTemplateName,
        })
    }

    // A workaround to fix any issue similar to Management page's vehicle tab's "Bulk Export Vehicles" button's rules not updating so it keeps getting the old empty device store
    // - DC (3/12/2021)
    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({ dataColumns: this.props.dataColumns });
        }
    }

    render() {
        const {
            title,
            dataSource,
            dataColumns,
        } = this.state;

        const columns = [
            ...Object.values(dataColumns).map(col => {
                return {
                    title: col.label,
                    dataIndex: col.label,
                    render: col.render
                }
            }),
            {
                title: UI_COLUMNS.uploadStatus,
                dataIndex: UI_COLUMNS.uploadStatus,
                render: status => {
                    let color = ''
                    switch (status) {
                        case UPLOAD_STATUS.success:
                            color = 'green'
                            break
                        case UPLOAD_STATUS.warning:
                            color = 'yellow'
                            break
                        case UPLOAD_STATUS.failed:
                            color = 'red'
                            break
                        case UPLOAD_STATUS.ready:
                            color = 'blue'
                            break
                        case UPLOAD_STATUS.loading:
                        default:
                            color = 'blue'
                            break
                    }
                    return <Tag color = {color}>{status}</Tag>
                }
            },
            {
                title: UI_COLUMNS.message,
                dataIndex: UI_COLUMNS.message,
                width: 400,
            },
        ]

        return (
            <form>
                <Dropdown
                    placement = "bottomLeft"
                    loading = {this.props.loading}
                    disabled = {this.props.disabled}
                    overlay = {
                        <Menu>
                            <Menu.Item key = "2" onClick = {this.onClick}>
                                Upload File

                                <input
                                    id = 'uploadFile'
                                    type = 'file'
                                    accept = '.xlsx' // Only allows excel files
                                    ref = 'fileUploader'
                                    style = {{ display: 'none' }}
                                    onChange = {this.onFileChange}
                                />
                            </Menu.Item>

                            <Menu.Item key = "3">
                                <a href = {this.props.templateUrl}>Download Template</a>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button
                        className = {this.props.className}
                        icon = {!this.props.compact ? <PlusOutlined/> : <UploadOutlined/>}
                        loading = {this.props.loading}
                        style = {{ ...this.props.style }}
                    >
                        {!this.props.compact ? this.props.buttonName : null}
                    </Button>
                </Dropdown>

                <Modal
                    visible = {dataSource.length > 0}
                    closable = {false}
                    title = {title}
                    width = {window.innerWidth * 0.85}
                    bodyStyle = {{
                        height: window.innerHeight * 0.7
                    }}
                    footer = {[
                        <Button
                            type = {'primary'}
                            key = 'upload'
                            loading = {this.state.isLoading}
                            disabled = {!this.state.isDone && dataSource && dataSource.filter(data => data[UI_COLUMNS.uploadStatus] === UPLOAD_STATUS.failed).length}
                            onClick = {() => {
                                if (!this.state.isLoading && this.state.isDone) {
                                    this.setState({
                                        dataSource: [],
                                        isLoading: false,
                                        isDone: false
                                    })
                                }
                                else {
                                    this.uploadExcel(dataSource)
                                }
                            }}
                        >
                            {
                                (!this.state.isLoading && this.state.isDone && `Done`)
                                || 'Upload'
                            }
                        </Button>,
                        <Button
                            key = 'back'
                            disabled = {this.state.loading}
                            onClick = {() => {
                                this.setState({
                                    dataSource: [],
                                    isLoading: false,
                                    isDone: false
                                })
                            }}
                        >
                            Cancel
                        </Button>,
                    ]}
                >
                    <Table
                        columns = {columns}
                        bordered
                        dataSource = {dataSource.map((data, i) => {
                            return {
                                key: i,
                                ...data
                            }
                        })}
                        scroll = {{
                            x: columns && columns.length * 150,
                            y: window.innerHeight * 0.5
                        }}
                    />
                </Modal>
            </form>
        );
    }
}

UploadExcelButton.propTypes = {
    title: PropTypes.string,
    loading: PropTypes.bool,
    uploadLoop: PropTypes.func,
    buttonName: PropTypes.string,
    templateUrl: PropTypes.string,
    dataColumns: PropTypes.object,
    excelTemplateName: PropTypes.string,
}

export default connect(null)(UploadExcelButton);