import React from 'react';
import { connect } from 'react-redux';

import { Modal } from 'antd';
import { PrimaryButton } from '../../../components/Button';

// Requires props:
// - isVisible
// - selectedVIDs
// - modalContents
// - onAdvancedFilterModalClose
// - onAdvancedFilterModalSubmit
class AdvancedFilterModal extends React.Component {    
    render() {
        const { style } = this.props;

        return (
            <Modal
                title = {"Advanced Filter"}
                visible = {this.props.isVisible}
                closable = {false}
                onCancel = {() => {
                    this.props.onAdvancedFilterModalClose()
                }}
                bodyStyle = {{
                    display: "flex", 
                    flexDirection: "column",

                    alignItems: "center",
                    justifyContent: "center",
                }}
                footer = {[
                    <PrimaryButton
                        key = "ModalSubmitButton"
                        loading = {(style && style.isLoadingSubmit) || false}
                        onClick = {() => {
                            !this.props.selectedVIDs.length ? 
                                this.props.onAdvancedFilterModalClose() :
                                this.props.onAdvancedFilterModalSubmit()
                        }}
                        style = {{ marginLeft: '15px' }}
                    >
                        Submit
                    </PrimaryButton>
                ]}
            >
                {this.props.modalContents}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
});

export default connect(mapStateToProps)(AdvancedFilterModal);