import React, { Component } from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Highlighter from 'react-highlight-words';
import EventStatusIcon from "../../../../components/EventStatusIcon";
import LocationColumnSearchProps from "../../LocationColumnSearchProps";

import { timeDiff } from '../../../../services/util';
import { SearchOutlined } from '@ant-design/icons';
import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../../../constants";
import {
    VEHICLE_COLOR,
    VEHICLE_STATUS,
} from "../../../../../../constants";
import {
    Table,
    Input,
    Button,
} from "antd";

// Required Props
// - selectedGeoID
class ExpandedTable extends Component {
    state = {
        dataSource: [],
    }

    getColumnSearchProps = (dataIndex) => {
        const handleSearch = (selectedKeys, confirm) => {
            confirm();
    
            this.setState({ searchText: selectedKeys[0] });
        };
    
        const handleReset = (clearFilters) => {
            clearFilters();
    
            this.setState({ searchText: '' });
        };

        return (
            {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style = {{ padding: 8 }}>
                        <Input
                            ref = {(node) => { this.searchInput = node }}
                            placeholder = {`Search ${dataIndex}`}
                            value = {selectedKeys[0]}
                            onPressEnter = {() => handleSearch(selectedKeys, confirm)}
                            onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            style = {{
                                display: 'block',
                                width: 188,
                                marginBottom: 8,
                            }}
                        />
        
                        <Button
                            type = "primary"
                            size = "small"
                            icon = {<SearchOutlined />}
                            onClick = {() => handleSearch(selectedKeys, confirm)}
                            style = {{
                                width: 90,
                                marginRight: 8,
                            }}
                        >
                            Search
                        </Button>
        
                        <Button
                            size = "small"
                            onClick = {() => handleReset(clearFilters)}
                            style = {{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </div>
                ),
                filterIcon: (filtered) => (
                    <SearchOutlined
                        style = {{ color: filtered ? '#1890ff' : undefined }}
                    />
                ),
                onFilter: (value, record) =>
                    record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase()),
                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => this.searchInput.select());
                    }
                },
                render: (text) => (
                    <Highlighter
                        autoEscape
                        textToHighlight = {text ? text : ""}
                        searchWords = {[this.state.searchText]}
                        highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
                    />
                ),
            }
        );
    };

    returnLatestGeofenceTemplateColorForGeoID = (geoID) => {
        let latestTemplate = {};

        Object.values(this.props.geofenceTemplates.byId)
            .filter((currTemplate) => currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
            .forEach((currTemplate) => {
                if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                    latestTemplate = currTemplate;
                }
            })

        // console.log("Color:", latestTemplate.colorHexCode);

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    setUpDataSource = () => {
        const newDataSource = 
            Object.values(this.props.vehicles.byId)
            .filter((currVehicle) => currVehicle.geofenceProfile[this.props.selectedGeoID]) // Only vehicles under this geofence are listed
            .sort((b, a) => b.vehiclePlate - a.vehiclePlate)
            .map(vehicle => {
                const device = this.props.devices.byId[vehicle.dvid];

                const fuelProfile = Object.keys(vehicle.fuelProfile || {}).map((key, i) => {
                    const vehicleTank = vehicle.fuelProfile[key];
                    const deviceLevel = (device && device.fuelProfile && device.fuelProfile[key]) || 0;

                    const fuelLevel = (deviceLevel * vehicleTank.fuelCapacity).toFixed(2);

                    return `[Tank ${i + 1}] ${fuelLevel} L`
                })

                const inGeofences = 
                    vehicle.geofenceProfile ?
                        Object.keys(vehicle.geofenceProfile)
                        .filter(geoid => vehicle.geofenceProfile[geoid] === 1)
                        .map(geoid => this.props.geofences.byId[geoid]) :
                        [];

                return {
                    key: vehicle.vid,

                    fuelProfile,

                    details: vehicle.dvid,
                    updatedAt: vehicle.updatedAt,
                    vehiclePlate: vehicle.vehiclePlate,
                    transitStatus: vehicle.transitStatus,
                    vehicleDisplayName: vehicle.vehicleDisplayName,
                    
                    location: { device, vehicle, inGeofences },
                    duration: timeDiff(vehicle.transitFrom, Date.now()),
                    speed: device && `${(device.speed && device.speed.toFixed(2)) || 0} km/h`,
                }
            })
        
        // console.log("newDataSource:", newDataSource);

        this.setState({ dataSource: newDataSource });
    }

    componentDidMount = () => {
        this.setUpDataSource();
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setUpDataSource();
        }
    }

    render() {
        const { dataSource } = this.state;

        const columns = [
            {
                title: "Details",
                dataIndex: "details",
                fixed: "left",
                render: (rowData) => <EventStatusIcon deviceId = {rowData} />
            },
            {
                title: "Vehicle Name",
                dataIndex: "vehicleDisplayName",
                fixed: "left",
                ...this.getColumnSearchProps("vehicleDisplayName"),
            },
            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                ...this.getColumnSearchProps("vehiclePlate"),
            },
            {
                title: "Status",
                dataIndex: "transitStatus",
                filters: 
                    Object.values(VEHICLE_STATUS)
                    .filter((status) => status !== VEHICLE_STATUS.ALL)
                    .map((status) => ({ text: status, value: status })),
                onFilter: (value, record) => record.transitStatus && record.transitStatus.indexOf(value) === 0,
                render: (transitStatus) => {
                    if (transitStatus) {
                        return (
                            <div style = {{ color: VEHICLE_COLOR[transitStatus] }}>
                                {transitStatus}
                            </div>
                        )
                    }

                    return '-'
                }
            },
            {
                title: "Duration",
                dataIndex: "duration",
                sorter: (a, b) => a.duration - b.duration,
            },
            {
                title: "Speed",
                dataIndex: "speed",
                sorter: (a, b) => a.speed - b.speed,
                render: (data) => data ? data : "-",
            },
            {
                title: "Fuel",
                dataIndex: "fuelProfile",
                render: (fuelProfile = []) => {
                    if (fuelProfile.length) {
                        return <ul>{fuelProfile.map((p, i) => <li key = {i}>{p}</li>)}</ul>
                    }

                    return `(No fuel profiles)`
                }
            },
            {
                title: "Location",
                dataIndex: "location",
                ...LocationColumnSearchProps("location"), //! Unique search function specifically for this column only. No need to declare render for this column as it is handled here.
            },
            {
                title: "Last Updated",
                dataIndex: "updatedAt",
                sorter: (a, b) => a.updatedAt - b.updatedAt,
                render: updatedAt => (updatedAt && moment(updatedAt).format("YYYY/MM/DD HH:mm:ss")) || '-'
            },
        ]

        return (
            <div 
                style = {{ 
                    margin: 5,
                    padding: 5,
                    backgroundColor: "white", 
                }}
            >
                <div style = {{ fontWeight: "bold", marginBottom: 10 }}>Vehicles Inside This Geofence</div>

                <Table
                    loading = {!dataSource.length}
                    columns = {columns}
                    dataSource = {dataSource}
                    pagination = {dataSource.length > 10}
                    scroll = {{
                        x: columns.length * 150,
                        // y: window.innerHeight * 0.5,
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    geofenceTemplates: state.v2.geofenceTemplate,
});

export default connect(mapStateToProps)(ExpandedTable);

