import React, { Component } from "react";
import { connect } from "react-redux";
import PrimaryButton from "./PrimaryButton";

class PrimaryButtonContainer extends Component {
  render() {
    return <PrimaryButton color={this.props.color} {...this.props} />;
  }
}

const mapStateToProps = (state, props) => ({
  color: state.v2.style.buttonColor
});

export default connect(mapStateToProps)(PrimaryButtonContainer);
