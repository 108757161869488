import * as actionType from '../action-types/notificationRT';

// export const archive_notification_rt = (notifyRT) => {
//     return {
//         type: actionType.ARCHIVE_NOTIFICATION_RT,
//         notifyRT: notifyRT
//     }
// }

export const update_notification_rt = (notifyRT) => {
    // console.log("NotifyRT:", notifyRT);

    return {
        type: actionType.UPDATE_NOTIFICATION_RT,
        notifyRT: notifyRT
    }
} 