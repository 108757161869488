import React from "react";
import PropTypes from "prop-types";
import EventBadge from "./EventBadge";
import { 
  Radio, 
  Tooltip, 
} from "antd";

import "./index.css";

const EventStatus = props => {
  return (
    <Tooltip title = {props.eventType}>
      <Radio.Button 
        value = {props.eventType} 
        
        // Not sure why need to declaring the style works instead of using a classname
        // Somehow classname is lower priority than Radio.Group's .css on this page but not in Dashboard
        style = {{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          width: 80,
          height: 61,
        }}
      >
        <EventBadge {...props} eventType = {props.eventType} count = {props.count} />
      </Radio.Button>
    </Tooltip>
  );
};

EventStatus.propTypes = {
  count: PropTypes.number,
  fontSize: PropTypes.number,
  eventType: PropTypes.string,
};

export default EventStatus;