import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import { LockOutlined } from '@ant-design/icons'
import {
    ROLE_TYPE,
    DISPLAY_USER_ROLE,
} from '../../../../../constants';
import {
    Form,
    Input,
    Button,
    Select,
    Modal,
    message,
    Skeleton,
} from "antd";

// Redux Actions
import { edit_user_request } from "../../../services/redux/actions/user";
import { resetPassword } from '../../../services/auth/authService';


const { Option } = Select;
const FormItem = Form.Item;

class EditCustomer extends Component {
    state = {
        userName: "",
        userEmail: "",
        userRole: "",
        userContact: "",
        creatorUid: "",
        userAddress: "",
        userPhonePrefix: "+60",

        phonePrefixOptions: ["+60"],
        userRoleOptions: [ROLE_TYPE.DISTRIBUTOR, ROLE_TYPE.FLEET_OWNER, ROLE_TYPE.FLEET_OPERATOR],

        thisUser: {},
    }

    submitForm = () => {
        // console.log('formData ', formData);
        this.setState({ isLoading: true })

        const {
            userRole,
            userName,
            userEmail,
            userContact,
            userAddress,
            userPhonePrefix,
        } = this.state

        if (
            userName
            && userRole
            && userEmail
            && userAddress
            && userContact
            && userPhonePrefix
        ) {
            const editedUser = {
                uid: this.state.thisUser.uid,
                userHierarchy: this.state.thisUser.userHierarchy,
                userName,
                userContact: userPhonePrefix + userContact,
                userEmail,
                userRole,
                userAddress,
            }

            // console.log(`Editing User:
            // - UID: ${editedUser.uid}
            // - Name: ${editedUser.userName}
            // - Role: ${editedUser.userRole}
            // - Email: ${editedUser.userEmail}
            // - Address: ${editedUser.userAddress}
            // - Contact: ${editedUser.userContact}
            // - Hierarchy: ${editedUser.userHierarchy}`
            // );

            this.props.dispatch(edit_user_request(editedUser));
        }
        else {
            message.error('Fields cannot be empty');
        }
    }

    componentDidMount = () => {
        this.setupDefaultFormValues()
    }

    setupDefaultFormValues = () => {

        const uid = this.props.router.location.state
        const thisUser = this.props.hierarchy.byId[uid]
        if (!thisUser) return

        this.setState({
            ...this.state,
            thisUser: thisUser,
            userName: thisUser.userName,
            userRole: thisUser.userRole,
            userEmail: thisUser.userEmail,
            creatorUid: thisUser.parentId,
            userAddress: thisUser.userAddress,
            userContact: (thisUser.userContact && thisUser.userContact.slice(3, thisUser.userContact.length)) || '',
            userPhonePrefix: (thisUser.userContact && thisUser.userContact.slice(0, 3)) || this.state.userPhonePrefix,
        })
    }

    componentDidUpdate = (prevProps) => {
        if (
            JSON.stringify(this.props.hierarchy) !== JSON.stringify(prevProps.hierarchy)
            || JSON.stringify(this.props.router) !== JSON.stringify(prevProps.router)
        ) {
            this.setupDefaultFormValues()
        }
    }

    comfirmPassword = () => {
        // console.log(`confirming...${this.state.userPassword}and ${this.state._userPassword}`);

        if (this.state.isUserPasswordMatch !== 'unknown') {
            if (this.state.userPassword === this.state._userPassword) {
                this.setState({ isUserPasswordMatch: true });

                // console.log('...confirmed');
            }
            else {
                this.setState({ isUserPasswordMatch: false })
                message.error('Passwords do not match');

                // console.log('...denied');
            }
        }
    }

    render() {
        const { user } = this.props;

        if (!this.state.thisUser.uid) return <Skeleton active />

        return (
            <div className="page-container">
                <Page title="Edit User">
                    {
                        this.state.thisUser
                        && this.state.thisUser.uid === user.uid &&
                        <p style={{ color: 'red' }}>
                            *You are editing the currently logged in user. You will be logged out once edits are saved
                            </p>
                    }

                    <Form className="login-form">
                        <FormItem
                            label="User Name"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                placeholder="e.g. ABC Sdn. Bhd."
                                value={this.state.userName}
                                onChange={e => this.setState({ userName: e.target.value })}
                            />
                        </FormItem>

                        <FormItem
                            label="Email address"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                placeholder="e.g. user@company.com"
                                value={this.state.userEmail}
                                onChange={e => this.setState({ userEmail: e.target.value })}
                            />
                        </FormItem>

                        {
                            user.userRole !== ROLE_TYPE.FLEET_OWNER
                            && user.userRole !== ROLE_TYPE.FLEET_OPERATOR &&
                            <FormItem
                                label="User Role"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Select
                                    defaultValue={this.state.userRole}
                                    value={this.state.userRole}
                                    placeholder="User Role"
                                    onChange={value => this.setState({ userRole: value })}
                                >
                                    {this.state.userRoleOptions.map(o => <Option key={o} value={o}>{DISPLAY_USER_ROLE(o)}</Option>)}
                                </Select>
                            </FormItem>
                        }

                        <FormItem
                            label="Contact Number"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                addonBefore={
                                    <Select
                                        value={this.state.userPhonePrefix}
                                        onChange={e => this.setState({ userPhonePrefix: e.target.value })}
                                    >
                                        {
                                            this.state.phonePrefixOptions.map((prefix, i) => (
                                                <Option key={i} value={prefix}>{prefix}</Option>)
                                            )
                                        }
                                    </Select>
                                }
                                placeholder="123456789"
                                value={this.state.userContact}
                                onChange={e => this.setState({ userContact: e.target.value })}
                            />
                        </FormItem>

                        <FormItem
                            label="Office Address"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                placeholder="Office Address"
                                value={this.state.userAddress}
                                onChange={e => this.setState({ userAddress: e.target.value })}
                            />
                        </FormItem>

                        <FormItem
                            label=' '
                            colon={false}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Button
                                icon={<LockOutlined />}
                                type="danger"
                                onClick={() => {
                                    let newPassword = ``
                                    let oldPassword = ``
                                    Modal.confirm({
                                        title: 'Reset Password',
                                        icon: <LockOutlined />,
                                        content: (
                                            <div>
                                                <small>Reset password for {this.state.userName}</small>
                                                <Input.Password
                                                    placeholder="Enter current password"
                                                    onChange={e => oldPassword = e.target.value}
                                                />
                                                <Input.Password
                                                    placeholder="Enter new password"
                                                    onChange={e => newPassword = e.target.value}
                                                />
                                            </div>
                                        ),
                                        okText: `Reset password`,
                                        onOk: () => {
                                            const userEmail = this.state.userEmail
                                            // console.log({
                                            //     userEmail,
                                            //     oldPassword,
                                            //     newPassword
                                            // })

                                            resetPassword(userEmail, oldPassword, newPassword)
                                                .then(resp => {
                                                    // console.log(resp)
                                                    message.success(`Reset password success`)
                                                })
                                                .catch(err => {
                                                    message.error(err.message)
                                                })
                                        },
                                        onCancel: () => {
                                            // console.log('Cancel');
                                        },
                                    });
                                }}
                            >
                                Reset password
                            </Button>
                        </FormItem>

                        <div style={{ position: "absolute", right: "100px" }}>
                            <Button
                                type="danger"
                                style={{ marginLeft: "22px" }}
                                className="login-form-button"
                                onClick={() => this.props.dispatch(goBackToPrev())}
                            >
                                Cancel
                            </Button>

                            <PrimaryButton
                                onClick={this.submitForm}
                                loading={this.props.style.isLoadingSubmit && this.state.isLoading}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </Form>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    hierarchy: state.v2.hierarchy,
});

const ConnectedAddCustomer = connect(mapStateToProps)(EditCustomer);
export default showSecondarySidebar(false)(ConnectedAddCustomer);