import * as actionType from '../action-types/migration';

const defaultState = {
    byId: {}
};

export const migration = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actionType.GET_STAGES: {
            action.users.map((data) => {
                const {
                    userStage = {},
                    user = {}
                } = data
                if (!newState.byId.hasOwnProperty(user.uid)) {
                    newState.byId[user.uid] = {
                        ...user
                    }
                }
                newState.byId[user.uid][userStage.stage] = userStage.createdAt
                return null;
            })

            return newState;
        }

        case actionType.MIGRATE_USER: {
            newState.byId[action.uid][action.targetStage] = action.migratedAt
            
            return newState;
        }

        case actionType.SET_USER_TO_MIGRATE: {
            newState.selectedUser = action.selectedUser

            return newState;
        }

        default: {
            return state;
        }
    }
}