import React, { Component } from "react";
import { connect } from "react-redux";

import AddRecipient from "./RecipientForm";
import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    NOTIFICATION_TYPE,
    DISPLAY_NOTIFICATION_TYPE
} from "../../../../../constants";
import {
    Form,
    Input,
    Button,
    Select,
    message,
    Checkbox,
    TreeSelect,
} from "antd";

// Redux Actions
import { edit_notification } from "../../../services/redux/actions/notifications"

const FormItem = Form.Item;
const {
    TreeNode,
    SHOW_PARENT
} = TreeSelect;

const emptyRecipient = {
    // name: "",
    // email: "",
    // contact: "",
}

class EditNotificationPage extends Component {
    state = {
        selectedNfID: "", // Just to easily get selected notification when submitting

        notificationName: "",
        notificationType: "",
        isKeypadEnabled: false,
        selectedRuleSettings: [],
        selectedGeofenceSettings: [],
        recipientList: [
            emptyRecipient
        ]
    }

    keySeperator = " - ";

    // Rule Template Tree Select Keys
    rtidKey = "RTID - ";
    ruidKey = "RuID - ";
    compliedKey = "Complied - ";
    violatedKey = "Violated - ";

    onChangeRuleSettings = value => {
        // console.log("On Change Rule Settings Value:", value);

        this.setState({
            ...this.state,
            selectedRuleSettings: value
        });
    };

    onChangeGeofenceSettings = value => {
        // console.log("On Change Geofence Settings Value:", value);

        this.setState({
            ...this.state,
            selectedGeofenceSettings: value
        });
    }

    addNewRecipient = () => {
        const newRecipientList = [...this.state.recipientList, {}];

        this.setState({
            ...this.state,
            recipientList: newRecipientList
        });
    }

    resetRecipient = () => {
        this.setState({
            ...this.state,
            recipientList: [emptyRecipient]
        });
    }

    updateRecipientUid = (uid, index) => {
        // console.log({ uid, index });

        let newRecipientList = [...this.state.recipientList];
        newRecipientList[index].uid = uid;

        // console.table(newRecipientList)

        this.setState({
            ...this.state,
            recipientList: newRecipientList,
        });
    }

    updateRecipientName = (newName, index) => {
        let newRecipientList = [...this.state.recipientList];
        newRecipientList[index].name = newName;

        this.setState({
            ...this.state,
            recipientList: newRecipientList,
        });
    }

    updateRecipientAuth = (newBool, index) => {
        let newRecipientList = [...this.state.recipientList];
        newRecipientList[index].isKeypadAuthorized = newBool ? 1 : 0;

        this.setState({
            ...this.state,
            recipientList: newRecipientList,
        });
    }

    updateRecipientEmail = (newEmail, index) => {
        let newRecipientList = [...this.state.recipientList];
        newRecipientList[index].email = newEmail;

        this.setState({
            ...this.state,
            recipientList: newRecipientList,
        });
    }

    updateRecipientContact = (newContact, index) => {
        let newRecipientList = [...this.state.recipientList];
        newRecipientList[index].contact = newContact;

        this.setState({
            ...this.state,
            recipientList: newRecipientList,
        });
    }

    updateRecipientKeypadEnabled = (newBool) => {
        this.setState({
            ...this.state,
            isKeypadEnabled: newBool ? 1 : 0,
        });
    }

    deleteRecipient = (index) => {
        const newRecipeintList = [...this.state.recipientList];

        newRecipeintList.splice(index, 1); // Delete specific element

        this.setState({
            ...this.state,
            recipientList: newRecipeintList
        });
    }

    setUpNewGeofences = () => {
        const {
            geofences,
            geofenceTemplate,
        } = this.props;

        // console.log("Selected Geofence Settings:", this.state.selectedGeofenceSettings);

        let newGeofences = {};

        this.state.selectedGeofenceSettings.forEach((selectedKey) => {
            if (selectedKey.includes(this.keySeperator)) {
                const keySplit = selectedKey.includes(this.keySeperator) ? selectedKey.split(this.keySeperator) : selectedKey;

                Object.keys(geofenceTemplate.byId).forEach((currGTID) => {
                    if (currGTID === keySplit[0]) {
                        Object.keys(geofences.byId).forEach((currGeoID) => {
                            if (currGeoID === keySplit[1]) {
                                if (!newGeofences[currGTID]) {
                                    newGeofences[currGTID] = [];
                                }

                                newGeofences[currGTID].push(currGeoID);
                            }
                        })
                    }
                })
            }
            else {
                newGeofences[selectedKey] = geofenceTemplate.byId[selectedKey].geofences;
            }
        })

        // console.log("New Geofences:", newGeofences);

        return newGeofences;
    }

    setUpNewRules = () => {
        const { ruleTemplate } = this.props;

        // console.log("Selected Rule Settings:", this.state.selectedRuleSettings);

        let newRules = {};

        this.state.selectedRuleSettings.forEach((selectedKey) => {
            if (ruleTemplate.byId[selectedKey] && ruleTemplate.byId[selectedKey].rules) {
                ruleTemplate.byId[selectedKey].rules.forEach((currRuID) => {
                    if (!newRules[selectedKey]) {
                        newRules[selectedKey] = {};
                    }

                    newRules[selectedKey][currRuID] = {
                        c: 1,
                        v: 1
                    };
                });
            }
            else {
                Object.keys(ruleTemplate.byId).forEach((currRTID) => {
                    const selectedRuID = selectedKey.replace(this.compliedKey, "").replace(this.violatedKey, "").replace(this.ruidKey, "");

                    // console.log("Selected RuID:", selectedRuID, ruleTemplate.byId[currRTID].rules.includes(selectedRuID));

                    if (ruleTemplate.byId[currRTID].rules.includes(selectedRuID)) {
                        const returnNewRules = () => {
                            if (selectedKey.includes(this.ruidKey)) {
                                return {
                                    c: 1,
                                    v: 1,
                                };
                            }
                            else if (selectedKey.includes(this.compliedKey)) {
                                return {
                                    ...newRules[currRTID][selectedRuID],
                                    c: selectedKey.includes(this.compliedKey) ? 1 : 0,
                                };
                            }
                            else if (selectedKey.includes(this.violatedKey)) {
                                return {
                                    ...newRules[currRTID][selectedRuID],
                                    v: selectedKey.includes(this.violatedKey) ? 1 : 0,
                                };
                            }
                            else {
                                return newRules[currRTID][selectedRuID];
                            }
                        }

                        if (!newRules[currRTID]) {
                            newRules[currRTID] = {};
                        }

                        if (!newRules[currRTID][selectedRuID]) {
                            newRules[currRTID][selectedRuID] = {
                                c: 0,
                                v: 0
                            };
                        }

                        newRules[currRTID][selectedRuID] = returnNewRules();
                    }
                })
            }
        })

        return newRules;
    }

    submitForm = () => {
        this.setState({ isLoading: true });

        const {
            recipientList,
            isKeypadEnabled,
            notificationName,
            notificationType,
            selectedRuleSettings,
        } = this.state;

        let isValid = true;

        if (!this.props.user.uid) {
            message.error("Current User ID Not Found");

            isValid = false;
        }

        if (!notificationName) {
            message.error("Please Enter The Notification's Name");

            isValid = false;
        }

        if (!Object.keys(DISPLAY_NOTIFICATION_TYPE).includes(notificationType.toString())) {
            message.error("Please Enter The Notification's Type");

            isValid = false;
        }

        if (selectedRuleSettings.length < 1) {
            message.error("Please Select Which Rule You Want To Be Notified Of");

            isValid = false;
        }

        if (recipientList.filter((currRecipient) => currRecipient && currRecipient).length < 1) {
            message.error("Please Enter At Least 1 Recipient For This Notification");

            isValid = false;
        }
        else {
            if (isKeypadEnabled && !recipientList.filter((currRecipient) => currRecipient.isKeypadAuthorized).length) {
                message.error("Please Authorize The Keypad For At Least 1 Recipient");
    
                isValid = false;
            }
        }

        if (isValid) {
            const returnFormattedRecipientList = () => {
                if (isKeypadEnabled) {
                    return (
                        recipientList.filter((currRecipient) => !currRecipient.isKeypadAuthorized).length ?
                            recipientList :
                            recipientList.map((currRecipient) => ({
                                ...currRecipient,
                                isKeypadAuthorized: true,
                            }))
                    )
                }

                return recipientList;
            }

            const editedNotification = {
                ...this.props.notifications.byNfID[this.state.selectedNfID],
                notificationName: notificationName,
                recipient: returnFormattedRecipientList(),
                serviceType: notificationType,
                rules: this.setUpNewRules(),
                geofences: this.setUpNewGeofences(),
                isKeypadEnabled: isKeypadEnabled,
            }
            
            // console.log("Edited Notification:", editedNotification);
            
            this.props.dispatch(edit_notification(editedNotification));
        }
    }

    setUpState = () => {
        const nfid = `nfid${this.props.router.location.hash}`;
        const selectedNotification = this.props.notifications.byNfID[nfid];

        // console.log({ nfid, selectedNotification });

        if (!selectedNotification) return

        const { geofenceTemplate }  = this.props;

        // Set up rules settings
        //--------------------------------------------------------------------------------------------------------------------
        let newRuleSettings = [];

        Object.keys(selectedNotification.rules).forEach((currRTID) => {
            Object.keys(selectedNotification.rules[currRTID]).forEach((currRuID) => {
                if (selectedNotification.rules[currRTID][currRuID].c === 1) {
                    newRuleSettings.push(this.compliedKey + currRuID);
                }

                if (selectedNotification.rules[currRTID][currRuID].v === 1) {
                    newRuleSettings.push(this.violatedKey + currRuID);
                }
            })
        })

        // console.log("New Rule Settings:", newRuleSettings);
        //--------------------------------------------------------------------------------------------------------------------

        // Set up geofence settings
        //--------------------------------------------------------------------------------------------------------------------
        let newGeofenceSettings = [];

        // Object.keys(selectedNotification.geofences).forEach((currGTID) => {
        //     selectedNotification.geofences[currGTID].forEach((currGeoID) => {
        //         newGeofenceSettings.push(currGTID + this.keySeperator + currGeoID);
        //         // newGeofenceSettings.push(currGeoID);
        //     })
        // })

        Object.keys(selectedNotification.geofences).forEach((currGTID) => {
            // If notification geofence template object has same geoid list length as corresponding geofence template, assume the object is the entire geofence template
            // Possible bug if somehow the geofence template object has same length but incorrect data in geoid list due to duplicates or something else 
            if (geofenceTemplate.byId[currGTID]
                && geofenceTemplate.byId[currGTID].geofences
                && geofenceTemplate.byId[currGTID].geofences.length === selectedNotification.geofences[currGTID].length) 
            {
                newGeofenceSettings.push(currGTID);
            }
            else {
                selectedNotification.geofences[currGTID].forEach((currGeoID) => {
                    newGeofenceSettings.push(currGTID + this.keySeperator + currGeoID);
                })
            }
        })

        // console.log("New Geofence Settings:", newGeofenceSettings);
        //--------------------------------------------------------------------------------------------------------------------

        this.setState({
            ...this.state,
            selectedNfID: nfid,

            recipientList: selectedNotification.recipient,
            notificationType: selectedNotification.serviceType,
            isKeypadEnabled: selectedNotification.isKeypadEnabled,
            notificationName: selectedNotification.notificationName,
            
            selectedRuleSettings: newRuleSettings,
            selectedGeofenceSettings: newGeofenceSettings,
        })
    }

    componentDidMount = () => {
        this.setUpState()
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.notifications) !== JSON.stringify(this.props.notifications)) {
            this.setUpState()
        }
    }

    render() {
        const {
            rules,
            ruleTemplate,
            geofences,
            geofenceTemplate,
        } = this.props;

        window.state = this.state;

        const defaultGT = geofenceTemplate.byName["UNGROUP"];
        const defaultRT = Object.values(ruleTemplate.byId).find((currRT) => currRT.templateName === "DEFAULT");

        const returnRuleTreeNodes = (currRuID) => {
            const currRule = rules.byRuID[currRuID];

            return (
                <TreeNode
                    key = {this.ruidKey + currRuID}
                    value = {this.ruidKey + currRuID}
                    title = {currRule.ruleName}
                >
                    <TreeNode
                        key = {this.compliedKey + currRuID}
                        value = {this.compliedKey + currRuID}
                        title = {currRule.ruleName + " - " + this.compliedKey + currRule.ruleCompliedName}
                    />

                    <TreeNode
                        key = {this.violatedKey + currRuID}
                        value = {this.violatedKey + currRuID}
                        title = {currRule.ruleName + " - " + this.violatedKey + currRule.ruleViolatedName}
                    />
                </TreeNode>
            )
        }
        
        return (
            <div className = "page-container">
                <Page title = "Edit Notification">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Notification Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Notification Name"
                                    value = {this.state.notificationName}
                                    onFocus = {e => e.target.select()}
                                    onChange = {e => this.setState({ notificationName: e.target.value })}
                                />
                            </FormItem>

                            <FormItem
                                label = "Notification Type"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                help = {
                                    this.state.notificationType === NOTIFICATION_TYPE.CALL ? (
                                        `Calling would be done in sequential order. E.g. 1st call to Recipient 1, 2nd call to Recipient 2, etc`
                                    ) : ``
                                }
                            >
                                <Select
                                    placeholder = "Select a notification type"
                                    value = {this.state.notificationType !== "" ? this.state.notificationType : undefined}
                                    onChange = {(value) => {
                                        // Reset recipients if notification type is changed
                                        if (this.state.notificationType !== value) {
                                            this.resetRecipient()
                                        }

                                        this.setState({ 
                                            notificationType: value,
                                        })
                                    }}
                                >
                                    {
                                        Object.keys(NOTIFICATION_TYPE).map((currType) => {
                                            // console.log("Current Type:", currType);

                                            return (
                                                <Select.Option key = {currType} value = {NOTIFICATION_TYPE[currType]}>
                                                    {DISPLAY_NOTIFICATION_TYPE[NOTIFICATION_TYPE[currType]]}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </FormItem>
                            
                            <FormItem
                                label = "Is Keypad Enabled?"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Checkbox 
                                    checked = {this.state.isKeypadEnabled ? true : false}
                                    onChange = {(e) => {
                                        this.updateRecipientKeypadEnabled(e.target.checked);
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label = "Rule Engine"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    rules.allRuIDs.length && ruleTemplate.allIds.length ?
                                        <TreeSelect
                                            treeCheckable = {true}
                                            showCheckedStrategy = {SHOW_PARENT}
                                            placeholder = 'Please Select A Rule Template'
                                            value = {this.state.selectedRuleSettings}
                                            onChange = {this.onChangeRuleSettings}
                                        >
                                            {/* Default Rule Template Option */}
                                            <TreeNode
                                                key = {defaultRT.rtid}
                                                value = {defaultRT.rtid}
                                                title = {defaultRT.templateName}
                                            >
                                                {
                                                    defaultRT.rules
                                                        .filter((currRuID) => rules.byRuID[currRuID])
                                                        .map((currRuID) => returnRuleTreeNodes(currRuID))
                                                }
                                            </TreeNode>

                                            {/* Other Rule Template Options */}
                                            {
                                                Object.values(ruleTemplate.byId)
                                                    .filter((currRT) => currRT.rtid !== defaultRT.rtid) // Filter out DEFAULT geofence template
                                                    .filter((currRT) => currRT.rtid && currRT.rules.length)
                                                    .map((currRT) => {
                                                        return (
                                                            <TreeNode
                                                                key = {currRT.rtid}
                                                                value = {currRT.rtid}
                                                                title = {ruleTemplate.byId[currRT.rtid].templateName}
                                                            >
                                                                {
                                                                    currRT.rules
                                                                        .filter((currRuID) => rules.byRuID[currRuID])
                                                                        .map((currRuID) => returnRuleTreeNodes(currRuID))
                                                                }
                                                            </TreeNode>
                                                        );
                                                    })
                                            }
                                        </TreeSelect> :
                                        <Input disabled = {true} placeholder = "No Rule Templates Found"/>
                                }
                            </FormItem>

                            <FormItem
                                label = "Geofences"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    geofences.allIds.length && geofenceTemplate.allIds.length ?
                                        <TreeSelect
                                            treeCheckable = {true}
                                            showCheckedStrategy = {SHOW_PARENT}
                                            placeholder = 'Please Select A Geofence Template (Optional)'
                                            value = {this.state.selectedGeofenceSettings}
                                            onChange = {this.onChangeGeofenceSettings}
                                        >
                                            {/* Default Geofence Template Option */}
                                            <TreeNode
                                                key = {defaultGT.gtid}
                                                value = {defaultGT.gtid}
                                                title = {defaultGT.templateName}
                                            >
                                                {
                                                    defaultGT.geofences
                                                        .filter((currGeoID) => geofences.byId[currGeoID])
                                                        .sort((a, b) => {
                                                            const A = geofences.byId[a].geofenceName.toLowerCase();
                                                            const B = geofences.byId[b].geofenceName.toLowerCase();

                                                            if (A < B) return -1;
                                                            if (A > B) return 1;

                                                            return 0;
                                                        })
                                                        .map((currGeoID) => {
                                                            return (
                                                                <TreeNode
                                                                    key = {defaultGT.gtid + this.keySeperator + currGeoID}
                                                                    value = {defaultGT.gtid + this.keySeperator + currGeoID}
                                                                    title = {geofences.byId[currGeoID].geofenceName}
                                                                />
                                                            )
                                                        })
                                                }
                                            </TreeNode>

                                            {/* Other Geofence Template Options */}
                                            {
                                                Object.values(geofenceTemplate.byId)
                                                    .filter((currGT) => currGT.gtid !== defaultGT.gtid) // Filter out UNGROUP geofence template
                                                    .filter((currGT) => currGT.gtid && currGT.geofences.length)
                                                    .map((currGT) => {
                                                        return (
                                                            <TreeNode
                                                                key = {currGT.gtid}
                                                                value = {currGT.gtid}
                                                                title = {currGT.templateName}
                                                            >
                                                                {
                                                                    currGT.geofences
                                                                        .filter((currGeoID) => geofences.byId[currGeoID])
                                                                        .sort((a, b) => {
                                                                            const A = geofences.byId[a].geofenceName.toLowerCase();
                                                                            const B = geofences.byId[b].geofenceName.toLowerCase();

                                                                            if (A < B) return -1;
                                                                            if (A > B) return 1;

                                                                            return 0;
                                                                        })
                                                                        .map((currGeoID) => {
                                                                            return (
                                                                                <TreeNode
                                                                                    key = {currGT.gtid + this.keySeperator + currGeoID}
                                                                                    value = {currGT.gtid + this.keySeperator + currGeoID}
                                                                                    title = {geofences.byId[currGeoID].geofenceName}
                                                                                />
                                                                            );
                                                                        })
                                                                }
                                                            </TreeNode>
                                                        );
                                                    })
                                            }
                                        </TreeSelect> :
                                        <Input disabled = {true} placeholder = "No Geofence Templates Found" />
                                }
                            </FormItem>

                            {
                                this.state.recipientList.map((recipient, index) =>
                                    <AddRecipient
                                        type = {this.state.notificationType}
                                        key = {index}
                                        index = {index}
                                        recipient = {recipient}
                                        deleteRecipient = {(index) => this.deleteRecipient(index)}
                                        updateRecipientUid = {uid => this.updateRecipientUid(uid, index)}
                                        updateRecipientName = {(newName) => this.updateRecipientName(newName, index)}
                                        updateRecipientAuth = {(newBool) => this.updateRecipientAuth(newBool, index)}
                                        updateRecipientEmail = {(newEmail) => this.updateRecipientEmail(newEmail, index)}
                                        updateRecipientContact = {(newContact) => this.updateRecipientContact(newContact, index)}
                                    />
                                )
                            }

                            {
                                Object.values(NOTIFICATION_TYPE).includes(this.state.notificationType) &&
                                    <div
                                        style = {{
                                            flex: 1,

                                            display: "flex",
                                            justifyContent: "center",

                                            marginBottom: 25,
                                        }}
                                    >
                                        <PrimaryButton onClick = {this.addNewRecipient}>{"Add Recipient"}</PrimaryButton>
                                    </div>
                            }

                            <div
                                style = {{
                                    padding: 5,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 10
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    onClick = {this.submitForm}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    rules: state.v2.rules,
    geofences: state.v2.geofences,
    ruleTemplate: state.v2.ruleTemplate,
    notifications: state.v2.notifications,
    geofenceTemplate: state.v2.geofenceTemplate,
});

const ConnectedEditNotification = connect(mapStateToProps)(EditNotificationPage);
export default showSecondarySidebar(false)(ConnectedEditNotification)
