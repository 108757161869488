import * as API from "../../api";
import * as ActionTypes from "../action-types/vehiclePackage";

import { message } from "antd";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
  setLoadingSubmit,
  unsetLoadingSubmit,
} from './style';


export const get_vehicle_packages = (vids) => dispatch => {
  dispatch(setLoadingSubmit());

  API.getVehiclePackages(vids)
    .then(data => {

      const {
        status,
        vehiclePackage: vehiclePackages = []
      } = data

      if (status !== 200) {
        message.error(data.message || 'Error in getting vehicle packages')
      }
      else {

        dispatch(get_vehicle_packages_success(vehiclePackages))
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const get_vehicle_packages_success = (vehiclePackages) => {
  return {
    type: ActionTypes.GET_VEHICLE_PACKAGES,
    vehiclePackages
  }
}

export const add_vehicle_package = (vid, uid, startAt, endAt, contractType) => dispatch => {
  dispatch(setLoadingSubmit());

  API.addVehiclePackage(vid, uid, startAt, endAt, contractType)
    .then(data => {

      if (data.status !== 200) {
        message.error(data.message || 'Error in adding vehicle package')
      }
      else {

        dispatch(add_vehicle_package_success(data.vehiclePackage))
        dispatch(goBackToPrev());
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const add_vehicle_package_success = (vehiclePackage) => {
  return {
    type: ActionTypes.ADD_VEHICLE_PACKAGE,
    vehiclePackage
  }
}

export const edit_vehicle_package = (vehiclePackage) => dispatch => {
  dispatch(setLoadingSubmit());

  API.editVehiclePackage(vehiclePackage)
    .then(data => {

      if (data.status !== 200) {
        message.error(data.message || 'Error in editing vehicle package')
      }
      else {
        message.success('Edited package')

        dispatch(edit_vehicle_package_success(data.vehiclePackage))
        dispatch(goBackToPrev());
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const edit_vehicle_package_success = (vehiclePackage) => {
  return {
    type: ActionTypes.EDIT_VEHICLE_PACKAGE,
    vehiclePackage
  }
}


export const renew_vehicle_package = (renewParams) => dispatch => {
  dispatch(setLoadingSubmit());

  API.renewVehiclePackage(renewParams)
    .then(data => {

      if (data.status !== 200) {
        message.error(data.message || 'Error in renewing vehicle package')
      }
      else {
        message.success('Renewed vehicle')

        dispatch(renew_vehicle_package_success(data.vehiclePackage))
        dispatch(goBackToPrev());
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const renew_vehicle_package_success = (vehiclePackage) => {
  return {
    type: ActionTypes.EDIT_VEHICLE_PACKAGE,
    vehiclePackage
  }
}


export const suspend_vehicle_package = (vid) => dispatch => {
  dispatch(setLoadingSubmit());

  API.suspendVehiclePackage(vid)
    .then(data => {

      if (data.status !== 200) {
        message.error(data.message || 'Error in suspending vehicle package')
      }
      else {
        message.success('Suspended vehicle')
        dispatch(suspend_vehicle_package_success(data.vehiclePackage))
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const suspend_vehicle_package_success = (vehiclePackage) => {
  return {
    type: ActionTypes.EDIT_VEHICLE_PACKAGE,
    vehiclePackage
  }
}


export const unsuspend_vehicle_package = (vid) => dispatch => {
  dispatch(setLoadingSubmit());

  API.unsuspendVehiclePackage(vid)
    .then(data => {

      if (data.status !== 200) {
        message.error(data.message || 'Error in unsuspending vehicle package')
      }
      else {

        message.success('Unsuspended vehicle')
        dispatch(unsuspend_vehicle_package_success(data.vehiclePackage))
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const unsuspend_vehicle_package_success = (vehiclePackage) => {
  return {
    type: ActionTypes.EDIT_VEHICLE_PACKAGE,
    vehiclePackage
  }
}



export const terminate_vehicle_package = (vid) => dispatch => {
  dispatch(setLoadingSubmit());

  API.terminateVehiclePackage(vid)
    .then(data => {

      if (data.status !== 200) {
        message.error(data.message || 'Error in terminateing vehicle package')
      }
      else {

        message.success('Terminateed vehicle')
        dispatch(terminate_vehicle_package_success(data.vehiclePackage))
      }
    })
    .finally(() => {
      dispatch(unsetLoadingSubmit());
    })
}

export const terminate_vehicle_package_success = (vehiclePackage) => {
  return {
    type: ActionTypes.EDIT_VEHICLE_PACKAGE,
    vehiclePackage
  }
}
