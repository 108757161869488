import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../components/Page";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../components/Button";
import {
    goBackToPrev,
} from "../../navigation/navigationService";
import {
    VEHICLE_PACKAGE_CONTRACT_TYPES,
    VEHICLE_PACKAGE_STATUSES,
} from '../../../../constants';
import {
    Form,
    Button,
    Select,
    message,
    DatePicker,
} from "antd";
import moment from 'moment'

// Redux Actions
import { get_user_hierarchy } from '../../services/redux/actions/hierarchy';
import { parseDate } from '../../util/time';
import { get_vehicle_packages, renew_vehicle_package } from '../../services/redux/actions/vehiclePackage';

const FormItem = Form.Item;
const { Option } = Select;

const FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES = {
    [VEHICLE_PACKAGE_CONTRACT_TYPES.CUSTOM]: {
        label: 'Custom',
        value: VEHICLE_PACKAGE_CONTRACT_TYPES.CUSTOM,
        durationInMonths: null
    },
    [VEHICLE_PACKAGE_CONTRACT_TYPES.TWELVE]: {
        label: '12 months',
        value: VEHICLE_PACKAGE_CONTRACT_TYPES.TWELVE,
        durationInMonths: 12
    },
    [VEHICLE_PACKAGE_CONTRACT_TYPES.TWENTY_FOUR]: {
        label: '24 months',
        value: VEHICLE_PACKAGE_CONTRACT_TYPES.TWENTY_FOUR,
        durationInMonths: 24
    }
}

class RenewVehiclePackagePage extends Component {
    state = {}

    submitForm = () => {
        this.setState({ isLoading: true });

        const {
            vid,
            contractType,
            startAt,
            endAt,
        } = this.state;

        try {
            if (!contractType) throw new Error(`Please select contract type`);

            
            const startAtEpoch = startAt && startAt.valueOf()
            const endAtEpoch = endAt && endAt.valueOf()

            const renewParams = {
                vid, 
                startAt: startAtEpoch,
                endAt: endAtEpoch,
                contractType,
            }

            console.log(renewParams)

            this.props.dispatch(renew_vehicle_package(renewParams))

        }
        catch (err) {
            message.error(err.message)
        }

    }


    setUpFormDefaultValues = () => {
        const { vehiclePackageId } = this.props.router.location.state || {};

        const vehiclePackage = this.props.vehiclePackage.byId[vehiclePackageId]

        if (!vehiclePackage) return

        const newStartAt =  moment(vehiclePackage.endAt)

        this.setState({
            uid: vehiclePackage.uid,
            vid: vehiclePackage.vid,
            startAt: newStartAt,
            endAt: this.getEndAt(vehiclePackage.contractType, newStartAt, undefined),
            contractType: vehiclePackage.contractType,
            status: vehiclePackage.status,
        })
    }



    componentDidMount = () => {
        this.setUpFormDefaultValues()
    }

    componentDidUpdate = prevProps => {

        /**Get vehicle subscription plan */
        if (JSON.stringify(this.props.vehicles) !== JSON.stringify(prevProps.vehicles)) {

            const vids = Object.keys(this.props.vehicles.byId)
            this.props.dispatch(get_vehicle_packages(vids));
        }

        /**Get user hierarchy */
        if (JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user)) {
            this.props.user && this.props.dispatch(get_user_hierarchy(this.props.user.uid))
        }
        if (
            JSON.stringify(this.props.router.location) !== JSON.stringify(prevProps.router.location)
            || JSON.stringify(this.props.vehiclePackage) !== JSON.stringify(prevProps.vehiclePackage)
        ) {

            this.setUpFormDefaultValues()
        }
    }

    getEndAt = (contractType, startAt = moment(), endAt) => {
        switch (contractType) {
            case VEHICLE_PACKAGE_CONTRACT_TYPES.TWELVE:
            case VEHICLE_PACKAGE_CONTRACT_TYPES.TWENTY_FOUR:
                const vehiclePackage = FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES[contractType]
                const durationInMonths = vehiclePackage && vehiclePackage.durationInMonths

                if (durationInMonths && startAt) {
                    console.log({startAt})
                    /**Duplicate is required because .add mutates value */
                    const _startAt = startAt.clone()
                    console.log({ durationInMonths, _startAt: _startAt.format('LLLL') })

                    return _startAt.add(durationInMonths, 'months')
                } else {
                    return undefined
                }
            default:
                if (!startAt) {
                    return undefined
                }
                return endAt
        }
    }

    getStatus = () => {
        const {
            startAt,
            endAt,
        } = this.state

        const now = moment.now()
        const startAtEpoch = startAt && startAt.valueOf()
        const endAtEpoch = endAt && endAt.valueOf()

        if (!startAtEpoch || !endAtEpoch) return undefined

        if (startAtEpoch > now) return <p style={{ color: 'blue', margin: 'auto' }}>{VEHICLE_PACKAGE_STATUSES.PENDING}</p>
        if (startAtEpoch <= now && endAtEpoch > now) return <p style={{ color: 'green', margin: 'auto' }}>{VEHICLE_PACKAGE_STATUSES.ACTIVE}</p>
        if (endAtEpoch <= now) return <p style={{ color: 'grey', margin: 'auto' }}>{VEHICLE_PACKAGE_STATUSES.EXPIRED}</p>
    }

    render() {

        const { vehiclePackageId } = this.props.router.location.state || {};

        const vehiclePackage = this.props.vehiclePackage.byId[vehiclePackageId]

        const { uid, vid } = this.state;

        const user = this.props.hierarchy.byId[uid]
        const vehicle = this.props.vehicles.byId[vid]
        const device = vehicle && this.props.devices.byId[vehicle.dvid]

        console.log({user, vehicle, device, vehiclePackage})

        return (
            <div className="page-container">
                <Page title="Renew Vehicle Subscription Plan">

                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">
                            <FormItem
                                label="User"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                {user && user.userEmail}
                            </FormItem>

                            <FormItem
                                label="Vehicle"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                {vehicle && vehicle.vehiclePlate}
                            </FormItem>

                            <FormItem
                                label="Device IMEI"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                {device && device.dvid && device.dvid.split('#')[1]}
                            </FormItem>

                            <FormItem
                                label="Contract Type"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                <Select
                                    placeholder="Select a contract type"
                                    value={this.state.contractType}
                                    onChange={contractType => {

                                        switch (contractType) {
                                            case VEHICLE_PACKAGE_CONTRACT_TYPES.TWELVE:
                                            case VEHICLE_PACKAGE_CONTRACT_TYPES.TWENTY_FOUR:
                                                const vehiclePackage = FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES[contractType]
                                                const durationInMonths = vehiclePackage && vehiclePackage.durationInMonths
                                                if (durationInMonths && this.state.startAt) {
                                                    // duplicate is required because .add mutates value
                                                    const startAt = this.state.startAt.clone()
                                                    console.log({ durationInMonths, startAt: startAt.format('LLLL') })

                                                    this.setState({
                                                        endAt: startAt.add(durationInMonths, 'months')
                                                    })
                                                }
                                                break
                                            default:
                                                break

                                        }

                                        this.setState({
                                            contractType: contractType
                                        }, () => {

                                        })

                                    }}
                                >
                                    {
                                        Object.values(FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES).map((contractType, i) => {
                                            return (
                                                <Option value={contractType.value} key={i}>
                                                    {contractType.label}
                                                </Option>
                                            );
                                        })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label="Start At"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                help={vehiclePackage && vehiclePackage.endAt && `Current package ends at ${parseDate(vehiclePackage.endAt)}`}
                            >
                                <DatePicker
                                    value={this.state.startAt}
                                    onChange={startAt => {
                                        this.setState({
                                            startAt,
                                            endAt: this.getEndAt(this.state.contractType, startAt, this.state.endAt)
                                        })
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label="End At"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                {
                                    this.state.contractType === VEHICLE_PACKAGE_CONTRACT_TYPES.CUSTOM ? (
                                        <DatePicker
                                            value={this.state.endAt}
                                            onChange={endAt => this.setState({ endAt })}
                                            disabledDate={date => {
                                                if (!this.state.startAt || !date) return false
                                                const isDisabled = date.valueOf() < this.state.startAt.valueOf()
                                                return isDisabled
                                            }}
                                        />
                                    ) : (
                                            this.state.endAt && parseDate(this.state.endAt.valueOf())
                                        )
                                }
                            </FormItem>

                            <FormItem
                                label="Status"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                {
                                    this.getStatus()
                                }
                            </FormItem>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    onClick={this.submitForm}
                                    loading={this.props.style.isLoadingSubmit && this.state.isLoading}
                                    style={{ marginLeft: 10 }}
                                >
                                    Renew
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    hierarchy: state.v2.hierarchy,
    ruleTemplate: state.v2.ruleTemplate,
    vehicleGroups: state.v2.vehicleGroups,
    geofenceTemplate: state.v2.geofenceTemplate,
    vehiclePackage: state.v2.vehiclePackage,
});

const ConnectedAddCustomerVehicle = connect(mapStateToProps)(RenewVehiclePackagePage);
export default showSecondarySidebar(false)(ConnectedAddCustomerVehicle);