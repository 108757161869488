import React, { useState, useEffect } from 'react';
import {
    connect,
    useDispatch,
    useSelector,
} from 'react-redux';

import MapSearchBar from '../MapSearchBar';
import MapControlPanel from '../MapControlPanel';
import POIGeofences from "./components/POIGeofences";
import TripRecordRoute from './components/TripRecordRoute';
import PolygonGeofences from "./components/PolygonGeofences";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

// Icons
import info from '../../../../img/Trip Record/info.svg';
import warning from '../../../../img/Trip Record/warning.svg';
import critical from '../../../../img/Trip Record/critical.svg';

import { CloseOutlined } from '@ant-design/icons';
import { moveToPage } from '../../navigation/navigationService';
import { 
    parseTime, 
    parseDuration, 
} from '../../util/time';
import {
    GoogleMap,
    TrafficLayer
} from '@react-google-maps/api';
import {
    Select,
    message,
    Timeline,
} from "antd";
import { 
    LOCATION, 
    EVENT_TYPES, 
    VEHICLE_COLOR, 
    VEHICLE_STATUS, 
} from '../../../../constants';

// Redux Actions
import { set_map_control } from '../../services/redux/actions/mapControl';
import { clear_selected_device } from "../../services/redux/actions/devices.js";
import { clear_selected_tsid } from "../../services/redux/actions/transitRecord.js";
import { clear_selected_geofence_geoid } from '../../services/redux/actions/geofences';
import {
    clear_enabled_vehicle_pop_ups,
    clear_enabled_geofence_pop_ups,
} from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const FILTERABLE_EVENT_TYPES = [
    EVENT_TYPES.INFO,
    EVENT_TYPES.WARNING,
    EVENT_TYPES.CRITICAL,
]

const FILTERABLE_TRANSIT_TYPES = [
    VEHICLE_STATUS.MOVING,
    VEHICLE_STATUS.IDLING,
    VEHICLE_STATUS.PARKING,
    VEHICLE_STATUS.DISCONNECTED,
]

const TRANSIT_AND_EVENT_DURATIONS = {
    ALL: {
        label: 'ALL',
        value: 0
    },
    ONE_MINUTE: {
        label: '> 1 min',
        value: 60 * 1000
    },
    TWO_MINUTES: {
        label: '> 2 mins',
        value: 2 * 60 * 1000
    },
    FIVE_MINUTES: {
        label: '> 5 mins',
        value: 5 * 60 * 1000
    },
    TEN_MINUTES: {
        label: '> 10 mins',
        value: 10 * 60 * 1000
    },
    FIFTEEN_MINUTES: {
        label: '> 15 mins',
        value: 15 * 60 * 1000
    },
    THIRTY_MINUTES: {
        label: '> 30mins',
        value: 30 * 60 * 1000
    }
}

const GoogleMapsComponentTripRecord = (props) => {
    // State
    const [mapRef, setMapRef] = useState(null);
    const [isExpanded] = useState(true)
    const [eventTypeFilters, setEventTypeFilters] = useState(FILTERABLE_EVENT_TYPES)
    const [transitTypeFilters, setTransitTypeFilters] = useState(FILTERABLE_TRANSIT_TYPES)
    const [transitAndEventDurationFilterKey, setTransitAndEventDurationFilterKey] = useState('ONE_MINUTE')

    // Redux Store
    const router = useSelector(state => state.router);
    const events = useSelector(state => state.v2.events);
    const mapControl = useSelector(state => state.v2.mapControl);
    const tripRecord = useSelector(state => state.v2.tripRecord);
    const transitRecord = useSelector(state => state.v2.transitRecord);

    const dispatch = useDispatch()

    // Initial mount of component
    useEffect(() => {
        // console.log('new google maps component onMount');

        dispatch(clear_selected_device());
        dispatch(clear_enabled_vehicle_pop_ups());
        dispatch(clear_selected_geofence_geoid());
        dispatch(clear_enabled_geofence_pop_ups());
    }, 
        [dispatch]
    )

    /**
     * If map did not set location to geofence/vehicles within 5 seconds, (a sign of a new account)
     * default location & zoom would be set instead
     */
    useEffect(() => {
        if (!mapRef) return

        setTimeout(() => {
            if (!mapRef.center) {
                // console.log(`[GoogleMapsComponent] Auto setting map location`)
                mapRef.panTo(LOCATION)
                mapRef.setZoom(7)
            }
        }, 
            5 * 1000
        )
    })

    /**
     * If map hasn't set a location yet,
     * Pan to all map item's circumference location
     * Else if map already has a location, do nothing
     */
    useEffect(() => {
        if (!mapRef) return
        if (mapRef.center) return
        // if (props.style.isLoadingSubmit) return

        const vehicleLocations = 
            Object.values(props.vehicles.byId)
            .map(vehicle => {
                const device = vehicle && props.devices.byId[vehicle.dvid];
                const location = device && device.location;

                return location && {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lon)
                }
            })
            .filter(l => l && (l.lat !== 0 && l.lng !== 0))

        const geofenceLocations = 
            Object.values(props.geofences.byId)
            .map(geofence => geofence.coordinates)
            .reduce((a, b) => [...a, ...b], [])
            .filter(l => l && (l.lat !== 0 && l.lng !== 0))

        const allLocations = [...vehicleLocations, ...geofenceLocations];

        // console.table(allLocations)
        if (allLocations.length) {
            const bounds = new window.google.maps.LatLngBounds();

            allLocations.forEach(c => { bounds.extend(c) });

            // console.log(`[GoogleMapsComponent] Setting map location by vehicles & geofences`);

            mapRef.fitBounds(bounds);
        }

    }, 
        [props.vehicles, props.geofences, props.devices, mapRef, props.style]
    )

    /**
     * Render geofence, vehicles, etc
     */
    useEffect(() => {
        const {
            geofences,
            mapControl,
        } = props;

        let zoom, location;

        /**
         * Pan to selected transit status
         */
        if (geofences.selectedId
            && geofences.byId[geofences.selectedId]
            && geofences.byId[geofences.selectedId].coordinates.length > 0
            && mapControl.mapControl === 1
        ) {
            const selectedGeofence = geofences.byId[geofences.selectedId];

            // console.log("Selected Transit:", selectedTransit);

            if (
                selectedGeofence.coordinates.length
                && selectedGeofence.coordinates[0]
                && selectedGeofence.coordinates[0].lat && selectedGeofence.coordinates[0].lng
            ) {
                // console.log("Test 1");

                zoom = 20;

                location = {
                    lat: parseFloat(selectedGeofence.coordinates[0].lat),
                    lng: parseFloat(selectedGeofence.coordinates[0].lng),
                }

                if (mapRef) {
                    mapRef.setZoom(zoom);
                    mapRef.panTo(location);

                    props.dispatch(set_map_control(0));
                }
            }
            else {
                message.error("No location found for this transit status");

                props.dispatch(clear_selected_tsid());
                props.dispatch(set_map_control(0));
            }
        }
    })

    const getTripDetailsDrawer = () => {
        const POINT_TYPES = {
            TRANSIT_RECORD: {
                label: `TRANSIT_RECORD`,
                render: transitRecord => {
                    const isTransitRecordSelected = router.location.state && router.location.state.tsid === transitRecord.tsid

                    return (
                        <div
                            key = {transitRecord.tsid}
                            style = {{
                                cursor: `pointer`,
                                padding: 10,
                                boxShadow: isTransitRecordSelected && `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`
                            }}
                        >
                            <Timeline.Item
                                color = {VEHICLE_COLOR[transitRecord.transitStatus]}
                                onClick = {() => {
                                    const tsid = router.location.state.tsid === transitRecord.tsid ? undefined : transitRecord.tsid;
                                    
                                    // console.log("Clicking transit record!");
    
                                    dispatch(moveToPage('TripRecord', {
                                        ...router.location.state,
                                        tsid: tsid
                                    }))
                                }}
                            >
                                <div
                                    style = {{
                                        display: `flex`,
                                        flexDirection: `row`,
                                        justifyContent: `space-between`,
                                    }}
                                >
                                    <h4 style = {{ fontWeight: 'bold' }}>
                                        {transitRecord.transitStatus}
                                    </h4>

                                    {parseTime(transitRecord.createdAt)}
                                </div>
    
                                <div>
                                    <h4>
                                        {transitRecord.duration && parseDuration(transitRecord.duration)}
                                    </h4>

                                    <h4>
                                        {transitRecord.transitStatus === VEHICLE_STATUS.MOVING && `${(transitRecord.totalMileage || 0).toFixed(2)} km`}
                                    </h4>

                                    <h4>
                                        {transitRecord.startAddress}
                                    </h4>
                                </div>
                            </Timeline.Item>
                        </div>
                    );
                }
            },
            EVENT_LOG: {
                label: `EVENT_LOG`,
                render: (eventLog) => {
                    const isEventLogSelected = router.location.state && router.location.state.eventCreatedAt === eventLog.eventCreatedAt;

                    let dot;

                    switch (eventLog.eventType) {
                        case EVENT_TYPES.CRITICAL:
                            dot = critical
                            break
                        case EVENT_TYPES.WARNING:
                            dot = warning
                            break

                        case EVENT_TYPES.INFO:
                        default:
                            dot = info
                            break
                    }

                    return (
                        <div
                            key = {eventLog.eventCreatedAt}
                            style = {{
                                cursor: `pointer`,
                                padding: 10,
                                boxShadow: isEventLogSelected && `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`
                            }}
                        >
                            <Timeline.Item
                                dot = {
                                    <img
                                        src = {dot}
                                        alt = ""
                                        style = {{
                                            width: 16,
                                            height: 16,
                                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                }
                                onClick = {() => {
                                    const newEventCreatedAt = router.location.state.eventCreatedAt === eventLog.eventCreatedAt ? undefined : eventLog.eventCreatedAt
    
                                    // console.log("Clicking event!");

                                    dispatch(moveToPage('TripRecord', {
                                        ...router.location.state,
                                        eventCreatedAt: newEventCreatedAt
                                    }))
                                }}
                            >
                                <div
                                    style = {{
                                        display: `flex`,
                                        flexDirection: `row`,
                                        justifyContent: `space-between`,
                                    }}
                                >
                                    <h4 style = {{ fontWeight: 'bold' }}>
                                        {eventLog.eventType}
                                    </h4>

                                    {parseTime(eventLog.createdAt)}
                                </div>
    
                                <div>
                                    <h4>
                                        {eventLog.eventName}
                                    </h4>
                                </div>
                            </Timeline.Item>
                        </div>
                    );
                }
            },
        }

        const { trid } = router.location.state || {}

        const tripRecords = tripRecord.byTrID[trid];
        if (!tripRecords) return null

        const { 
            vid, 
            endTime, 
            startTime, 
        } = tripRecords;

        const transitRecords = 
            Object.values(transitRecord.byTsid)
            .filter(transitRecord => startTime <= transitRecord.startTime && endTime >= transitRecord.endTime)
            .filter(transitRecord => transitTypeFilters.includes(transitRecord.transitStatus))
            .filter(transitRecord => {
                const duration = TRANSIT_AND_EVENT_DURATIONS[transitAndEventDurationFilterKey];

                if (!duration) return true

                return transitRecord.duration >= duration.value
            })
            .map(transitRecord => {
                return {
                    ...transitRecord,
                    createdAt: transitRecord.startTime,
                    type: POINT_TYPES.TRANSIT_RECORD.label,
                }
            })

        const eventLogs =
            (
                events.byId[vid] 
                && Object.values(events.byId[vid])
                    .filter(eventLog => startTime <= eventLog.createdAt && endTime >= eventLog.createdAt)
                    .filter(eventLog => eventTypeFilters.includes(eventLog.eventType))
                    .map(eventLog => {
                        return {
                            ...eventLog,
                            type: POINT_TYPES.EVENT_LOG.label,
                        }
                    })
            ) ||
            []

        const allPoints = [...transitRecords, ...eventLogs].sort((a, b) => a.createdAt - b.createdAt);

        return (
            <div
                style = {{
                    display: 'flex',
                    flexDirection: 'column',

                    position: 'absolute',
                    overflow: `auto`,
                    resize: `horizontal`,
                    backgroundColor: 'white',
                    
                    right: 0,
                    zIndex: 99,
                    opacity: 0.9,
                    height: '100%',
                    width: isExpanded ? 300 : 80,
                }}
            >
                <div style = {{ flex: 1 }}>
                    <div
                        style = {{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: `space-between`,

                            marginLeft: 5,
                        }}
                    >
                        <h3>Trip Details</h3>
                        <CloseOutlined
                            onClick = {() => {
                                dispatch(moveToPage('TripRecord', { ...router.location.state, trid: null }));
                            }}
                            style = {{ margin: 5 }}
                        />
                    </div>

                    <div style = {{ paddingBottom: 10 }}>
                        <div style = {styles.headers}>
                            Duration
                        </div>

                        <Select
                            defaultValue = {transitAndEventDurationFilterKey}
                            onChange = {(transitAndEventDurationFilterKey) => {
                                setTransitAndEventDurationFilterKey(transitAndEventDurationFilterKey)
                            }}
                            style = {{ width: '100%' }}
                        >
                            {
                                Object.keys(TRANSIT_AND_EVENT_DURATIONS).map(key => {
                                    const duration = TRANSIT_AND_EVENT_DURATIONS[key];

                                    return (
                                        <Select.Option key = {key}>
                                            {duration.label}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>


                    <div style = {{ paddingBottom: 10 }}>
                        <div style = {styles.headers}>
                            Event Types
                        </div>

                        <Select
                            mode = 'multiple'
                            defaultValue = {eventTypeFilters}
                            onChange = {eventTypes => {
                                setEventTypeFilters(eventTypes)
                            }}
                            style = {{ width: '100%' }}
                        >
                            {
                                FILTERABLE_EVENT_TYPES.map(eventType => {
                                    let dot

                                    switch (eventType) {
                                        case EVENT_TYPES.CRITICAL:
                                            dot = critical
                                            break
                                        case EVENT_TYPES.WARNING:
                                            dot = warning
                                            break

                                        case EVENT_TYPES.INFO:
                                        default:
                                            dot = info
                                            break
                                    }

                                    return (
                                        <Select.Option key = {eventType}>
                                            <img
                                                src = {dot}
                                                alt = ""
                                                style = {{
                                                    width: 16,
                                                    height: 16,
                                                    marginRight: 10,
                                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                }}
                                            />

                                            {eventType}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>

                    <div style = {{ paddingBottom: 10 }}>
                        <div style = {styles.headers}>
                            Transit Types
                        </div>
                        
                        <Select
                            mode = 'multiple'
                            defaultValue = {transitTypeFilters}
                            onChange = {transitTypes => {
                                setTransitTypeFilters(transitTypes)
                            }}
                            style = {{ width: '100%' }}
                        >
                            {
                                FILTERABLE_TRANSIT_TYPES.map(transitType => {
                                    return (
                                        <Select.Option key = {transitType}>
                                            {transitType}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>
                </div>

                <div
                    style = {{
                        flex: 5,

                        padding: 10,
                        overflow: 'scroll',
                    }}
                >
                    <Timeline>
                        {
                            allPoints.map((point) => {
                                const render = POINT_TYPES[point.type] && POINT_TYPES[point.type].render;

                                if (!render) return null

                                return render(point);
                            })
                        }
                    </Timeline>
                </div>
            </div>
        )
    }

    return (
        <GoogleMap
            id = 'example-map'
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragStart = {() => {
                dispatch(set_map_control(0))
            }}
        >
            <POIGeofences mapRef = {mapRef} />
            <PolygonGeofences mapRef = {mapRef} />

            {mapControl.uiControl.showLiveTraffic && <TrafficLayer />}

            <div className = "searchBar">
                <MapSearchBar />
            </div>

            <div className = "controlPanel">
                <MapControlPanel />
            </div>

            <TripRecordRoute mapRef = {mapRef} />

            {getTripDetailsDrawer()}
        </GoogleMap>
    )
}

const styles = {
    headers: {
        color: 'white',
        backgroundColor: 'black',
        paddingLeft: 10,
    }
}

const mapStateToProps = (state) => ({
    style: state.style,
    router: state.router,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    mapControl: state.v2.mapControl,
});

const GoogleMapsComponentWithLoadScript = (props) => GoogleMapsLoadScript(GoogleMapsComponentTripRecord, props);
export default connect(mapStateToProps)(GoogleMapsComponentWithLoadScript);