import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Col,
    Form,
    Input,
    Select,
    InputNumber,
} from "antd";
import {
    DeleteOutlined
} from '@ant-design/icons'
// import { DEVICE_TRIGGERED_EVENTS } from '../../../../../constants';

const FormItem = Form.Item;
const Option = Select.Option;

const OPERATOR_TYPES = {
    QUANTITATIVE: [
        { value: '>', label: 'More than' },
        { value: '<', label: 'Less than' },
    ],
    POSITION: [
        { value: '>', label: 'Inside' },
        { value: '<', label: 'Outside' },
    ],
    INCLUSION: [
        { value: '==', label: 'Include' },
        { value: '!=', label: 'Exclude' },
    ],
    TEMPORAL: [
        { value: '<', label: 'Before' },
        { value: '>', label: 'After' },
    ],
    BOOLEAN: [
        { value: '==', label: 'Is' },
        { value: '!=', label: 'Is Not' },
    ]
}
const MS_IN_MINUTE = 1 * 1000 * 60

class ConditionForm extends Component {
    state = {
        parameter: "",
        operator: "",
        value: "",
    }

    CONDITIONS = {
        speed: {
            label: 'Speed',
            value: 'speed',
            operatorType: OPERATOR_TYPES.QUANTITATIVE,
            valueComponent: () => {
                return (
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Speed Limit (km/h)"
                        value={this.state.value}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value,
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value,
                            });
                        }}
                    />
                )
            }

        },
        geofence: {
            label: 'Geofence',
            value: 'geofence',
            operatorType: OPERATOR_TYPES.POSITION,
            valueComponent: () => {
                return (
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        mode="tags"
                        optionFilterProp="children"
                        placeholder="Select the geofence"
                        tokenSeparators={[',']}
                        value={this.state.value ? this.state.value : undefined}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value: value
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value
                            });
                        }}
                    >
                        {
                            Object.values(this.props.geofences.byId).map(geofence => (
                                <Option
                                    key={geofence.geoid}
                                    value={geofence.geoid}
                                >
                                    {geofence.geofenceName}
                                </Option>
                            ))
                        }
                    </Select>)
            }
        },
        parkingTime: {
            label: 'Parking Duration (mins)',
            value: 'parkingTime',
            operatorType: OPERATOR_TYPES.QUANTITATIVE,
            valueComponent: () => {
                return (
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Parking Duration (mins)"
                        value={this.state.value ? this.state.value / MS_IN_MINUTE : undefined}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value,
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value * MS_IN_MINUTE
                            });
                        }}
                    />
                )
            }
        },
        idlingTime: {
            label: 'Idling Duration (mins)',
            value: 'idlingTime',
            operatorType: OPERATOR_TYPES.QUANTITATIVE,
            valueComponent: () => {
                return (
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Idling Duration (mins)"
                        value={this.state.value ? this.state.value / MS_IN_MINUTE : undefined}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value,
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value * MS_IN_MINUTE
                            });
                        }}
                    />
                )
            }
        },
        activeDay: {
            label: 'Active Day',
            value: 'activeDay',
            operatorType: OPERATOR_TYPES.INCLUSION,
            valueComponent: () => {
                return (
                    <Select
                        style={{ width: '100%' }}
                        mode="tags"
                        placeholder="Select the days"
                        tokenSeparators={[',']}
                        value={this.state.value ? this.state.value : undefined}
                        onChange={(value) => {
                            // console.log("value:", value);

                            this.setState({
                                ...this.state,
                                value: value
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value
                            });
                        }}
                    >
                        {
                            ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                                .map((value, i) => {
                                    return <Option
                                        key={i}
                                    >{value}</Option>
                                })
                        }

                    </Select>
                )
            }
        },
        activeTime: {
            label: 'Active Time',
            value: 'activeTime',
            operatorType: OPERATOR_TYPES.TEMPORAL,
            valueComponent: () => {
                return (
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="24 Hour Format (0000 - 2359)"
                        value={this.state.value}
                        onChange={(value) => {
                            // const MS_IN_MINUTE = 1 * 1000 * 60
                            this.setState({
                                ...this.state,
                                value,
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value
                            });
                        }}
                    />
                )

            }
        },
        deviceMessage: {
            label: 'Device Triggered Event',
            value: 'deviceMessage',
            operatorType: OPERATOR_TYPES.BOOLEAN,
            valueComponent: () => {
                return (
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select device triggered event"
                        value={this.state.value}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value: value
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value
                            });
                        }}
                    >
                        {
                            [
                                {
                                    label: 'SOS',
                                    value: 'help me'
                                }
                            ].map((event, i) => (
                                <Option
                                    key={i}
                                    value={event.value}
                                >
                                    {event.label}
                                </Option>
                            ))
                        }
                    </Select>)
            }
        },
        cellularStatus: {
            label: 'Cellular Status',
            value: 'cellularStatus',
            operatorType: OPERATOR_TYPES.BOOLEAN,
            valueComponent: () => {
                const CELLULAR_STATUSES = {
                    0: `Not Available`,
                    1: `Available`
                }
                return (
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Select status"
                        tokenSeparators={[',']}
                        value={CELLULAR_STATUSES[this.state.value]}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value: value
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value
                            });
                        }}
                    >
                        {
                            Object.keys(CELLULAR_STATUSES)
                                .map((key, i) => {
                                    return <Option key={key} value={key}>
                                        {CELLULAR_STATUSES[key]}
                                    </Option>
                                })
                        }

                    </Select>
                )

            }
        },
        engineStatus: {
            label: 'Engine Status',
            value: 'engineStatus',
            operatorType: OPERATOR_TYPES.BOOLEAN,
            valueComponent: () => {
                const ENGINE_STATUSES = {
                    0: `Off`,
                    1: `On`
                }
                return (
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Select status"
                        tokenSeparators={[',']}
                        value={ENGINE_STATUSES[this.state.value]}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value: value
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value
                            });
                        }}
                    >
                        {
                            Object.keys(ENGINE_STATUSES)
                                .map((key, i) => {
                                    return <Option key={key} value={key}>
                                        {ENGINE_STATUSES[key]}
                                    </Option>
                                })
                        }

                    </Select>
                )

            }
        },
        gpsStatus: {
            label: 'GPS Status',
            value: 'gpsStatus',
            operatorType: OPERATOR_TYPES.BOOLEAN,
            valueComponent: () => {
                const GPS_STATUS = {
                    0: `Not Available`,
                    1: `Available`
                }
                return (
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Select status"
                        tokenSeparators={[',']}
                        value={GPS_STATUS[this.state.value]}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value: value
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value: value
                            });
                        }}
                    >
                        {
                            Object.keys(GPS_STATUS)
                                .map((key, i) => {
                                    return <Option key={key} value={key}>
                                        {GPS_STATUS[key]}
                                    </Option>
                                })
                        }

                    </Select>
                )

            }
        },
        accelerate: {
            label: 'Accelerate',
            value: 'accelerate',
            operatorType: OPERATOR_TYPES.QUANTITATIVE,
            valueComponent: () => {
                return (
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Speed (km/h)"
                        value={this.state.value}
                        onChange={(value) => {
                            this.setState({
                                ...this.state,
                                value,
                            });

                            this.props.updateCondition({
                                parameter: this.state.parameter,
                                operator: this.state.operator,
                                value,
                            });
                        }}
                    />
                )
            }

        },
    }

    componentDidMount = () => {
        if (this.props.condition) {
            this.setState({
                ...this.state,
                ...this.props.condition
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.condition) !== JSON.stringify(this.props.condition)) {
            this.setState({
                ...this.state,
                ...this.props.condition
            })
        }
    }

    render() {
        /**
         * For rules within default rule templates, only value is editable. Param and operator is not
         */
        const ruleTemplate = this.props.ruleTemplate.byId[this.props.rtid]
        const isDefault = ruleTemplate && ruleTemplate.templateName === 'DEFAULT'

        return (
            <FormItem
                label={`Condition ${this.props.index + 1}`}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 15 }}
            >
                <Col span={9}>
                    <Select
                        showSearch
                        disabled={isDefault}
                        placeholder="Parameter"
                        optionFilterProp="children"
                        value={this.state.parameter}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value) => {
                            this.setState({
                                parameter: value,
                                operator: "",
                                value: "",
                            })

                            this.props.updateCondition({
                                parameter: value,
                                operator: "",
                                value: "",
                            });
                        }}
                    >
                        {
                            Object.keys(this.CONDITIONS).map((key, i) => {
                                const condition = this.CONDITIONS[key]
                                return <Option
                                    key={i}
                                    value={key}
                                >
                                    {condition.label}
                                </Option>
                            })
                        }
                    </Select>
                </Col>

                <Col span={6}>
                    {
                        <Select
                            showSearch
                            disabled={!this.CONDITIONS[this.state.parameter] || isDefault}
                            style={{ width: '100%' }}
                            placeholder="Operator"
                            optionFilterProp="children"
                            value={this.state.operator}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state,
                                    operator: value
                                });

                                this.props.updateCondition({
                                    parameter: this.state.parameter,
                                    operator: value,
                                    value: this.state.value
                                });
                            }}
                        >
                            {
                                this.CONDITIONS[this.state.parameter] &&
                                this.CONDITIONS[this.state.parameter].operatorType.map((operator, i) => {
                                    return <Option value={operator.value} key={i}>{operator.label}</Option>
                                })
                            }
                        </Select>
                    }
                </Col>

                <Col span={9}>
                    {
                        this.state.operator && this.CONDITIONS[this.state.parameter] ?
                            this.CONDITIONS[this.state.parameter].valueComponent()
                            :
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Value"
                                value={undefined}
                                disabled
                            />
                    }
                </Col>

                {
                    this.props.index > 0 &&
                    <Col span={2}>
                        <button className="transparent-button">
                            <DeleteOutlined onClick={() => this.props.deleteCondition(this.props.index)}/>
                        </button>
                    </Col>
                }
            </FormItem>
        )
    }
}

const mapStateToProps = (state) => ({
    geofences: state.v2.geofences,
    ruleTemplate: state.v2.ruleTemplate,
});

export default connect(mapStateToProps)(ConditionForm);