import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
    // TreeSelect,
} from "antd";

// Redux Actions
import { migrate_user } from "../../../services/redux/actions/migration";

const FormItem = Form.Item;
const { Option } = Select;

const stageList = [
    "V1",
    "V2",
    // "V3",
]

class MigrationPage extends Component {
    state = {
        targetStage: "",
        selectedUser: {},
    }
    
    submitForm = () => {
        // console.log('formData ', formData);

        const {
            targetStage,
            selectedUser,
        } = this.state;

        let isValid = true;

        if (!selectedUser.uid) {
            message.error("Selected User Not Found");
            isValid = false;
        }

        if (!selectedUser.stage) {
            message.error("Source Stage Not Found");
            isValid = false;
        }

        if (!targetStage) {
            message.error("Please Select The Stage You Want To Send To");
            isValid = false;
        }

        if (isValid) {
            // console.log(
            //     `Migrating User:
            //     - Source UID: ${selectedUser.uid}
            //     - Source Stage: ${selectedUser.stage}
            //     - Target Stage: ${targetStage}`
            // );

            this.props.dispatch(migrate_user(selectedUser.uid, selectedUser.stage, targetStage));
        }
    }

    setUpState = () => {
        this.setState({
            ...this.state,
            selectedUser: this.props.migration.selectedUser,
        })
    }

    componentDidMount = () => {
        const { migration } = this.props;

        if (migration.selectedUser.uid) {
            this.setUpState();
        }
        else {
            this.props.dispatch(goBackToPrev());
        }
    }

    render() {
        return (
            <div className = "page-container">
                <Page title = "Migrate User">
                    <Form className = "login-form">
                        <FormItem
                            label = "Selected User"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            {/* <TreeSelect
                                disabled
                                treeDefaultExpandAll
                                treeData = {this.state.userOptions}
                                // placeholder = "Select A User"
                                placeholder = "No Selected User Found"
                                dropdownStyle = {{ maxHeight: 400, overflow: 'auto' }}
                                value = {
                                    this.state.selectedUID 
                                    && this.props.hierarchy.byId[this.state.selectedUID] ? 
                                        this.state.selectedUID : 
                                        undefined
                                }
                                onChange = {uid => 
                                    this.setState({ 
                                        ...this.state,
                                        selectedUID: uid,
                                    })
                                }
                                style = {{ width: '100%' }}
                            /> */}

                            <Input 
                                disabled
                                placeholder = "No Selected User Found"
                                value = {this.state.selectedUser ? this.state.selectedUser.userName : undefined}
                                // onChange = {(e) => this.searchInputOnChange(e)}
                            />
                        </FormItem>

                        <FormItem
                            label = "Selected Stage"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input 
                                disabled
                                placeholder = "No Selected User Stage Found"
                                value = {this.state.selectedUser ? this.state.selectedUser.stage : undefined}
                                // onChange = {(e) => this.searchInputOnChange(e)}
                            />
                        </FormItem>
                        
                        <FormItem
                            label = "Target Stage"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Select
                                value = {this.state.targetStage ? this.state.targetStage : undefined}
                                placeholder = "Select A Stage"
                                onChange = {(value) => {
                                    this.setState({ 
                                        targetStage: value,
                                    })
                                }}
                            >
                                {
                                    stageList
                                    .filter((stage) => 
                                        this.state.selectedUser 
                                        && this.state.selectedUser.stage 
                                        && stage.toLowerCase() !== this.state.selectedUser.stage.toLowerCase()
                                    )
                                    .map(stage => {
                                        return (
                                            <Option key = {stage} value = {stage}>
                                                {stage}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>

                        <div style = {{ position: "absolute", right: "100px" }}>
                            <Button
                                type = "danger"
                                className = "login-form-button"
                                style = {{ marginLeft: "22px" }}
                                // disabled = {this.props.style.isLoadingSubmit}
                                onClick = {() => this.props.dispatch(goBackToPrev())}
                            >
                                Cancel
                            </Button>
                            
                            <PrimaryButton
                                loading = {this.props.isLoading}
                                // disabled = {this.props.style.isLoadingSubmit}
                                onClick = {this.submitForm}
                            >
                                Submit
                            </PrimaryButton>
                        </div>
                    </Form>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    migration: state.v2.migration,
});

const ConnectedMigrationPage = connect(mapStateToProps)(MigrationPage);
export default showSecondarySidebar(false)(ConnectedMigrationPage);