import React from "react";
import { connect } from "react-redux";

import moment from "moment";
import Page from "../../components/Page";
import Highlighter from 'react-highlight-words';
import AcknowledgeSOSModal from "./AcknowledgeSOSModal";
// import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { parseTime } from '../../util/time';

import {
    Form,
    Table,
    Input,
    Button,
    Checkbox,
    DatePicker,
} from "antd";
import {
    SearchOutlined
} from '@ant-design/icons'

// Redux Actions
import { get_escalation_states } from '../../services/redux/actions/escalations';

const defaultFilter = "ALL";

const STATUSES = {
    '-1': {
        value: -1,
        color: 'red',
        label: 'FAILED'
    },
    '0': {
        value: 0,
        color: 'blue',
        label: 'PENDING'
    },
    '1': {
        value: 1,
        color: 'green',
        label: 'SUCCESS'
    },
}

class SOSAlertsPage extends React.Component {
    state = {
        dataSource: [],

        // Filter Stuff
        vehiclePlateList: [],

        // Selected Filter Stuff
        startTime: moment().startOf("day"),
        endTime: moment().startOf("day").add(1, "day"),
        selectedVehiclePlate: defaultFilter,
        selectedAcknowledgeStatus: defaultFilter,

        // Modal Stuff
        selectedECID: "",
        isAcknowledgeModalOpen: false,
    }

    returnStatus = (status) => {
        const obj = STATUSES[status && status.toString()];

        if (!obj) return <p style = {{ color: 'black' }}>{'UNKNOWN'}</p>

        return <p style = {{ color: obj.color }}>{obj.label}</p>
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style = {{ padding: 8 }}>
                <Input
                    ref = {(node) => { this.searchInput = node }}
                    placeholder = {`Search ${dataIndex}`}
                    value = {selectedKeys[0]}
                    onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        display: 'block',

                        width: 188,
                        marginBottom: 8,
                    }}
                />

                <Button
                    type = "primary"
                    icon = {<SearchOutlined/>}
                    size = "small"
                    onClick = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        width: 90,
                        marginRight: 8,
                    }}
                >
                    Search
                </Button>

                <Button
                    size = "small"
                    style = {{ width: 90 }}
                    onClick = {() => this.handleReset(clearFilters)}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                autoEscape
                textToHighlight = {text ? text : ""}
                searchWords = {[this.state.searchText]}
                highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();

        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();

        this.setState({ searchText: '' });
    };

    returnDataSource = (dataSource) => {
        const newDataSource =
            dataSource.map((currRowData) => {
                const { escalations } = this.props;

                const currVehicle = this.props.vehicles.byId[currRowData.vid];
                const currDevice = this.props.devices.byId[currVehicle && currVehicle.dvid];
                const recipient = 
                    currRowData.ecid
                    && escalations.records.byECID[currRowData.ecid]
                    && escalations.records.byECID[currRowData.ecid][0]
                    && escalations.records.byECRID[escalations.records.byECID[currRowData.ecid][0]]
                    && escalations.records.byECRID[escalations.records.byECID[currRowData.ecid][0]]

                const acknowledgedBy = this.props.hierarchy.byId[currRowData.acknowledgedBy]

                return ({
                    key: currRowData.ecid,
                    createdAt: currRowData.createdAt,
                    vehiclePlate: (currVehicle && currVehicle.vehiclePlate) || "-",
                    location:
                        currDevice
                        && currDevice.location
                        && currDevice.location.lat
                        && currDevice.location.lon ?
                            `${currDevice.location.lat}, ${currDevice.location.lon}` :
                            "-",
                    status: currRowData && currRowData.status,
                    recipientName: (recipient && recipient.contactName) || '-',
                    contactNumber: (recipient && recipient.contactNumber) || '-',
                    acknowledgedBy: (acknowledgedBy && acknowledgedBy.userName) || '-',
                    acknowledgedAt: parseTime(currRowData.acknowledgedAt),
                    acknowledgedRemarks: currRowData.acknowledgedRemark,
                    isAcknowledged: currRowData.isAcknowledged ? true : false, // Convert currRowData from number to bool
                });
            })


        return newDataSource;
    }

    setUpDataSource = () => {
        const { escalations } = this.props;

        this.setState({
            dataSource:
                this.returnDataSource(
                    Object.values(escalations.states.byECID)
                    .sort((a, b) => { // Sort so future data is automatically sorted according to which is last updated
                        return b.createdAt - a.createdAt;
                    })
                ),
        })
    }

    componentDidMount = () => {
        this.setUpDataSource();
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setUpDataSource();
        }
    }

    render() {
        const {
            endTime,
            startTime,
            dataSource,
            // vehiclePlateList,
            // selectedVehiclePlate,
            // selectedAcknowledgeStatus,
        } = this.state;

        const expandedRowRender = (rowData) => {
            // console.log("Row ECID:", rowData);

            const { escalations } = this.props;

            const recordColumns = [
                {
                    title: "Date",
                    dataIndex: "createdAt",
                    render: time => parseTime(time)
                    // filters: Object.values(this.props.staff.byUID).map(staff => staff.userName).map(s => ({ text: s, value: s })),
                    // onFilter: (value, record) => value && record.serviceAdvisor.indexOf(value) === 0,
                },

                {
                    title: "Vehicle Plate",
                    dataIndex: "vehiclePlate",
                    ...this.getColumnSearchProps('vehiclePlate')
                },

                {
                    title: "Vehicle Model",
                    dataIndex: "vehicleModel",
                    ...this.getColumnSearchProps('vehicleModel')
                },

                // {
                //     title: "Location",
                //     dataIndex: "location",
                //     render: location => {
                //         if (!location) return location
                //         if (!location.lat) return location
                //         if (!location.lng) return location

                //         return (
                //             <a
                //                 target = "_blank"
                //                 rel = "noopener noreferrer"
                //                 href = {`https://www.google.com.my/maps/place/${location.lat},${location.lon}`}
                //             >
                //                 {`${location.lat}, ${location.lng}`}
                //             </a>
                //         )
                //     }
                // },

                {
                    title: "Contact Number",
                    dataIndex: "contact",
                    ...this.getColumnSearchProps('contact')
                },

                {
                    title: "Status",
                    dataIndex: "status",
                    render: (recordStatus) => this.returnStatus(recordStatus),
                    filters: Object.values(STATUSES).map(s => ({ text: s.label, value: s.value })),
                    onFilter: (value, record) => record.status === value,
                },
            ]

            const recordDataSource =
                escalations.records.byECID[rowData.key] &&
                escalations.records.byECID[rowData.key].map((currECRID) => {
                    const currRecord = escalations.records.byECRID[currECRID];
                    const currVehicle = this.props.vehicles.byId[currRecord.vid];
                    const currDevice = this.props.devices.byId[currVehicle && currVehicle.dvid];

                    return ({
                        key: currECRID,
                        createdAt: currRecord.createdAt,
                        vehiclePlate: currVehicle && currVehicle.vehiclePlate ? currVehicle.vehiclePlate : "-",
                        vehicleModel: currVehicle && currVehicle.vehicleModel ? currVehicle.vehicleModel : "-",
                        location:
                            currDevice
                                && currDevice.location
                                && currDevice.location.lat
                                && currDevice.location.lon ?
                                `${currDevice.location.lat}, ${currDevice.location.lon}` :
                                "-",
                        contact: `${currRecord.contactNumber} (${currRecord.contactName})`,
                        status: currRecord && currRecord.status,
                    })
                });

            return (
                <div style = {{ margin: 20 }}>
                    <Table
                        columns = {recordColumns}
                        dataSource = {recordDataSource}
                        bordered
                        size = "small"
                        pagination = {false}
                        style = {{ width: recordColumns.length * 250 }}
                    />
                </div>
            );
        }

        const columns = [
            {
                title: "Date",
                dataIndex: "createdAt",
                render: time => parseTime(time)
                // filters: Object.values(this.props.staff.byUID).map(staff => staff.userName).map(s => ({ text: s, value: s })),
                // onFilter: (value, record) => value && record.serviceAdvisor.indexOf(value) === 0,
            },

            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                ...this.getColumnSearchProps('vehiclePlate')
            },

            // {
            //     title: "Location",
            //     dataIndex: "location",
            // },

            {
                title: "Status",
                dataIndex: "status",
                render: (status) => this.returnStatus(status),
                filters: Object.values(STATUSES).map(s => ({ text: s.label, value: s.value })),
                onFilter: (value, record) => record.status === value,
            },

            {
                title: "Last Called Recipient Name",
                dataIndex: "recipientName",
                ...this.getColumnSearchProps('recipientName')
            },

            {
                title: "Last Called Contact Number",
                dataIndex: "contactNumber",
                ...this.getColumnSearchProps('contactNumber')
            },

            {
                title: "Acknowledged By",
                dataIndex: "acknowledgedBy",
                ...this.getColumnSearchProps('acknowledgedBy')
            },

            {
                title: "Acknowledged At",
                dataIndex: "acknowledgedAt",
                ...this.getColumnSearchProps('acknowledgedAt')
            },

            {
                title: "Remarks",
                dataIndex: "acknowledgedRemarks",
                ...this.getColumnSearchProps('acknowledgedRemarks')
            },

            {
                title: "Acknowledge?",
                // dataIndex: "isAcknowledged",
                // fixed: "right",
                render: (rowData) => (
                    <Checkbox
                        checked = {rowData.isAcknowledged}
                        disabled = {this.props.style.isLoadingSubmit || rowData.isAcknowledged}
                        onChange = {(event) => {
                            // console.log("Test:", rowData.isAcknowledged, event.target.checked);

                            if (event.target.checked) {
                                this.setState({
                                    selectedECID: rowData.key,
                                    isAcknowledgeModalOpen: true,
                                })
                            }
                        }}
                    />
                )
            },
        ]

        return (
            <Page title = "SOS Alerts">
                <div
                    style = {{
                        display: "block",
                        minHeight: "95px",
                        padding: "10px 10px 10px 10px",
                    }}
                >
                    <Form layout = 'inline' style = {{ marginBottom: 5 }}>
                        <Form.Item>
                            <span>Start time: </span>

                            <DatePicker
                                showTime
                                defaultValue = {startTime}
                                onChange = {(value) => this.setState({ startTime: value })}
                                style = {{ width: 200 }}
                            />

                            <span style = {{ marginLeft: '10px' }}>End time: </span>

                            <DatePicker
                                showTime
                                defaultValue = {endTime}
                                onChange = {(value) => this.setState({ endTime: value })}
                                style = {{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading = {this.props.style.isLoadingSubmit}
                                onClick = {() => {
                                    const nfids = Object.keys(this.props.notifications.byNfID);
                                    const endTime = this.state.endTime && this.state.endTime.valueOf();
                                    const startTime = this.state.startTime && this.state.startTime.valueOf();

                                    this.props.dispatch(get_escalation_states(nfids, startTime, endTime));
                                }}
                                style = {{ marginLeft: '15px' }}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>

                    {/* <div
                        style = {{
                            display: "flex",
                            justifyContent: "flex-end",

                            marginBottom: 10,
                        }}
                    >
                        <ExportExcelButton
                            disabled = {dataSource.length === 0}
                            filename = {excelname}
                            sheetData = {dataSource}
                            sheetName = {`Service Reminder Record`}
                            sheetRow = {
                                columns
                                    .filter(col => col.title !== `Actions`)
                                    .map(col => {
                                        switch (col.dataIndex) {
                                            case 'completedAt':
                                            case 'createdAt':
                                                return {
                                                    label: col.title,
                                                    formatter: value => col.render(value[col.dataIndex])
                                                }
                                            default:
                                                return {
                                                    label: col.title,
                                                    formatter: value => value[col.dataIndex]
                                                }
                                        }
                                    })
                            }
                        />
                    </div> */}
                </div>

                <Table
                    columns = {columns}
                    dataSource = {dataSource}
                    pagination = {true}
                    expandedRowRender = {expandedRowRender}
                    scroll = {{
                        x: columns && columns.length * 200,
                        y: window.innerHeight
                    }}
                />

                <AcknowledgeSOSModal
                    isVisible = {this.state.isAcknowledgeModalOpen}
                    editedState = {this.props.escalations.states.byECID[this.state.selectedECID]}
                    onClose = {() => {
                        this.setState({
                            isAcknowledgeModalOpen: false,
                        })
                    }}
                />
            </Page>
        )
    };
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    hierarchy: state.v2.hierarchy,
    escalations: state.v2.escalations,
    notifications: state.v2.notifications,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(SOSAlertsPage));