import React from "react";
import { connect } from "react-redux";

import EventList from "./EventList";
import EventStatusBar from "./EventStatusBar";
import VehicleSelectForm from "../../components/VehicleSelectForm";

import { LeftOutlined } from "@ant-design/icons";
import { moveToDash } from "../../navigation/navigationService";

// Redux Actions
import { set_selected_vehicle } from '../../services/redux/actions/vehicles';
import { get_event_by_timeframe_request } from '../../services/redux/actions/events';

import "../../../../App.css";

class EventRecord extends React.Component {
    state = {}

    render() {
        return (
            <div className = "page-container" style = {{ width: "inherit" }}>
                <div
                    className = "scroll-div"
                    style = {{ display: "flex", flex: 1, width: "inherit" }}
                >
                    <div style = {{ width: "inherit" }}>
                        <div
                            className = "page-header"
                            style = {{
                                color: '#FFFFFF',
                                backgroundColor: this.props.style.pageHeaderColor,
                            }}
                        >
                            <div className = "page-header-contents">
                                <button
                                    className = "transparent-button"
                                    onClick = {() => this.props.dispatch(moveToDash())}
                                >
                                    <LeftOutlined />
                                </button>

                                <div>Event Record</div>
                            </div>
                        </div>

                        <div style = {{ padding: "10px 20px 10px 20px" }}>
                            <VehicleSelectForm
                                pathname = {this.props.location.pathname}
                                onSubmit = {(startTime, endTime, vid) => {
                                    const vehicle = this.props.vehicles.byId[vid];
                                    const dvid = vehicle && vehicle.dvid;

                                    endTime = endTime && endTime.valueOf();
                                    startTime = startTime && startTime.valueOf();

                                    // console.log({ startTime, endTime, vid })

                                    this.setState({
                                        vid,
                                        dvid,
                                        endTime,
                                        startTime,
                                    })

                                    this.props.dispatch(set_selected_vehicle(vid));
                                    this.props.dispatch(get_event_by_timeframe_request([vid], startTime, endTime));
                                }}
                            />
                        </div>

                        <div
                            style = {{
                                color: '#FFFFFF',
                                backgroundColor: this.props.style.pageHeaderColor,

                                padding: "5px 0px 5px 10px",
                            }}
                        >
                            Event List
                        </div>

                        <EventStatusBar/>

                        {/* <VehicleList filterString = {this.state.filterString}/> */}
                        
                        <EventList vid = {this.state.vid}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    events: state.v2.events,
    vehicles: state.v2.vehicles,
});

export default connect(mapStateToProps)(EventRecord);
