import * as ActionTypes from "../action-types/vehiclePackage";
const defaultState = {
  byId: {},
  loadingStatus: ''
};

export const vehiclePackage = (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch(action.type) {
    case ActionTypes.GET_VEHICLE_PACKAGES: {

      // console.log("Vehicle:", action.vehicles);
      
      action.vehiclePackages.map(vehiclePackage => {
        newState.byId[vehiclePackage.vehiclePackageId] = vehiclePackage;
        return null;
      })

      return newState;
    }

    case ActionTypes.EDIT_VEHICLE_PACKAGE:
    case ActionTypes.ADD_VEHICLE_PACKAGE: 
      newState.byId[action.vehiclePackage.vehiclePackageId] = action.vehiclePackage
      return newState;
    
    case ActionTypes.SET_LOADING_STATUS:
      newState.loadingStatus = action.loadingStatus
      return newState
    
      
    default: {
      return state;
    }
  }
}