import { api, headers } from '../constants'

export const getInfoByUser = (uid) => (
    fetch(`${api}/user/getInfo`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        "uid": uid
      })
    })
      .then(res => res.json())
)
  
export const addUser = (currentUser, newUser) => {
    return fetch(`${api}/user/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
        currentUser: currentUser,
        newUser: newUser
        })
    })
        .then(res => res.json())
}

export const editUser = (user) => (
    fetch(`${api}/user/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
        user: user
        })
    })
        .then(res => res.json())
)

export const updateUserProfile = (user) => (
    fetch(`${api}/user/profile/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            user: user
        })
    })
        .then(res => res.json())
)

/**
 * For fleet operator only
 * @param {} user 
 * @returns 
 */
export const configureUser = (user, vgid) => (
    fetch(`${api}/user/configure`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
        user, vgid
        })
    })
        .then(res => res.json())
)

export const getUserHierarchy = (uid) => {

    return fetch(`${api}/user/gethierarchy`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
        uid
        })
    })
        .then(res => res.json())
}