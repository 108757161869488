import React from "react";
import Highlighter from 'react-highlight-words';

import { SearchOutlined } from '@ant-design/icons';
import {
  Input,
  Button,
} from "antd";

const tableColumnSearchProps = (dataIndex, searchText, onSearchTextChange, onSearchTextReset) => {
  const searchInput = React.createRef();

  return {
    filterDropdown: ({ confirm, clearFilters, selectedKeys, setSelectedKeys }) => (
      <div style = {{ padding: 8 }}>
        <Input
          // ref = {(node) => {
          //   searchInput = node;
          // }}
          ref = {searchInput}
          value = {selectedKeys[0]}
          placeholder = {`Search ${dataIndex}`}
          onPressEnter = {() => {
            confirm(); // Closes the dropdown for some reason
            onSearchTextChange(selectedKeys[0]);
          }}
          onChange = {(event) => setSelectedKeys(event.target.value ? [event.target.value] : [])}
          style = {{ 
            width: 188, 
            marginBottom: 8, 
            display: 'block' 
          }}
        />

        <Button
          size = "small"
          icon = {<SearchOutlined/>}
          type = "primary"
          onClick = {() => {
            confirm(); // Closes the dropdown for some reason
            onSearchTextChange(selectedKeys[0]);
          }}
          style = {{ 
            width: 90, 
            marginRight: 8 
          }}
        >
          Search
        </Button>

        <Button 
          size = "small" 
          onClick = {() => {
            clearFilters();
            onSearchTextReset(clearFilters);
          }}
          style = {{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex] ?
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) :
        "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) => (
      <Highlighter
        autoEscape
        searchWords = {[searchText]}
        textToHighlight = {text ? text.toString() : ""}
        highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
      />
    ),
  }
};

export default tableColumnSearchProps;