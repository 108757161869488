import React, { useState } from 'react';
import { 
    // LoadScript, 
    useJsApiLoader 
} from '@react-google-maps/api';

import { config } from "../../../../../config";

// const google = window.google

const GoogleMapsLoadScript = (MapComponent, props) => {
    const [
        libraries,
        // setLibraries
    ] = useState(['drawing'])

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: config.googleMapAPI,
        libraries
    })


    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded && <MapComponent {...props} />
}

export default GoogleMapsLoadScript