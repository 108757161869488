import * as ActionTypes from "../action-types/vehiclePackageLog";
const defaultState = {
  byId: {},
  loadingStatus: ''
};

export const vehiclePackageLog = (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch(action.type) {
    case ActionTypes.GET_VEHICLE_PACKAGE_LOGS: {

      // console.log("Vehicle:", action.vehicles);
      
      action.vehiclePackageLogs.map(vehiclePackageLog => {
        if(!newState.byId[vehiclePackageLog.vehiclePackageId]){
          newState.byId[vehiclePackageLog.vehiclePackageId] = []
        }
        newState.byId[vehiclePackageLog.vehiclePackageId].push(vehiclePackageLog)
        return null;
      })

      return newState;
    }

    case ActionTypes.SET_LOADING_STATUS:
      newState.loadingStatus = action.loadingStatus
      return newState
    
    default: {
      return state;
    }
  }
}