import { DISPLAY_USER_ROLE } from '../../../../../constants';
import * as actionType from '../action-types/hierarchy';

const initialState = {
  byId: {},
  allIds: [],

  hierarchyTree: {},
  treeData: [],

  selectedId: null,
  selectedVID: "",
  selectedDVID: "",

  // Only temporarily available when new user is added/edited 
  // So that User Management Add User/Configure User page can add/edit groups to it if applicable
  tempNewUID: "",
}

export const hierarchy = (state = initialState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case actionType.GET_USER_HIERARCHY: {
      const {
        byId,
        hierarchy,
      } = action;

      // console.log({
      //   byId,
      //   allIds,
      //   hierarchy,
      // })

      const treeData = Object.keys(hierarchy["0"]).map((uid, i)=>{
        return {
          title: uid,
          key: `0-${i}`
        }
      })

      // const uid = Object.keys(hierarchy["0"])[0] || {}
      // const user = byId[uid] || {}


      return {
        ...newState,
        hierarchyTree: hierarchy,
        allIds: Object.keys(byId || {}),
        byId: byId,
        treeData: treeData
      }
    }

    case actionType.SELECT_USER_HIERARCHY: {
      // console.log("Select User Hierarchy:", action);

      // check if user data is already queried previously. If no, replace with new info. Else, simply re-use
      if (action.info) {
        newState.byId[action.user.uid] = {
          ...newState.byId[action.user.uid],
          ...action.info,
          deviceGroups: action.info.deviceGroups,
          vehicleGroups: action.info.vehicleGroups,
          rulesTemplates: action.info.ruleTemplates,
        }
      }

      // console.log("New State:", newState);

      return {
        ...newState,
        selectedId: action.user.uid,
      }
    }

    case actionType.UPDATE_USER_HIERARCHY_DEVICES: {
      if (newState.byId[action.uid] && newState.byId[action.uid].deviceGroups && newState.byId[action.uid].deviceGroups.devices) {
        const {
          uid,
          newDevice
        } = action;

        let updatedHierarchyDevices = newState.byId[uid].deviceGroups.devices;

        // Remove old device from edited user's hierarchy then add the updated device
        updatedHierarchyDevices = updatedHierarchyDevices.filter((currDevice) => currDevice.dvid !== newDevice.dvid);
        updatedHierarchyDevices.push(newDevice);

        // Update the store
        newState.byId[uid].deviceGroups.devices = updatedHierarchyDevices;
      }

      return newState;
    }

    case actionType.UPDATE_SELECTED_USER_HIERARCHY: {
      const {
        uid,
        vgidList,
        gtidList,
        rtidList,
        newVehicle
      } = action;

      if (newState.byId[uid]) {
        const selectedUser = newState.byId[uid];

        // Updating vehicle groups
        if (selectedUser.vehicleGroups
          && selectedUser.vehicleGroups.vehicleGroups) {
          const selectedUserVG = selectedUser.vehicleGroups;

          // Add new vehicle if not in VG vehicle list
          if (!selectedUserVG.vehicles.find((currVehicle) => currVehicle.vid === newVehicle.vid)) {
            // console.log("Test 1");

            selectedUserVG.vehicles.push(newVehicle);
          }
          else {
            // console.log("Test 2");

            const editedVehicle = selectedUserVG.vehicles[selectedUserVG.vehicles.indexOf(selectedUserVG.vehicles.find((currVehicle) => currVehicle.vid === newVehicle.vid))];

            editedVehicle.ruleTemplates = rtidList;
            editedVehicle.geofenceTemplates = gtidList;
          }

          // Update VGs' vehicle lists
          selectedUserVG.vehicleGroups.forEach((currVG) => {
            if (vgidList.includes(currVG.vgid)) {
              // VG may have no change so do nothing
              if (!currVG.vehicles.includes(newVehicle.vid)) {
                currVG.vehicles.push(newVehicle.vid);
              }
            }
            else {
              // Remove vid from VG if vehicle no longer attached to said VG
              if (currVG.vehicles.includes(newVehicle.vid)) {
                currVG.vehicles.splice(currVG.vehicles.indexOf(newVehicle.vid), 1);
              }
            }
          })

          selectedUser.vehicleGroups = selectedUserVG;
        }
      }

      return newState;
    }

    case actionType.UPDATE_TREE_DATA: {
      return {
        ...newState,
        treeData: action.treeData,
        // treeData: [action.treeData]
      };
    }

    case actionType.SELECT_USER_HIERARCHY_VID: {
      return {
        ...newState,
        selectedVID: action.newVID,
      };
    }

    case actionType.SELECT_USER_HIERARCHY_DVID: {
      return {
        ...newState,
        selectedDVID: action.newDVID,
      };
    }

    case actionType.ADD_USER_HIERARCHY: {
      // console.log("Add User Hierarchy:", action);

      const {
        newUser,
        parentUID,
      } = action;

      // console.log("Test 0:", newState.hierarchyTree);

      // Updating hierarchy tree
      for (let i = 0; i < Object.keys(newState.hierarchyTree).length; i++) {
        let isFound = false;
        const currTierInt = Object.keys(newState.hierarchyTree)[i];

        // console.log("Test 1:", currTierInt);

        for (let j = 0; j < Object.keys(newState.hierarchyTree[currTierInt]).length; j++) {
          const currTierUID = Object.keys(newState.hierarchyTree[currTierInt])[j];

          // console.log("Test 2:", currTierUID, parentUID);

          if (currTierUID === parentUID) {
            newState.hierarchyTree[currTierInt][currTierUID].push(newUser.uid);

            isFound = true;

            break;
          }
        }

        if (isFound) {
          break;
        }
      }

      return {
        ...newState,
        byId: {
          ...newState.byId,
          [newUser.uid]: newUser,
        },
        allIds: !newState.allIds.includes(newUser.uid) ? [...newState.allIds, newUser.uid] : newState.allIds,
        hierarchyTree: newState.hierarchyTree,
        treeData: [{
          title: `${newState.byId[Object.keys(newState.hierarchyTree["0"])[0]].userName} (${DISPLAY_USER_ROLE(newState.byId[Object.keys(newState.hierarchyTree["0"])[0]].userRole)})`,
          key: `0-${Object.keys(newState.hierarchyTree["0"])[0]}`
        }],

        tempNewUID: newUser.uid, // Set tempNewUID here
      }
    }

    case actionType.EDIT_USER_HIERARCHY: {
      const { editedUser } = action;

      return {
        ...newState,
        byId: {
          ...newState.byId,
          [editedUser.uid]: {
            ...newState.byId[editedUser.uid],
            ...editedUser,
          },
        },

        tempNewUID: editedUser.uid, // Set tempNewUID here
      }
    }

    case actionType.ADD_GROUPS_TO_USER: {
      if (!action.data) return newState
      if (!action.data.relations) return newState
      // console.log("Add Groups To User Data:", action.data);
      // console.log("Selected User", newState.byId[action.data.uid]);

      newState.byId[action.data.uid] = {
        ...newState.byId[action.data.uid],
        deviceGroups: action.data.relations.deviceGroups.devices ? {
          devices: [
            ...newState.byId[action.data.uid].deviceGroups.devices,
            ...action.data.relations.deviceGroups.devices
          ],
          deviceGroups: [
            ...newState.byId[action.data.uid].deviceGroups.deviceGroups,
            ...action.data.relations.deviceGroups.deviceGroups
          ],
        } :
          newState.byId[action.data.uid].deviceGroups,
        vehicleGroups: [
          ...newState.byId[action.data.uid].vehicleGroups,
          ...action.data.relations.vehicleGroups
        ],
        rulesTemplates: [
          ...newState.byId[action.data.uid].rulesTemplates,
          ...action.data.relations.rulesTemplates
        ],
        geofenceTemplates: [
          ...newState.byId[action.data.uid].geofenceTemplates,
          ...action.data.relations.geofenceTemplates
        ],
      }

      return newState;
    }

    case actionType.UPDATE_GROUPS_TO_USER: {
      // console.log("Update Groups To User Data:", action.data);

      newState.byId[action.data.uid] = {
        ...newState.byId[action.data.uid],
        vehicleGroups: action.data.relations.vehicleGroups,
        rulesTemplates: action.data.relations.rulesTemplates,
        geofenceTemplates: action.data.relations.geofenceTemplates,
      }

      return newState;
    }

    case actionType.CLEAR_TEMP_NEW_UID: {
      return {
        ...newState,
        tempNewUID: "",
      };
    }

    default: {
      return state;
    }
  }
}