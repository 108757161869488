import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import Highlighter from 'react-highlight-words';
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { moveToPage } from '../../navigation/navigationService';
import {  
    parseTime, 
    parseDurationByDays,
} from '../../util/time';
import { 
    VEHICLE_PACKAGE_STATUSES, 
    VEHICLE_PACKAGE_CONTRACT_TYPES, 
} from '../../../../constants';
import {
    SearchOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
    Input,
    Modal,
    Table,
    Button,
    Select,
    message,
    Descriptions,
} from 'antd';

// React Actions
import { get_user_hierarchy } from "../../services/redux/actions/hierarchy";
import { get_vehicle_package_logs } from '../../services/redux/actions/vehiclePackageLog';
import { 
    get_vehicle_packages, 
    suspend_vehicle_package, 
    unsuspend_vehicle_package,
} from '../../services/redux/actions/vehiclePackage';

const FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES = {
    [VEHICLE_PACKAGE_CONTRACT_TYPES.CUSTOM]: {
        label: 'Custom',
        value: VEHICLE_PACKAGE_CONTRACT_TYPES.CUSTOM,
        durationInMonths: null
    },
    [VEHICLE_PACKAGE_CONTRACT_TYPES.TWELVE]: {
        label: '12 months',
        value: VEHICLE_PACKAGE_CONTRACT_TYPES.TWELVE,
        durationInMonths: 12
    },
    [VEHICLE_PACKAGE_CONTRACT_TYPES.TWENTY_FOUR]: {
        label: '24 months',
        value: VEHICLE_PACKAGE_CONTRACT_TYPES.TWENTY_FOUR,
        durationInMonths: 24
    }
}

class VehicleSubscriptionPage extends React.Component {
    state = {
        date: moment(),

        selectedUID: "",
        selectedVID: "",
        selectedVehicleDisplayName: "", // Used for export title

        dataLog: null,
        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        filteredInfo: {},
        sortedInfo: {
            order: 'descend',
            columnKey: 'updateAt',
        },
    }

    startTimeChange = (value) => {
        // console.log(`selected ${value}`);
        // console.log("Date:", moment(value).format());

        this.setState({
            ...this.state,
            startTime: value
        });
    }

    endTimeChange = (value) => {
        // console.log(`selected ${value}`);
        // console.log("Date:", moment(value).format());

        this.setState({
            ...this.state,
            endTime: value
        });
    }

    submit = () => {

        const vids = Object.keys(this.props.vehicles.byId)

        this.props.dispatch(get_vehicle_packages(vids));
    }

    setupDatasource = () => {
        const columns = [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status, rowData) => {

                    const isExpiring = rowData.isExpiring

                    let color = 'black'
                    switch (status) {
                        case VEHICLE_PACKAGE_STATUSES.PENDING:
                            color = 'blue'
                            break
                        case VEHICLE_PACKAGE_STATUSES.EXPIRED:
                            color = 'grey'
                            break
                        case VEHICLE_PACKAGE_STATUSES.ACTIVE:
                            color = 'green'
                            break
                        case VEHICLE_PACKAGE_STATUSES.SUSPENDED:
                        case VEHICLE_PACKAGE_STATUSES.TERMINATED:
                            color = 'red'
                            break
                        default:
                            break
                    }

                    return <div>
                        <p style={{ color, margin: 'auto' }}>
                            {status}
                        </p>
                        {isExpiring && <i>(Expiring soon)</i>}
                    </div>
                },
                filters: Object.values(VEHICLE_PACKAGE_STATUSES).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => record && record.status === value,

            },

            {
                title: "User",
                dataIndex: "user",
                // fixed: 'left',
                ...this.getColumnSearchProps('user')
            },

            {
                title: "Vehicle",
                dataIndex: "vehiclePlate",
                ...this.getColumnSearchProps('vehiclePlate')
            },

            {
                title: "Device IMEI",
                dataIndex: "imei",
                ...this.getColumnSearchProps('imei')
            },
            {
                title: "Contract Type",
                dataIndex: "contractType",
                filters: Object.values(FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES).map(c => c.label).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => record && record.contractType === value,
            },
            {
                title: 'Start At',
                dataIndex: 'startAt',
                key: 'startAt',
                render: time => parseTime(time),
                sorter: (a, b) => a.startAt - b.startAt,
            },
            {
                title: 'End At',
                dataIndex: 'endAt',
                key: 'endAt',
                render: time => parseTime(time),
                sorter: (a, b) => a.endAt - b.endAt,
            },
            {
                title: 'Duration',
                dataIndex: 'duration',
                key: 'duration',
                render: duration => parseDurationByDays(duration),
                sorter: (a, b) => a.duration - b.duration,
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: time => parseTime(time),
                sorter: {
                    compare: (a, b) => a.createdAt - b.createdAt,
                    multiple: 1
                },
                defaultSortOrder: 'descend',
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: time => parseTime(time),
                sorter: {
                    compare: (a, b) => a.updatedAt - b.updatedAt,
                    multiple: 2
                },
                defaultSortOrder: 'descend',
            },
            {
                title: "Actions",
                fixed: 'right',
                render: rowData => {
                    const isTerminated = rowData.status === VEHICLE_PACKAGE_STATUSES.TERMINATED

                    if (isTerminated) return null

                    const OPTIONS = {
                        ADD: {
                            label: 'Add',
                            disabled: rowData.vehiclePackageId,
                            action: () => {
                                const vid = rowData.key
                                const user = this.findUserForVehicleFromHierarchy(vid)

                                this.props.dispatch(moveToPage('/VehicleSubscription/Add', {
                                    vid: vid,
                                    uid: user && user.uid,
                                }))
                            }
                        },
                        EDIT:
                        {
                            label: 'Edit',
                            disabled: !rowData.vehiclePackageId || rowData.status === VEHICLE_PACKAGE_STATUSES.SUSPENDED,
                            action: () => {
                                console.log('edit')

                                this.props.dispatch(moveToPage('/VehicleSubscription/Edit', {
                                    vehiclePackageId: rowData.vehiclePackageId,
                                }))
                            }
                        },
                        RENEW: {
                            label: 'Renew',
                            disabled: !rowData.vehiclePackageId || rowData.status === VEHICLE_PACKAGE_STATUSES.SUSPENDED,
                            action: () => {
                                console.log('renew')

                                this.props.dispatch(moveToPage('/VehicleSubscription/Renew', {
                                    vehiclePackageId: rowData.vehiclePackageId,
                                }))
                            }
                        },
                        SUSPEND: {
                            label: <span style={{ color: 'red' }}>Suspend</span>,
                            hidden: !rowData.vehiclePackageId || rowData.status === VEHICLE_PACKAGE_STATUSES.SUSPENDED,
                            action: () => {
                                console.log('suspend')
                                const { vid, vehiclePlate, user } = rowData

                                Modal
                                .confirm({
                                        title: `Confirm suspend ${vehiclePlate} ?`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: (
                                            <ul>
                                                <li>{user} will be affected by this immediately</li>
                                                <li>This action is permanent until you undo it</li>
                                                <li>You can still remove this suspension later</li>
                                            </ul>
                                        ),
                                        onOk: () => {
                                            this.props.dispatch(suspend_vehicle_package(vid))
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                            }
                        },
                        UNSUSPEND: {
                            label: <span style={{ color: 'green' }}>Unsuspend</span>,
                            hidden: !rowData.vehiclePackageId || rowData.status !== VEHICLE_PACKAGE_STATUSES.SUSPENDED,
                            action: () => {
                                console.log('unsuspend')
                                const { vid, vehiclePlate, user } = rowData


                                Modal
                                    .confirm({
                                        title: `Confirm unsuspend ${vehiclePlate} ?`,
                                        icon: <CheckCircleOutlined />,
                                        content: (
                                            <ul>
                                                <li>{user} will be affected by this immediately</li>
                                                <li>This action is permanent until you undo it</li>
                                                <li>You can still suspend later</li>
                                            </ul>
                                        ),
                                        onOk: () => {
                                            this.props.dispatch(unsuspend_vehicle_package(vid))
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                            }
                        }
                    }

                    return (
                        <Select
                            style={{ width: '100%' }}
                            placeholder={`Select an action`}
                            onChange={key => {
                                const option = OPTIONS[key]
                                option && option.action && option.action()
                            }}
                        >
                            {
                                Object.keys(OPTIONS)
                                    .map(key => {
                                        const option = OPTIONS[key]
                                        if (!option.hidden) {
                                            return <Select.Option
                                                key={key}
                                                disabled={option.disabled}

                                            >
                                                {option.label}
                                            </Select.Option>
                                        }
                                        return null
                                    })

                            }
                        </Select>
                    )
                }
            }
        ]

        const dataSource =
            Object.values(this.props.vehicles.byId)
                .map(vehicle => {
                    const vehiclePackage = Object.values(this.props.vehiclePackage.byId).find(vehiclePackage => vehiclePackage.vid === vehicle.vid)

                    const device = vehicle && this.props.devices.byId[vehicle.dvid]
                    const user = this.findUserForVehicleFromHierarchy(vehicle.vid)
                    const duration = (vehiclePackage && (vehiclePackage.endAt - vehiclePackage.startAt)) || 0

                    const contractType = vehiclePackage
                        && FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES[vehiclePackage.contractType]
                        && FORMATTED_VEHICLE_PACKAGE_CONTRACT_TYPES[vehiclePackage.contractType].label

                    const isExpiring = getIsExpiring(vehiclePackage)

                    function getIsExpiring(vehiclePackage) {
                        const MS_IN_DAY = 24 * 60 * 60 * 1000
                        const IS_EXPIRING_DAYS_THRESHOLD = 3

                        if (!vehiclePackage) return false

                        const daysLeft = (vehiclePackage.endAt - moment.now()) / MS_IN_DAY
                        const isActive = vehiclePackage.status === VEHICLE_PACKAGE_STATUSES.ACTIVE
                        const isLessThanThreshold = daysLeft < IS_EXPIRING_DAYS_THRESHOLD

                        const isExpiring = isActive && isLessThanThreshold

                        return isExpiring
                    }

                    return {
                        ...vehiclePackage,
                        key: vehicle.vid,
                        user: user && `${user.userEmail} (${user.userRole})`,
                        vehiclePlate: vehicle && vehicle.vehiclePlate,
                        imei: device && device.dvid && device.dvid.split('#')[1],
                        duration: duration,
                        createdAt: vehiclePackage && vehiclePackage.createdAt,
                        contractType: contractType,
                        isExpiring,
                    }
                })
                .sort((a, b) => {
                    if (a.isExpiring && !b.isExpiring) return -1
                    if (a.vehiclePackageId && !b.vehiclePackageId) return -1
                    else return 1
                })

        this.setState({
            columns, dataSource
        })
    }

    findUserForVehicleFromHierarchy = vid => {
        /**1. Go through hierarchy to find user who has access to this vehicle */
        const users = Object.values(this.props.hierarchy.byId).filter(user => {
            const vids = (user.vehicleGroups && user.vehicleGroups.map(vg => vg.vehicles).reduce((a, b) => [...a, ...b], [])) || []
            return vids.find(_vid => _vid === vid)
        })

        /**
         * 2. It's possible there are multiple users accessing the same vehicle, e.g. distributor & fleetOwner.
         * In this situation, the non-currently signed in user is prioritized. Else, return the sole user
         */
        if (users.length > 1) {
            return users.find(user => user.uid !== this.props.user.uid)
        } else {
            return users[0]
        }

    }

    componentDidMount = () => {
        this.submit()
        this.setupDatasource()
        this.props.user && this.props.dispatch(get_user_hierarchy(this.props.user.uid))
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setupDatasource()
        }

        if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
            this.props.user && this.props.dispatch(get_user_hierarchy(this.props.user.uid))
        }

        if (
            JSON.stringify(Object.keys(prevProps.vehicles.byId).length) !== JSON.stringify(Object.keys(this.props.vehicles.byId).length)
        ) {
            this.submit()
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => { this.searchInput = node }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block',
                    }}
                />

                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    size="small"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    style={{
                        width: 90,
                        marginRight: 8,
                    }}
                >
                    Search
                </Button>

                <Button
                    size="small"
                    style={{ width: 90 }}
                    onClick={() => this.handleReset(clearFilters)}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                textToHighlight={text ? text : ""}
                searchWords={[this.state.searchText]}
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                autoEscape
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();

        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();

        this.setState({ searchText: '' });
    };

    getVehiclePackageLogTable = vehiclePackageId => {
        if (!vehiclePackageId) return null
        const columns = [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: status => {

                    let color = 'black'
                    switch (status) {
                        case VEHICLE_PACKAGE_STATUSES.PENDING:
                            color = 'blue'
                            break
                        case VEHICLE_PACKAGE_STATUSES.EXPIRED:
                            color = 'grey'
                            break
                        case VEHICLE_PACKAGE_STATUSES.ACTIVE:
                            color = 'green'
                            break
                        case VEHICLE_PACKAGE_STATUSES.SUSPENDED:
                        case VEHICLE_PACKAGE_STATUSES.TERMINATED:
                            color = 'red'
                            break
                        default:
                            break
                    }

                    return <p style={{ color, margin: 'auto' }}>{status}</p>
                },
                filters: Object.values(VEHICLE_PACKAGE_STATUSES).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => record && record.status === value,

            },
            {
                title: 'Start At',
                dataIndex: 'startAt',
                key: 'startAt',
                render: time => parseTime(time),
                sorter: (a, b) => a.startAt - b.startAt,
            },
            {
                title: 'End At',
                dataIndex: 'endAt',
                key: 'endAt',
                render: time => parseTime(time),
                sorter: (a, b) => a.endAt - b.endAt,
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: time => parseTime(time),
                sorter: {
                    compare: (a, b) => a.createdAt - b.createdAt,
                    multiple: 1
                },
                defaultSortOrder: 'descend',
            }
        ]

        const vehiclePackageLogs = this.props.vehiclePackageLog.byId[vehiclePackageId]

        const dataSource = vehiclePackageLogs && vehiclePackageLogs.map(log => {
            const duration = (log && (log.endAt - log.startAt)) || 0
            return {
                key: log.createdAt,
                status: log.status,
                startAt: log.startAt,
                endAt: log.endAt,
                createdAt: log.createdAt,
                updatedAt: log.updatedAt,
                duration: duration,
                ...log
            }
        })

        return (
            <div style={{ width: '80%', marginLeft: 50, }}>
                <Table
                    loading={this.props.style.isLoadingSubmit}
                    columns={columns}
                    dataSource={dataSource}
                />
            </div>
        )
    }

    render() {
        const {
            columns = [],
            dataSource = [],
        } = this.state;

        const packagesByType = {}
        dataSource && dataSource.map(d => {

            if (!d.status) return null

            if (!packagesByType[d.status]) {
                packagesByType[d.status] = 1
            }
            packagesByType[d.status]++

            return null
        })

        return (
            <div className="page-container">
                <Page title="Vehicle Subscriptions">

                    <Descriptions column={1} title="Packages summary">
                        {
                            Object.keys(packagesByType).map(type => {
                                return <Descriptions.Item key={type} label={type}>{packagesByType[type]}</Descriptions.Item>
                            })
                        }
                    </Descriptions>
                    <div>
                        <Table
                            expandable={{
                                expandedRowRender: record => this.getVehiclePackageLogTable(record.vehiclePackageId),
                                rowExpandable: record => record.vehiclePackageId,
                            }}
                            onExpand={(isExpand, rowData) => {
                                const vid = rowData.vid
                                if (isExpand) {
                                    if (rowData.vehiclePackageId) {
                                        const vids = [vid]
                                        this.props.dispatch(get_vehicle_package_logs(vids))
                                    } else {
                                        message.error('This vehicle has no package')
                                    }
                                }
                            }}
                            pagination={{ pageSize: 10 }}
                            loading={this.props.style.isLoadingSubmit}
                            columns={columns}
                            scroll={{
                                y: window.innerHeight,
                                x: columns && columns.length * 150,
                            }}
                            dataSource={dataSource}
                        />
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state,
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    hierarchy: state.v2.hierarchy,
    vehicleLog: state.v2.vehicleLog,
    vehicles: state.v2.vehicles,
    vehiclePackage: state.v2.vehiclePackage,
    vehiclePackageLog: state.v2.vehiclePackageLog,
    devices: state.v2.devices,
});

const ConnectedVehicleInspectorPage = connect(mapStateToProps)(VehicleSubscriptionPage);
export default showSecondarySidebar(false)(ConnectedVehicleInspectorPage);