import 
    React, 
    { 
        useState,
        useEffect, 
        // useRef 
    } 
from 'react';
import { useDispatch, useSelector } from 'react-redux'

import NEWGoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { getLatLngCenter } from './methods/getLatLngCenter';
import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../constants";
import {
    Polygon,
    GoogleMap,
    // InfoWindow,
    OverlayView,
    DrawingManager,
} from '@react-google-maps/api';

// Redux Actions
import { clear_selected_geofence_geoid } from '../../services/redux/actions/geofences';
import {
    // set_map_control,
    set_new_geofence_from_map
} from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const GoogleMapsComponentEditPolygon = (props) => {
    const dispatch = useDispatch()

    // State
    const [mapRef, setMapRef] = useState(null);
    const setPolygonRef = useState(null)[1];
    const [drawingManagerRef, setDrawingManagerRef] = useState(null);

    // Redux Stores
    const mapControl = useSelector(state => state.v2.mapControl);
    const geofenceTemplates = useSelector(state => state.v2.geofenceTemplate);

    const returnLatestGeofenceTemplateColorByGTIDs = (gtidList) => {
        let latestTemplate = {};

        gtidList.forEach((currGTID) => {
            if (!latestTemplate.createdAt || geofenceTemplates.byId[currGTID].createdAt > latestTemplate.createdAt) {
                latestTemplate = geofenceTemplates.byId[currGTID];
            }
        })

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }
   
    useEffect(() => {
        if (mapRef && mapControl.geofence && mapControl.geofence.coordinates && mapControl.geofence.coordinates.length) {
            const bounds = new window.google.maps.LatLngBounds();

            mapControl.geofence.coordinates.map(c => {
                bounds.extend(c)
                return null;
            })

            mapRef.fitBounds(bounds)
        }
    }, [mapControl, mapRef])

    const putPolygonComplete = polygon => {
        // console.log(polygon);

        // remove previously drawn polygon
        polygon.setMap(null);

        // setting up new polygon
        mapControl.geofence.coordinates = polygon.getPath().getArray().map(coordinate => {
            return {
                lat: coordinate.lat(),
                lng: coordinate.lng(),
            }
        })

        dispatch(set_new_geofence_from_map(mapControl.geofence));

        // revert drawingMode to null, ie drag
        if (drawingManagerRef) {
            drawingManagerRef.setDrawingMode(null);
        }

        dispatch(clear_selected_geofence_geoid());
    }

    return (
        <GoogleMap
            id = 'example-map'
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
        >

            {/* Polygon */}
            {
                mapControl.geofence && mapControl.geofence.coordinates &&
                <div>
                    <Polygon
                        onLoad = {ref => setPolygonRef(ref)}
                        path = {mapControl.geofence.coordinates}
                        options = {{
                            // strokeColor: "#ff0000",
                            strokeColor: returnLatestGeofenceTemplateColorByGTIDs(mapControl.selectedGeofenceTemplates),
                        }}
                    />
                    
                    {/* <InfoWindow position = {getLatLngCenter(mapControl.geofence.coordinates)}>
                        <div>
                            <h4>{mapControl.geofence.geofenceName}</h4>

                            {
                                mapControl.geofence.geofenceComment && (
                                    <div>{mapControl.geofence.geofenceComment}</div>
                                )
                            }
                        </div>
                    </InfoWindow> */}
                    
                    <OverlayView
                        options = {{ disableAutoPan: true }}
                        position = {getLatLngCenter(mapControl.geofence.coordinates)}
                        mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset = {(width, height) => ({
                            x: -(width / 2),
                            y: -height - 3,
                        })}
                    >
                        <div 
                            style = {{
                                background: 'white',

                                border: '1px solid #ccc',
                                borderRadius: '10px',

                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 8,
                                paddingBottom: 1,
                            }}
                        >
                            <h4>{mapControl.geofence.geofenceName}</h4>
                            <div>{mapControl.geofence.geofenceComment}</div>
                        </div>
                    </OverlayView>
                </div>
            }

            {
                <DrawingManager
                    onLoad = {ref => setDrawingManagerRef(ref)}
                    drawingMode = {null}
                    options = {{
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                window.google.maps.drawing.OverlayType.POLYGON,
                            ],
                        },
                        polygonOptions: {
                            strokeColor: "#ff0000",
                        }
                    }}

                    onPolygonComplete = {polygon => putPolygonComplete(polygon)}
                />
            }

        </GoogleMap>
    )
}

const GoogleMapsComponentWithLoadScript = (props) => NEWGoogleMapsLoadScript(GoogleMapsComponentEditPolygon, props)

export default GoogleMapsComponentWithLoadScript