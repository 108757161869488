import SvgGpsFixed from "../assets/components/GpsFixed.js";
import SvgGpsOff from "../assets/components/GpsOff.js";
import SvgHourglassEmpty from "../assets/components/HourglassEmpty.js";
import SvgHourglassFull from "../assets/components/HourglassFull.js";
import SvgIosSpeedometer from "../assets/components/IosSpeedometer.js";
import SvgLocalParking from "../assets/components/LocalParking.js";
import SvgSignalCellular4Bar from "../assets/components/SignalCellular4Bar.js";
import SvgSignalCellularOff from "../assets/components/SignalCellularOff.js";
import Info from "../assets/components/Info.js";

import SVG_ALL from '../assets/svg/icon_all.svg' ;
import SVG_MOVING from '../assets/svg/iconmonstr-navigation-1.svg' ;
import SVG_IDLING from '../assets/svg/iconmonstr-media-control-49.svg' ;
import SVG_PARKING from '../assets/svg/icon_parking.svg' ;
import SVG_DISCONNECT from '../assets/svg/icon_ligthing.svg' ;

import SVG_INFO from '../assets/svg/icon-info.svg'
import SVG_WARNING from '../assets/svg/icon-warning.svg'
import SVG_CRITICAL from '../assets/svg/icon-critical.svg'
import SVG_CONTROL from '../assets/svg/icon-control-24px.svg'

const EVENT_SVG = {
    gpsFull: SvgGpsFixed,
    gpsNo: SvgGpsOff,
    idleOver: SvgHourglassFull,
    idleNormal: SvgHourglassEmpty,
    parking: SvgLocalParking,
    cellFull: SvgSignalCellular4Bar,
    cellNo: SvgSignalCellularOff,
    speed: SvgIosSpeedometer,
    info: Info
}

const ALL_VEHICLE_TRNASIT_STATUS = [ 'ALL', 'MOVING', 'IDLING', 'PARKING', 'DISCONNECTED' ]
const ALL_EVENT_TYPE = [ 'ALL' ]

const VEHICLE_TRNASIT_STATUS_OBJECT = {
    'ALL': 'ALL',
    'MOVING': 'MOVING',
    'IDLING': 'IDLING',
    'PARKING': 'PARKING',
    'DISCONNECTED': 'DISCONNECTED' 
}

const EVENT_TICKET_TYPES_OBJECT = {
    'ALL': "ALL",
    'INFO': "INFO",
    'WARNING': "WARNING",
    'CRITICAL': "CRITICAL",
    'CONTROL': "CONTROL"
};

const VEHICLE_TRNASIT_STATUS_COLOR = {
    [VEHICLE_TRNASIT_STATUS_OBJECT.ALL] : "#C4C4C4",
    [VEHICLE_TRNASIT_STATUS_OBJECT.MOVING] : "#52C41A",
    [VEHICLE_TRNASIT_STATUS_OBJECT.IDLING] : "#FFB517",
    [VEHICLE_TRNASIT_STATUS_OBJECT.PARKING] : "#0488DB",
    [VEHICLE_TRNASIT_STATUS_OBJECT.DISCONNECTED] : "#333333",
    'NULL': "#333333"
}

const VEHICLE_TRANSIT_STATUS_ICON = {
    [VEHICLE_TRNASIT_STATUS_OBJECT.ALL]: SVG_ALL,
    [VEHICLE_TRNASIT_STATUS_OBJECT.MOVING]: SVG_MOVING,
    [VEHICLE_TRNASIT_STATUS_OBJECT.IDLING]: SVG_IDLING, 
    [VEHICLE_TRNASIT_STATUS_OBJECT.PARKING]: SVG_PARKING,
    [VEHICLE_TRNASIT_STATUS_OBJECT.DISCONNECTED]: SVG_DISCONNECT
}

const EVENT_TICKET_COLOR = {
    [EVENT_TICKET_TYPES_OBJECT.ALL]: "#C4C4C4",
    [EVENT_TICKET_TYPES_OBJECT.INFO]: "#52C41A", //old color: #37c837
    [EVENT_TICKET_TYPES_OBJECT.WARNING]: "#FFB517", //old color: #7F2AFF
    [EVENT_TICKET_TYPES_OBJECT.CRITICAL]: "#0488DB", //old color: #D4FF2A
    [EVENT_TICKET_TYPES_OBJECT.CONTROL]: "#856B37", //old color: #2A2AFF,
    'NULL': "#333333"
};

const EVENT_TICKET_TYPES_ICON = {
    [EVENT_TICKET_TYPES_OBJECT.ALL]: SVG_ALL,
    [EVENT_TICKET_TYPES_OBJECT.INFO]: SVG_INFO,
    [EVENT_TICKET_TYPES_OBJECT.WARNING]: SVG_WARNING,
    [EVENT_TICKET_TYPES_OBJECT.CRITICAL]: SVG_CRITICAL,
    [EVENT_TICKET_TYPES_OBJECT.CONTROL]: SVG_CONTROL
}

export {
    ALL_VEHICLE_TRNASIT_STATUS,
    ALL_EVENT_TYPE,
    EVENT_SVG,
    EVENT_TICKET_COLOR,
    EVENT_TICKET_TYPES_ICON,
    EVENT_TICKET_TYPES_OBJECT,
    VEHICLE_TRNASIT_STATUS_COLOR,
    VEHICLE_TRANSIT_STATUS_ICON,
    VEHICLE_TRNASIT_STATUS_OBJECT
}