import 
  React, 
  { 
    useEffect,
  } 
from 'react';
import { useDispatch } from 'react-redux'
import firebase from "firebase/app";

import SASwitchingVersionPanel from './pages/SASwitchingVersionPanel'

// Redux Actions
import { sa_user_sign_in_action } from './services/redux/actions/user';

import './App.css';
import './index.css';

const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sa_user_sign_in_action(firebase.auth().currentUser.uid));
  }, [dispatch])

  return <SASwitchingVersionPanel />
}

export default App