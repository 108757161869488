import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";

import configureStore, { history } from './configureStore'

import {
    ConnectedRouter
} from "connected-react-router";
import {
    Switch,
    Route,
    Redirect
} from "react-router";

import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/storage";

import { config } from "./config";

/**
 * Pages
 */
import SALoginPage from "./pages/SALoginPage";

const store = configureStore({});

//Initialize Firebase Cloud Messaging (FCM)
firebase.initializeApp(config.firebaseConfig);

const ConnectedApp = ({ rootStore }) => {
    return (
        <Provider store={rootStore}>
            {/* <PersistGate persistor={persistor}> */}
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path="/Login" component={SALoginPage} />
                        <Route
                            path="/"
                            render={() => (
                                firebase.auth().currentUser ? (
                                    <App />
                                ) : (
                                    <Redirect to={{ pathname: "/Login" }} />
                                )
                            )}
                        />
                    </Switch>
                </ConnectedRouter>
            {/* </PersistGate> */}
        </Provider>
    );
};

firebase.auth().onAuthStateChanged(user => {
    ReactDOM.render(
        <ConnectedApp rootStore={store} />,
        document.getElementById("root")
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
