import { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from 'antd';
import { parseTime } from '../../util/time';
import { RULE_TYPE_ANT } from '../../../../constants';
import { wsContainer } from '../../services/websocket';

// Redux Actions
import { update_device } from '../../services/redux/actions/devices';
import { update_vehicle } from '../../services/redux/actions/vehicles';
import { user_sign_in_request } from '../../services/redux/actions/user';
import { update_notification_rt } from '../../services/redux/actions/notificationRT';

notification.config({
    placement: 'bottomRight',
    bottom: 50,
    duration: 60,
});

const BackgroundTask = () => {
    const user = useSelector(state => state.v2.user);
    const vehicles = useSelector(state => state.v2.vehicles.byId);
    const notificationRTs = useSelector(state => state.v2.notificationRT.byEVID);
    const notificationRTLatest = useSelector(state => state.v2.notificationRT.latestEVID);

    const dispatch = useDispatch();

    // const redux_update_device = useCallback((data) => dispatch(update_device(JSON.parse(data))), [dispatch])
    // const redux_update_vehicle = useCallback((data) => dispatch(update_vehicle(JSON.parse(data))), [dispatch])
    const redux_update_notification_rt = useCallback((data) => dispatch(update_notification_rt(JSON.parse(data))), [dispatch])

    // const timeDiff = (time) => moment(Number(time)).utc().diff(moment().utc())

    const usePrevious = (value) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        });

        return ref.current;
    }

    const prevNotificationRTLatest = usePrevious(notificationRTLatest);

    useEffect(() => {
        // console.log("Test");

        wsContainer(
            (err, data) => {
                // console.log("BG Tasks:", err, data);

                if (!err) {
                    if (data.mode === 'dev' && data.id) {
                        // console.log('[BackgroundTask] WebSoc -  Device', data.item);
                        
                        dispatch(update_device(data.item));
                    } 
                    else if (data.mode === 'vhc' && data.id) {
                        // console.log('[BackgroundTask] WebSoc - Vehicle', data.item);
                        
                        dispatch(update_vehicle(data.item));
                    } 
                    else if (data.mode === 'notify' && data.id) {
                        // console.log('[BackgroundTask] WebSoc - Notify', data);

                        dispatch(redux_update_notification_rt(JSON.stringify(data.item)));
                    }
                    else if (data.mode === 'geofence' && data.id) {
                        const vhcObject = {
                            vid: data.id,
                            geofenceProfile: {},
                            geofenceProfileChanged: true
                        }
                        vhcObject.geofenceProfile[data.item.geoid] = data.item.result;

                        dispatch(update_vehicle(vhcObject))
                    }
                }
            },
            () => {
                // console.log(`resubscribe...`);

                const uid = user && user.uid;

                if (uid) {
                    dispatch(user_sign_in_request(uid));
                }
            }
        )
    })

    const onNotificationComing = () => {
        // console.log("onNotificationComing:", user);

        if (user.preference && user.preference.in_app_notification && prevNotificationRTLatest !== notificationRTLatest) {
            const getOccurredVehicle = (notificationRTLatest) => {
                const latestNotification = notificationRTs[notificationRTLatest];
                const vehicle = latestNotification && vehicles[latestNotification.vid];

                return vehicle && vehicle.vehicleDisplayName;
            }

            const getDisplayEventType = (notificationRTLatest) => {
                const latestNotification = notificationRTs[notificationRTLatest];

                return latestNotification && latestNotification.eventType;
            }

            const getDisplayEventName = (notificationRTLatest) => {
                const latestNotification = notificationRTs[notificationRTLatest];

                return latestNotification && latestNotification.eventName;
            }

            const getEventAt = (notificationRTLatest) => {
                const latestNotification = notificationRTs[notificationRTLatest];

                return latestNotification && parseTime(latestNotification.createdAt);
            }

            // console.log("notificationRTLatest:", notificationRTLatest);

            if (notificationRTLatest) {
                const createdAt = getEventAt(notificationRTLatest);
                const message = getOccurredVehicle(notificationRTLatest);
                const description = getDisplayEventName(notificationRTLatest);
                const eventType = RULE_TYPE_ANT[getDisplayEventType(notificationRTLatest)];

                // console.log("eventType:", eventType);

                if (user.preference.in_app_notification_info) {
                    switch(eventType) {
                        case 'warn': {
                            user.preference.in_app_notification_warning
                            && notification[eventType]({
                                message,
                                description: `${description} at ${createdAt}`,
                                placement: `bottomRight`,
                            })

                            break;
                        }
                        // case 'notify':
                        case 'error': {
                            user.preference.in_app_notification_critical
                            && notification[eventType]({
                                message,
                                description: `${description} at ${createdAt}`,
                                placement: `bottomRight`,
                            })

                            break;
                        }
                        case 'info':
                        default: {
                            user.preference.in_app_notification_info
                            && notification[eventType]({
                                message,
                                description: `${description} at ${createdAt}`,
                                placement: `bottomRight`,
                            })
                            
                            break;
                        }
                    }
                }
            }

        }
    }

    useEffect(onNotificationComing, [notificationRTLatest, onNotificationComing])

    return null
}

export default BackgroundTask