import React from "react";
import { connect } from "react-redux";

import UserInfoTab from "./UserInfoTab";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../components/Button";
import { moveToPage } from "../../navigation/navigationService";
import { 
  Card, 
  Result
} from "antd";
import { 
  ROLE_TYPE,
  DISPLAY_USER_ROLE,
} from '../../../../constants';
import { 
  EditOutlined, 
  PlusOutlined, 
} from '@ant-design/icons';



// Redux Actions
import { select_user } from '../../services/redux/actions/hierarchy';

class CustomerManagementPage extends React.Component {
  refreshUserHierarchy = () => {
    if (this.props.hierarchy.selectedId) {
      // console.log(`select_user`);

      const uid = this.props.hierarchy.selectedId;
      const user = this.props.hierarchy.byId[uid];

      this.props.dispatch(select_user(user));
    }
  }

  componentDidMount = () => {
    this.refreshUserHierarchy()
  }

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(prevProps.user)!==JSON.stringify(this.props.user)) {
      this.refreshUserHierarchy()
    }
  }

  render() {
    const { hierarchy, user } = this.props;

    const rows = [
      [
        {
          title: "Name",
          value: (hierarchy.selectedId && hierarchy.byId[hierarchy.selectedId].userName) || ''
        },
        {
          title: "Email",
          value: (hierarchy.selectedId && hierarchy.byId[hierarchy.selectedId].userEmail) || ''
        }
      ],
      [
        {
          title: "Contact",
          value: (hierarchy.selectedId && hierarchy.byId[hierarchy.selectedId].userContact) || ''
        },
        {
          title: "Address",
          value: (hierarchy.selectedId && hierarchy.byId[hierarchy.selectedId].userAddress) || ''
        }
      ],
      [
        {
          title: "Role",
          value: (hierarchy.selectedId && DISPLAY_USER_ROLE(hierarchy.byId[hierarchy.selectedId].userRole)) || ''
        },
        {
          title: "",
          value: ""
        }
      ]
    ]


    if (!hierarchy.selectedId) {

      if (this.props.style.isLoadingSubmit){
        return (
          <div
            style = {{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Result
              title = "Loading"
              subTitle = {`Fetching user data`}
            />
          </div>
        )
      }

      return (
        <div
          style = {{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Result
            title = "No user selected"
            subTitle = {`Select a user from the sidebar`}
          />
        </div>
      )
    }


    return (
      <div>
        <Card
          title = {
            <span
              style = {{
                display: "inline-table",
                width: "100%",
                height: "56px",

                position: "absolute",
                top: 0,
                left: 0,

                color: this.props.style.textColor,
                backgroundColor: this.props.style.pageHeaderColor,
              }}
            >
              <p
                style = {{
                  fontWeight: "bold",
                  fontSize: "24px",
                  margin: "10px"
                }}
              >
                {hierarchy.selectedId ? hierarchy.byId[hierarchy.selectedId].userName : "User Name"}
              </p>
            </span>
          }
          style = {{
            margin: "5% 10%"
          }}
        >
          <div style = {{ padding: "10px 15px 10px 15px" }}>
            {
              rows.map(row => (
                <div
                  key = {row[0].title}
                  style = {{
                    display: "flex",
                    flexDirection: "row",
                    margin: "20px"
                  }}
                >
                  <div style = {{ width: "15%", fontWeight: "bold" }}>
                    {row[0].title}
                  </div>

                  <div style = {{ width: "30%", paddingLeft: "5px" }}>
                    {row[0].value}
                  </div>

                  <div style = {{ width: "10%" }} />

                  <div style = {{ width: "15%", fontWeight: "bold" }}>
                    {row[1].title}
                  </div>

                  <div style = {{ width: "30%", paddingLeft: "5px" }}>
                    {row[1].value}
                  </div>
                </div>
              ))
            }
          </div>
        </Card>

        <div
          style = {{
            margin: "5% 10%",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <div>
            {
              hierarchy.selectedId
              && hierarchy.byId[hierarchy.selectedId]
              && hierarchy.byId[hierarchy.selectedId].userRole !== ROLE_TYPE.FLEET_OPERATOR &&
                <PrimaryButton 
                  icon = {<EditOutlined/>} 
                  onClick = {() => this.props.dispatch(moveToPage("/UserManagement/Edit",  hierarchy.selectedId))}
                  style = {{ marginRight: 5 }}
                >
                  Edit user
                </PrimaryButton>
            }

            {
              hierarchy.selectedId
              && hierarchy.byId[hierarchy.selectedId]
              && hierarchy.byId[hierarchy.selectedId].userRole === ROLE_TYPE.FLEET_OPERATOR &&
                <PrimaryButton 
                  icon = {<PlusOutlined/>} 
                  onClick = {() => this.props.dispatch(moveToPage("/UserManagement/ConfigureUser",  hierarchy.selectedId))}
                  style = {{ marginRight: 5 }}
                >
                  Configure User
                </PrimaryButton>
            }
            
            {
              user
              && user.userRole
              && user.userRole !== ROLE_TYPE.FLEET_OWNER
              && user.userRole !== ROLE_TYPE.FLEET_OPERATOR &&
                <PrimaryButton 
                  icon = {<PlusOutlined/>} 
                  onClick = {() => this.props.dispatch(moveToPage("/UserManagement/AddVehicle"))}
                  style = {{ marginRight: 5 }}
                >
                  Add Vehicle
                </PrimaryButton>
            }

            {
              user.userRole
              && user.userRole
              && user.userRole !== ROLE_TYPE.FLEET_OWNER
              && user.userRole !== ROLE_TYPE.FLEET_OPERATOR &&
                <PrimaryButton 
                  icon = {<PlusOutlined/>} 
                  onClick = {() => this.props.dispatch(moveToPage("/UserManagement/AddDevice", {targetUser: hierarchy.selectedId}))}
                >
                  Add Device
                </PrimaryButton>
            }
          </div>
        </div>

        <UserInfoTab />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.v2.user,
  style: state.v2.style,
  hierarchy: state.v2.hierarchy,
});

// export default  connect(mapStateToProps)(CustomerManagementPage);

const ConnectedCustomerManagementPage = connect(mapStateToProps)(CustomerManagementPage);
export default showSecondarySidebar(true)(ConnectedCustomerManagementPage)