import React, { Component } from "react";
import { connect } from 'react-redux';

import moment from "moment";
import EventAvatar from "./EventAvatar";

import { EmptyIndicator } from "../../../components/custom-components/EmptyIndicator";
import { LoadingIndicator } from "../../../components/custom-components/LoadingIndicator";
import { 
    List, 
    message 
} from "antd";

// Redux Actions
import { set_selected_evid } from "../../../services/redux/actions/events";
import { set_selected_vehicle } from "../../../services/redux/actions/vehicles";
import { 
    set_map_control,
    add_enabled_event_pop_up,
} from "../../../services/redux/actions/mapControl";

import "./index.css";

class EventList extends Component {
    render() {
        const { 
            vid,
            events,
        } = this.props;

        const currEventObj = events.byId[vid];

        // console.log("Event Type List", events.byType[this.props.statusBar.selectedEventType]);

        if (this.props.style.isLoadingEvent) {
            return <LoadingIndicator />
        }

        if (!(currEventObj && Object.keys(currEventObj).length)) {
            return <EmptyIndicator />
        }

        return(
            <List
                className = "vehicle-list"
                dataSource = {events.byType[this.props.statusBar.selectedEventType] || events.byType["ALL"]}
                renderItem = {
                    (evid) => {
                        const currEvent = currEventObj[evid];
                        
                        // console.log("Current EVID:", evid);

                        return(
                            <div
                                // Choose an event.
                                onClick = {() => {
                                    if (currEvent.location) {
                                        this.props.dispatch(set_selected_evid(evid));
                                        this.props.dispatch(set_selected_vehicle(vid));
                                        this.props.dispatch(add_enabled_event_pop_up(evid));

                                        this.props.dispatch(set_map_control(1));
                                    }
                                    else {
                                        message.warning("No location was found for this event");
                                    }
                                }}
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-start",

                                    padding: 10,
                                    
                                    cursor: "pointer",
                                }}
                            >
                                <div
                                    style = {{ 
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <EventAvatar eventType = {currEvent.eventType} />
                                </div>

                                <div
                                    style = {{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <b>
                                        {`${currEvent.eventName} ${(currEvent.eventName === "OVERSPEED" && currEvent.speed && `${currEvent.speed.toFixed(2)}KM/H` )|| ''}`}
                                    </b>

                                    <div className = "description">
                                        <span>{moment(currEvent.createdAt).format("YYYY/MM/DD HH:mm:ss")}</span>

                                        <div className = 'address'>
                                            <p>{currEvent.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
            />
        )
    };
};

const mapStateToProps = (state) => ({
    style: state.v2.style,
    events: state.v2.events,
    vehicles: state.v2.vehicles,
    statusBar: state.v2.statusBar,
})

export default connect(mapStateToProps)(EventList);
