import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import MapControlPanel from '../MapControlPanel';
import POIGeofences from "./components/POIGeofences";
import PolygonGeofences from "./components/PolygonGeofences";
import NEWGoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import {
    GoogleMap,
    TrafficLayer,
} from '@react-google-maps/api';

// Redux Actions
import { set_map_control } from '../../services/redux/actions/mapControl';
import { clear_selected_device } from "../../services/redux/actions/devices.js";
import { clear_selected_geofence_geoid } from '../../services/redux/actions/geofences';
import {
    clear_enabled_vehicle_pop_ups,
    clear_enabled_geofence_pop_ups,
} from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const  GoogleMapsComponentGeofence = (props) => {
    const mapControl = useSelector(state => state.v2.mapControl);

    const dispatch = useDispatch();

    const [mapRef, setMapRef] = useState(null);

    // Initial mount of component
    useEffect(() => {
        // console.log('new google maps component onMount');

        dispatch(clear_selected_device());
        dispatch(clear_enabled_vehicle_pop_ups());
        dispatch(clear_selected_geofence_geoid());
        dispatch(clear_enabled_geofence_pop_ups());

    }, [dispatch])

    const getMapCenterAndZoom = () => {
        if (mapRef) {
            return {
                center: mapRef.getCenter(),
                zoom: mapRef.getZoom()
            }
        }

        return {
            center: mapControl.location,
            zoom: mapControl.zoom
        }
    }

    return (
        <GoogleMap
            id='example-map'
            center={getMapCenterAndZoom().center}
            zoom={getMapCenterAndZoom().zoom}
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
            onDragStart={() => {
                dispatch(set_map_control(0))
            }}
            // onDragEnd={() => {
            //     if (mapRef) {
            //         const lat = mapRef.getCenter().lat();
            //         const lng = mapRef.getCenter().lng();
            //         const zoom = mapRef.getZoom();

            //         mapRef.setCenter({ lat, lng });
            //         mapRef.setZoom(zoom);
            //     }
            // }}
        >
            <POIGeofences mapRef = {mapRef}/>
            <PolygonGeofences mapRef = {mapRef}/>

            {mapControl.uiControl.showLiveTraffic && <TrafficLayer/>}

            {/* <div className = "searchBar">
                <MapSearchBar/>
            </div> */}

            <div className = "controlPanel">
                <MapControlPanel disableToggleVehicleInfo = {true} disableToggleLiveTraffic = {true}/>
            </div>
        </GoogleMap>
    )
}

const GoogleMapsComponentWithLoadScript = (props) => NEWGoogleMapsLoadScript(GoogleMapsComponentGeofence, props)

export default GoogleMapsComponentWithLoadScript