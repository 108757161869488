export const GET_VEHICLE_GROUPS = "GET_VEHICLE_GROUPS";
export const ADD_VEHICLE_GROUP = "ADD_VEHICLE_GROUP";
export const EDIT_VEHICLE_GROUP = "EDIT_VEHICLE_GROUP";

export const REASSIGN_VEHICLE_GROUPS = "REASSIGN_VEHICLE_GROUPS";
export const ASSIGN_VEHICLE_GROUP = "ASSIGN_VEHICLE_GROUP";

export const UPDATE_VEHICLE_GROUP_AFTER_ADD = "UPDATE_VEHICLE_GROUP_AFTER_ADD";
export const UPDATE_VEHICLE_GROUP_AFTER_EDIT = "UPDATE_VEHICLE_GROUP_AFTER_EDIT";
export const UPDATE_VEHICLES_IN_VEHICLE_GROUP = "UPDATE_VEHICLES_IN_VEHICLE_GROUP";

export const CLEAR_TEMP_NEW_VGID = "CLEAR_TEMP_NEW_VGID";