import * as actionType from '../action-types/notificationRT';

const defaultState = {
    byEVID: {}, 
    allEVIDS: [],
    latestEVID: "",
    selectedEVID: "",
};

export const notificationRT = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        // case actionType.ARCHIVE_NOTIFICATION_RT: {
        //     const notifyRT = action.notifyRT;

        //     delete newState.byEVID[notifyRT.evid]
        //     newState.allEVIDS.filter(evid => evid !== notifyRT.evid)
            
        //     return newState;
        // }

        case actionType.UPDATE_NOTIFICATION_RT: {
            const notifyRT = action.notifyRT;

            newState.byEVID[notifyRT.evid] = notifyRT;
            newState.allEVIDS.push(notifyRT.evid);
            newState.latestEVID = notifyRT.evid;

            // console.log("UPDATE_NOTIFICATION_RT:", newState);

            return newState;
        }

        default: {
            return state;
        }
    }
}

// vid: "vhc#a17bf9b0-3ce7-11ea-aaf7-ad2d2391ff77"
// evid: "ev#eda9e0b0-473a-11ea-a6f9-17340d3cb4ff"
// ruid: "ru#710cc710-c54c-11e9-b0d5-35b3ebd91db5"
// rtid: "rt#100cc710-c54c-11e9-b0d5-35b3ebd91db5"
// eventName: "Engine Started"
// eventType: "INFO"
// eventResult: 1
// location: {lat: "3.047592166667", lon: "101.687254"}
// createdAt: 1580812900923
// eventCreatedAt: "ev#eda9e0b0-473a-11ea-a6f9-17340d3cb4ff#158081290