import * as API from "../../api";
import * as actionType from "../action-types/geofenceTemplate";

import { message } from "antd";
import { get_geofences_data } from './geofences';
import { goBackToPrev } from "../../../navigation/navigationService";
import {
  setLoadingSubmit,
  unsetLoadingSubmit
} from './style';

export const get_geofence_template = (geofenceTemplate, uid) => (dispatch) => {
    API.getGeofenceByTemplate(geofenceTemplate, uid)
    .then(data => {
        // console.log("Get Geofence Template Data:", data);

        data.geofenceTemplates && dispatch(get_geofence_template_success(data.geofenceTemplates))
        data.hasOwnProperty('geofences') && dispatch(get_geofences_data(data.geofences))
    })
}

const get_geofence_template_success = (geofenceTemplates) => {
    return {
        type: actionType.GET_GEOFENCE_TEMPLATE,
        geofenceTemplates: geofenceTemplates
    }
}

export const add_geofence_template = (uid, colorHexCode, geofenceTemplateName) => (dispatch) => {
    return (
        API.addGeofenceTemplate(uid, colorHexCode, geofenceTemplateName)
        .then((data) => {
            // console.log("Add Geofence Template Data:", data);
    
            if (data.err || data.message) {
                message.error(data.err || data.message);
            }
            else {
                message.success("Successfully Added Geofence Template");
    
                dispatch(add_geofence_template_success(data.geofenceTemplate));
            }
    
            return data.status;
        })
    );
}

const add_geofence_template_success = (geofenceTemplate) => {
    return {
        type: actionType.ADD_GEOFENCE_TEMPLATE,
        geofenceTemplate: geofenceTemplate
    }
}

export const edit_geofence_template = (geofenceTemplate) => (dispatch) => {
    return (
        API.editGeofenceTemplate(geofenceTemplate)
        .then((data) => {
            // console.log("Edit Geofence Template Data:", data);
    
            if (data.err || data.message) {
                message.error(data.err || data.message);
            }
            else {
                message.success("Successfully Edited Geofence Template");
    
                dispatch(edit_geofence_template_success(data.geofenceTemplate));
            }

            return data.status;
        })
    );
}

const edit_geofence_template_success = (geofenceTemplate) => {
    return {
        type: actionType.EDIT_GEOFENCE_TEMPLATE,
        geofenceTemplate: geofenceTemplate
    }
}

// Called in geofences actions
export const reassign_geofence_templates = (uid, selectedGeoID, gtidList) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.reassignGeofenceTemplates(uid, selectedGeoID, gtidList)
    .then(data => {
        // console.log("Reassign Geofence Templates Data:", data);

        if (data.errorMessage) {
            message.error(data.errorMessage);
            
            dispatch(unsetLoadingSubmit());
        }
        else {
            message.success("Successfully Reassigned Geofence Template(s)");

            dispatch(reassign_geofence_templates_success(data.geofenceTemplates));
            dispatch(goBackToPrev());

            dispatch(unsetLoadingSubmit());
        }
    })
}

const reassign_geofence_templates_success = (geofenceTemplates) => {
    return {
        type: actionType.REASSIGN_GEOFENCE_TEMPLATES,
        geofenceTemplates: geofenceTemplates
    }
}

export const assign_geofences_to_geofence_template = (uid, gtid, geoidList) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.assignGeofencesToGeofenceTemplate(uid, gtid, geoidList)
    .then(data => {
        // console.log("Assign Geofence To Geofence Template Data:", data);

        if (data.err) {
            message.error(data.err);
            
            dispatch(unsetLoadingSubmit());
        }
        else {
            message.success("Successfully Assigned Geofences To Geofence Template");

            dispatch(assign_geofence_to_geofence_template_success(data.geofenceTemplate));
            dispatch(goBackToPrev());

            dispatch(unsetLoadingSubmit());
        }
    })
}

const assign_geofence_to_geofence_template_success = (geofenceTemplate) => {
    return {
        type: actionType.ASSIGN_GEOFENCES_TO_GEOFENCE_TEMPLATE,
        geofenceTemplate: geofenceTemplate
    }
}

export const unassign_geofences_from_geofence_template = (uid, selectedGTID, geoidList) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.unassignGeofencesFromGeofenceTemplate(uid, selectedGTID, geoidList)
    .then(data => {
        // console.log("Unassign Geofence From Geofence Template Data:", data);

        if (data.err) {
            message.error(data.err);
            
            dispatch(unsetLoadingSubmit());
        }
        else {
            // message.success("Successfully Unassigned Geofence(s) From Geofence Template");

            dispatch(unassign_geofences_from_geofence_template_success(data.geofenceTemplate));
            dispatch(goBackToPrev());

            dispatch(unsetLoadingSubmit());
        }
    })
}

const unassign_geofences_from_geofence_template_success = (geofenceTemplate) => {
    return {
        type: actionType.UNASSIGN_GEOFENCES_FROM_GEOFENCE_TEMPLATE,
        geofenceTemplate: geofenceTemplate
    }
}

// Also called in create_geofence_with_api action and when uploading excel
export const update_geofence_template_geofences = (selectedGTIDList, newGeoID) => {
    return {
        type: actionType.UPDATE_GEOFENCE_TEMPLATE_GEOFENCES,
        selectedGTIDList: selectedGTIDList,
        newGeoID: newGeoID,
    }
}

export const clear_temp_new_gtid = () => {
    return {
        type: actionType.CLEAR_TEMP_NEW_GTID,
    }
}