import React from "react";
import {
    useDispatch, 
} from 'react-redux'
import { Form } from "antd";

import "../App.css";
import {
    IMAGE_RESOURCE, 
    COMPANY
} from "../constants";

import { goBackToPrev } from '../navigation/navigationService'
import {
    sa_user_sign_out
} from "../services/redux/actions/user";
import {
    signOutAccount
} from "../services/auth/authService"

import { config } from '../config';

const AppV1 = (props) => {

    const dispatch = useDispatch()

    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                flex: 1,
                alignItems: "center"
            }}
        >
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    backgroundImage:`url(${IMAGE_RESOURCE.LOGINBACKGROUND})`,
                    backgroundSize: "cover"
                }}
            />

            <div
                style={{
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "35px 30px 15px 30px",
                            borderRadius: "17px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <img
                                src={IMAGE_RESOURCE.LOGO}
                                alt={COMPANY.NAME}
                                style={{ height: "80px" }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <div
                                style={{
                                    width: "250px",
                                    paddingTop: "30px"
                                }}
                            >
                                <Form className="login-form">
                                    <Form.Item style={{ marginBottom: "15px" }}>
                                        <p>Opps, you need to go to our <a href={config.v1URL}>V1 Site</a></p>
                                    </Form.Item>
                                    <Form.Item>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <div>
                                                <div
                                                    className="ButtonLink"
                                                    onClick={() => {
                                                        signOutAccount(() => dispatch(sa_user_sign_out()))
                                                        dispatch(goBackToPrev())
                                                    }}
                                                    style={{ color: "black" }}
                                                >
                                                    Back
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: 'black',
                    borderRadius: "17px",
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    color: 'white',
                    position: 'absolute',
                    bottom: '2vh',
                    right: '5vw',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    zIndex: 1
                }}
            >
                {config.version}
            </div>
        </div>
    );
}

export default AppV1