export const GET_GEOFENCES = 'GET_GEOFENCES';
export const ARCHIVE_GEOFENCE = 'ARCHIVE_GEOFENCE';

export const EDIT_POI = 'EDIT_POI';
export const EDIT_POLYGON = 'EDIT_POLYGON';
export const EDIT_GEOFENCE = "EDIT_GEOFENCE";
export const CREATE_GEOFENCE = "CREATE_GEOFENCE";
export const DELETE_GEOFENCE = 'V2_DELETE_GEOFENCE'

export const SET_SELECTED_GEOFENCE_GEOID = "SET_SELECTED_GEOFENCE_GEOID";
export const CLEAR_SELECTED_GEOFENCE_GEOID = "CLEAR_SELECTED_GEOFENCE_GEOID";