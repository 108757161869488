import React from 'react';

const parseAddress = (lat, lon, address) => {
    return (
        <a
            target = "_blank"
            rel = "noopener noreferrer"
            href = {`https://www.google.com.my/maps/place/${lat},${lon}`}
        >
            {address ? address : `${lat}, ${lon}`}
        </a>
    );
}

export default parseAddress;