import
React,
{
  useEffect,
  useCallback
}
  from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Container
import AppV2 from '../../containers/AppV2'
import AppV1 from '../../containers/AppV1'

// Verion Redux Action
import { user_sign_in_request } from '../../containers/v2/services/redux/actions/user'

const SASwitchingVersionPanel = (props) => {

  const user = useSelector(state => state.user);
  // const stage = ((user && Object.keys(user).length) && (user.stage || 'UNKNOWN')) || 'NULL'
  const userStages = user.stages && user.stages.map(s => s.stage)
  const stage = userStages ? (userStages.includes('V2') && 'V2') || 'V1' : 'UNKNOWN' // user can have 1 or more stages. v2 takes precendence over v1

  const uid = user.uid;

  // console.log("user", user)
  // console.log("stage", stage)

  const dispatch = useDispatch();

  const redux_get_user_v2 = useCallback((uid) => dispatch(user_sign_in_request(uid)), [dispatch])

  useEffect(() => {
    switch (stage) {
      case 'V2': {
        redux_get_user_v2(uid)
        break;
      }
      default: {
        break;
      }
    }
  }, [dispatch, redux_get_user_v2, uid, stage])

  const renderContainer = () => {
    // console.log({ stage });

    switch (stage) {
      case 'V2': {
        return <AppV2 />
      }
      case "V1":
        {
          return <AppV1 />
        }
      case 'UNKNOWN':
      default: {
        return null;
      }
    }
  }

  return (
    <div>
      {renderContainer()}
    </div>
  )

}

export default SASwitchingVersionPanel