import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
} from "antd";

// Redux Actions
import { edit_rule_template } from "../../../services/redux/actions/ruleTemplate";

const FormItem = Form.Item;

class EditRuleTemplate extends Component {

    state = {
        ruleTemplateName: "",
    }

    submitForm = () => {
        const rtid = this.props.router.location.state
        const ruleTemplate = this.props.ruleTemplate.byId[rtid]

        const uid = this.props.user.uid
        const newRuleTemplate = {
            ...ruleTemplate,
            updatedBy: uid,
            templateName: this.state.ruleTemplateName
        }

        // console.log({
        //     newRuleTemplate,
        //     uid
        // })

        this.props.dispatch(edit_rule_template(uid, newRuleTemplate));
    }

    setFormDefaultValue = () => {
        const rtid = this.props.router.location.state
        const ruleTemplate = this.props.ruleTemplate.byId[rtid]

        if (!ruleTemplate) return

        this.setState({
            ruleTemplateName: ruleTemplate.templateName
        })
    }

    componentDidUpdate = prevProps => {
        if (JSON.stringify(this.props.ruleTemplate) !== JSON.stringify(prevProps.ruleTemplate)) {
            this.setFormDefaultValue()
        }
    }

    componentDidMount = () => {
        this.setFormDefaultValue()
    }

    render() {
        return (
            <div className = "page-container">
                <Page title = "Edit New Rule Template">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Rule Template Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Rule Template Name"
                                    value = {this.state.ruleTemplateName}
                                    onChange = {e => this.setState({ ruleTemplateName: e.target.value })}
                                />
                            </FormItem>

                            <div
                                style = {{
                                    padding: 5,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 10
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    onClick = {this.submitForm}
                                    disabled = {this.state.ruleTemplateName && this.state.ruleTemplateName.length === 0}
                                    loading = {this.props.style.isLoadingSubmit}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    ruleTemplate: state.v2.ruleTemplate,
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
});

const ConnectedEditRule = connect(mapStateToProps)(EditRuleTemplate);
export default showSecondarySidebar(false)(ConnectedEditRule)
