import * as ActionTypes from "../action-types/style";
import { 
    COLOR, 
    IMAGE_RESOURCE,
    SCREENLAYOUT
} from "../../../../../constants";

const INITIAL_STATE = {
  sidebarWidth: SCREENLAYOUT.FULL_SIDEBAR_WIDTH,
  sidebarColor: COLOR.BRAND_PRIMARY,
  pageHeaderColor: COLOR.BRAND_PRIMARY,
  tableHeaderColor: LightenDarkenColor(COLOR.BRAND_PRIMARY, 0.3),
  textColor: COLOR.TEXT_COLOUR,
  buttonColor: COLOR.BRAND_PRIMARY,
  logoUrl: IMAGE_RESOURCE.LOGO,
  isSidebarCollapsed : false,
  isLoadingFleetRecord: false,
  isLoadingEvent: false,
  isLoadingSubmit : false,
  toggleUpdate: false,
};

export const style = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOGGLE_UPDATE": {
      return {...state, toggleUpdate: !state.toggleUpdate}
    }

    case ActionTypes.EXPAND_SIDEBAR: {
      return { ...state, sidebarWidth: SCREENLAYOUT.EXPAND_SIDEBAR_WIDTH };
    }

    case ActionTypes.ORIGINAL_SIDEBAR: {
      return { ...state, sidebarWidth: SCREENLAYOUT.FULL_SIDEBAR_WIDTH };
    }

    case ActionTypes.TOGGLE_SIDEBAR: {
      return { ...state, isSidebarCollapsed: !state.isSidebarCollapsed };
    }

    case ActionTypes.CLOSE_SIDEBAR: {
      return { ...state, isSidebarCollapsed: true };
    }

    case ActionTypes.OPEN_SIDEBAR: {
      return { ...state, isSidebarCollapsed: false };
    }

    case ActionTypes.CHANGE_PAGE_HEADER_COLOR: {
      let newColor = LightenDarkenColor(action.color, 0.3);
      let newfontColor = getTextColor(action.color);
      let newbuttonColor = LightenDarkenColor(action.color, -0.2);

      return {
        ...state,
        sidebarColor: action.color,
        pageHeaderColor: action.color,
        tableHeaderColor: newColor,
        textColor: newfontColor,
        buttonColor: newbuttonColor
      };
    }

    case ActionTypes.SET_LOGO: {
      return { ...state, logoUrl: action.url };
    }

    case ActionTypes.SET_LOADING_FLEETRECORD: {
      return {...state, isLoadingFleetRecord: true }
    }

    case ActionTypes.UNSET_LOADING_FLEETRECORD: {
      return {...state, isLoadingFleetRecord: false}
    }

    case ActionTypes.SET_LOADING_EVENT: {
      return {...state, isLoadingEvent: true }
    }

    case ActionTypes.UNSET_LOADING_EVENT: {
      return {...state, isLoadingEvent: false}
    }

    case ActionTypes.SET_LOADING_SUBMIT: {
      // console.log("Loading 2");

      return {...state, isLoadingSubmit: true }
    }

    case ActionTypes.UNSET_LOADING_SUBMIT: {
      // console.log("Done Loading 2");

      return {...state, isLoadingSubmit: false}
    }

    default: {
      return state;
    }
  }
};

function LightenDarkenColor(color, percent) {
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

function getTextColor(col) {
  var rgbCol = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(col);

  var r = parseInt(rgbCol[1], 16);
  var g = parseInt(rgbCol[2], 16);
  var b = parseInt(rgbCol[3], 16);

  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  var textCol = yiq > 125 ? "black" : "white";

  return textCol;
}


