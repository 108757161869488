import * as ActionTypes from "../action-types/managementControl";

const defaultState = {
    currTab: ""
};

export const managementControl = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.SET_MANAGEMENT_TAB: {
            return {
                ...newState,
                currTab: action.newTab,
            };
        }

        case ActionTypes.CLEAR_MANAGEMENT_TAB: {
            return {
                ...newState,
                currTab: "",
            };
        }

        default: {
            return state;
        }
    }
}