import * as actionType from "../action-types/mapControl";
import { 
    LOCATION, 
    VEHICLE_STATUS,
    DISPLAY_RULE_TYPE 
} from "../../../../../constants";

const defaultState = {
    polygonOnMapList: [], // To clear out polygons that are drawn by react-google-map's drawing manager

    geofence: {}, 
    poiCoded: null, /* */
    location: LOCATION, /* */
    zoom: 7.5, /* */
    selectedGeofenceTemplates: [],

    geofenceFilterString: "",

    uiControl: {
        // Filter stuff
        durationMin: 0,
        selectedEventTypes: [
            DISPLAY_RULE_TYPE[0], 
            DISPLAY_RULE_TYPE[1], 
            DISPLAY_RULE_TYPE[2]
        ],
        selectedTransitTypes: [
            VEHICLE_STATUS.MOVING, 
            VEHICLE_STATUS.IDLING, 
            VEHICLE_STATUS.PARKING, 
            VEHICLE_STATUS.DISCONNECTED
        ],

        // info window
        showAllVehiclesInfoWindow: true,
        showAllGeofencesInfoWindow: true,

        // all geofence stuffs
        showAllPOIs: true,
        showAllZones: true,
        showAllGeofence: true,

        showInfoWindowEvents: [],
        showInfoWindowVehicles: [],
        showInfoWindowGeofences: [],

        // not used for now
        showLiveTraffic: false,
        showVehicleTrail: true,
    },


    /**
     * 0 = user, e.g. user draqgged
     * 2 = map, e.g. default start position
     * geoid/dvid/etc =  props, e.g. in the process of zooming into a prop
     */
    mapControl: 2,
};

export const mapControl = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state))

    // console.log('mapControl ', action, defaultState)

    switch(action.type) {
        case actionType.RESET_MAP_LOCATION: {
            // console.log('SET_MAP_LOCATION', action.location)
            newState.location = LOCATION;

            return newState;
        }

        case actionType.SET_MAP_LOCATION: {
            // console.log('SET_MAP_LOCATION', action.location)
            newState.location = action.location;

            return newState;
        }

        case actionType.SET_MAP_ZOOM: {
            newState.zoom = action.zoom;

            return newState;
        }

        case actionType.SET_MAP_CONTROL: {
            newState.mapControl = action.control

            return newState;
        }

        case actionType.SET_NEW_GEOFENCE: {
            newState.drawed = true;
            newState.geofence = action.geofence;

            return newState;
        }

        case actionType.CLEAR_GEOFENCE: {
            newState.geofence = {};
            newState.poiCoded = null;

            return newState;
        }

        case actionType.SET_SELECTED_GEOFENCE_TEMPLATES: {
            newState.selectedGeofenceTemplates = action.geofenceTemplateList;

            return newState;
        }

        case actionType.CLEAR_SELECTED_GEOFENCE_TEMPLATES: {
            newState.selectedGeofenceTemplates = [];

            return newState;
        }

        case actionType.ADD_MAP_SHAPE_INTO_LIST: {
            newState.polygonOnMapList.push(action.drawnShape);

            return newState;
        }

        case actionType.CLEAR_MAP_SHAPES_FROM_LIST: {
            newState.polygonOnMapList = []; // Clear array

            return newState;
        }

        case actionType.GET_BROWSER_LOC: {
            newState.location = action.location;

            return newState;
        }

        case actionType.SET_MAP_FILTERS: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    durationMin: action.durationMin,
                    selectedEventTypes: action.selectedEventTypes,
                    selectedTransitTypes: action.selectedTransitTypes,
                }
            };
        }

        case actionType.ADD_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowVehicles: !newState.uiControl.showInfoWindowVehicles.includes(action.enabledIdentifier) ?
                        [...newState.uiControl.showInfoWindowVehicles, action.enabledIdentifier] :
                        newState.uiControl.showInfoWindowVehicles,
                }
            };
        }

        case actionType.REMOVE_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowVehicles: newState.uiControl.showInfoWindowVehicles.filter(id=>id!==action.enabledIdentifier)
                }
            };
        }

        case actionType.CLEAR_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowVehicles: [],
                }
            };
        }

        case actionType.ADD_ENABLED_GEOFENCE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowGeofences: !newState.uiControl.showInfoWindowGeofences.includes(action.enabledIdentifier) ?
                        [...newState.uiControl.showInfoWindowGeofences, action.enabledIdentifier] :
                        newState.uiControl.showInfoWindowGeofences,
                }
            };
        }

        case actionType.CLEAR_ENABLED_GEOFENCE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowGeofences: [],
                }
            };
        }

        case actionType.ADD_ENABLED_EVENT_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowEvents: !newState.uiControl.showInfoWindowEvents.includes(action.enabledIdentifier) ?
                        [...newState.uiControl.showInfoWindowEvents, action.enabledIdentifier] :
                        newState.uiControl.showInfoWindowEvents,
                }
            };
        }

        case actionType.REMOVE_ENABLED_EVENT_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowEvents: newState.uiControl.showInfoWindowEvents.filter(id=>id!==action.enabledIdentifier)
                }
            };
        }

        case actionType.CLEAR_ENABLED_EVENT_POP_UPS: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowEvents: [],
                }
            };
        }

        case actionType.SET_NEW_GEOFENCE_FILTER_STRING: {
            return {
                ...newState,
                geofenceFilterString: action.newFilterString,
            };
        }

        case actionType.RESET_GEOFENCE_FILTER_STRING: {
            return {
                ...newState,
                geofenceFilterString: defaultState.geofenceFilterString,
            };
        }

        case actionType.ENABLE_SHOW_VEHICLE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllVehiclesInfoWindow: true,
                }
            };
        }

        case actionType.DISABLE_SHOW_VEHICLE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllVehiclesInfoWindow: false,
                }
            };
        }

        case actionType.ENABLE_SHOW_GEOFENCE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofencesInfoWindow: true,
                }
            };
        }

        case actionType.DISABLE_SHOW_GEOFENCE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofencesInfoWindow: false,
                }
            };
        }

        case actionType.ENABLE_SHOW_GEOFENCES: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofence: true,
                }
            };
        }

        case actionType.DISABLE_SHOW_GEOFENCES: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofence: false,
                }
            };
        }

        case actionType.ENABLE_SHOW_POIS: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllPOIs: true,
                }
            };
        }

        case actionType.DISABLE_SHOW_POIS: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllPOIs: false,
                }
            };
        }

        case actionType.ENABLE_SHOW_ZONES: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllZones: true,
                }
            };
        }

        case actionType.DISABLE_SHOW_ZONES: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllZones: false,
                }
            };
        }

        case actionType.ENABLE_SHOW_LIVE_TRAFFIC: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showLiveTraffic: true,
                }
            };
        }

        case actionType.DISABLE_SHOW_LIVE_TRAFFIC: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showLiveTraffic: false,
                }
            };
        }

        case actionType.ENABLE_SHOW_VEHICLE_TRAIL: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showVehicleTrail: true,
                }
            };
        }

        case actionType.DISABLE_SHOW_VEHICLE_TRAIL: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showVehicleTrail: false,
                }
            };
        }

        case actionType.RESET_UI_CONTROL: {
            return {
                ...newState,
                uiControl: defaultState.uiControl,
            };
        }

        default: {
            return state;
        }
    }
}