import { 
    COLOR, 
    IMAGE_RESOURCE,
    SCREENLAYOUT
} from "../../../constants";

const INITIAL_STATE = {
  sidebarWidth: SCREENLAYOUT.FULL_SIDEBAR_WIDTH,
  sidebarColor: COLOR.BRAND_PRIMARY,
  pageHeaderColor: COLOR.BRAND_PRIMARY,
  textColor: COLOR.TEXT_COLOUR,
  buttonColor: COLOR.BRAND_PRIMARY,
  logoUrl: IMAGE_RESOURCE.LOGO,
  isSidebarCollapsed : false,
  isLoadingFleetRecord: false,
  isLoadingEvent: false,
  isLoadingSubmit : false,
  toggleUpdate: false,
};

export const style = (state = INITIAL_STATE, action) => {
  switch (action.type) {



    case "EXPAND_SIDEBAR": {
      return { ...state, sidebarWidth: SCREENLAYOUT.EXPAND_SIDEBAR_WIDTH };
    }
    case "ORIGINAL_SIDEBAR": {
      return { ...state, sidebarWidth: SCREENLAYOUT.FULL_SIDEBAR_WIDTH };
    }
    case "TOGGLE_SIDEBAR": {
      return { ...state, isSidebarCollapsed: !state.isSidebarCollapsed };
    }
    case "CLOSE_SIDEBAR" : {
      return { ...state, isSidebarCollapsed: true };
    }
    case "OPEN_SIDEBAR": {
      return { ...state, isSidebarCollapsed: false };
    }
    case "SET_LOADING_SUBMIT": {
      // console.log("Loading 1");
      return {...state, isLoadingSubmit: true }
    }

    case "UNSET_LOADING_SUBMIT": {
      // console.log("Done Loading 1");

      return {...state, isLoadingSubmit: false}
    }



    default: {
      return state;
    }
  }
};


