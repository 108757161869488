
export const select_status = (status) => {

    // console.log("SELECT STATUS", status)

    return{
      type: "SELECT_STATUS",
      status: status
    }
  }


export const select_event_type = (eventType) => {
  return{
    type: "SELECT_EVENT_TYPE",
    eventType: eventType
  }
}
