import * as API from "../../api";
import * as actionType from "../action-types/rules";

import { message } from "antd";
import { goBackToPrev } from "../../../navigation/navigationService";
import { 
  setLoadingSubmit, 
  unsetLoadingSubmit,
} from './style';

// Redux Actions
import { add_rule_to_rule_template } from "./ruleTemplate";

export const get_rules = (rules) => {
  return {
    type: actionType.GET_RULES,
    rules: rules
  }
}

export const add_rule = (selectedRTID, newRule) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.addRule(selectedRTID, newRule)
  .then(data => {
    // console.log("Add Rule Data:", data);

    if (data.err) {
      message.error(data.err);
      
    }
    else {
      message.success("Successfully Added Rule");

      dispatch(add_rule_success(data.rule));
      dispatch(add_rule_to_rule_template(data.rule.ruid, selectedRTID));

      dispatch(goBackToPrev());
    }
  })
  .finally(()=>{
      dispatch(unsetLoadingSubmit());
  })
}

const add_rule_success = (data) => {
  return {
    type: actionType.ADD_RULE,
    data: data
  }
}

export const edit_rule = (editedRule) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.editRule(editedRule)
  .then(data => {
    // console.log("Edit Rule Data:", data);

    if (data.err) {
      message.error(data.err);
      
      dispatch(unsetLoadingSubmit());
    }
    else {
      message.success("Successfully Edited Rule");

      dispatch(edit_rule_success(data.rule));

      dispatch(goBackToPrev());

      dispatch(unsetLoadingSubmit());
    }
  })
}

const edit_rule_success = (data) => {
  return {
    type: actionType.EDIT_RULE,
    data: data
  }
}

// export const set_selected_ruid = (selectedRuID) => {
//   return {
//     type: actionType.SET_SELECTED_RUID,
//     selectedRuID: selectedRuID
//   }
// }