import React, { memo, useState, useEffect } from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux';

import { moveToPage } from '../../../navigation/navigationService';
import {
    Timeline,
    Descriptions,
} from 'antd';
import {
    parseTime,
    parseDuration,
} from '../../../util/time';
import {
    EVENT_TYPES,
    VEHICLE_COLOR,
    VEHICLE_STATUS
} from '../../../../../constants';
import {
    Marker,
    Polyline,
    InfoWindow,
    MarkerClusterer,
} from '@react-google-maps/api';

import info from '../../../../../img/Trip Record/info.svg';
import warning from '../../../../../img/Trip Record/warning.svg';
import critical from '../../../../../img/Trip Record/critical.svg';

const TripRecordRoute = (props) => {
    const { mapRef } = props;

    // Redux Store
    const router = useSelector(state => state.router);
    const events = useSelector(state => state.v2.events);
    const vehicles = useSelector(state => state.v2.vehicles);
    const deviceLog = useSelector(state => state.v2.deviceLog);
    const mapControl = useSelector(state => state.v2.mapControl);
    const tripRecord = useSelector(state => state.v2.tripRecord);
    const transitRecord = useSelector(state => state.v2.transitRecord);

    // State
    const [thisEventLogs, setThisEventLogs] = useState([]);
    const [thisDeviceLogs, setThisDeviceLogs] = useState([]);
    const [playbackMarkerDeviceLog, setPlaybackMarkerDeviceLog] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const {
            vid,
            trid,
            tsid,
            endTime,
            startTime,
        } = router.location.state || {}

        const vehicle = vehicles.byId[vid]
        const dvid = vehicle && vehicle.dvid
        const thisTransit = transitRecord.byTsid[tsid];
        const thisTripRecord = tripRecord.byTrID[trid];

        if (!dvid || !startTime || !endTime) return

        const currEvents = events.byId[vid];
        const currDeviceLog = deviceLog.byId[dvid];

        /**Format device logs */
        const newDeviceLogs = 
            currDeviceLog 
            && currDeviceLog.deviceLog 
            && currDeviceLog.deviceLog.length ?
                currDeviceLog.deviceLog
                .filter(log => log.location)
                .map(log => {
                    let isTripSelected;
                    if (!thisTripRecord) {
                        isTripSelected = false;
                    }
                    else {
                        isTripSelected = thisTripRecord.startTime <= log.createdAt && thisTripRecord.endTime >= log.createdAt
                    }

                    let isTransitSelected
                    if (!thisTransit) {
                        isTransitSelected = false;
                    } 
                    else {
                        isTransitSelected = thisTransit.startTime <= log.createdAt && thisTransit.endTime >= log.createdAt;
                    }

                    const lat = log.location && !isNaN(log.location.lat) && parseFloat(log.location.lat);
                    const lng = log.location && !isNaN(log.location.lon) && parseFloat(log.location.lon);

                    if (isNaN(lat) ||isNaN(lng)) return null

                    return {
                        ...log,
                        lat: Number(lat),
                        lng: Number(lng),
                        isTripSelected,
                        isTransitSelected
                    }
                })
                .filter(log => log)
                .sort((a, b) => a.deviceTime - b.deviceTime) :
                []

        /**Format event logs */
        const newEventLogs = 
            newDeviceLogs.length 
            && currEvents 
            && Object.values(currEvents).length ?
                Object.values(currEvents)
                .filter(eventLog => startTime <= eventLog.createdAt && endTime >= eventLog.createdAt)
                .map(eventLog => {
                    const lat = eventLog.location && !isNaN(eventLog.location.lat) && parseFloat(eventLog.location.lat)
                    const lng = eventLog.location && !isNaN(eventLog.location.lon) && parseFloat(eventLog.location.lon)

                    if (isNaN(lat) || isNaN(lng)) return null

                    return {
                        ...eventLog,
                        lat: Number(lat),
                        lng: Number(lng),
                    }
                })
                .filter(log => log)
                .sort((a, b) => a.createdAt - b.createdAt) :
                []

        const playbackMarkerDeviceLog = newDeviceLogs[deviceLog.markerPlaybackIndex];

        if (mapRef && newDeviceLogs.length && mapControl.mapControl !== 0) {
            const bounds = new window.google.maps.LatLngBounds();

            newDeviceLogs.forEach(c => bounds && bounds.extend(c));

            // console.log(`[GoogleMapsComponent] Setting map location by route`);

            mapRef.fitBounds(bounds, [10]);
        }

        setThisEventLogs(newEventLogs);
        setThisDeviceLogs(newDeviceLogs);
        setPlaybackMarkerDeviceLog(playbackMarkerDeviceLog);
    }, 
        [router, tripRecord, deviceLog, events, mapControl, mapRef, transitRecord.byTsid, vehicles.byId]
    )

    const getTransitRecordInfoWindow = () => {
        const selectedTransitRecord = router.location.state && transitRecord.byTsid[router.location.state.tsid]

        if (!selectedTransitRecord) return null

        const startPosition = 
            selectedTransitRecord.startLocation 
            && selectedTransitRecord.startLocation.lat 
            && selectedTransitRecord.startLocation.lon
            && { 
                lat: parseFloat(selectedTransitRecord.startLocation.lat), 
                lng: parseFloat(selectedTransitRecord.startLocation.lon) 
            }

        const endPosition = 
            selectedTransitRecord.endLocation 
            && selectedTransitRecord.endLocation.lat 
            && selectedTransitRecord.endLocation.lon
            && { 
                lat: parseFloat(selectedTransitRecord.endLocation.lat), 
                lng: parseFloat(selectedTransitRecord.endLocation.lon) 
            }

        if (!startPosition) return null

        return (
            <InfoWindow
                zIndex = {1000}
                position = {startPosition}
                options = {{
                    disableAutoPan: true,
                }}
            >
                <div style = {{ paddingTop: 10 }}>
                    <Timeline>
                        <Timeline.Item color = {VEHICLE_COLOR[selectedTransitRecord.transitStatus]}>
                            <div
                                style = {{
                                    display: `flex`,
                                    flexDirection: `row`,
                                    justifyContent: `space-between`
                                }}
                            >
                                <h4 style = {{ fontWeight: 'bold' }}>{selectedTransitRecord.transitStatus}</h4>
                            </div>

                            <div>
                                <h4>Duration</h4>
                                
                                {selectedTransitRecord.duration && parseDuration(selectedTransitRecord.duration)}

                                {
                                    selectedTransitRecord.transitStatus === VEHICLE_STATUS.MOVING && (
                                        <div>
                                            <h4>Distance</h4>
                                            {(selectedTransitRecord.totalMileage || 0).toFixed(2)} km
                                        </div>
                                    )
                                }

                                <h4>Summary</h4>
                                
                                <Timeline>
                                    <Timeline.Item color = "green">
                                        <h4>{parseTime(selectedTransitRecord.startTime)}</h4>
                                        <h4>{selectedTransitRecord.startAddress}</h4>

                                        <a
                                            href = {`https://www.google.com.my/maps/place/${startPosition.lat},${startPosition.lng}`}
                                            target = '_blank'
                                            rel = 'noopener noreferrer'
                                        >
                                            {`${startPosition.lat}, ${startPosition.lng}`}
                                        </a>
                                    </Timeline.Item>

                                    <Timeline.Item color = "red">
                                        <h4>{parseTime(selectedTransitRecord.endTime)}</h4>
                                        <h4>{selectedTransitRecord.endAddress}</h4>

                                        <a
                                            href = {`https://www.google.com.my/maps/place/${endPosition.lat},${endPosition.lng}`}
                                            target = '_blank'
                                            rel = 'noopener noreferrer'
                                        >
                                            {`${endPosition.lat}, ${endPosition.lng}`}
                                        </a>
                                    </Timeline.Item>
                                </Timeline>
                            </div>
                        </Timeline.Item>
                    </Timeline>
                </div>
            </InfoWindow>
        )
    }

    const getEventLogMarkersWithCluster = () => {
        // const zoom = mapRef && mapRef.getZoom();
        // const isZoomedSufficiently = zoom > 18;

        return (
            // isZoomedSufficiently && (
                // <MarkerClusterer maxZoom = {20}>
                <MarkerClusterer>
                    {
                        (clusterer) => {
                            return (
                                thisEventLogs.map(eventLog => {
                                    eventLog.lat = Number(eventLog.lat);
                                    eventLog.lng = Number(eventLog.lng);
        
                                    let dot
                                    switch (eventLog.eventType) {
                                        case EVENT_TYPES.CRITICAL:
                                            dot = critical
                                            break
                                        case EVENT_TYPES.WARNING:
                                            dot = warning
                                            break
        
                                        case EVENT_TYPES.INFO:
                                        default:
                                            dot = info
                                            break
                                    }
        
                                    const isShowEventInfoWindow = router.location.state && router.location.state.eventCreatedAt === eventLog.eventCreatedAt;
                                    
                                    return (
                                        <Marker
                                            key = {eventLog.eventCreatedAt}
                                            icon = {dot}
                                            clusterer = {clusterer}
                                            position = {{ lat: eventLog.lat, lng: eventLog.lng }}
                                            onClick = {() => {
                                                // const thisTripRecord = Object.values(tripRecord.byTrID).find(tr => {
                                                //     return tr.startTime <= eventLog.createdAt && tr.endTime >= eventLog.createdAt
                                                // })
    
                                                // const trid = thisTripRecord && thisTripRecord.trid
            
                                                dispatch(moveToPage(`TripRecord`, {
                                                    ...router.location.state,
                                                    // trid,
                                                    eventCreatedAt: eventLog.eventCreatedAt,
                                                }))
                                            }}
                                        >
                                            {
                                                isShowEventInfoWindow && 
                                                    <InfoWindow
                                                        zIndex = {1000}
                                                        position = {{ lat: eventLog.lat, lng: eventLog.lng }}
                                                        options = {{
                                                            disableAutoPan: true,
                                                        }}
                                                    >
                                                        <div style = {{ paddingTop: 10 }}>
                                                            <Timeline>
                                                                <Timeline.Item
                                                                    key = {eventLog.eventCreatedAt}
                                                                    dot = {
                                                                        <img
                                                                            src = {dot}
                                                                            alt = ""
                                                                            style = {{
                                                                                width: 16,
                                                                                height: 16,
                                                                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                                            }}
                                                                        />
                                                                    }
                                                                >
                                                                    {parseTime(eventLog.createdAt)}
                
                                                                    <h4 style = {{ fontWeight: 'bold' }}>{eventLog.eventType}</h4>
                
                                                                    <div>
                                                                        <p>{eventLog.eventName}</p>
                                                                    </div>
                                                                </Timeline.Item>
                                                            </Timeline>
                                                        </div>
                                                    </InfoWindow>
                                            }
                                        </Marker>
                                    );
                                })
                            );
                        }
                    }
                </MarkerClusterer>
            // )
        );
    }

    const getDeviceLogPaths = () => {
        const zoom = mapRef && mapRef.getZoom();
        const isZoomedSufficiently = zoom > 18;

        const DEVICE_LOG_TYPE = {
            MEMORY: {
                label: 'MEMORY',
                options: {
                    strokeColor: '#757575',
                    strokeOpacity: 0.75,
                    fillColor: '#757575',
                    fillOpacity: 0.75,
                }
            },
            SELECTED_TRANSIT: {
                label: 'SELECTED_TRANSIT',
                options: {
                    strokeColor: 'orange',
                    strokeOpacity: 0.75,
                    fillColor: 'orange',
                    fillOpacity: 0.75,
                    zIndex: 100,
                }
            },
            SELECTED_TRIP: {
                label: 'SELECTED_TRIP',
                options: {
                    strokeColor: 'blue',
                    strokeOpacity: 0.75,
                    fillColor: 'blue',
                    fillOpacity: 0.75,
                    zIndex: 100,
                }
            },
            NORMAL: {
                label: 'NORMAL',
                options: {
                    strokeColor: 'red',
                    strokeOpacity: 0.75,
                    fillColor: 'red',
                    fillOpacity: 0.75,
                }
            }
        }

        const getType = (log) => {
            const isTripSelected = log.isTripSelected;
            const isTransitSelected = log.isTransitSelected;
            const isMemory = log.devicePackage === "gps#memory";

            if (isMemory) return DEVICE_LOG_TYPE.MEMORY.label
            else if (isTransitSelected) return DEVICE_LOG_TYPE.SELECTED_TRANSIT.label
            else if (isTripSelected) return DEVICE_LOG_TYPE.SELECTED_TRIP.label
            else return DEVICE_LOG_TYPE.NORMAL.label
        }

        // Filters points that deviate too far from the previous point
        const filterPathPoints = (path) => {
            const maxDeviation = 1;
            let lastPoint = {};
            let filteredPath = [];
            
            // console.log("filterPathPoints 1:", path);
            
            path.forEach((currPoint) => {
                // console.log("filterPathPoints 2:", lastPoint, currPoint);

                if (lastPoint) {
                    // Only add the point if the point isn't too far from it's previous point
                    //---------------------------------------------------------------------------------------------------------
                    const latDiff = currPoint.lat - lastPoint.lat;
                    const lngDiff = currPoint.lng - lastPoint.lng;
                    const dist_points = latDiff * latDiff + lngDiff * lngDiff;
                    const radius = maxDeviation * maxDeviation;
    
                    if (dist_points < radius) {
                        filteredPath.push(currPoint);
                    }
                    //---------------------------------------------------------------------------------------------------------
                }
                else {
                    filteredPath.push(currPoint);
                }
    
                lastPoint = currPoint;
            })

            return filteredPath;
        }

        // Chunk device log points into arrays based on which trip summary they belong to
        // Is an object containing a trid and an array of points
        const paths = 
            tripRecord.formattedData.tripSummarys ?
                tripRecord.formattedData.tripSummarys.map((currSummary) => {
                    const currTripRecord = currSummary.tripRecord;

                    return ({
                        trid: currTripRecord.trid,
                        path: 
                            filterPathPoints( // Also remove any points in the paths that deviated too far
                                thisDeviceLogs
                                .filter((currLog) => currLog.createdAt >= currTripRecord.startTime && currLog.createdAt <= currTripRecord.endTime)
                                .map((currLog) => {
                                    const logType = getType(currLog);
                                    
                                    const newLog = {
                                        ...currLog,
                                        type: logType,
                                        options: DEVICE_LOG_TYPE[logType].options,
                                    }

                                    // console.log("newLog:", newLog);
                                    // console.log("Test:", currLog, newLog);
        
                                    return newLog;
                                })
                            ),
                    });
                }) :
                []

        // console.log("Paths:", paths);

        return (
            paths.map((pathObj, i) => {
                if (!pathObj.path[0]) return null
                
                let currPath = pathObj.path
    
                /**
                 * dk 22 feb 2021
                 * 
                 * Manually link up with previous path via last connected dot.
                 * This is required, else rendered paths would be disconnected.
                 */
                if (i > 0) {
                    const prevPathObj = paths[i - 1];
                    const finalLog = prevPathObj.path[prevPathObj.path.length - 1];
    
                    if (finalLog) {
                        currPath = [ finalLog, ...currPath ];
                    }
                }

                // console.log("Path:", path);

                // Will chunk a trip's currPath even more to allow transit status coloring
                const returnPolylinesWithColor = () => {
                    let prevType = null;
                    let currChunk = [];
                    let chunkList = [];

                    currPath.forEach((currPoint, j) => {
                        if ((prevType && currPoint.type !== prevType) || j === currPath.length - 1) {
                            // console.log("Creating new chunk!");

                            chunkList.push(currChunk);
                            currChunk = []; // Reset currChunk after pushing into chunkList
                        }
                        
                        currChunk.push(currPoint);

                        prevType = currPoint.type;
                    })

                    // console.log("chunkList:", chunkList);

                    return (
                        chunkList.map((chunk, j) => {
                            // console.log("chunk:", chunk);
                            // console.log("options:", DEVICE_LOG_TYPE[chunk[0].type].options);
                            
                            // Adding next chunk's point to connect the chunks together
                            if (j < chunkList.length - 1) {
                                const nextChunk = chunkList[j + 1];
                                const firstPoint = nextChunk[0];
                
                                if (firstPoint) {
                                    chunk = [ ...chunk, firstPoint ];
                                }
                            }
    
                            return (
                                <Polyline
                                    key = {`Chunk ${j}`}
                                    path = {chunk.map((log) => ({ lat: log.lat, lng: log.lng }))}
                                    options = {{
                                        // ...DEVICE_LOG_TYPE[chunk[0].type].options,
                                        ...(chunk[0] ? DEVICE_LOG_TYPE[chunk[0].type].options : {}), // Some chunks don't have points
                                        icons: [{
                                            icon: { path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                                            repeat: '100px',
                                            offset: '100%'
                                        }],
                                    }}
                                    onClick = {() => {
                                        // console.log("Polyline OnClick Event:", pathObj.trid);
                                        
                                        dispatch(moveToPage('TripRecord', { ...router.location.state, trid: pathObj.trid }));
                                    }}
                                    style = {{ cursor: `pointer` }}
                                />
                            );
                        })
                    );
                }
    
                return (
                    <div key = {i}>
                        {
                            i === 0 && currPath[0] && (
                                <Marker
                                    key = {`${i}start`}
                                    position = {currPath[0]}
                                >
                                    <InfoWindow
                                        zIndex = {1000}
                                        position = {currPath[0]}
                                        options = {{
                                            disableAutoPan: true,
                                        }}
                                    >
                                        <div
                                            style = {{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                                fontWeight: "bold",
                                            }}
                                        >
                                            {`Start Journey`}
                                        </div>
                                    </InfoWindow>
                                </Marker>
                            )
                        }

                        {
                            i === currPath.length - 1 && currPath[currPath.length - 1] && (
                                <Marker
                                    key = {`${i}end`}
                                    position = {currPath[currPath.length - 1]}
                                >
                                    <InfoWindow
                                        zIndex = {1000}
                                        position = {currPath[currPath.length - 1]}
                                        options = {{
                                            disableAutoPan: true,
                                        }}
                                    >
                                        <div
                                            style = {{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                                fontWeight: "bold",
                                            }}
                                        >
                                            {`End Journey`}
                                        </div>
                                    </InfoWindow>
                                </Marker>
                            )
                        }

                        {
                            isZoomedSufficiently &&
                                <MarkerClusterer maxZoom = {20}>
                                    {
                                        (clusterer) => {
                                            return (
                                                currPath.map((log, index) => {
                                                    const isShowDeviceLogInfoWindow = 
                                                        router.location.state 
                                                        && router.location.state.deviceLogDeviceTime === log.deviceTime;
        
                                                    return (
                                                        <Marker
                                                            key = {`${log.dvid}|${log.deviceTime}|${index}`}
                                                            clusterer = {clusterer}
                                                            position = {{ lat: log.lat, lng: log.lng }}
                                                            onClick = {() => {            
                                                                dispatch(moveToPage('TripRecord', {
                                                                    ...router.location.state,
                                                                    deviceLogDeviceTime: log.deviceTime,
                                                                }))
                                                            }}
                                                        >
                                                            {
                                                                isShowDeviceLogInfoWindow && (
                                                                    <InfoWindow
                                                                        zIndex = {1000}
                                                                        position = {{ lat: log.lat, lng: log.lng, }}
                                                                        options = {{
                                                                            disableAutoPan: true,
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style = {{
                                                                                height: 200,
                                                                                paddingTop: 10,
                                                                                overflow: 'scroll',
                                                                            }}
                                                                        >
                                                                            <Descriptions
                                                                                bordered
                                                                                size = {'small'}
                                                                                column = {1}
                                                                            >
                                                                                <Descriptions.Item label = {`Created At`}>
                                                                                    {parseTime(log.createdAt)}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`DeviceMessage`}>
                                                                                    {log.deviceMessage}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Location`}>
                                                                                    <a
                                                                                        target = '_blank'
                                                                                        rel = 'noopener noreferrer'
                                                                                        href = {`https://www.google.com.my/maps/place/${parseFloat(log.location.lat)},${parseFloat(log.location.lon)}`}
                                                                                    >
                                                                                        {`${log.location.lat}, ${log.location.lon}`}
                                                                                    </a>
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Speed`}>
                                                                                    {log.speed || 0} km/h
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Altitude`}>
                                                                                    {log.altitude}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Heading`}>
                                                                                    {log.heading} degree
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Fuel`}>
                                                                                    {log.fuel}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Device Time`}>
                                                                                    {parseTime(log.deviceTime)}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Package Time`}>
                                                                                    {log.packageTime}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`GPS Status`}>
                                                                                    {log.gpsStatus}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Engine Status`}>
                                                                                    {log.engineStatus}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Is Alive`}>
                                                                                    {log.isAlive}
                                                                                </Descriptions.Item>

                                                                                <Descriptions.Item label = {`Device Package`}>
                                                                                    {log.devicePackage}
                                                                                </Descriptions.Item>
                                                                            </Descriptions>
                                                                        </div>
                                                                    </InfoWindow>
                                                                )
                                                            }
                                                        </Marker>
                                                    );
                                                })
                                            );
                                        }
                                    }
                                </MarkerClusterer>
                        }

                        {returnPolylinesWithColor()}
                    </div>
                )
            })
        );
    }

    return (
        <div>
            {
                playbackMarkerDeviceLog && (
                    <Marker position = {{ lat: playbackMarkerDeviceLog.lat, lng: playbackMarkerDeviceLog.lng }}/>
                )
            }

            {getDeviceLogPaths()}
            {getTransitRecordInfoWindow()}
            {getEventLogMarkersWithCluster()}
        </div>

    )
}

export default memo(TripRecordRoute);