import * as actionType from '../action-types/ruleTemplate'

const defaultState = {
    byId: {}, 
    allIds: [],

    selectedId: null,

    relationByRules: {},
};

export const ruleTemplate = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case actionType.GET_RULE_TEMPLATES: {
            action.ruleTemplates.forEach(currRT => {
                newState.byId[currRT.rtid] = currRT;
                newState.relationByRules[currRT.rtid] = currRT.rules;
                
                if(!newState.allIds.includes(currRT.rtid)) newState.allIds.push(currRT.rtid);
            });

            return newState;
        }

        case actionType.UPDATE_RULE_TEMPLATES:
        case actionType.CREATE_RULE_TEMPLATE: 
            const newRuleTemplate = action.ruleTemplate;

            newState.byId[newRuleTemplate.rtid] = newRuleTemplate;
            newState.relationByRules[newRuleTemplate.rtid] = newRuleTemplate.rules;
            
            newState.allIds.push(newRuleTemplate.rtid);

            return newState;             
        
        
        case actionType.ADD_RULE_TO_RULE_TEMPLATE: {
            newState.byId[action.selectedRTID].rules.push(action.newRuID);
            newState.relationByRules[action.selectedRTID] = newState.byId[action.selectedRTID].rules;

            return newState;
        }


        default: {
            return state;
        }
    }
}