import 
    React, 
    { 
        // useState, 
        useEffect 
    } 
from 'react';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';

import { message } from 'antd';
import { EVENT_COLOR } from "../../../../../constants";
import {
    Marker,
    InfoWindow,
    OverlayView,
} from '@react-google-maps/api';

// Redux Actions
import { 
    set_map_control,
    add_enabled_event_pop_up,
    clear_enabled_event_pop_ups,
    remove_enabled_event_pop_up,
} from '../../../services/redux/actions/mapControl';
import {
    set_selected_evid,
    clear_selected_evid,
} from '../../../services/redux/actions/events';

import "../gmaps.css";

const EventMarkers = (props) => {
    const { mapRef } = props;

    const events = useSelector(state => state.v2.events);
    const vehicles = useSelector(state => state.v2.vehicles);
    const mapControl = useSelector(state => state.v2.mapControl);

    const dispatch = useDispatch();

    // Initial mount of component
    useEffect(() => {
        dispatch(clear_selected_evid());
        dispatch(clear_enabled_event_pop_ups());
    }, 
        [dispatch]
    )

    // Pan to selected event
    useEffect(() => {
        let zoom, location;

        if (events.selectedEvID
            && vehicles.selectedId
            && events.byId[vehicles.selectedId][events.selectedEvID]
            && events.byId[vehicles.selectedId][events.selectedEvID].location
            && mapControl.mapControl === 1) 
        {
            const selectedEvent = events.byId[vehicles.selectedId][events.selectedEvID];

            // console.log("Panning to selected event:", selectedEvent.location);

            if (selectedEvent.location.lat && selectedEvent.location.lon) {
                zoom = 20;

                location = {
                    lat: parseFloat(selectedEvent.location.lat),
                    lng: parseFloat(selectedEvent.location.lon),
                }

                if (mapRef) {
                    mapRef.setZoom(zoom);
                    mapRef.panTo(location);

                    dispatch(set_map_control(0));
                }
            }
            else {
                message.error("No location found for this event");

                dispatch(set_map_control(0));
            }
        }
    })
    
    const renderEventMarker = (event) => {
        if (event.location) {
            const markerPos = {
                lat: parseFloat(event.location.lat),
                lng: parseFloat(event.location.lon)
            }

            return (
                <Marker
                    key = {event.evid}
                    icon = {{
                        path: window.google && window.google.maps && window.google.maps.SymbolPath.CIRCLE,
                        scale: 5,
                        strokeColor: EVENT_COLOR[event.eventType],
                        fillOpacity: 1,
                    }}
                    position = {markerPos}
                    onClick = {() => {
                        // dispatch(clear_enabled_event_pop_ups());
                        dispatch(set_selected_evid(event.evid));
                        dispatch(add_enabled_event_pop_up(event.evid));

                        dispatch(set_map_control(1));
                    }}
                >
                    {
                        mapControl.uiControl.showInfoWindowEvents.includes(event.evid) ?
                            <InfoWindow
                                zIndex = {1000}
                                options = {{ 
                                    disableAutoPan: true,
                                }}
                                mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                position = {markerPos}
                                onCloseClick = {
                                    () => {
                                        dispatch(clear_selected_evid());
                                        dispatch(remove_enabled_event_pop_up(event.evid));
                                    }
                                }
                            >
                                <div 
                                    style = {{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div style = {{ fontWeight: "bold" }}>
                                        {event.eventName}
                                    </div>
                                </div>
                            </InfoWindow> :
                            null
                    }
                </Marker>
            )
        }
    }

    return (
        vehicles.selectedId
        && events.byId[vehicles.selectedId] ?
            Object.values(events.byId[vehicles.selectedId]).map((currEvent) => renderEventMarker(currEvent)) :
            null
    )
}

export default EventMarkers