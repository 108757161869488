import * as API from "../../api";
import * as actionType from "../action-types/geofences";

import { 
  goBackToPrev, 
  // moveToPage 
} from '../../../navigation/navigationService';

import { message } from "antd";
import { 
  reassign_geofence_templates,
  update_geofence_template_geofences, 
} from './geofenceTemplate';
// import { goBackToPrev } from "../../../../navigation/navigationService";
import {
  setLoadingSubmit,
  unsetLoadingSubmit
} from './style';

export const get_geofences_data = (geofences) => {
  // console.log("Get Geofence Data:", geofences);

  return {
    type: actionType.GET_GEOFENCES,
    geofences: geofences
  }
}

export const create_geofence_with_api = ((uid, geofence, gtidList) => (dispatch, getState) => {
  // console.log("Creating Geofence!");

  dispatch(setLoadingSubmit());

  API.createGeofence(uid, geofence, gtidList)
  .then(data => {
    // console.log("Create Geofence Data: ", data);

    if (data.err) {
      message.error(data.err);
    }
    else {
      message.success("Successfully Added Geofence");

      dispatch(create_geofence_success(data.geoid, geofence));
      dispatch(update_geofence_template_geofences(gtidList, data.geoid));

      dispatch(goBackToPrev());
    }

    dispatch(unsetLoadingSubmit());
  })
  .finally(() => {
    dispatch(unsetLoadingSubmit());
  })
})

// Exported to GeofenceList.js for the export button
export const create_geofence_success = (geoid, geofence) => {
  return {
    type: actionType.CREATE_GEOFENCE,
    geoid: geoid,
    geofence: geofence,
  }
}

export const edit_geofence = ((uid, editedGeofence, gtidList) => dispatch => {
  // console.log("Editing Geofence!");

  return (
    API.editGeofence(editedGeofence)
    .then((data) => {
      // console.log("Edit Geofence Data:", data);

      if (data.err || data.errorMessage) {
        message.error(data.err || data.errorMessage);
      }
      else {
        message.success("Successfully Edited Geofence");
        
        // dispatch(moveToPage(`/Geofence`));
  
        dispatch(edit_geofence_success(data.geofence));
        dispatch(reassign_geofence_templates(uid, editedGeofence.geoid, gtidList));
      }

      return data.status
    })
  );
})

const edit_geofence_success = (geofence) => {
  return {
    type: actionType.EDIT_GEOFENCE,
    geofence: geofence,
  }
}

export const archive_geofence = (uid, geoid, callback = () => { }) => (dispatch, getState) => {
  const { v2 } = getState();

  const {
    configs,
    geofences
  } = v2;

  // console.log("geofences.selectedId", geofences.selectedId)

  let affectedConfig = Object.keys(configs.byId).filter((cf) => configs.byId[cf].geofences.includes(geoid))
  let configSet = affectedConfig.map(cf => configs.byId[cf])

  geofences.selectedId === geoid && dispatch(clear_selected_geofence_geoid())
  // console.log("state", getState())

  API.archiveGeofence(uid, geoid, configSet)
    .then(data => {

      if (data && data.hasOwnProperty('errorMessage')) {
        callback('Failure')
      }
      else {
        dispatch(archive_geofence_success({
          geoid: data.geoid
        }))

        callback('Success')
      }
    })
}

export const archive_geofence_success = (geo) => {
  return {
    type: actionType.ARCHIVE_GEOFENCE,
    geoid: geo.geoid
  }
}

export const set_selected_geofence_geoid = (geoid) => {
  return {
    type: actionType.SET_SELECTED_GEOFENCE_GEOID,
    geoid: geoid
  }
}

export const clear_selected_geofence_geoid = () => {
  return {
    type: actionType.CLEAR_SELECTED_GEOFENCE_GEOID,
  };
}

export const delete_geofence_request = (geoid) => (dispatch) => {

  dispatch(setLoadingSubmit());

  API
  .archiveGeofence(geoid)
  .then((data) => {

    if(data.status === 200) {

      message.success("Successfully Delete Geofence");

      dispatch(delete_geofence_success(data.affected_geoid, data.affected_gtids))

    } else {
      message.error(data.err);
    }

  })
  .finally(() => {
    dispatch(unsetLoadingSubmit());
  })
}

export const delete_geofence_success = (geoid, gtids) => {
  return {
    type: actionType.DELETE_GEOFENCE,
    geoid, 
    gtids
  };
}