import React from "react";
import { connect } from "react-redux";

import { TreeSelect } from "antd";

// Redux Actions
import { set_map_control } from "../../services/redux/actions/mapControl";
import { clear_selected_device } from "../../services/redux/actions/devices";
import { 
    set_selected_geofence_geoid,
    clear_selected_geofence_geoid,
} from "../../services/redux/actions/geofences";

const { TreeNode } = TreeSelect;

class MapSearchBar extends React.Component {
    // Just to trigger rerender
    state = {
        dataSourceObj: {},
        geofenceVehicleTally: {},
    }

    seperatorKey = " - ";

    setUpDataSource = (searchText = "") => {
        const { 
            geofences,
            geofenceTemplate, 
        } = this.props;

        // console.log("Set Up Data Source Search Text:", searchText);

        let newDataSourceObj = {};

        const gtidList = Object.keys(geofenceTemplate.byId).sort((a, b) => {
            const vA = geofenceTemplate.byId[a].templateName.toLowerCase();
            const vB = geofenceTemplate.byId[b].templateName.toLowerCase();
            
            if (vA === "ungroup") {
                return -1;
            }
            else if (vB === "ungroup") {
                return 1;
            }
            else {
                if (vA < vB) return -1;
                if (vA > vB) return 1;
            }
      
            return 0;
        });

        gtidList.forEach((currGTID) => {
            const currGTGeofences = geofenceTemplate.byId[currGTID].geofences.filter((currGeoID) => Object.keys(geofences.byId).includes(currGeoID));

            if (currGTGeofences.length > 0) {
                // console.log("Current GT Geofences:", currGTGeofences);

                // Sort GeoID list first
                currGTGeofences.sort((a, b) => {
                    const vA = geofences.byId[a].geofenceName.toLowerCase();
                    const vB = geofences.byId[b].geofenceName.toLowerCase();
              
                    if (vA < vB) return -1;
                    if (vA > vB) return 1;
                    return 0;
                })

                // Set up empty array if new GTID
                if (!Object.keys(newDataSourceObj).includes(currGTID)) {
                    newDataSourceObj[currGTID] = [];
                }

                currGTGeofences.forEach((currGeoID) => {
                    const currGeofence = geofences.byId[currGeoID];
    
                    // console.log("Current Geofence Name:", currGeofence.geofenceName);
    
                    if (currGeofence.geofenceName.toLowerCase().includes(searchText.toLowerCase())) {
                        newDataSourceObj[currGTID].push(currGeoID);
                    }
                })
            }
        });

        this.setState({
            ...this.state,
            dataSourceObj: newDataSourceObj,
        },
            // () => console.log("New DataSource Object:", this.state.dataSourceObj)
        )
    }

    tallyNoOfVehiclesInGeofences = () => {
        let newTally = {};

        // Set up newTally to avoid any missing geofences
        Object.keys(this.props.geofences.byId).forEach((currGeofenceID) => { newTally[currGeofenceID] = 0 });

        Object.values(this.props.vehicles.byId)
        .filter((currVehicle) => 
            currVehicle 
            && currVehicle.geofenceProfile 
            && typeof currVehicle.geofenceProfile === "object" 
            && Object.keys(currVehicle.geofenceProfile).length
        ) // Make sure geofenceProfile exists and is an object and has keys
        .forEach((currVehicle) => {
            Object.keys(currVehicle.geofenceProfile).forEach((currGeofenceID) => {
                // Just in case the vehicle has a geofence that isn't listed in the geofence redux store, we add it to the tally list
                if (newTally[currGeofenceID] === null) {
                    newTally[currGeofenceID] = 0;
                }
                
                // Make sure vehicle is in the geofence
                // If vehicle is in geofence, currVehicle.geofenceProfile[currGeofenceID] = 1
                if (currVehicle.geofenceProfile[currGeofenceID]) {
                    newTally[currGeofenceID] = Number(newTally[currGeofenceID]) + 1; // Increase tally
                }
            })
        })

        // console.log("newTally:", newTally);

        this.setState({ geofenceVehicleTally: newTally });
    }

    componentDidMount = () => {
        if (Object.keys(this.props.geofences.byId).length > 0 && Object.keys(this.props.geofenceTemplate.byId).length > 0) {
            this.setUpDataSource();
        }
    }

    componentDidUpdate = (prevProps) => {
        const {
            vehicles,
            geofences,
            geofenceTemplate,
        } = this.props;

        if (
            (prevProps.geofences !== geofences || prevProps.geofenceTemplate !== geofenceTemplate) 
            && Object.keys(geofences.byId).length
            && Object.keys(geofenceTemplate.byId).length
        ) {
            this.setUpDataSource();
        }
        
        // Update "no. of vehicles in geofence" count
        if (
            (prevProps.vehicles !== vehicles || prevProps.geofences !== geofences) 
            && Object.keys(vehicles.byId).length
            && Object.keys(geofences.byId).length
        ) {
            this.tallyNoOfVehiclesInGeofences();
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clear_selected_geofence_geoid());
    }

    render() {
        const { 
            dataSourceObj, 
            geofenceVehicleTally,
        } = this.state;

        const {
            geofences,
            geofenceTemplate,
        } = this.props;

        return (                
            <div>
                <TreeSelect
                    showSearch
                    filterTreeNode = {false} // Needs this or search works funny
                    placeholder = "Search Geofences"
                    treeDefaultExpandedKeys = {geofenceTemplate.byName["UNGROUP"] ? [geofenceTemplate.byName["UNGROUP"].gtid] : []}
                    dropdownStyle = {{
                        maxHeight: 400, 
                        overflow: 'auto', 
                    }}
                    onSearch = {(searchText) => {
                        // console.log("Search Text:", searchText);

                        this.setUpDataSource(searchText);
                    }}
                    onSelect = {(value) => {
                        // console.log("On Select Value:", value.split(this.seperatorKey)[1]);

                        this.props.dispatch(clear_selected_device());
                        this.props.dispatch(set_selected_geofence_geoid(value.split(this.seperatorKey)[1]));
                        this.props.dispatch(set_map_control(1));
                    }}
                    style = {{ width: "100%" }}
                >
                    {
                        Object.keys(dataSourceObj).map((currGTID) => {
                            // console.log("Current GTID 1:", currGTID, dataSourceObj[currGTID].length, dataSourceObj[currGTID]);
                            // console.log("Key 1:", currGTID);

                            if (dataSourceObj[currGTID].length > 0) {
                                return (
                                    <TreeNode 
                                        key = {`${currGTID}`} 
                                        value = {`${currGTID}`}
                                        selectable = {false}
                                        title = {
                                            geofenceTemplate.byId[currGTID] ? 
                                                `${geofenceTemplate.byId[currGTID].templateName} (${dataSourceObj[currGTID].map((currGeoID) => geofenceVehicleTally[currGeoID]).reduce((prevValue, currValue) => prevValue + currValue)})` : 
                                                ""
                                        }
                                    >
                                        {
                                            dataSourceObj[currGTID].map((currGeoID) => {
                                                // console.log("Current GTID 2:", currGTID);
                                                // console.log("Current GeoID:", currGeoID);
    
                                                return(
                                                    <TreeNode 
                                                        key = {currGTID + this.seperatorKey + currGeoID}
                                                        value = {currGTID + this.seperatorKey + currGeoID}
                                                        title = {`${geofences.byId[currGeoID].geofenceName} (${geofenceVehicleTally[currGeoID] ? geofenceVehicleTally[currGeoID] : 0})`}
                                                    />
                                                );
                                            })
                                        }
                                    </TreeNode>
                                );
                            }

                            return null;
                        })
                    }
                </TreeSelect>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    geofenceTemplate: state.v2.geofenceTemplate
});

export default connect(mapStateToProps)(MapSearchBar);