import 
  React, 
  {
    useState,
    useEffect,
  }
from "react";
import {
  useDispatch,
  useSelector
} from 'react-redux'

import moment from 'moment';
import * as API from '../../../services/api';
import Highlighter from 'react-highlight-words';
import UploadExcelButton from "../../../components/UploadExcelButton";

import { ROLE_TYPE } from '../../../../../constants';
import { PrimaryButton } from "../../../components/Button";
import { moveToPage } from "../../../navigation/navigationService";
import {
  VEHICLE_COLOR,
  TEMPLATE_URLS,
} from "../../../../../constants";
import {
  Input,
  Table,
  Button,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

// Redux Actions
import { add_new_vehicle_success } from "../../../services/redux/actions/vehicles";
import { get_vehicles_groups } from '../../../services/redux/actions/vehicleGroups';
// import { update_selected_user_hierarchy } from '../../../services/redux/actions/hierarchy';

//! Do not rearrange
const PERMIT_STATUSES = {
  "OK": "In Service",
  "APPROACHING": "Expiring Soon",
  "LATE": "Expired",
}

const VehiclePanel = () => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.v2.user);
  const devices = useSelector(state => state.v2.devices);
  const vehicles = useSelector(state => state.v2.vehicles);
  const ruleTemplate = useSelector(state => state.v2.ruleTemplate);
  const vehicleGroups = useSelector(state => state.v2.vehicleGroups);
  const geofenceTemplate = useSelector(state => state.v2.geofenceTemplate);

  const [ columns, setColumns ] = useState([]);
  const [ dataSource, setDataSource] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ searchText, setSearchText ] = useState('');

  const setSortedInfo = useState(null)[1];
  const setFilteredInfo = useState(null)[1];

  const onChangeTable = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setFilteredInfo(filters);
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0])
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  }; 
  
  // Component Did Mount
  // Get vehicles again just in case user comes to this page while searching for vehicles in another page
  useEffect(() => {
    if (user.uid && Object.keys(vehicleGroups.byVGID).length) {
      dispatch(get_vehicles_groups(Object.keys(vehicleGroups.byVGID), user.uid));
    }
  },
    [dispatch, user.uid, vehicleGroups.byVGID]
  )

  useEffect(() => {
    const getColumnSearchProps = (dataIndex) => {
      let searchInput;
  
      return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style = {{ padding: 8 }}>
            <Input
              ref = {node => {
                searchInput = node;
              }}
              value = {selectedKeys[0]}
              placeholder = {`Search ${dataIndex}`}
              onPressEnter = {() => handleSearch(selectedKeys, confirm)}
              onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              style = {{ 
                display: 'block',

                width: 188, 
                marginBottom: 8, 
              }}
            />
    
            <Button
              type = "primary"
              size = "small"
              icon = {<SearchOutlined />}
              onClick = {() => handleSearch(selectedKeys, confirm)}
              style = {{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
    
            <Button 
              size = "small" 
              onClick = {() => handleReset(clearFilters)}
              style = {{ width: 90 }} 
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.select());
          }
        },
        render: (text) => (
          <Highlighter
            autoEscape
            searchWords = {[searchText]}
            textToHighlight = {text ? text.toString() : "-"}
            highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
          />
        ),
      })
    };

    const setupDataSource = () => {
      const newDataSource =
        Object.values(vehicles.byId)
          .map(vehicle => {
            const device = vehicle.dvid && Object.values(devices.byId).find(device => device.dvid === vehicle.dvid);

            const vgStr =
              Object.values(vehicleGroups.byVGID)
                .filter((currVG) => currVG.groupName !== "ALL" && currVG.vehicles.includes(vehicle.vid)) // Make sure to remove default VG as we'll add it later
                .map((currVG) => currVG.groupName)
                .sort((a, b) => {
                  const A = a.toLowerCase();
                  const B = b.toLowerCase();
  
                  if (A < B) return -1;
                  if (A > B) return 1;
  
                  return 0;
                })
                .join(', ');

            const newPermitStatus = () => {
              if (isNaN(vehicle.permitDueDate)) return "-"

              const today = moment().startOf("day");

              // If current time is ahead of due date
              if (today.valueOf() >= vehicle.permitDueDate) {
                return PERMIT_STATUSES.LATE;
              }

              const threeMonthsFromToday = today.add(3, "months").valueOf();

              if (vehicle.permitDueDate >= threeMonthsFromToday) {
                return PERMIT_STATUSES.OK;
              }
              else {
                return PERMIT_STATUSES.APPROACHING;
              }
            }
  
            return {
              key: vehicle.vid,
              vehiclePlate: vehicle.vehiclePlate,
              vehicleDisplayName: vehicle.vehicleDisplayName,
              vehicleType: vehicle.vehicleType,
              vehicleModel: vehicle.vehicleModel,
              dvid: vehicle.dvid,
              simCard: device && device.simCard,
              transitStatus: vehicle.transitStatus,
              createdAt: vehicle.createdAt,
              updatedAt: vehicle.updatedAt,
              vehicleGroups: vgStr,
              engineServiceDueDate: vehicle.engineServiceDueDate,
              engineServiceNextWorkingHour: vehicle.engineServiceNextWorkingHour,
              engineServiceNextMileage: vehicle.engineServiceNextMileage,
              transmissionServiceDueDate: vehicle.transmissionServiceDueDate,
              transmissionServiceNextMileage: vehicle.transmissionServiceNextMileage,
              rearAxleServiceDueDate: vehicle.rearAxleServiceDueDate,
              rearAxleServiceNextMileage: vehicle.rearAxleServiceNextMileage,
              serviceDueDate: vehicle.serviceDueDate,
              serviceNextMileage: vehicle.serviceNextMileage,
              subsStartDate: vehicle.subsStartDate,
              subsEndDate: vehicle.subsEndDate,

              odometer: (vehicle.mileage && vehicle.mileage.toFixed(0)) || '-',

              permitDueDate: vehicle.permitDueDate,
              permitStatus: newPermitStatus(),
            }
          })
          .filter((currVehicle) => currVehicle.vehiclePlate)
          .sort((a, b) => {
            const A = a.createdAt;
            const B = b.createdAt;
  
            if (A < B) return -1;
            if (A > B) return 1;
  
            return 0;
          })
  
      const newColumns = [
        {
          title: "Vehicle Plate",
          dataIndex: "vehiclePlate",
          fixed: 'left',
          ...getColumnSearchProps("vehiclePlate"),
        },
        {
          title: "Vehicle Name",
          dataIndex: "vehicleDisplayName",
          ...getColumnSearchProps("vehicleDisplayName"),
        },
        {
          title: "Vehicle Groups",
          dataIndex: "vehicleGroups",
          ...getColumnSearchProps("vehicleGroups"),
        },
        {
          title: "Type",
          dataIndex: "vehicleType",
          ...getColumnSearchProps("vehicleType"),
        },
        {
          title: "Model",
          dataIndex: "vehicleModel",
          ...getColumnSearchProps("vehicleModel"),
        },
        {
          title: "Assigned Device",
          dataIndex: "dvid",
          isHidden: user && [ROLE_TYPE.FLEET_OWNER, ROLE_TYPE.FLEET_OPERATOR].includes(user.userRole), //this column will be filtered out if user is fleetOwner
          ...getColumnSearchProps("dvid"),
        },
        {
          title: "Device Sim Card",
          dataIndex: "simCard",
          key: "simCard",
          isHidden: user && [ROLE_TYPE.FLEET_OWNER, ROLE_TYPE.FLEET_OPERATOR].includes(user.userRole), //this column will be filtered out if user is fleetOwner
          ...getColumnSearchProps("simCard"),
        },
        {
          title: "Status",
          dataIndex: "transitStatus",
          key: "transitStatus",
          filters: [
            { text: "MOVING", value: "MOVING" },
            { text: "IDLING", value: "IDLING" },
            { text: "PARKING", value: "PARKING" },
            { text: "DISCONNECTED", value: "DISCONNECTED" }
          ],
          onFilter: (value, record) => record.transitStatus.includes(value),
          render: (status) => {
            if (!status || !Object.keys(VEHICLE_COLOR).includes(status)) {
              return <div style = {{ color: VEHICLE_COLOR["DISCONNECTED"] }}>{status}</div>
            }
  
            return <div style = {{ color: VEHICLE_COLOR[status] }}>{status}</div>
          },
        },
        {
          title: "Odometer",
          dataIndex: "odometer",
        },
        {
          title: "Registration Date",
          dataIndex: "createdAt",
          defaultSortOrder: "descend",
          sorter: (a, b) => a.createdAt - b.createdAt,
          render: text => moment(Number(text)).format("YYYY/MM/DD h:mm:ss A"),
        },
        {
          title: "Engine Service Next Odometer",
          dataIndex: "engineServiceNextMileage",
          render: text => (text && `${text} km`) || '-',
        },
        {
          title: "Engine Service Next Working Hour",
          dataIndex: "engineServiceNextMileage",
          render: text => (text && `${text} hrs`) || '-',
        },
        {
          title: "Engine Service Due Date",
          dataIndex: "engineServiceDueDate",
          sorter: (a, b) => a.engineServiceDueDate - b.engineServiceDueDate,
          render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
        },
        {
          title: "Transmission Service Next Odometer",
          dataIndex: "transmissionServiceNextMileage",
          render: text => (text && `${text} km`) || '-',
        },
        {
          title: "Transmission Service Due Date",
          dataIndex: "transmissionServiceDueDate",
          sorter: (a, b) => a.transmissionServiceDueDate - b.transmissionServiceDueDate,
          render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
        },
        {
          title: "Rear Axle Service Next Odometer",
          dataIndex: "rearAxleServiceNextMileage",
          render: text => (text && `${text} km`) || '-',
        },
        {
          title: "Rear Axle Service Due Date",
          dataIndex: "rearAxleServiceDueDate",
          sorter: (a, b) => a.rearAxleServiceDueDate - b.rearAxleServiceDueDate,
          render: text => (text && moment(Number(text)).format("YYYY/MM/DD")) || '-',
        },
        {
          title: "Service Next Odometer",
          dataIndex: "serviceNextMileage",
          render: text => (text && `${text} km`) || '-',
        },
        {
          title: "Service Due Date",
          dataIndex: "serviceDueDate",
          sorter: (a, b) => a.serviceDueDate - b.serviceDueDate,
          render: text => (text && moment(text).format("YYYY/MM/DD")) || '-',
        },
        {
          title: "Subscription Start Date",
          dataIndex: "subsStartDate",
          sorter: (a, b) => a.subsStartDate - b.subsStartDate,
          render: text => (text && moment(text).format("YYYY/MM/DD")) || '-',
        },
        {
          title: "Subscription End Date",
          dataIndex: "subsEndDate",
          sorter: (a, b) => a.subsEndDate - b.subsEndDate,
          render: text => (text && moment(text).format("YYYY/MM/DD")) || '-',
        },
        {
          title: "Permit Due Date",
          dataIndex: "permitDueDate",
          sorter: (a, b) => a.permitDueDate - b.permitDueDate,
          render: text => (text && moment(text).format("YYYY/MM/DD")) || '-',
        },
        {
          title: "Permit Status",
          dataIndex: "permitStatus",
          filters: Object.keys(PERMIT_STATUSES).map((currKey) => ({ text: PERMIT_STATUSES[currKey], value: PERMIT_STATUSES[currKey] })),
          onFilter: (value, record) => record.permitStatus.includes(value),
          render: (status) => {
            switch (status) {
              case PERMIT_STATUSES.OK: {
                return <div style = {{ color: "green" }}>{status}</div>
              }
              
              case PERMIT_STATUSES.APPROACHING: {
                return <div style = {{ color: "orange" }}>{status}</div>
              }
              
              case PERMIT_STATUSES.LATE: {
                return <div style = {{ color: "red" }}>{status}</div>
              }
        
              default:
                return "-"
            }
          },
        },
        {
          title: "Actions",
          fixed: 'right',
          render: vehicle => (
            <Button.Group>
              <button className = "transparent-button">
                <EditOutlined
                  onClick = {() => {
                    dispatch(moveToPage(`/Management/Vehicle/Edit/${vehicle.key}`, {
                      uid: user.uid,
                      vehicle: vehicles.byId[vehicle.key],
                    }))
                  }}
                />
              </button>
            </Button.Group>
          )
        }
      ];

      // console.log("newDataSource:", newDataSource);
  
      setColumns(newColumns);
      setDataSource(newDataSource);
      
      setIsLoading(false);
    }

    setupDataSource();
  }, 
    [dispatch, user, devices, vehicles, vehicleGroups.byVGID, searchText]
    // [dispatch, user, devices, vehicles, vehicleGroups, searchText]
  )

  return (
    <div style = {{ display: "flex", flex: 1 }}>
      <div
        style = {{
          width: "95%",
          margin: "0 5%"
        }}
      >
        {
          user.userRole !== ROLE_TYPE.FLEET_OWNER ?
            <div
              style = {{
                display: "flex",
                justifyContent: "flex-end",
    
                padding: 5,
                marginBottom: 10,
              }}
            >
              <UploadExcelButton
                title = 'Bulk create vehicles'
                buttonName = {`Bulk Create Vehicles`}
                loading = {isLoading}
                disabled = {!(Object.keys(devices.byId).length) || !(Object.keys(ruleTemplate.byId).length) || !(Object.keys(vehicleGroups.byVGID).length) || !(Object.keys(geofenceTemplate.byId).length)}
                templateUrl = {TEMPLATE_URLS.BULK_CREATE_VEHICLE_TEMPLATE}
                excelTemplateName = {`Bulk Create Vehicle Template`}
                dataColumns = {
                  {
                    vehicleDisplayName: {
                      label: 'Vehicle Display Name',
                      rule: (vehicleDisplayName, allVehicleDisplayName) => {
                        return true
                      },
                    },
                    vehiclePlate: {
                      label: 'Vehicle Plate',
                      rule: (vehiclePlate, allVehiclePlates) => {
                        return true
                      },
                    },
                    imei: {
                      optional: true,
                      label: 'Device Imei',
                      rule: (imei) => {
                        // console.log("Device Store:", devices);d

                        const device = Object.keys(devices.byId).find(dvid => dvid.includes(imei));
                        const availableDevices = Object.values(devices.byId).filter(d => d.assignedStatus !== 1).map(d => d.dvid.split('#')[1]);

                        // console.log("availableDevices:", availableDevices);
    
                        if (!device) throw new Error(`Invalid imei. Available devices are ${availableDevices}`)
                        if (device.assignedStatus === 1) throw new Error(`Device is already assigned.${availableDevices && availableDevices.length ? " Available devices are " + availableDevices : "There are currently no available devices"}`)
    
                        return true
                      },
                    }
                  }
                }
                uploadLoop = {async (excelData) => {
                  const imei = excelData.imei && excelData.imei.toString();
                  const dvid = Object.keys(devices.byId).find(dvid => dvid.includes(imei));
    
                  const vehiclePlate = excelData.vehiclePlate && excelData.vehiclePlate.toString();
                  const vehicleDisplayName = excelData.vehicleDisplayName && excelData.vehicleDisplayName.toString();
    
                  const vehicle = {
                    vehicleDisplayName,
                    vehiclePlate,
                    dvid: dvid || '-',
                  }
    
                  const vgids = Object.values(vehicleGroups.byVGID).filter(vg => vg.groupName === 'ALL').map(vg => vg.vgid);
                  const rtids = Object.values(ruleTemplate.byId).filter(rt => rt.templateName === 'DEFAULT').map(rt => rt.rtid);
                  const gtids = Object.values(geofenceTemplate.byId).filter(gt => gt.templateName === 'UNGROUP').map(gt => gt.gtid);
    
                  const targetUserDetails = {
                    uid: user.uid,
                    userRole: user.userRole,
                  }
    
                  // console.log("Bulk Creating Vehicle:", { device: dvid, imei, vehicle, targetUserDetails, vgids, gtids, rtids });
    
                  const data = await API.addVehicle(vehicle, targetUserDetails, vgids, gtids, rtids)
    
                  switch (data.status) {
                    case 200: {
                      dispatch(add_new_vehicle_success(data.vehicle));
                      // dispatch(updateVehicleGroupAfterAdd(data.vehicle.vid, vgids));
                      // dispatch(update_selected_user_hierarchy(targetUserDetails.uid, data.vehicle, vgids, gtids, rtids));
    
                      return {
                        uploadStatus: 'SUCCESS',
                        message: ''
                      }
                    }
                    default: {
                      return {
                        uploadStatus: 'FAILED',
                        message: data.message
                      }
                    }
                  }
                }}
              />
    
              <PrimaryButton
                icon = {<PlusOutlined />}
                onClick = {() => dispatch(moveToPage("/Management/Vehicle/Add", { uid: user.uid }))}
                style = {{ marginLeft: 10 }}
              >
                Add New Vehicle
              </PrimaryButton>
    
              <PrimaryButton
                icon = {<PlusOutlined />}
                onClick = {() => dispatch(moveToPage("/Management/VehicleGroup/Add"))}
                style = {{ marginLeft: 10 }}
              >
                Add New Vehicle Group
              </PrimaryButton>
            </div> :
            null
        }

        <div>
          <Table
            loading = {isLoading}
            dataSource = {dataSource}
            columns = {columns && columns.filter(c => !c.isHidden)}
            onChange = {onChangeTable}
            scroll = {{
              x: true,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default VehiclePanel;