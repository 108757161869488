import React from "react";
import { 
    Row, 
    Alert 
} from "antd";

/**
 * Indicates no data is available.
 */
export const EmptyIndicator = () => {
    return (
        <Row type = "flex" justify = "center" style = {{ margin: 10 }}>
            <Alert
                showIcon
                type = "info"
                message = "No data"
                // message = "Loading Data..."
                description = "Looks like we have nothing to show you here!"
                // description = "We're retrieving the relevant data right now."
            />
        </Row>
    );
};
