import moment from 'moment';
import { TABLE_TYPE_KEYS } from '../TableTypes';

const filterDataSource = (defaultFilter, state) => {
    let newDataSource = state.dataSource;

    // console.log("filterDataSource:", state.currTable);

    switch(state.currTable) {
        case TABLE_TYPE_KEYS.EVENT: {
            // console.log("Filtering event datasource:", state.selectedEventTypes);

            newDataSource = 
                newDataSource.filter((currData) => state.selectedEventTypes.includes(defaultFilter) || state.selectedEventTypes.includes(currData.eventType));
            
            break;
        }

        case TABLE_TYPE_KEYS.GEOFENCE: {
            // console.log("Filtering geofence datasource:", state.selectedGeofenceFilters, state.selectedDuration);

            newDataSource = 
                newDataSource
                .filter((currData) => state.selectedGeofenceFilters.includes(defaultFilter) || state.selectedGeofenceFilters.includes(currData.geoid)) // Filter by geofence
                .filter((currData) => (state.selectedDuration === defaultFilter || (!isNaN(currData.inAt) && !isNaN(currData.outAt) && (moment(currData.outAt).valueOf() - moment(currData.inAt).valueOf()) > (state.selectedDuration * 60000)))); // Filter by time
            
            break;
        }

        case TABLE_TYPE_KEYS.IDLING: {
            // console.log("Filtering event datasource:", state.selectedEventTypes);

            newDataSource = 
                newDataSource.filter((currData) => (currData.transitStatus === "IDLING")); //&& (currData.duration >= 1800000)
            
            break;
        }

        default:
            break;
    }

    return newDataSource;
}

export default filterDataSource;