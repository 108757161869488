import React, { useState } from "react";
import { useDispatch } from 'react-redux'

import "../../App.css";
import {
    IMAGE_RESOURCE,
    COMPANY
} from "../../constants";

import { config } from '../../config';

import { message } from "antd";

import { signInAccount } from "../../services/auth/authService";
import { sa_user_sign_in_action } from '../../services/redux/actions/user';
import { moveToSV } from '../../navigation/navigationService';

import SALoginForm from './components/SALoginForm';

const SALoginPage = (props) => {

    const dispatch = useDispatch()

    const [isLoginLoading, setIsLoginLoading] = useState(false)

    const loginPerformed = result => {

        if (result.type === "success") {
            dispatch(sa_user_sign_in_action(result.result.user.uid))
            dispatch(moveToSV())
        }
        else {
            let errorMessage;

            switch (result.error.code) {
                case "auth/invalid-email":
                    errorMessage = "Please enter a valid email address";
                    break;
                case "auth/user-disabled":
                    errorMessage =
                        "User has been disabled, please contact admin";
                    break;
                case "auth/user-not-found":
                    errorMessage = "User does not exist";
                    break;
                case "auth/wrong-password":
                    errorMessage = "Entered a wrong password, please try again";
                    break;
                default:
                    errorMessage = result.error.code;
                    break;
            }

            message.error(errorMessage);
        }
    };

    const loginPressed = (email, password) => {
        setIsLoginLoading(true)
        signInAccount(email, password, loginResult => {
            loginPerformed(loginResult)
            setIsLoginLoading(false)
        });
    };

    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                flex: 1,
                alignItems: "center"
            }}
        >
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    backgroundImage: `url(${IMAGE_RESOURCE.LOGINBACKGROUND})`,
                    backgroundSize: "cover"
                }}
            />

            <div
                style={{
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "35px 30px 15px 30px",
                            borderRadius: "17px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <img
                                src={IMAGE_RESOURCE.LOGO}
                                alt={COMPANY.NAME}
                                style={{ height: "80px" }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <div
                                style={{
                                    width: "250px",
                                    paddingTop: "30px"
                                }}
                            >
                                <SALoginForm
                                    {...props}
                                    onLoginPress={(email, password) =>
                                        loginPressed(email, password)
                                    }
                                    isLoginLoading={isLoginLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: 'black',
                    borderRadius: "17px",
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    color: 'white',
                    position: 'absolute',
                    bottom: '2vh',
                    right: '5vw',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    zIndex: 1
                }}
            >
                {config.version}
            </div>
        </div>
    );
}

export default SALoginPage