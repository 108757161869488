// import React from 'react';

import { message } from 'antd';
import sanityCheckNumber from '../../../util/SanityCheckNumber';
import { TABLE_TYPE_KEYS } from '../TableTypes';
import { parseDayNight } from '../../../util/time';


const returnNewDataSource = (currTable, vehicles, geofences, geofenceTemplates, litrePerKM, fuelUnitPrice, fuelPricePerLitre, apiResponse) => {
    let newDataSource = [];

    // Set Up
    switch (currTable) {
        case TABLE_TYPE_KEYS.MILEAGE: {
            newDataSource = apiResponse.mileageReport.map((d, index) => {

                let fd = {}

                Object.keys(d).map(dk => {
                    fd[dk] = d[dk] || 0
                    return null;
                })

                const totalLitresSpent =fd.totalMileage * litrePerKM;

                return ({
                    ...fd,
                    key: index,
                    vehicle: vehicles.byId[fd.vid].vehicleDisplayName,
                    totalLitresSpent,
                    totalFuelExpenses: totalLitresSpent * fuelPricePerLitre,
                });
            })

            break;
        }

        case TABLE_TYPE_KEYS.DAILY_MILEAGE: {
            newDataSource = apiResponse.mileageReports.map((d, index) => {
                const totalLitresSpent = d.totalMileage * litrePerKM;
                
                return ({
                    ...d,
                    key: index,
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    totalLitresSpent,
                    totalFuelExpenses: totalLitresSpent * fuelPricePerLitre,
                });
            })

            break;
        }

        case TABLE_TYPE_KEYS.TRIP: {
            newDataSource = apiResponse.tripReport.map((d, index) => (
                {
                    ...d,
                    key: index,
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    fuelUsed: sanityCheckNumber(d.fuelUsed) ? d.fuelUsed.toFixed(2) : "-",
                    topSpeed: sanityCheckNumber(d.topSpeed) ? d.topSpeed.toFixed(2) : "-",
                    totalMileage: sanityCheckNumber(d.totalDistance) ? d.totalDistance.toFixed(2) : "-",
                    totalMovingDuration: d.tripStatus === "PARKING" ? 0 : d.totalMovingDuration,
                }
            ))

            break;
        }

        case TABLE_TYPE_KEYS.TRANSIT: {
            newDataSource = apiResponse.transitReport.map((d, index) => (
                {
                    ...d,
                    key: index,
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    totalMileage: sanityCheckNumber(d.totalMileage) ? d.totalMileage.toFixed(2) : "-",
                    fuelUsed: sanityCheckNumber(d.totalMovingFuelConsumption) ? d.totalMovingFuelConsumption.toFixed(2) : "-",
                }
            ))

            break;
        }

        case TABLE_TYPE_KEYS.DETAIL: {

            newDataSource = apiResponse.detailReport.map((d, index) => {
                const currVehicle = d.dvid && Object.values(vehicles.byId).find((currVehicle) => currVehicle.dvid === d.dvid);

                return (
                    {
                        ...d,
                        key: index,
                        deviceIMEI: d.dvid.split("#")[1],
                        gpsStatus: d.gpsStatus ? "ON" : "OFF",
                        engineStatus: d.engineStatus ? "ON" : "OFF",
                        fuel: sanityCheckNumber(d.fuel) ? d.fuel.toFixed(2) : "-",
                        speed: sanityCheckNumber(d.speed) ? d.speed.toFixed(2) : "-",
                        heading: !(d.heading === null || isNaN(Number(d.heading))) ? d.heading : "-",
                        vehicle: currVehicle && currVehicle.vehicleDisplayName ? currVehicle.vehicleDisplayName : "-",
                        geofences: d.geofences && d.geofences.length > 0 ? d.geofences : [] 
                    }
                );
            })

            break;
        }

        case TABLE_TYPE_KEYS.DISCONNECT: {
            newDataSource = apiResponse.disconnectReport.map((d ,index) => ({
                ...d,
                key: index,
                deviceIMEI: d.dvid.split('#')[1],
                vehicle: d.vehiclePlate,
                endTime: d.endTime || 'ONGOING'
            }))
            
            break;
        }

        case TABLE_TYPE_KEYS.GEOFENCE: {
            newDataSource = apiResponse.geofenceReport.map((d, index) => {
                let geofenceTemplatesString = "";

                Object.values(geofenceTemplates.byId)
                .filter((currGT) => currGT.geofences.includes(d.geoid))
                .sort((a, b) => {
                    const A = a.templateName.toLowerCase();
                    const B = b.templateName.toLowerCase();

                    if (B === "ungroup" || A < B) return -1;
                    if (A === "ungroup" || A > B) return 1;
                    return 0;
                })
                .forEach((currGT) => {
                    !geofenceTemplatesString ? geofenceTemplatesString += currGT.templateName : geofenceTemplatesString += `, ${currGT.templateName}`;
                })

                return ({
                    ...d,
                    key: index,
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    geofenceTemplates: geofenceTemplatesString,
                    geofenceName: geofences.byId[d.geoid].geofenceName,
                })
            })

            break;
        }

        case TABLE_TYPE_KEYS.EVENT: {
            newDataSource = apiResponse.eventReport.map((d, index) => (
                {
                    ...d,
                    key: index,
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                }
            ))

            break;
        }

        case TABLE_TYPE_KEYS.REFUEL_REPORT: {
            const refuels = [];
            
            apiResponse.fuelAnalyses.forEach(d => {
                d.refuels.forEach(refuel => {
                    const vehicleRefuels = {
                        vid: d.vid,
                        fuelTankNumber: d.fuelTankNumber,
                        
                        ...refuel,
                    }

                    refuels.push(vehicleRefuels);
                })
            })

            newDataSource = refuels.map((d, index) => {
                const currVehicle = vehicles.byId[d.vid];

                let key = 'fuel';
                if (d.fuelTankNumber === 1) {
                    key = `fuel`;
                } 
                else if (d.fuelTankNumber > 1) {
                    key = `fuel${d.fuelTankNumber - 1}`;
                }
                
                const fuelTank = (currVehicle && currVehicle.fuelProfile[key]) || {};
                const thisFuelTankCapacity = fuelTank.fuelCapacity || 0;

                const {
                    endLog,
                    startLog,
                } = d;

                const deviceFuelUse = (endLog && startLog && (endLog.fuel - startLog.fuel) )|| 0;
                const deviceFuelUseLitre = deviceFuelUse / 100 * thisFuelTankCapacity;

                return {
                    key: index,
                    index: index,
                    vehicle: currVehicle.vehicleDisplayName,
                    fuelTankNumber: d.fuelTankNumber,
                    fuelCapacity: thisFuelTankCapacity,

                    endTime: endLog && endLog.deviceTime,
                    startTime: startLog && startLog.deviceTime,

                    endFuel: endLog && endLog.fuel / 100 * thisFuelTankCapacity,
                    startFuel: startLog && startLog.fuel / 100 * thisFuelTankCapacity,

                    location: startLog && startLog.location, // Cannot name location as address

                    deviceFuelUse: deviceFuelUseLitre,
                    deviceTotalCost: deviceFuelUseLitre * fuelUnitPrice,
                }
            })

            break;
        }

        case TABLE_TYPE_KEYS.FUEL_ANALYSIS: {
            const MS_IN_HOUR = 1000 * 60 * 60;

            newDataSource = apiResponse.fuelAnalyses.map((d, index) => {
                const currVehicle = vehicles.byId[d.vid];

                let key = 'fuel';
                if (d.fuelTankNumber === 1) {
                    key = `fuel`;
                } 
                else if (d.fuelTankNumber > 1) {
                    key = `fuel${d.fuelTankNumber - 1}`;
                }

                const fuelTank = (currVehicle && currVehicle.fuelProfile[key]) || {};
                const thisFuelTankCapacity = fuelTank.fuelCapacity || 0;

                const {
                    totalIdlingDuration,
                    totalMovingDistance,
                    totalParkingDuration,
                    totalIdlingFuelConsumption,
                    totalMovingFuelConsumption,
                    totalParkingFuelConsumption,
                } = d.fuelConsumption;

                const totalMovingFuelConsumptioninLitre = thisFuelTankCapacity && totalMovingFuelConsumption && -(thisFuelTankCapacity * totalMovingFuelConsumption);
                const movingFuelConsumptionRateInLitre = totalMovingFuelConsumptioninLitre / totalMovingDistance;
                const movingFuelEfficiencyInLitre = totalMovingDistance / totalMovingFuelConsumptioninLitre;

                const totalParkingFuelConsumptioninLitre = thisFuelTankCapacity && totalParkingFuelConsumption && -(thisFuelTankCapacity * totalParkingFuelConsumption);
                const parkingFuelConsumptionRateInLitrePerHour = (totalParkingFuelConsumptioninLitre / totalParkingDuration) * MS_IN_HOUR;
                const parkingFuelEffiencyInLitre = (totalParkingDuration / totalParkingFuelConsumptioninLitre);

                const totalIdlingFuelConsumptioninLitre = thisFuelTankCapacity && totalIdlingFuelConsumption && -(thisFuelTankCapacity * totalIdlingFuelConsumption);
                const idlingFuelConsumptionRateInLitrePerHour = (totalIdlingFuelConsumptioninLitre / totalIdlingDuration) * MS_IN_HOUR;
                const idlingFuelEffiencyInLitre = (totalIdlingDuration / totalIdlingFuelConsumptioninLitre);

                const totalFuelConsumptionInLitre = totalMovingFuelConsumptioninLitre + totalParkingFuelConsumptioninLitre + totalIdlingFuelConsumptioninLitre;
                const totalFuelConsumptionRate = totalFuelConsumptionInLitre / totalMovingDistance;
                const totalFuelConsumptionEfficiency = totalMovingDistance / totalFuelConsumptionInLitre;

                return {
                    key: d.vid,
                    index: index,
                    ...fuelTank,
                    fuelTankNumber: d.fuelTankNumber,
                    vehicle: currVehicle && currVehicle.vehicleDisplayName,
                    ...d.fuelConsumption,

                    movingFuelEfficiencyInLitre,
                    movingFuelConsumptionRateInLitre,
                    totalMovingFuelConsumptioninLitre,

                    parkingFuelEffiencyInLitre,
                    totalParkingFuelConsumptioninLitre,
                    parkingFuelConsumptionRateInLitrePerHour,

                    idlingFuelEffiencyInLitre,
                    totalIdlingFuelConsumptioninLitre,
                    idlingFuelConsumptionRateInLitrePerHour,

                    totalFuelConsumptionRate,
                    totalFuelConsumptionInLitre,
                    totalFuelConsumptionEfficiency,
                }
            })

            break;
        }

        case TABLE_TYPE_KEYS.DAILY_FUEL_ANALYSIS: {
            const MS_IN_HOUR = 1000 * 60 * 60;

            newDataSource = apiResponse.fuelAnalyses.map((d, index) => {
                const currVehicle = vehicles.byId[d.vid];

                let key = 'fuel';
                if (d.fuelTankNumber === 1) {
                    key = `fuel`;
                } 
                else if (d.fuelTankNumber > 1) {
                    key = `fuel${d.fuelTankNumber - 1}`;
                }

                const fuelTank = (currVehicle && currVehicle.fuelProfile[key]) || {}
                const thisFuelTankCapacity = fuelTank.fuelCapacity || 0

                const {
                    totalIdlingDuration,
                    totalMovingDistance,
                    totalParkingDuration,
                    totalIdlingFuelConsumption,
                    totalMovingFuelConsumption,
                    totalParkingFuelConsumption,
                } = d.fuelConsumption;

                const totalMovingFuelConsumptioninLitre = thisFuelTankCapacity && totalMovingFuelConsumption && -(thisFuelTankCapacity * totalMovingFuelConsumption);
                const movingFuelConsumptionRateInLitre = totalMovingFuelConsumptioninLitre / totalMovingDistance;
                const movingFuelEfficiencyInLitre = totalMovingDistance / totalMovingFuelConsumptioninLitre;

                const totalParkingFuelConsumptioninLitre = thisFuelTankCapacity && totalParkingFuelConsumption && -(thisFuelTankCapacity * totalParkingFuelConsumption);
                const parkingFuelConsumptionRateInLitrePerHour = (totalParkingFuelConsumptioninLitre / totalParkingDuration) * MS_IN_HOUR;
                const parkingFuelEffiencyInLitre = (totalParkingDuration / totalParkingFuelConsumptioninLitre);

                const totalIdlingFuelConsumptioninLitre = thisFuelTankCapacity && totalIdlingFuelConsumption && -(thisFuelTankCapacity * totalIdlingFuelConsumption);
                const idlingFuelConsumptionRateInLitrePerHour = (totalIdlingFuelConsumptioninLitre / totalIdlingDuration) * MS_IN_HOUR;
                const idlingFuelEffiencyInLitre = (totalIdlingDuration / totalIdlingFuelConsumptioninLitre);

                const totalFuelConsumptionInLitre = totalMovingFuelConsumptioninLitre + totalParkingFuelConsumptioninLitre + totalIdlingFuelConsumptioninLitre;
                const totalFuelConsumptionRate = totalFuelConsumptionInLitre / totalMovingDistance;
                const totalFuelConsumptionEfficiency = totalMovingDistance / totalFuelConsumptionInLitre;

                return {
                    key: index,
                    ...d,
                    ...fuelTank,
                    fuelTankNumber: d.fuelTankNumber,
                    vehicle: currVehicle && currVehicle.vehicleDisplayName,
                    ...d.fuelConsumption,
                    totalMovingFuelConsumptioninLitre,
                    movingFuelConsumptionRateInLitre,
                    movingFuelEfficiencyInLitre,

                    totalParkingFuelConsumptioninLitre,
                    parkingFuelConsumptionRateInLitrePerHour,
                    parkingFuelEffiencyInLitre,

                    totalIdlingFuelConsumptioninLitre,
                    idlingFuelConsumptionRateInLitrePerHour,
                    idlingFuelEffiencyInLitre,

                    totalFuelConsumptionInLitre,
                    totalFuelConsumptionRate,
                    totalFuelConsumptionEfficiency,
                }
            })

            break;
        }

        case TABLE_TYPE_KEYS.IDLING: {
            newDataSource = apiResponse.transitReport.map((d, index) => {
                let fd = {}

                Object.keys(d).map(dk => {
                    fd[dk] = d[dk] || 0
                    return null;
                })

                const totalLitresSpent =fd.totalMileage * litrePerKM;

                return ({
                    ...d,
                    key: index,
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    dayNight: parseDayNight(d.startTime),
                    totalMileage: sanityCheckNumber(d.totalMileage) ? d.totalMileage.toFixed(2) : "-",
                    fuelUsed: sanityCheckNumber(d.totalMovingFuelConsumption) ? d.totalMovingFuelConsumption.toFixed(2) : "-",
                    totalLitresSpent,
                    totalFuelExpenses: totalLitresSpent * fuelPricePerLitre,

                })
            })

            break;
        }
        
        case TABLE_TYPE_KEYS.OVERSPEEDING: {
            newDataSource = apiResponse.overspeedingReport.map((d, index) => (
                {
                    ...d,
                    key: index,
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                }
            ))

            break;
        }

        

        default: {
            newDataSource = newDataSource.map((d, index) => ({
                ...d,
                key: index,
                vehicle: vehicles.byId[d.vid].vehicleDisplayName,
            }))

            break;
        }
    }

    // Sort Data Source
    newDataSource = newDataSource.sort((a, b) => {
        const vA = a.vehicle.toLowerCase();
        const vB = b.vehicle.toLowerCase();

        if (vA < vB) return -1;
        if (vA > vB) return 1;
        return 0;
    })

    if (!newDataSource.length) {
        message.info("No reports found!");
    }

    // console.log("newDataSource:", newDataSource);

    return newDataSource;
}

export default returnNewDataSource;