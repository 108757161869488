import * as actionType from "../action-types/ticketStates";

const defaultState = {
    byTicketId: {},
};

export const ticketStates = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actionType.GET_TICKET_STATES: {
            action.ticketStates.map(ticketState => {
                newState.byTicketId[ticketState.ticketId] = ticketState;
                return null
            })

            return newState;
        }

        case actionType.RESOLVE_TICKET_STATES: {
            action.ticketIds.map(ticketId=>{
                delete newState.byTicketId[ticketId]
                return null
            })

            return newState;
        }

        default: {
            return state;
        }
    }
}