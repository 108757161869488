import * as ActionTypes from "../action-types/tripRecordControl";

import { message } from "antd";

export const enableTripRecord = () => {
    return {
        type: ActionTypes.ENABLE_TRIP_RECORD,
    };
}

export const disableTripRecord = () => {
    return {
        type: ActionTypes.DISABLE_TRIP_RECORD,
    };
}

export const enableTransitRecord = () => {
    return {
        type: ActionTypes.ENABLE_TRANSIT_RECORD,
    };
}

export const disableTransitRecord = () => {
    return {
        type: ActionTypes.DISABLE_TRANSIT_RECORD,
    };
}

export const storeTripTimelineData = (data) => {
    if (Object.keys(data).length < 1) {
        message.info("No trips found within this time period");
    }

    return {
        type: ActionTypes.STORE_TRIP_TIMELINE_DATA,
        data: data,
    };
}

export const clearTripTimelineData = () => {
    return {
        type: ActionTypes.CLEAR_TRIP_TIMELINE_DATA,
    };
}

export const storeTransitTimelineData = (data) => {
    return {
        type: ActionTypes.STORE_TRANSIT_TIMELINE_DATA,
        data: data,
    };
}

export const clearTransitTimelineData = () => {
    return {
        type: ActionTypes.CLEAR_TRANSIT_TIMELINE_DATA,
    };
}

export const storeSearchDetails = (selectedDVID, selectedVID, startTime, endTime) => {
    return {
        type: ActionTypes.STORE_SEARCH_DETAILS,
        selectedDVID: selectedDVID,
        selectedVID: selectedVID,
        startTime: startTime,
        endTime: endTime,
    };
}

export const clearSearchDetails = () => {
    return {
        type: ActionTypes.CLEAR_SEARCH_DETAILS,
    };
}

export const setSelectedTripDetails = (selectedStartTime, selectedEndTime) => {
    return {
        type: ActionTypes.SET_SELECTED_TRIP_DETAILS,
        selectedStartTime: selectedStartTime,
        selectedEndTime: selectedEndTime,
    };
}

export const clearSelectedTripDetails = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_TRIP_DETAILS,
    };
}