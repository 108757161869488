import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
} from "antd";

// Redux Actions
import { add_rule_template } from "../../../services/redux/actions/ruleTemplate";

const FormItem = Form.Item;

class AddRule extends Component {

    state = {
        ruleTemplateName: "",
    }

    submitForm = () => {
        // console.log('submit form:', this.props.user.uid, this.state.ruleTemplateName);

        this.props.dispatch(add_rule_template(this.props.user.uid, this.state.ruleTemplateName));
    }

    render() {
        // console.log(this.props.style.isLoadingSubmit);

        return (
            <div className = "page-container">
                <Page title = "Add New Rule Template">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Rule Template Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Rule Template Name"
                                    value = {this.state.ruleTemplateName}
                                    onChange = {e => this.setState({ ruleTemplateName: e.target.value })}
                                />
                            </FormItem>

                            <div
                                style = {{
                                    padding: 5,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 10
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    onClick = {this.submitForm}
                                    loading = {this.props.style.isLoadingSubmit}
                                    disabled = {!this.state.ruleTemplateName || this.state.ruleTemplateName.length === 0}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
});

const ConnectedAddRule = connect(mapStateToProps)(AddRule);
export default showSecondarySidebar(false)(ConnectedAddRule)
