import React from "react";
import { connect } from "react-redux";

import Page from "../../components/Page";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { Radio } from "antd";
import { ROLE_TYPE } from '../../../../constants';
import { moveToPage } from '../../navigation/navigationService';

// Panels
import DevicePanel from "./DevicePanel";
import VehiclePanel from "./VehiclePanel";
import RuleEnginePanel from "./RuleEnginePanel";
import NotificationPanel from "./NotificationPanel";

const PANELS = {
    Vehicle: {
        label: 'Vehicles',
        value: 'Vehicle',
        component: <VehiclePanel />,
    },
    Device: {
        label: 'Devices',
        value: 'Device',
        component: <DevicePanel />,
        hiddenFrom: [ROLE_TYPE.FLEET_OWNER, ROLE_TYPE.FLEET_OPERATOR]
    },
    Rule: {
        label: 'Rule Engine',
        value: 'Rule',
        component: <RuleEnginePanel />,
    },
    Notification: {
        label: 'Notification',
        value: 'Notification',
        component: <NotificationPanel />,
    },
}

class ManagementPage extends React.Component {

    onChangeRadio = e => {
        this.props.dispatch(moveToPage(`/Management/${e.target.value}`))
    }


    render() {

        /**Default tab would be vehicle */
        let tab = this.props.router.location.pathname.split('/').pop()
        if (!PANELS[tab]) {
            tab = PANELS.Vehicle.value
        }

        return (
            <div className="page-container">
                <Page title="Management">
                    <Radio.Group value={tab} buttonStyle="solid" onChange={this.onChangeRadio}>
                        {
                            Object.values(PANELS)
                                .filter(panel => {
                                    if (!this.props.user.userRole) return false
                                    if (!panel.hiddenFrom) return true
                                    return !panel.hiddenFrom.includes(this.props.user.userRole)
                                })
                                .map(panel =>
                                    <Radio.Button key={panel.value} value={panel.value}>
                                        {panel.label}
                                    </Radio.Button>
                                )
                        }
                    </Radio.Group>

                    <div style={{ marginTop: 30 }}>
                        {
                            PANELS[tab] && PANELS[tab].component
                        }
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    Vehicle: state.v2.Vehicle,
    managementControl: state.v2.managementControl,
});

const ConnectedManagementPage = connect(mapStateToProps)(ManagementPage);
export default showSecondarySidebar(false)(ConnectedManagementPage);