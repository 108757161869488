import React, { Component } from 'react'
import ReactExport from "react-data-export";

import { PrimaryButton } from "../Button";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportExcelButton extends Component {
    render() {
        const {
            onClick,
            filename,
            disabled,
            sheetRow,
            sheetData,
            sheetName,
        } = this.props;

        // console.log("Sheet Data:", sheetData);

        return (
            <ExcelFile
                filename = {filename}
                element = {
                    <PrimaryButton
                        disabled = {disabled}
                        onClick = {() => onClick && onClick()}
                        style = {{ marginLeft: "15px" }}
                    >
                        Export
                    </PrimaryButton>
                }
            >
                <ExcelSheet
                    name = {sheetName}
                    data = {sheetData}
                >
                    {
                        sheetRow.map((col, i) =>
                            <ExcelColumn
                                key = {i}
                                label = {col.label}
                                value = {val => col.formatter ? col.formatter(val) : val}
                            />
                        )
                    }
                </ExcelSheet>
            </ExcelFile>
        )
    }
}
