import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import NEWGoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../constants";
import {
    Marker,
    Circle,
    GoogleMap,
    OverlayView,
    DrawingManager,
} from '@react-google-maps/api';

// Redux Actions
import {
    set_map_control,
    set_new_geofence_from_map
} from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const GoogleMapsComponentAddPOI = (props) => {
    const dispatch = useDispatch()

    // Redux Stores
    // Have to put here because thisGeofence state defaults as mapControl.geofence
    const mapControl = useSelector(state => state.v2.mapControl);
    const geofenceTemplates = useSelector(state => state.v2.geofenceTemplate);

    // State
    const [mapRef, setMapRef] = useState(null);
    const [drawingManagerRef, setDrawingManagerRef] = useState(null);
    const [thisGeofence, setThisGeofence] = useState(mapControl.geofence);

    const returnLatestGeofenceTemplateColorByGTIDs = (gtidList) => {
        let latestTemplate = {};

        gtidList.forEach((currGTID) => {
            if (!latestTemplate.createdAt || geofenceTemplates.byId[currGTID].createdAt > latestTemplate.createdAt) {
                latestTemplate = geofenceTemplates.byId[currGTID];
            }
        })

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    const getMarker = (colorHexCode) => {
        return require(`../../../../img/POI Markers 2/${(colorHexCode || DEFAULT_GEOFENCE_HEXCODE).replace("#", "").toUpperCase()}.svg`)
    }

    /**
     * Deep Cloning useEffect()
     */
    const useDeepEffect = (fn, deps) => {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);
      
        useEffect(() => {
          const isSame = prevDeps.current.every((obj, index) =>
            JSON.parse(JSON.stringify(obj)) === JSON.parse(JSON.stringify(deps[index]))
          );
      
          if (isFirst.current || !isSame) {
            fn();
          }
      
          isFirst.current = false;
          prevDeps.current = deps;
        }, [fn, deps]);
    }

    const getMapCenterAndZoom = () => {
        if (mapRef) {
            return {
                center: mapRef.getCenter(),
                zoom: mapRef.getZoom()
            }
        }

        return {
            center: mapControl.location,
            zoom: mapControl.zoom
        }
    }
    
    const putMarkerComplete = marker => {
        const newMarker = {
            ...mapControl.geofence,
            coordinates: [{
                lat: Number(marker.position.lat()),
                lng: Number(marker.position.lng()),
            }]
        }

        dispatch(set_new_geofence_from_map(newMarker));

        // console.log(circle)

        // revert drawingMode to null, ie drag
        if (drawingManagerRef) {
            drawingManagerRef.setDrawingMode(null);
        }

        // setIsDrawing(true);
    }

    useDeepEffect(() => {
        setThisGeofence(mapControl.geofence);
    }, [mapControl.geofence])

    return (
        <GoogleMap
            id = 'example-map'
            zoom = {getMapCenterAndZoom().zoom}
            center = {getMapCenterAndZoom().center}
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragStart = {() => {
                dispatch(set_map_control(0));
            }}
            onDragEnd = {() => {
                if (mapRef) {
                    const zoom = mapRef.getZoom();
                    const lat = mapRef.getCenter().lat();
                    const lng = mapRef.getCenter().lng();

                    mapRef.setCenter({ lat, lng });
                    mapRef.setZoom(zoom);
                }
            }}
        >
            {/* POI */}
            {
                thisGeofence
                && thisGeofence.coordinates &&
                    <Marker
                        icon = {getMarker(returnLatestGeofenceTemplateColorByGTIDs(mapControl.selectedGeofenceTemplates))}
                        position = {thisGeofence.coordinates[0]}
                    >
                        <OverlayView
                            options = {{ disableAutoPan: true }}
                            position = {thisGeofence.coordinates[0]}
                            mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                            // getPixelPositionOffset = {(width, height) => ({
                            //     x: -(width / 2),
                            //     y: -height - 3,
                            // })}
                        >
                            <div
                                style = {{
                                    background: 'white',
                                    border: '1px solid #ccc',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 8,
                                    paddingBottom: 1,
                                }}
                            >
                                <h3>{thisGeofence.geofenceName}</h3>

                                {thisGeofence.geofenceComment && <div>{thisGeofence.geofenceComment}</div>}
                            </div>
                        </OverlayView>
                    </Marker>
            }

            {
                thisGeofence
                && thisGeofence.coordinates &&
                    <Circle
                        center = {thisGeofence.coordinates[0]}
                        radius = {thisGeofence.geofenceRadius}
                        options = {{
                            // strokeColor: "#ff0000",
                            strokeColor: returnLatestGeofenceTemplateColorByGTIDs(mapControl.selectedGeofenceTemplates)
                        }}
                    />
            }

            {
                <DrawingManager
                    drawingMode = {window.google.maps.drawing.OverlayType.MARKER}
                    options = {{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                window.google.maps.drawing.OverlayType.MARKER,
                            ],
                        },
                        markerOptions: {
                            visible: false,
                        },
                    }}
                    onLoad = {ref => setDrawingManagerRef(ref)}
                    onMarkerComplete = {marker => putMarkerComplete(marker)}
                />
            }
        </GoogleMap>
    )
}

const GoogleMapsComponentWithLoadScript = (props) => NEWGoogleMapsLoadScript(GoogleMapsComponentAddPOI, props);

export default GoogleMapsComponentWithLoadScript;