import React from "react";
import { Menu } from "antd";

const SidebarItems = props => {
  
  return (
    <Menu
      theme={props.theme}
      selectedKeys={[props.selectedKeys]}
      onClick={props.onClick}
      mode='inline'
      style={{
        height: "calc(90vh - 68px)", // Minus the logo height
        overflowY: "auto",
        background: "transparent"
      }}
    >
      {props.items.map(item => 
        <Menu.Item key={item.key} {...props}>
          {item.icon}
          <span>{item.label}</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

SidebarItems.defaultProps = {
  items: [],
  onClick: () => {},
  theme: "dark",
  collapsed: "true"
};

export default SidebarItems;
