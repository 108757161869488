import {
    wsURL,
    closeWS,
    wsClient,
    wsReconnectInterval,
} from './config';

var wsc = null;

const getClient = () => wsc;
const closeWSC = () => closeWS(wsc);
const wsState = () => wsc.readyState;
const reinitialiseWS = () => { wsc = new WebSocket(wsURL) };

const wsContainer = (
    cb = () => { },
    resubscribe = () => {}
) => {
    if (!wsc || ![WebSocket.OPEN, WebSocket.CONNECTING].includes(wsc.readyState)) {
        reinitialiseWS();
    }

    wsClient(
        wsc,
        (data) => {
            // console.log("wsClient:", data);

            if (data === 'reconnect') {
                // console.log(`restarting`);

                resubscribe();

                // console.log("current session state", wsc.readyState);
                
                setTimeout(wsContainer(cb, resubscribe), wsReconnectInterval);
            }
        },
        (err, data) => {
            // console.log("[WebSoc]", data);

            cb(err, data);
        }
    )
}

const subscribeWS = (ids, uid) => {
    const subscribe_msg = {
        action: "subscribeTo",
        data: {
            ids: ids,
            uid: uid
        }
    }

    // console.log("websocket state", client.readyState)

    if (wsc.readyState === 0) {
        const retry = setInterval(
            () => {
                if (wsc.readyState !== 0) {
                    // console.log("Sending subscribe message in timeout",subscribe_msg);

                    wsc.send(JSON.stringify(subscribe_msg));
                    clearInterval(retry);
                }
            },
            3000
        )
    } 
    else {
        wsc.send(JSON.stringify(subscribe_msg))
    }
}

export {
    wsState,
    closeWS,
    closeWSC,
    getClient,
    subscribeWS,
    wsContainer,
    reinitialiseWS,
}