import {
    Button, DatePicker, Input, message, Modal, Radio, Select, Table
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { EVENT_TYPES } from '../../../../constants';
import { PrimaryButton } from '../../components/Button';
import ExportExcelButton from '../../components/ExportExcelButton';
import showSecondarySidebar from '../../components/Layout/helpers/showSecondarySidebar';
import Page from '../../components/Page';
import TableColumnSearchProps from '../../components/TableColumnSearchProps';
// APIs
import {
    reportDailyFuelAnalysis, 
    reportDetail, 
    reportEvent, 
    reportFuelAnalysis, 
    reportGeofence,
    reportRefuel, 
    reportDisconnect,
    reportOverspeeding,
    // new
    getReportTransitTimeframeSync,
    getReportTripTimeframeSync,
    getReportMileageTimeframeSync,
    getReportDailyMileageTimeframeSync,
} from '../../services/api';
import {
    parseDate, parseDuration,
    parseDurationByHours, parseTime
} from '../../util/time';
import AdvancedFilterModal from "./AdvancedFilterModal";
import AdvancedFilters from "./AdvancedFilters";
import "./index.css";
import returnFilteredDataSource from "./ReturnFilteredDataSource";
import returnNewDataSource from "./ReturnNewDataSource";
// Important Stuff
import SearchFields from "./SearchFields";
import {
    TABLE_TYPES,
    TABLE_TYPE_KEYS
} from "./TableTypes";

const defaultFilter = "All";

// Do not rearrange
const durationFormats = {
    hrsMinsSecs: "Hrs, Mins and Secs",
    decimals: "Decimals"
}

class VehicleReportPage extends React.Component {
    state = {
        isLoading: false,

        dataSource: [],
        filteredDataSource: [],

        // Search Stuff
        selectedVIDs: [],
        endTime: moment().add(1, 'days').startOf('day'),
        startTime: moment().startOf('day'),
        currTable: TABLE_TYPE_KEYS.TRIP,
        tempTable: TABLE_TYPE_KEYS.TRIP,
        fuelUnitPrice: 2, // Just for Fuel Analysis report
        litrePerKM: 0.093,
        fuelPricePerLitre: 2.05,

        // Advanced Filter Stuff
        selectedDuration: 1, // Default is > 1 mins
        selectedEventTypes: [defaultFilter],
        selectedGeofenceFilters: [defaultFilter],

        // Modal Stuff
        isExporting: false,
        isAdvancedFilterVisibleModal: false,
        selectedDurationFormat: Object.keys(durationFormats)[0],
    }

    // Immediately pass new selected IDs because there are different filters for DETAILS table
    setSelectedVIDs = (newSelectedVIDs) => {
        this.setState({
            ...this.state,
            selectedVIDs: newSelectedVIDs
        },
            // () => console.log("New Selected VIDs:", this.state.selectedVIDs)
        );
    }

    filterDataSource = () => {
        this.setState({
            filteredDataSource: returnFilteredDataSource(defaultFilter, this.state),
        })
    }

    setEventFilter = (newEventTypes) => {
        // console.log("setEventFilter:", newEventTypes);

        this.setState({
            selectedEventTypes: newEventTypes,
        },
            () => this.filterDataSource()
        );
    }

    setDurationFilter = (newDuration) => {
        // console.log("setDurationFilter:", newDuration);

        this.setState({
            selectedDuration: newDuration,
        },
            () => this.filterDataSource()
        );
    }

    setGeofenceFilter = (newGeofenceFilters) => {
        // console.log("setGeofenceFilter:", newGeofenceFilters);

        this.setState({
            selectedGeofenceFilters: newGeofenceFilters,
        },
            () => this.filterDataSource()
        );
    }

    returnGetReportAPI = async () => {
        const { selectedVIDs } = this.state;

        const properEndTime = this.state.endTime.valueOf();
        const properStartTime = this.state.startTime.valueOf();

        switch (this.state.currTable) {
            case TABLE_TYPE_KEYS.TRIP: return await getReportTripTimeframeSync(selectedVIDs, properStartTime, properEndTime);
            case TABLE_TYPE_KEYS.EVENT: return await reportEvent(selectedVIDs, properStartTime, properEndTime);
            case TABLE_TYPE_KEYS.MILEAGE: return await getReportMileageTimeframeSync(selectedVIDs, properStartTime, properEndTime);
            case TABLE_TYPE_KEYS.DAILY_MILEAGE: return await getReportDailyMileageTimeframeSync(selectedVIDs, properStartTime, properEndTime)
            case TABLE_TYPE_KEYS.TRANSIT: return await getReportTransitTimeframeSync(selectedVIDs, properStartTime, properEndTime);
            case TABLE_TYPE_KEYS.DETAIL: return await reportDetail(selectedVIDs.map((currVID) => this.props.vehicles.byId[currVID].dvid), properStartTime, properEndTime);
            case TABLE_TYPE_KEYS.DISCONNECT: return reportDisconnect(selectedVIDs, properStartTime, properEndTime)
            case TABLE_TYPE_KEYS.GEOFENCE: {
                return await reportGeofence(
                    selectedVIDs,
                    Object.values(this.props.geofences.byId)
                        .filter((currGeofence) => currGeofence.geofenceName)
                        .sort((a, b) => {
                            const gA = a.geofenceName.toLowerCase();
                            const gB = b.geofenceName.toLowerCase();

                            if (gA < gB) return -1;
                            if (gA > gB) return 1;
                            return 0;
                        })
                        .map((currGeofence) => currGeofence.geoid),
                    properStartTime,
                    properEndTime
                );
            }
            case TABLE_TYPE_KEYS.FUEL_ANALYSIS: return await reportFuelAnalysis(selectedVIDs, properStartTime, properEndTime)
            case TABLE_TYPE_KEYS.DAILY_FUEL_ANALYSIS: return await reportDailyFuelAnalysis(selectedVIDs, properStartTime, properEndTime)
            case TABLE_TYPE_KEYS.REFUEL_REPORT: return await reportRefuel(selectedVIDs, properStartTime, properEndTime)
            case TABLE_TYPE_KEYS.IDLING: return await getReportTransitTimeframeSync(selectedVIDs, properStartTime, properEndTime)
            case TABLE_TYPE_KEYS.OVERSPEEDING: {
                const ra = await reportDetail(selectedVIDs.map((currVID) => this.props.vehicles.byId[currVID].dvid), properStartTime, properEndTime);
                const rb = await reportEvent(selectedVIDs, properStartTime, properEndTime)
                
                const rules = Object.values(this.props.rules.byRuID)
                
                return await reportOverspeeding(selectedVIDs.map((currVID) =>(
                    {
                        vid: currVID,
                        dvid: this.props.vehicles.byId[currVID].dvid
                    }
                )), ra, rb, rules)
            }
            default: return null;
        }
    }

    submitForm = () => {
        if (this.state.startTime.valueOf() < this.state.endTime.valueOf()) {
            this.setState({
                isLoading: true,
                isAdvancedFilterVisibleModal: false,

                dataSource: [],
                filteredDataSource: [],
                currTable: this.state.tempTable, // Officially set currTable as selected table
            },
                () => {
                    this.returnGetReportAPI()
                    .then((data) => {
                        // console.log("Get Report Data:", this.state.currTable, data);

                        if (data.status !== 200) {
                            message.error(`Get report error ${(data.message || data.errorMessage)}`);
                        }
                        else {
                            message.success('Get report done');

                            const newDataSource = returnNewDataSource(
                                this.state.currTable,
                                this.props.vehicles,
                                this.props.geofences,
                                this.props.geofenceTemplates,
                                this.state.litrePerKM,
                                this.state.fuelUnitPrice,
                                this.state.fuelPricePerLitre,
                                data
                            );

                            this.setState({
                                dataSource: newDataSource,
                            },
                                () => this.filterDataSource()
                            )
                        }
                    })
                    .catch((err) => {
                        message.error(`Get report error ${err}`);
                    })
                    .finally(() => {
                        this.setState({ isLoading: false });
                    })
                }
            )
        }
        else {
            message.error("Start time must be before end time!");
        }
    }

    returnFormattedColumns = () => {
        const sortableKeys = [
            "fuel",
            "inAt",
            "outAt",
            "speed",
            "heading",
            "endTime",
            "topSpeed",
            "fuelUsed",
            "duration",
            "startTime",
            "createdAt",
            "deviceTime",
            "totalEvents",
            "totalMileage",
            "numberOfTrips",
            "totalEngineDuration",
            "totalMovingDuration",
            "totalIdlingDuration",
            "totalParkingDuration",
        ];

        const searchableKeys = [
            "vehicle",
            "deviceIMEI",
            "geofenceName",
            "geofenceTemplates",
            "geofences"
        ];

        const filterableKeys = [
            "eventType",
            "gpsStatus",
            "tripStatus",
            "engineStatus",
            "devicePackage",
            "transitStatus",
            "dayNight",
        ];

        const newColumns =
            TABLE_TYPES[this.state.currTable].columns
                .map((currColumn) => {
                    if (sortableKeys.includes(currColumn.key)) {
                        return {
                            ...currColumn,
                            sortDirections: ["ascend", "descend"],
                            sorter: (a, b) => {
                                let A = a[currColumn.key];
                                let B = b[currColumn.key];

                                // In case sorting numbers
                                if (!isNaN(Number(A)) && !isNaN(Number(B))) {
                                    A = parseFloat(A);
                                    B = parseFloat(B);
                                }

                                // console.log("Test:", A, B);

                                if (A === "-" || isNaN(A) || A < B) return -1;
                                if (B === "-" || isNaN(B) || A > B) return 1;
                                return 0;
                            }
                        }
                    }
                    else if (searchableKeys.includes(currColumn.key)) {
                        return {
                            ...currColumn,
                            ...TableColumnSearchProps(
                                currColumn.key,
                                this.state.searchText,
                                (newSearchText) => {
                                    this.setState({
                                        searchText: newSearchText,
                                    })
                                },
                                () => {
                                    this.setState({
                                        searchText: "",
                                    })
                                },
                            ),
                        }
                    }
                    else if (filterableKeys.includes(currColumn.key)) {
                        const returnFilterObjects = (stringArr) => {
                            return stringArr.map((currString) => ({
                                text: currString,
                                value: currString
                            }))
                        }

                        // Cases must include/match all filterable keys
                        const returnFilters = () => {
                            switch (currColumn.key) {
                                case "tripStatus": {
                                    return returnFilterObjects(["TRANSIT", "PARKING"]);
                                }

                                case "transitStatus": {
                                    return returnFilterObjects(["MOVING", "IDLING", "PARKING", "DISCONNECTED"]);
                                }

                                case "devicePackage": {
                                    return returnFilterObjects(["auth", "heartbeat", "latest", "gps#memory", "event#session#error", "event#session#close", "event#session#timeout"]);
                                }

                                case "gpsStatus": {
                                    return returnFilterObjects(["ON", "OFF"]);
                                }

                                case "engineStatus": {
                                    return returnFilterObjects(["ON", "OFF"]);
                                }

                                case "eventType": {
                                    return returnFilterObjects(Object.values(EVENT_TYPES).filter((type) => type !== "ALL"))
                                }

                                case "geofences": {
                                    return returnFilterObjects([ ...new Set(this.state.dataSource.map(ds => ds.geofences).reduce((a,b) => [...a,...b], []))].sort())
                                }

                                case "dayNight": {
                                    return returnFilterObjects(["Day", "Night"]);
                                }

                                default: {
                                    return [];
                                }
                            }
                        }

                        return {
                            ...currColumn,
                            filters: returnFilters(),
                            onFilter: (value, record) => record[currColumn.key] !== null && record[currColumn.key].includes(value),
                        }
                    }
                    else {
                        return currColumn;
                    }
                })

        // console.log("New Columns:", newColumns);

        return newColumns;
    }

    returnExportData = () => {
        const isDateArr = [
            // "createdAt",
        ];

        const isTimeArr = [
            "inAt",
            "outAt",
            "endTime",
            "startTime",
            "createdAt",
            "deviceTime",
        ];

        const isDurationArr = [
            "duration",
            "totalDuration",
            "totalEngineDuration",
            "totalMovingDuration",
            "totalIdlingDuration",
            "totalParkingDuration",
        ];

        const isAddressArr = [
            "location",
            "endLocation",
            "startLocation",
        ];

        // Add address columns into export columns if needed
        const returnFormattedExportColumns = () => {
            const returnNewColumn = (dataIndex) => {
                switch (dataIndex) {
                    case "location": {
                        return {
                            title: 'Address',
                            key: 'address',
                            dataIndex: 'address',
                        };
                    }

                    case "endLocation": {
                        return {
                            title: 'End Address',
                            key: 'endAddress',
                            dataIndex: 'endAddress',
                        };
                    }

                    case "startLocation": {
                        return {
                            title: 'Start Address',
                            key: 'startAddress',
                            dataIndex: 'startAddress',
                        };
                    }

                    default:
                        return null;
                }
            }

            let formattedColumns = [...TABLE_TYPES[this.state.currTable].columns];

            // console.log("Old Columns:", formattedColumns);

            // Replacing location columns in Event/ Detail report exported excel
            if (this.state.currTable !== TABLE_TYPE_KEYS.EVENT && this.state.currTable !== TABLE_TYPE_KEYS.DETAIL) {
                for (let i = 0; i < formattedColumns.length; i++) {
                    const col = formattedColumns[i];

                    if (isAddressArr.includes(col.dataIndex)) {
                        formattedColumns.splice(i, 0, returnNewColumn(col.dataIndex));

                        i += 1;
                    }
                }
            }

            // console.log("New Columns:", formattedColumns);

            return formattedColumns;
        }

        const returnFormattedExportData = (value, dataIndex) => {
            if (value[dataIndex] && value[dataIndex] !== "-") {
                if (isDateArr.includes(dataIndex)) {
                    return parseDate(value[dataIndex]);
                }
                if (isTimeArr.includes(dataIndex)) {
                    return parseTime(value[dataIndex]);
                }
                else if (isDurationArr.includes(dataIndex)) {
                    // return parseDuration(value[dataIndex]);

                    const durationFormatKeys = Object.keys(durationFormats);

                    switch (this.state.selectedDurationFormat) {
                        case durationFormatKeys[0]: return parseDuration(value[dataIndex]);
                        case durationFormatKeys[1]:
                        default:
                             return parseDurationByHours(value[dataIndex]);
                    }
                }
                else if (isAddressArr.includes(dataIndex)) {
                    return value[dataIndex] && value[dataIndex].lat && value[dataIndex].lon ? `${value[dataIndex].lat}, ${value[dataIndex].lon}` : "-";
                }
                else {
                    return value[dataIndex].toString();
                }
            }
            else {
                return "-";
            }
        }

        const newExportedData = returnFormattedExportColumns().map((col) => ({
            label: col.title,
            formatter: (value) => returnFormattedExportData(value, col.dataIndex),
        }))

        // console.log("New Exported Data:", newExportedData);

        return newExportedData;
    }

    render() {
        const {
            endTime,
            startTime,
            tempTable,
            currTable,
            selectedVIDs,
            filteredDataSource,
        } = this.state;

        const { style } = this.props;

        const formattedColumns = this.returnFormattedColumns();

        return (
            <div className = 'page-container'>
                <Page title = 'Vehicle Report'>
                    <div className = "formField" style = {{ marginBottom: 5 }}>
                        <div>
                            <div className = "formField">
                                <SearchFields
                                    defaultFilter = {defaultFilter}
                                    tempTable = {tempTable}
                                    selectedVIDs = {selectedVIDs}
                                    onVIDChange = {(newIDs) => this.setSelectedVIDs(newIDs)}
                                />

                                <div className = "formField">
                                    <span className = "formFieldLabel">Start Time: </span>

                                    <DatePicker
                                        showTime
                                        value = {startTime}
                                        onChange = {value => this.setState({ startTime: value })}
                                        style = {{ width: '177px' }}
                                    />
                                </div>

                                <div className = "formField">
                                    <span className = "formFieldLabel">End Time: </span>

                                    <DatePicker
                                        showTime
                                        value = {endTime}
                                        onChange = {value => this.setState({ endTime: value })}
                                        style = {{ width: '177px' }}
                                    />
                                </div>

                                <div className = "formField">
                                    <span className = "formFieldLabel">Report: </span>

                                    <Select
                                        placeholder = {"Select a Report"}
                                        value = {TABLE_TYPES[tempTable].title}
                                        onChange = {(newTable) => {
                                            // console.log({ tempTable, newTable });

                                            if (tempTable !== newTable) {
                                                this.setState({
                                                    tempTable: newTable,

                                                    // Advanced Filter Stuff
                                                    selectedDuration: 1, // Default is > 1 mins
                                                    selectedEventTypes: [defaultFilter],
                                                    selectedGeofenceFilters: [defaultFilter],
                                                });
                                            }
                                            else {
                                                this.setState({
                                                    selectedVIDs: [],
                                                });
                                            }
                                        }}
                                        style = {{ width: 200 }}
                                    >
                                        {
                                            Object.keys(TABLE_TYPES).map((currKey) =>
                                                <Select.Option key = {currKey} value = {currKey}>
                                                    {TABLE_TYPES[currKey].title}
                                                </Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>

                            {/* Only available if mileage report */}
                            {
                                tempTable === TABLE_TYPE_KEYS.IDLING || tempTable === TABLE_TYPE_KEYS.MILEAGE || tempTable === TABLE_TYPE_KEYS.DAILY_MILEAGE ?
                                    <div className = "formField" style = {{ marginTop: 5 }}>
                                        <div className = "formField">
                                            <span className = "formFieldLabelLeft">Estimated Litre per KM (RM): </span>

                                            <Input
                                                min = {0}
                                                type = {"number"}
                                                value = {this.state.litrePerKM}
                                                onChange = {(event) => {
                                                    const newLitrePerKM = event.target.value;

                                                    const newDataSource = this.state.dataSource.map((currData) => {
                                                        const totalLitresSpent = currData.totalMileage * newLitrePerKM;

                                                        return {
                                                            ...currData,
                                                            totalLitresSpent,
                                                            totalFuelExpenses: totalLitresSpent * this.state.fuelPricePerLitre,
                                                        }
                                                    })

                                                    this.setState({
                                                        ...this.state,
                                                        dataSource: newDataSource,
                                                        litrePerKM: newLitrePerKM,
                                                    },
                                                        () => this.filterDataSource()
                                                    )
                                                }}
                                                style = {{ width: 100 }}
                                            />
                                        </div>
                                        
                                        <div className = "formField">
                                            <span className = "formFieldLabel">Estimated Fuel Price per Litre (RM/L): </span>

                                            <Input
                                                min = {0}
                                                type = {"number"}
                                                value = {this.state.fuelPricePerLitre}
                                                onChange = {(event) => {
                                                    const newFuelPricePerLitre = event.target.value;

                                                    const newDataSource = this.state.dataSource.map((currData) => ({
                                                        ...currData,
                                                        totalFuelExpenses: currData.totalLitresSpent * newFuelPricePerLitre,
                                                    }))

                                                    this.setState({
                                                        ...this.state,
                                                        dataSource: newDataSource,
                                                        fuelPricePerLitre: newFuelPricePerLitre,
                                                    },
                                                        () => this.filterDataSource()
                                                    )
                                                }}
                                                style = {{ width: 100 }}
                                            />
                                        </div>
                                    </div> :
                                    null
                            }

                            {/* Only available if refuel report */}
                            {
                                tempTable === TABLE_TYPE_KEYS.REFUEL_REPORT ?
                                    <div className = "formField" style = {{ marginTop: 5 }}>
                                        <span className = "formFieldLabelLeft">Fuel Price (RM): </span>

                                        <Input
                                            min = {0}
                                            type = {"number"}
                                            value = {this.state.fuelUnitPrice}
                                            onChange = {(event) =>
                                                this.setState({
                                                    ...this.state,
                                                    fuelUnitPrice: event.target.value,
                                                })
                                            }
                                            style = {{ width: 100 }}
                                        />
                                    </div> :
                                    null
                            }
                        </div>

                        <div className = "formField">
                            <PrimaryButton
                                loading = {this.state.isLoading}
                                disabled = {(tempTable !== TABLE_TYPE_KEYS.GEOFENCE && !selectedVIDs.length) || (tempTable === TABLE_TYPE_KEYS.GEOFENCE && !selectedVIDs.length)}
                                onClick = {this.submitForm}
                                style = {{ marginLeft: '15px' }}
                            >
                                Submit
                            </PrimaryButton>

                            <Button
                                disabled = {!(filteredDataSource.length && tempTable === currTable)}
                                onClick = {() => 
                                    this.setState({ 
                                        isExporting: true,
                                        selectedDurationFormat: Object.keys(durationFormats)[0], // Reset selectedDurationFormat to default
                                    })
                                }
                                style = {{ marginLeft: '15px' }}
                            >
                                Export
                            </Button>
                        </div>
                    </div>

                    {
                        (currTable === TABLE_TYPE_KEYS.EVENT || currTable === TABLE_TYPE_KEYS.GEOFENCE) ?
                            <div
                                style = {{
                                    width: "100%",

                                    padding: 10,
                                    marginBottom: 5,
                                    backgroundColor: "white",
                                }}
                            >
                                <div style = {{ marginBottom: 10, fontWeight: "bold" }}>Advanced Filters</div>

                                {
                                    <AdvancedFilters
                                        tempTable = {tempTable}
                                        selectedDuration = {this.state.selectedDuration}
                                        selectedEventTypes = {this.state.selectedEventTypes}
                                        selectedGeofenceFilters = {this.state.selectedGeofenceFilters}
                                        onEventFilterChange = {(newEventFilter) => this.setEventFilter(newEventFilter)}
                                        onDurationFilterChange = {(newDurationFilter) => this.setDurationFilter(newDurationFilter)}
                                        onGeofenceFilterChange = {(newGeofenceFilter) => this.setGeofenceFilter(newGeofenceFilter)}
                                    />
                                }
                            </div> :
                            null
                    }

                    <Table
                        columns = {formattedColumns}
                        dataSource = {filteredDataSource}
                        pagination = {{ pageSize: 25 }}
                        scroll = {{
                            x: formattedColumns && formattedColumns.length * 150,
                        }}
                    />

                    <AdvancedFilterModal
                        isVisible = {this.state.isAdvancedFilterVisibleModal}
                        selectedVIDs = {selectedVIDs}
                        modalContents = {
                            <AdvancedFilters
                                isModal
                                tempTable = {tempTable}
                                selectedDuration = {this.state.selectedDuration}
                                selectedEventTypes = {this.state.selectedEventTypes}
                                selectedGeofenceFilters = {this.state.selectedGeofenceFilters}
                                onEventFilterChange = {(newEventFilter) => this.setEventFilter(newEventFilter)}
                                onDurationFilterChange = {(newDurationFilter) => this.setDurationFilter(newDurationFilter)}
                                onGeofenceFilterChange = {(newGeofenceFilter) => this.setGeofenceFilter(newGeofenceFilter)}
                            />
                        }
                        onAdvancedFilterModalClose = {() => {
                            this.setState({
                                ...this.state,
                                isAdvancedFilterVisibleModal: false,
                            })
                        }}
                        onAdvancedFilterModalSubmit = {this.submitForm}
                    />

                    {/* A modal for users to set which duration format they want */}
                    <Modal
                        title = {`Exporting ${TABLE_TYPES[this.state.currTable].title} Report`}
                        visible = {this.state.isExporting}
                        closable = {false}
                        footer = {[
                            <Button
                                key = "Cancel"
                                loading = {(style && style.isLoadingSubmit) || false}
                                onClick = {() => this.setState({ isExporting: false })}
                                style = {{ marginLeft: '15px' }}
                            >
                                Cancel
                            </Button>,
                            <ExportExcelButton
                                disabled = {!(filteredDataSource.length && tempTable === currTable)}
                                filename = {`Vehicle Report - ${currTable} - ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
                                sheetName = {`Vehicle Report`}
                                sheetRow = {this.returnExportData()}
                                sheetData = {filteredDataSource}
                                onClick = {() => this.setState({ isExporting: false })}
                            />
                        ]}
                    >
                        Select how you want to display your durations:
                        
                        <Radio.Group 
                            value = {this.state.selectedDurationFormat}
                            onChange = {(event) => {
                                this.setState({
                                    selectedDurationFormat: event.target.value,
                                })
                            }}
                        >
                            {
                                Object.keys(durationFormats).map((currKey) => 
                                    <Radio 
                                        key = {currKey} 
                                        value = {currKey}
                                    >
                                        {durationFormats[currKey]}
                                    </Radio>
                                )
                            }
                        </Radio.Group>
                    </Modal>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    geofenceTemplates: state.v2.geofenceTemplate,
    rules: state.v2.rules,
});

const ConnectedPage = connect(mapStateToProps)(VehicleReportPage);
export default showSecondarySidebar(false)(ConnectedPage);